import React, { useState, useEffect, useContext, useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";
import { getReportData, getReportDataSummary } from "../../../api/ApiPtlCalls";
import ColoursList from "../../ColoursList";
import ColouredTextBox from "../../ColouredTextBox";
import {
  Model01RptRefreshContext,
  Model01SimSettingsContext,
  Model01SimAilmentsContext,
} from "../Model01";
import {
  workOutBackGroundColor,
  workOutColor,
} from "../../../common/colour_selector";
import { numberWithCommas } from "../../../common/math_functions";
import styles from "../../../indexOrg.module.scss";
import DemoSetup from "./DemoSetup";

require("highcharts/modules/accessibility")(Highcharts);

const RptPtlUpload = ({ position, seriesHidden, setSeriesHidden }) => {
  const chartComponentRef = useRef(null);
  const [isRefreshNeeded, refreshNeeded] = useContext(Model01RptRefreshContext); // eslint-disable-line no-unused-vars
  const [data, writeSimSettings] = useContext(Model01SimSettingsContext); // eslint-disable-line no-unused-vars
  // eslint-disable-next-line no-unused-vars
  const [dsSimAilments, setDsSimAilments] = useContext(
    Model01SimAilmentsContext
  );
  const [dayData, setDayData] = useState({});
  const [summaryData, setSummaryData] = useState({});
  const [BoxColour, setBoxColour] = useState("lightgrey");
  const [BoxFontColour, setBoxFontColour] = useState("black");
  const [strAvgWaitNumber, setStrAvgWaitNumber] = useState(0);
  const [strPTLNumber, setStrPTLNumber] = useState(0);

  // eslint-disable-next-line no-unused-vars
  const setBoxes = (ailmentDetails) => {
    // Data retrieved too....
    setBoxColour("#ffff00");
    setBoxFontColour("#fa5403;");
  };
  var ailmentNumber = 0;

  useEffect(() => {
    const hideSeries = (stringArray) => {
      chartComponentRef &&
        chartComponentRef.current &&
        chartComponentRef.current.chart &&
        chartComponentRef.current.chart.series &&
        chartComponentRef.current.chart.series.forEach((element) => {
          if (stringArray.indexOf(element.name) > -1) {
            element.hide();
          } else {
            element.show();
          }
        });
    };
    seriesHidden &&
      seriesHidden.length !== undefined &&
      hideSeries(seriesHidden);
    setBoxColour(workOutBackGroundColor(dsSimAilments, seriesHidden));
    setBoxFontColour(workOutColor(dsSimAilments, seriesHidden));
  }, [dsSimAilments, seriesHidden]);

  // Get data dependant on day number
  useEffect(() => {
    // Report number 2 = ptl uploaded
    if (data.id !== undefined) {
      getReportData(
        data.id,
        2,
        0,
        (simData) => {
          // console.log("simData: ", simData);
          setDayData(simData);
        },
        (error) => {
          console.log("simData Error: ", error);
        }
      );
    }
  }, [data.id, isRefreshNeeded]);

  useEffect(() => {
    if (data.id !== undefined) {
      getReportDataSummary(
        data.id,
        2,
        (simData) => {
          // console.log("simData: ", simData);
          if (simData && simData.targets && simData.targets.length > 0) {
            let subData = simData.targets.filter(
              (e) => !(seriesHidden.indexOf(e.ailment) > -1)
            );
            //setStrAvgWaitNumber(123);
            //setStrPTLNumber(321);
            var total_N = 0;
            var total_N_Avg = 0;
            subData.forEach((e) => {
              total_N += e.patient_wait_count;
              total_N_Avg += e.patient_wait_average * e.patient_wait_count;
            });
            // Work out the week
            var result = (total_N_Avg / total_N / 7).toFixed(1);
            if (!isNaN(result)) {
              setStrAvgWaitNumber(result);
            } else {
              setStrAvgWaitNumber(0);
            }
            setStrPTLNumber(numberWithCommas(total_N));
          } else {
            setStrAvgWaitNumber(0);
            setStrPTLNumber(0);
          }

          getReportDataSummary(
            data.id,
            1,
            (simDataInner) => {
              // console.log("simDataInner: ", simDataInner);
              if (simDataInner.max_week_duration > simData.max_week_duration) {
                simData.max_week_duration = simDataInner.max_week_duration;
              }
              if (simDataInner.max_patient_count > simData.max_patient_count) {
                simData.max_patient_count = simDataInner.max_patient_count;
              }
              setSummaryData(simData);
            },
            (error) => {
              console.log("simDataSummary Error: ", error);
              setSummaryData(simData);
            }
          );
        },
        (error) => {
          console.log("simDataSummary Error: ", error);
        }
      );
    }
    // (e) => !(seriesHidden.indexOf(e.ailment) > -1);
  }, [data.id, isRefreshNeeded, seriesHidden]);

  // Work out distinct ailments
  // var ailments = [];
  // try {
  //   ailments = [...new Set(dayData.map((item) => item.ailment))].sort();
  // } catch (error) {}

  // Build a dataset for each of the ailments
  var myDataSeries =
    dsSimAilments &&
    dsSimAilments.length > 0 &&
    dayData &&
    dayData.length > 0 &&
    dsSimAilments
      .filter((r) => r.emergency === false)
      .map((ailment) => ({
        name: ailment.title,
        color: ailment.rpt_colour
          ? ailment.rpt_colour
          : ColoursList(ailmentNumber++),
        data: dayData
          .filter((data) => {
            // Older data does not have the ailment_id so use the title
            if (data.fk_sim_ailment_id)
              return data.fk_sim_ailment_id === ailment.id;
            else return data.ailment === ailment.title;
          })
          .map((data) => ({
            y: data.patient_count,
            x: data.current_week_duration,
          })),
        dataLabels: { enabled: false },
        pointWidth: 15,
      }));
  // .filter((data) => data.ailment === ailment.title)

  // Configure the chart - including adding the dataset
  const config = {
    title: {
      text: "",
    },
    // subtitle: {
    //   text: 'Source: <a href="https://staffingscience.com">Staffing Science</a>',
    // },
    credits: {
      enabled: false,
    },
    chart: {
      type: "column",
      style: {
        fontFamily: "Arial",
      },
    },
    yAxis: {
      title: { text: "Patient Count" },
      max: summaryData.max_patient_count,
    },
    xAxis: {
      start: 0,
      end: summaryData.max_week_duration,
      min: 0,
      max: summaryData.max_week_duration,
      plotLines: [
        {
          color: "black",
          dashStyle: "dot",
          width: 2,
          value: 18,
          label: {
            rotation: 0,
            y: 15,
            style: {
              fontStyle: "italic",
            },
            text: "18 wks",
          },
          zIndex: 3,
        },
        {
          color: "black",
          dashStyle: "dot",
          width: 2,
          value: 52,
          label: {
            rotation: 0,
            y: 15,
            style: {
              fontStyle: "italic",
            },
            text: "52 wks",
          },
          zIndex: 3,
        },
        {
          color: "black",
          dashStyle: "dot",
          width: 2,
          value: 78,
          label: {
            rotation: 0,
            y: 15,
            style: {
              fontStyle: "italic",
            },
            text: "78 wks",
          },
          zIndex: 3,
        },
        {
          color: "black",
          dashStyle: "dot",
          width: 2,
          value: 104,
          label: {
            rotation: 0,
            y: 15,
            style: {
              fontStyle: "italic",
            },
            text: "104 wks",
          },
          zIndex: 3,
        },
      ],
    },
    plotOptions: {
      column: {
        events: {
          legendItemClick: function (event) {
            var ailment_title = this.name;
            // var existing_state = this.visible; // eslint-disable-line no-unused-vars
            // var chart = this.chart; // eslint-disable-line no-unused-vars
            // var chart2 = event.target.chart; // eslint-disable-line no-unused-vars
            var workingArray = [];
            if (this.visible) {
              // Add to hide list
              workingArray = [...seriesHidden, ailment_title];
            } else {
              // Remove from hide list
              workingArray = seriesHidden.filter((e) => e !== ailment_title);
            }
            setSeriesHidden(workingArray);
          },
        },
      },
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
      footerFormat: "</table>",
      shared: true,
      useHTML: true,
    },
    series: myDataSeries,
  };

  return (
    <>
      {Object.keys(summaryData).length > 0 ? (
        <div
          className={`${styles.panel} ${
            position === "first" ? styles.first : styles.last
          }`}
        >
          <div className={styles.chartContainer}>
            <div style={{ fontWeight: "bold", height: "48px" }}>
              <span style={{ fontWeight: "bold" }}>
                {summaryData &&
                  summaryData.model_start_dtg &&
                  moment(
                    Date.parse(summaryData.model_start_dtg) - 86400000
                  ).format("DD-MM-YYYY")}
              </span>
            </div>
            <HighchartsReact
              highcharts={Highcharts}
              options={config}
              ref={chartComponentRef}
            />
          </div>
          <div className={styles.rowRpt} style={{ position: "relative" }}>
            <div style={{ marginRight: "25px" }}>
              <ColouredTextBox
                backgroundColor={BoxColour}
                color={BoxFontColour}
                number={strPTLNumber}
                text={"Total Patients"}
              />
            </div>
            <div style={{ marginRight: "25px" }}>
              <ColouredTextBox
                backgroundColor={BoxColour}
                color={BoxFontColour}
                number={strAvgWaitNumber}
                text={"Av. Wait Time (weeks)"}
                // unitText="weeks"
              />
            </div>
            <div
              style={{
                position: "absolute",
                top: 0,
                right: "calc(-60% - 10px)",
                marginLeft: "min(10px, 5%)",
              }}
            >
              <DemoSetup />
            </div>
          </div>
        </div>
      ) : (
        <p></p>
      )}
    </>
  );
};

export default RptPtlUpload;
