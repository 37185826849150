import React from "react";
import styles from "../indexOrg.module.scss";
const ColouredTextBox = ({
  backgroundColor,
  color,
  number,
  text,
  unitText,
  boarder,
  boarderColour,
}) => {
  var styleObj = {
    backgroundColor: `${backgroundColor}`,
  };
  var styleObjBorder = {
    backgroundColor: `${backgroundColor}`,
    border: "1px solid ",
    borderColor: `${boarderColour}`,
  };

  var styleText = {
    backgroundColor: `${backgroundColor}`,
    // fontSize: 10,
    color: `${color}`,
  };
  var styleNumber = {
    backgroundColor: `${backgroundColor}`,
    color: `${color}`,
    fontSize: 14,
    fontWeight: "bold",
  };
  return (
    <div
      className={styles.rptBoxBorder}
      style={boarder === "true" ? styleObjBorder : styleObj}
    >
      <span style={styleText}>
        {text}
        <span style={styleNumber}>{number}</span> {unitText}
      </span>
    </div>
  );
};

export default ColouredTextBox;
