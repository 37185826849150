import React from "react";
import moment from "moment";

// Nice tutorial from:
// https://www.youtube.com/watch?v=u-CCnDayNJw
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Button } from "react-bootstrap";
import styles from "../../scss/style.module.scss";

import TextField from "../TextField";
import RadioGroupCtrl from "../RadioGroupCtrl";
import { postAnalysis } from "../../api/ApiConsultCalls";

const NewConsult = ({ formComplete }) => {
  const validate = Yup.object({
    title: Yup.string()
      .max(50, "Must be 50 chars or less")
      .required("Required"),
    start_date: Yup.string().required("Required"),
    end_date: Yup.string().required("Required"),
    // automated_activity: Yup.boolean(),
    // automated_vetting: Yup.boolean(),
    automated_import: Yup.number()
      .integer()
      .min(0, "Must be positive")
      .max(2, "Must be 2 or less")
      .required(),
  });

  // Close this form, cancel has been pressed
  const CancelForm = () => {
    formComplete(false);
  };

  // Submit has been pressed so administer it.
  const SaveFormData = async (values) => {
    values.start_time = moment(values.start_time, "HH:mm:ss").format(
      "HH:mm:ss"
    );
    values.end_time = moment(values.end_time, "HH:mm:ss").format("HH:mm:ss");
    // alert("made it here with: " + JSON.stringify(values, null, 2));
    var newDetails = {
      enabled: true,
      locked: false,
    };
    var myDataCheck = { ...newDetails, ...values };
    await postAnalysis(
      myDataCheck,
      (result) => {
        // Success
        // Grab the new id.
        formComplete(true, result.id);
      },
      (error) => {
        // Failure
        alert("Error: " + JSON.stringify(error, null, 2));
      }
    );
  };

  return (
    <>
      <Formik
        initialValues={{
          title: "",
          start_date: "",
          end_date: "",
          automated_import: 0,
        }}
        validationSchema={validate}
        onSubmit={async (values) => {
          SaveFormData(values);
        }}
      >
        {(formik) => (
          <>
            <h3 className="font-weight-bold-display-4">Add New Analysis</h3>
            <Form>
              <div>
                <div className={styles.form_halves}>
                  <div className={styles.left_control_box}>
                    <h5>1. Choose how to enter activity data</h5>
                    <RadioGroupCtrl
                      label=""
                      name="automated_import"
                      options={[
                        { value: 0, label: "Manual upload" },
                        { value: 1, label: "Automated Reporting import" },
                        {
                          value: 2,
                          label: "Automated Reporting & Vetting import",
                        },
                      ]}
                    />
                  </div>
                  <div className={styles.right_control}></div>
                </div>
                <div className={styles.form_halves}>
                  <div className={styles.left_control_box}>
                    <h5>
                      2. Create name of analysis and start/end dates of analysis
                      period
                    </h5>
                    <TextField
                      label="Analysis Name"
                      name="title"
                      type="text"
                      maxLength="256"
                    />
                    <TextField
                      label="Start Date"
                      name="start_date"
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <TextField
                      label="End Date"
                      name="end_date"
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                  <div className={styles.right_control}></div>
                </div>
              </div>

              <div>
                <Button className="btn " type="submit">
                  Save
                </Button>{" "}
                <Button className="btn " type="button" onClick={CancelForm}>
                  Cancel
                </Button>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};

export default NewConsult;
