import React from "react";
import TimeSegmentGrid from "./TimeSegmentGrid";

const TabTime = ({ tabNo }) => {
  const ctlValid = (controlNumber, value) => {
    // ctlValidation[controlNumber] = value;
    // setCtlValidation(ctlValidation);
    // validateTab(
    //   tabNo,
    //   ctlValidation.every((element) => element === true)
    // );
  };

  return (
    <>
      <div style={{ marginBottom: "35px" }}>
        <h3>Time Segments</h3>
        <TimeSegmentGrid ctrlValid={ctlValid} ctrlNumber="0" value={tabNo} />
      </div>
    </>
  );
};

export default TabTime;
