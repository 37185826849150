import * as React from "react";
import { useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useFormikContext } from "formik";

// Template for a custom control - for a Formik control
// For more complex control remember use to use ErrorControl
export default function DropdownMonth(label, fieldName, myDisabled, props) {
  const formikProps = useFormikContext();
  const [fName] = useState(label.fieldName);

  // ***** Manually update the formik field *****
  const handleStepChange = (event) => {
    var result = 0;
    try {
      result = parseInt(event.target.value);
    } catch (error) {}
    formikProps.setFieldValue(fName, result);
    formikProps.setFieldTouched(fName, true);
  };

  return (
    <div>
      <FormControl variant="standard" sx={{ minWidth: 120 }}>
        <InputLabel id="demo-simple-select-standard-label">
          {label.label}
        </InputLabel>
        <React.Fragment>
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            value={formikProps.values["theatre_down_time_month"]}
            onChange={handleStepChange}
            disabled={label.disabled}
          >
            <MenuItem value={0}>
              <em>None</em>
            </MenuItem>
            <MenuItem value={1}>January</MenuItem>
            <MenuItem value={2}>February</MenuItem>
            <MenuItem value={3}>March</MenuItem>
            <MenuItem value={4}>April</MenuItem>
            <MenuItem value={5}>May</MenuItem>
            <MenuItem value={6}>June</MenuItem>
            <MenuItem value={7}>July</MenuItem>
            <MenuItem value={8}>August</MenuItem>
            <MenuItem value={9}>September</MenuItem>
            <MenuItem value={10}>October</MenuItem>
            <MenuItem value={11}>November</MenuItem>
            <MenuItem value={12}>December</MenuItem>
          </Select>
        </React.Fragment>
      </FormControl>
    </div>
  );
}
