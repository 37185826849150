import React, { useState, useEffect, useContext } from "react";
import { ConsultSettingsContext } from "../../Consult";
import moment from "moment";

const ActivityDate = () => {
  const [data] = useContext(ConsultSettingsContext);
  const [showActivityDates, setShowActivityDates] = useState(false);

  useEffect(() => {
    setShowActivityDates(data.activity_start && data.activity_end);
  }, [data]);

  return (
    <React.Fragment>
      {showActivityDates ? (
        <React.Fragment>
          Activity data range <br />
          start:
          {moment(data.activity_start).format("DD/MM/YYYY")} {"  "} <br />
          end: {moment(data.activity_end).format("DD/MM/YYYY")}
        </React.Fragment>
      ) : (
        <React.Fragment></React.Fragment>
      )}
    </React.Fragment>
  );
};

export default ActivityDate;
