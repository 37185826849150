import React, { useEffect, useState, useContext } from "react";
import {
  ConsultSettingsContext,
  ConsultRefreshChildrenContext,
} from "../../Consult";
import Table from "react-bootstrap/Table";
import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import styles from "../../../../scss/style.module.scss";
import table from "../../../../table.module.scss";
import { getJobPlanActivities } from "../../../../api/ApiConsultCalls";
import { fixNumberString } from "../../../../common/math_functions";

const JobPlanActivitiesTable = () => {
  const [data] = useContext(ConsultSettingsContext);
  const refreshChild = useContext(ConsultRefreshChildrenContext);
  const [dsJPInclude, setDsJPInclude] = useState({});
  const [totalPAs, setTotalPAs] = useState(0);
  const [showGrandTotal, setShowGrandTotal] = useState(false);

  useEffect(() => {
    // Query the db and update the local table

    if (data.id !== undefined) {
      getJobPlanActivities(
        data.id,
        (optionData) => {
          setDsJPInclude(optionData);

          let tot = 0;

          // Iterate through the filtered data - Group specific
          optionData &&
            optionData.length > 0 &&
            optionData.forEach((sim) => {
              tot += sim.pa_standard + sim.pa_premium;
            });

          setTotalPAs(tot);
        },
        (error) => {}
      );
    }
  }, [data.id, refreshChild]);

  let lastTitle = "";
  let previousTitle = "";
  const preparedData =
    dsJPInclude && dsJPInclude.length > 0
      ? dsJPInclude.map((sim, index) => {
          const currentTitle = `${sim.category} ${sim.benchmark}`;
          const isNewTitle = lastTitle !== currentTitle;
          const isShowTotal = lastTitle !== "" && isNewTitle;
          if (isNewTitle) {
            // If we get more than one we need a greand total
            if (lastTitle !== "" && !showGrandTotal) setShowGrandTotal(true);
            // Catch the previous title - used for the total
            previousTitle = lastTitle;
            // Update the running var
            lastTitle = currentTitle;
          }
          return {
            ...sim,
            isNewTitle,
            isShowTotal,
            previousTitle,
            displayTitle: currentTitle,
          };
        })
      : [];

  // The summaryRecord is to prompt the show total at the bottom of the table
  // Note the previousTitle is the lastTitle and will get calculated.
  const summaryRecord = {
    activity: "",
    pa_standard_hrs: 0,
    pa_premium_hrs: 0,
    isNewTitle: false, // Since this is a summary, not a new title
    isShowTotal: true, // Indicate that this is a summary/total row
    previousTitle: lastTitle,
    displayTitle: "",
  };

  const fullData = [...preparedData, summaryRecord];

  const calcTotal = (title) => {
    // This seemed the cleanest way to do this.
    let tot = 0;

    if (dsJPInclude && dsJPInclude.length > 0) {
      let dsTotal = dsJPInclude.filter((sim) => {
        return title === `${sim.category} ${sim.benchmark}`;
      });

      // Iterate through the filtered data - Group specific
      dsTotal &&
        dsTotal.length > 0 &&
        dsTotal.forEach((sim) => {
          tot += sim.pa_standard + sim.pa_premium;
        });
    }
    return tot;
  };

  return (
    <>
      {dsJPInclude && dsJPInclude.length > 0 ? (
        <TableContainer>
          <Table className={table.dashboard} hover="true">
            <TableHead>
              <TableRow className={styles.borderedHeadRow}>
                <TableCell className={styles.colChar255}></TableCell>
                <TableCell className={styles.colNumber}>
                  Normal PA (hrs)
                </TableCell>
                <TableCell className={styles.colNumber}>
                  Premium PA (hrs)
                </TableCell>
                <TableCell className={styles.colNumber}>Total PAs</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fullData.length > 0 &&
                fullData.map((sim, index) => (
                  <React.Fragment key={index}>
                    {sim.isShowTotal ? (
                      <>
                        <TableRow>
                          <TableCell>
                            <b>Total</b>
                          </TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell>
                            <b>
                              {fixNumberString(calcTotal(sim.previousTitle), 2)}
                            </b>
                          </TableCell>
                        </TableRow>
                      </>
                    ) : (
                      <></>
                    )}
                    {sim.isNewTitle ? (
                      <TableRow>
                        <TableCell>
                          <b>{sim.displayTitle}</b>
                        </TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    ) : (
                      <></>
                    )}
                    {sim.displayTitle !== "" ? (
                      <>
                        <TableRow>
                          <TableCell>{sim.activity}</TableCell>
                          <TableCell>{sim.pa_standard_hrs}</TableCell>
                          <TableCell>{sim.pa_premium_hrs}</TableCell>
                          <TableCell>
                            {fixNumberString(
                              sim.pa_standard + sim.pa_premium,
                              2
                            )}
                          </TableCell>
                        </TableRow>
                      </>
                    ) : (
                      <></>
                    )}
                  </React.Fragment>
                ))}
              {showGrandTotal ? (
                <TableRow key={"totals"}>
                  <TableCell>
                    <b>Grand Total</b>
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell>
                    <b>{fixNumberString(totalPAs, 2)}</b>
                  </TableCell>
                </TableRow>
              ) : (
                <></>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <></>
      )}
    </>
  );
};

export default JobPlanActivitiesTable;
