import React, { useState, useContext } from "react";
import { runDemoSetup } from "../../../api/ApiPtlCalls";
import {
  Model01RefreshAllContext,
  Model01SimSettingsContext,
  Model01IsLocked,
} from "../Model01";

const DemoSetup = () => {
  const [data, writeSimSettings] = useContext(Model01SimSettingsContext); // eslint-disable-line no-unused-vars
  const [messages, setMessages] = useState([]);

  const [isLocked] = useContext(Model01IsLocked);
  const [ssUser] = useState(
    localStorage.getItem("ssSupport") === "true" ||
      localStorage.getItem("ssSales") === "true"
  );

  // eslint-disable-next-line no-unused-vars
  const [isSysAdmin, setIsSysAdmin] = useState(
    localStorage.getItem("isSysAdmin").replace(/['"]+/g, "")
  );

  // Grab the function for if there is a ptl upload
  const refreshAll = useContext(Model01RefreshAllContext);

  // Get the simulation data
  const runSetup = async () => {
    runDemoSetup(
      data.id,
      (simData) => {
        if (simData.result === "True") {
          setMessages(["Demo data set up correctly."]);
          refreshAll();
        } else {
          setMessages(["Demo data set up failed."]);
        }
      },
      (error) => {
        setMessages([
          "There has been a problem setting up the demo data - " + error,
        ]);
      }
    );
  };

  return (
    <div>
      {ssUser && (
        <span>
          &nbsp;{"  "}&nbsp; &nbsp;{"  "}&nbsp;
          <button
            className={`btn btn-outline-primary ${isLocked ? "d-none" : ""}`}
            onClick={() => runSetup()}
          >
            Set up PTL for demo
          </button>
        </span>
      )}

      {messages &&
        messages.map((msg, index) => (
          <div key={index} className="alert alert-success">
            {msg}
          </div>
        ))}
    </div>
  );
};

export default DemoSetup;
