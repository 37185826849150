import React from "react";
import style from "../scss/style.module.scss";

const SessionPerDayDisplay = (props) => {
  return (
    <table className={style.sessionContainer}>
      <thead>
        <tr>
          <th>M</th>
          <th>T</th>
          <th>W</th>
          <th>T</th>
          <th>F</th>
          <th>S</th>
          <th>S</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{props.sessions_monday}</td>
          <td>{props.sessions_tuesday}</td>
          <td>{props.sessions_wednesday}</td>
          <td>{props.sessions_thursday}</td>
          <td>{props.sessions_friday}</td>
          <td>{props.sessions_saturday}</td>
          <td>{props.sessions_sunday}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default SessionPerDayDisplay;
