import { removeTrailingSlash } from "../common/urls";
import { get, put, post, del } from "./FetchVerbs";

// THIS THE THE CORE API - taken from the .env file
const api_url = removeTrailingSlash(process.env.REACT_APP_CORE_API_URL);

// *************************************************************************************
// Companies / Users / Login
// *************************************************************************************
export const getUsers = async (companyId, callback, failFunction) => {
  const url = api_url + "/api/user/company/" + companyId;
  await get(url, "Error getting users for company:", callback, failFunction);
};

export const postUser = async (data, callback, failFunction) => {
  const url = api_url + "/api/user";
  await post(url, data, "Error with post new company:", callback, failFunction);
};
// Update existing
export const putUser = async (data, callback, failFunction) => {
  const url = api_url + "/api/user/" + data["id"];
  await put(
    url,
    data,
    "Error with put company changes:",
    callback,
    failFunction
  );
};

export const delUser = async (dataId, callback, failFunction) => {
  const url = api_url + "/api/user/" + dataId;
  await del(url, "Error with delete user:", callback, failFunction);
};

export const newPasswordEMailUser = async (data, callback, failFunction) => {
  const url = api_url + "/api/email";
  await post(
    url,
    data,
    "Error with sending password email to user",
    callback,
    failFunction
  );
};

export const userForgottenPassword = async (data, callback, failFunction) => {
  const url = api_url + "/api/fpwd";
  await post(
    url,
    data,
    "Error with sending password email to user",
    callback,
    failFunction
  );
};

export const writePasswordForUser = async (data, callback, failFunction) => {
  const url = api_url + "/api/writepassword";
  await put(
    url,
    data,
    "Error with posting user password",
    callback,
    failFunction
  );
};

export const getUserURLToken = async (userId, callback, failFunction) => {
  const url = api_url + "/api/emailuser";
  await get(url, "Error getting users for company:", callback, failFunction);
};

export const getCompanyList = async (callback, failFunction) => {
  const url = api_url + "/api/company";
  await get(url, "Error getting company list:", callback, failFunction);
};

export const delCompany = async (dataId, callback, failFunction) => {
  const url = api_url + "/api/company/" + dataId;
  await del(url, "Error with delete company:", callback, failFunction);
};

export const postCompany = async (data, callback, failFunction) => {
  const url = api_url + "/api/company";
  await post(url, data, "Error with post new company:", callback, failFunction);
};
// Update existing
export const putCompany = async (data, callback, failFunction) => {
  const url = api_url + "/api/company/" + data["id"];
  await put(
    url,
    data,
    "Error with put company changes:",
    callback,
    failFunction
  );
};

export async function postLogin(data, callback, failFunction) {
  fetch(api_url + "/api/login", {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        return response.text().then((text) => {
          throw new Error(text);
        });
      }
    })
    .then((data) => {
      if (typeof callback !== "undefined") {
        callback(data);
      }
    })
    .catch((error) => {
      console.error("Error loggin in", error);
      if (typeof failFunction !== "undefined") {
        failFunction(error);
      }
    });
}

export async function getUsersList(data, callback, failFunction) {
  const url = api_url + "/api/modulesupport/users";
  await post(
    url,
    data,
    "Error with getting user names:",
    callback,
    failFunction
  );
}

export async function postAuditTrailEntry(
  description,
  detail,
  callback,
  failFunction
) {
  const url = api_url + "/api/admaudit";
  var data = {
    Description: description,
    Detail: detail,
    UserId: localStorage.getItem("userId"),
  };
  await post(
    url,
    data,
    "Error with posting audit trail:",
    callback,
    failFunction
  );
}
