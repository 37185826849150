import React, { useContext, useState, useEffect } from "react";
import styles from "../../../../scss/style.module.scss";
import table from "../../../../table.module.scss";
import {
  ConsultSettingsContext,
  ConsultRefreshChildrenContext,
} from "../../Consult";
import { getExamTypesAdditions } from "../../../../api/ApiConsultCalls";
import Table from "react-bootstrap/Table";
import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import ModalLayout from "../../../modal/Modal";
import AdditionalTable from "./AdditionalTable";

/// This for shows the extra exam type modifications that effect this analysis
/// This therefore shows trust wide ones as well as analysis specific ones.

const StandardsTable = () => {
  const [data] = useContext(ConsultSettingsContext); // eslint-disable-line no-unused-vars
  const refreshChild = useContext(ConsultRefreshChildrenContext);
  const [dsAppended, setDsAppended] = useState([]);

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    // Fetch the JobPlan details.
    if (data.id !== undefined) {
      getExamTypesAdditions(
        data.id,
        (data) => {
          if (data && data.length > 0) {
            // Append the info.
            setDsAppended(data);
          } else {
            setDsAppended(data);
          }
        },
        (error) => {}
      );
    }
  }, [data.id, refreshChild]);

  const modalClose = () => {
    setShowModal(false);
  };

  // const dataUpdated = (e) => {
  //   alert(e);
  //   refreshChild();
  // };

  const newRecord = () => {
    setShowModal(true);
  };

  return (
    <>
      <div>
        Change List for this analysis / trust
        {dsAppended && dsAppended.length > 0 ? (
          <React.Fragment>
            <TableContainer>
              <Table className={table.dashboard} hover="true">
                <TableHead>
                  <TableRow className={styles.borderedHeadRow}>
                    <TableCell>nicip_code</TableCell>
                    <TableCell>nicip_desc</TableCell>
                    <TableCell>original</TableCell>
                    {/* <TableCell>trust duration</TableCell> */}
                    <TableCell>analysis duration</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dsAppended.map((sim, index) => (
                    <TableRow key={sim.id}>
                      <TableCell>{sim.nicip_code}</TableCell>
                      <TableCell>{sim.nicip_desc}</TableCell>
                      <TableCell>{sim.original_duration}</TableCell>
                      {/* <TableCell>{sim.duration_company}</TableCell> */}
                      <TableCell>{sim.duration_analysis}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <br />
            No changes made
            <br />
          </React.Fragment>
        )}
        <br />
        <>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <button className={`btn btn-outline-primary`} onClick={newRecord}>
              Edit Exam Durations{" "}
            </button>
          </div>
        </>
      </div>

      <ModalLayout
        title={"Alter the exam durations"}
        show={showModal}
        handleClose={modalClose}
      >
        <AdditionalTable fk_analysis_id={data.id} />
      </ModalLayout>
    </>
  );
};

export default StandardsTable;
