import React from "react";
import { useField } from "formik";

// import MdTextField from "@mui/material/TextField";
import { TextField as MdTextField } from "@mui/material";

// Nice tutorial from:
// https://www.youtube.com/watch?v=u-CCnDayNJw

// For all functions look at HTML input info:
// https://www.w3schools.com/tags/tag_input.asp

const TextField = ({
  required = false,
  variant = "standard",
  label,
  ...props
}) => {
  const [field, meta] = useField(props);
  return (
    <div>
      <MdTextField
        autoComplete="off"
        className={`form-control shadow-none 
        ${meta.touched && meta.error && " is-invalid "} 
        ${props.type && props.type === "date" && " form-control-date "} 
        ${props.type && props.type === "time" && " form-control-time "} 
        ${props.type && props.type === "number" && " form-control-number "}
        ${props.type && props.type === "checkbox" && " form-control-checkbox "}
        `}
        style={{
          paddingRight: meta.touched && meta.error ? "0" : "",
          paddingBottom: "20px",
          backgroundColor: "transparent",
        }}
        {...field}
        {...props}
        // fullWidth
        label={label}
        // variant="filled"
        variant={variant}
        required={required}
      />
    </div>
  );
  /* {label && <label htmlFor={field.name}>{label}</label>}
      <input
        className={`form-control shadow-none ${
          meta.touched && meta.error && "is-invalid"
        }`}
        {...field}
        {...props}
        autoComplete="off"
      /> 
      
      <ErrorMessage component="div" name={field.name} className="error" />
      
      
      */
};
export default TextField;
