import React, { useState, useEffect } from "react";
import CheckBoxSimple from "./CheckBox";

const SelectableList = ({
  dsData,
  checkedStateJs,
  setCheckedStateJs,
  readonly,
  hideUnselected = false,
  onChange,
}) => {
  /* eslint-disable  no-unused-vars */

  const sortStringTitles = (a, b) => {
    return String(a.title).localeCompare(b.title);
  };
  const [first, setFirst] = useState(true);
  /* eslint-enable  no-unused-vars */

  const handleOnChange = (element) => {
    if (!readonly) {
      // Locate the value within the local array and updated it
      checkedStateJs[element] = !checkedStateJs[element];
      setCheckedStateJs(checkedStateJs);
      setFirst(!first);
      if (onChange !== undefined) {
        onChange(element);
      }
    }
  };

  useEffect(() => {
    // Re-render control is the state changes
  }, [checkedStateJs]);

  return (
    <>
      <br />
      {dsData &&
        dsData.length > 0 &&
        checkedStateJs &&
        dsData
          .filter((item) => item !== null)
          .sort(sortStringTitles)
          .map((item, key) => (
            <div
              key={"div_" + item.id + key}
              className={
                !hideUnselected ? "" : checkedStateJs[item] ? "" : "d-none"
              }
            >
              <span key={item.id + key}>
                <CheckBoxSimple
                  checked={checkedStateJs[item] || false}
                  onChange={() => handleOnChange(item)}
                />{" "}
                <label
                  htmlFor={`custom-checkbox-${item}`}
                  className="input-checkbox-text"
                >
                  {item}
                </label>
                <br />
              </span>
            </div>
          ))}
    </>
  );
};

export default SelectableList;
