function numberWithCommas(x) {
  if (x === undefined) return "";
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

// function numberWithCommas2(x) {
//   if (x === undefined) return "";

//   // Convert to string and split into whole and decimal parts
//   // NOTE that the toString will remove the zero from 6.0 to 6...!!!
//   const parts = x.toString().split(".");

//   // Format the whole part with commas
//   const wholePart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

//   // Check if there is a decimal part
//   if (parts[1]) {
//     return `${wholePart}.${parts[1]}`; // Include the decimal part if it exists
//   } else {
//     return wholePart; // Return the whole part as is if there's no decimal part
//   }
// }

function roundNumber(x, decimal_places) {
  var decimal = x - Math.floor(x);
  if (decimal > 0)
    return parseFloat(Number.parseFloat(x).toFixed(decimal_places));
  else return x;
}

function fixNumberString(x, decimal_places) {
  if (x === undefined || x === null || isNaN(x)) {
    x = 0;
  }
  var decimal = x - Math.floor(x);
  if (decimal > 0) return Number.parseFloat(x).toFixed(decimal_places);
  else return x.toFixed(decimal_places); // Always round to the specified decimal places.
}

function hideIfZero(num) {
  if (num === 0) return "";
  if (isNaN(num)) return "";
  else return num;
}

module.exports = {
  numberWithCommas,
  // numberWithCommas2,
  roundNumber,
  fixNumberString,
  hideIfZero,
};
