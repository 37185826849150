import { removeTrailingSlash, concatUrl } from "../common/urls";
import {
  get,
  put,
  post,
  del,
  add,
  getText,
  getFileDownload,
} from "./FetchVerbs";

const api_url = removeTrailingSlash(process.env.REACT_APP_PTL_API_URL);

// *************************************************************************************
// Theatre Type calls
// *************************************************************************************

export const getSimTheatreType = async (sim_id, callback, failFunction) => {
  const url = api_url + "/api/simtheatretype/getall/" + sim_id;
  await get(
    url,
    "Error getting simulation theatre types:",
    callback,
    failFunction
  );
};
export const getSimTheatreLink = async (sim_id, callback, failFunction) => {
  const url = api_url + "/api/simtheatrelink/getall/" + sim_id;
  await get(
    url,
    "Error getting simulation theatre links:",
    callback,
    failFunction
  );
};

// Update existing
export const putTheatreType = async (data, callback, failFunction) => {
  const url = api_url + "/api/simtheatretype/" + data["id"];
  await put(
    url,
    data,
    "Error with put theatre type changes:",
    callback,
    failFunction
  );
};

// Create new
export const postTheatreType = async (data, callback, failFunction) => {
  const url = api_url + "/api/simtheatretype";
  await post(
    url,
    data,
    "Error with post theatre type changes:",
    callback,
    failFunction
  );
};

// Update existing
export const putTheatreLink = async (
  theatre_id,
  data,
  callback,
  failFunction
) => {
  const url = api_url + "/api/simtheatrelinkplus/" + theatre_id;
  await put(
    url,
    data,
    "Error with put theatre type changes:",
    callback,
    failFunction
  );
};

// Create new
export const postTheatreLink = async (
  theatre_id,
  data,
  callback,
  failFunction
) => {
  const url = api_url + "/api/simtheatrelinkplus/" + theatre_id;
  await post(
    url,
    data,
    "Error with post theatre type changes:",
    callback,
    failFunction
  );
};

export const updateTheatreSchedule = async (
  dataId,
  data,
  callback,
  failFunction
) => {
  const url = `${api_url}/api/simsessiondemanddiary/${dataId}`;

  await put(
    url,
    data,
    "Error with post update theatre schedule:",
    callback,
    failFunction
  );
};

export const addToTheatreSchedule = async (data, callback, failFunction) => {
  const url = `${api_url}/api/simsessiondemanddiary`;
  await post(
    url,
    data,
    "Error with post sessiondemanddiary changes:",
    callback,
    failFunction
  );
};

export const delSimTheatreType = async (dataId, callback, failFunction) => {
  const url = api_url + "/api/simtheatretype/" + dataId;
  await del(url, "Error with delete theatre type:", callback, failFunction);
};

export const getSimTheatreSchedule = async (dataId, callback, failFunction) => {
  const url = `${api_url}/api/simsessiondemanddiary/getall/${dataId}`;
  await get(url, "Error getting theatre schedule:", callback, failFunction);
};

// *************************************************************************************
// Report data
// *************************************************************************************

export const getReportData = async (
  sim_id,
  rpt_number,
  day_no,
  callback,
  failFunction
) => {
  const url =
    api_url + "/api/rptdata/" + sim_id + "/" + rpt_number + "/" + day_no;
  await get(url, "Error getting report data:", callback, failFunction);
};

export const getReportDataAll = async (
  sim_id,
  rpt_number,
  callback,
  failFunction
) => {
  const url = api_url + "/api/rptdata/getall/" + sim_id + "/" + rpt_number;
  await get(url, "Error getting report data:", callback, failFunction);
};

export const getReportDataSummary = async (
  sim_id,
  rpt_number,
  callback,
  failFunction
) => {
  const url = api_url + "/api/rptdatasummary/" + sim_id + "/" + rpt_number;
  await get(url, "Error getting report summary data:", callback, failFunction);
};

export const getReportDataCsv = async (
  sim_id,
  rpt_number,
  callback,
  failFunction
) => {
  const url = api_url + "/api/exportcsv/" + rpt_number + "/" + sim_id;
  await getText(url, "Error getting report data csv:", callback, failFunction);
};

// *************************************************************************************
// model  NB use the FN url
// *************************************************************************************

export const runModel = async (simulation_id, callback, failFunction) => {
  const url = concatUrl(api_url, "/api/execmodel/" + simulation_id);
  await put(url, "", "Error running the model", callback, failFunction);
};

export const getModellingStatus = async (
  simulation_id,
  callback,
  failFunction
) => {
  const url = api_url + "/api/simmodellingstatus/" + simulation_id;
  await get(url, "Error getting model status:", callback, failFunction);
};

// *************************************************************************************
// simptl
// *************************************************************************************

// Create new
export const postPTLFile = async (
  file,
  simulation_id,
  callback,
  failFunction
) => {
  const url = api_url + "/api/simwaitinglist";
  const params = {
    simulation_id: simulation_id,
    add_child_records: true,
  };
  add(
    file,
    params,
    url,
    "Error uploading layer data file: ",
    callback,
    failFunction
  );
};

export const runDemoSetup = async (sim_id, callback, failFunction) => {
  const url = api_url + "/api/simdemo/" + sim_id;
  await get(url, "Error setting up demo simulation:", callback, failFunction);
};

// *************************************************************************************
// simsessiondemand
// *************************************************************************************

// Update existing
export const putSessionDemand = async (data, callback, failFunction) => {
  const url = api_url + "/api/simsessiondemand/" + data["id"];
  await put(
    url,
    data,
    "Error with put sessiondemand changes:",
    callback,
    failFunction
  );
};

// Create new
export const postSessionDemand = async (data, callback, failFunction) => {
  const url = api_url + "/api/simsessiondemand";
  await post(
    url,
    data,
    "Error with post sessiondemand changes:",
    callback,
    failFunction
  );
};

export const delSimSessionDemand = async (dataId, callback, failFunction) => {
  const url = api_url + "/api/simsessiondemand/" + dataId;
  await del(url, "Error with delete sessiondemand:", callback, failFunction);
};

export const delTheatreSession = async (dataId, callback, failFunction) => {
  const url = `${api_url}/api/simsessiondemanddiary/${dataId}`;
  await del(url, "Error with delete theatre session:", callback, failFunction);
};

export const getSimSessionDemand = async (sim_id, callback, failFunction) => {
  const url = api_url + "/api/simsessiondemand/getall/" + sim_id;
  await get(
    url,
    "Error getting simulation sessiondemand:",
    callback,
    failFunction
  );
};

// *************************************************************************************
// simpatientdemand
// *************************************************************************************

// Update existing
export const putPatientDemand = async (data, callback, failFunction) => {
  const url = api_url + "/api/simpatientdemand/" + data["id"];
  await put(
    url,
    data,
    "Error with put patientdemand changes:",
    callback,
    failFunction
  );
};

// Create new
export const postPatientDemand = async (data, callback, failFunction) => {
  const url = api_url + "/api/simpatientdemand";
  await post(
    url,
    data,
    "Error with post patientdemand changes:",
    callback,
    failFunction
  );
};

export const delSimPatientDemand = async (dataId, callback, failFunction) => {
  const url = api_url + "/api/simpatientdemand/" + dataId;
  await del(url, "Error with delete patientdemand:", callback, failFunction);
};

export const getSimPatientDemand = async (sim_id, callback, failFunction) => {
  const url = api_url + "/api/simpatientdemand/getall/" + sim_id;
  await get(
    url,
    "Error getting simulation patientdemand:",
    callback,
    failFunction
  );
};

// *************************************************************************************
// Acuity
// *************************************************************************************

// Update existing
export const putAcuity = async (data, callback, failFunction) => {
  const url = api_url + "/api/simacuity/" + data["id"];
  await put(
    url,
    data,
    "Error with put acuity changes:",
    callback,
    failFunction
  );
};

// Create new
export const postAcuity = async (data, callback, failFunction) => {
  const url = api_url + "/api/simacuity";
  await post(
    url,
    data,
    "Error with post acuity changes:",
    callback,
    failFunction
  );
};

export const delSimAcuity = async (dataId, callback, failFunction) => {
  const url = api_url + "/api/simacuity/" + dataId;
  await del(url, "Error with delete ailment:", callback, failFunction);
};

export const getSimAcuity = async (sim_id, callback, failFunction) => {
  const url = api_url + "/api/simacuity/getall/" + sim_id;
  await get(url, "Error getting simulation acuity:", callback, failFunction);
};

// *************************************************************************************
// Ailments
// *************************************************************************************

// Update existing
export const putAilment = async (data, callback, failFunction) => {
  const url = api_url + "/api/simailments/" + data["id"];
  await put(
    url,
    data,
    "Error with put ailment changes:",
    callback,
    failFunction
  );
};

// Create new
export const postAilment = async (data, callback, failFunction) => {
  const url = api_url + "/api/simailments";
  await post(
    url,
    data,
    "Error with post ailment changes:",
    callback,
    failFunction
  );
};

export const delSimAilments = async (dataId, callback, failFunction) => {
  const url = api_url + "/api/simailments/" + dataId;
  await del(url, "Error with delete ailment:", callback, failFunction);
};

export const getSimAilments = async (sim_id, callback, failFunction) => {
  const url = api_url + "/api/simailments/getall/" + sim_id;
  await get(url, "Error getting simulation ailments:", callback, failFunction);
};

// *************************************************************************************
// Settings
// *************************************************************************************

export const getSimulationList = async (callback, failFunction) => {
  const url = api_url + "/api/simsettings/getall";
  await get(url, "Error getting simulation list:", callback, failFunction);
};

export const getSimulation = async (simGuid, callback, failFunction) => {
  const url = api_url + "/api/simsettings/" + simGuid;
  await get(url, "Error getting simulation:", callback, failFunction);
};
// Create New
export const postSimulation = async (data, callback, failFunction) => {
  const url = api_url + "/api/simsettings";
  await post(
    url,
    data,
    "Error with post new simulation:",
    callback,
    failFunction
  );
};

// Create duplicate
export const duplicateSimulation = async (simGuid, callback, failFunction) => {
  const url = api_url + "/api/simsettings/" + simGuid;
  await post(
    url,
    "",
    "Error with creating duplicate simulation:",
    callback,
    failFunction
  );
};

// Update existing
export const putSimulation = async (data, callback, failFunction) => {
  const url = api_url + "/api/simsettings/" + data["id"];
  await put(
    url,
    data,
    "Error with put simulation changes:",
    callback,
    failFunction
  );
};

export const delSimulation = async (dataId, callback, failFunction) => {
  const url = api_url + "/api/simsettings/" + dataId;
  await del(url, "Error with delete simulation:", callback, failFunction);
};

export const lockSimulation = async (simGuid, callback, failFunction) => {
  const url = api_url + "/api/simsettings/lock/" + simGuid;
  await post(url, "", "Error with lock simulation:", callback, failFunction);
};

export const downloadExcelTemplate = async (failFunction) => {
  // Replace 'fileName' with the actual file name you want to download
  const fileName = "PtlTemplate.xlsx";
  const url = api_url + "/api/template";
  await getFileDownload(
    url,
    "Error download template file",
    fileName,
    failFunction
  );
};
