import React, { useContext } from "react";
import DocLogo from "../images/doc_logo.png";
import logo_top_ptl from "../images/ptl_planner_enabled.png";
import logo_top_ptl_disabled from "../images/ptl_planner_disabled.png";
import logo_top_consult from "../images/jp_reckoner_enabled.png";
import logo_top_consult_disabled from "../images/jp_reckoner_disabled.png";

import { Link } from "react-router-dom"; // Used to prevent a re-load
import { CurrentApplicationShowing } from "./contexts/CurrentAppContext";

const HeaderButtons = () => {
  const { activeApp } = useContext(CurrentApplicationShowing);
  return (
    <>
      <div>
        <br />
        <img
          className="inline-block headerImage"
          src={DocLogo}
          alt="Staffing Science"
          width={200}
          height={78}
          // styleName="vertical-align: bottom"
        />
        <br />
        <br />
      </div>
      <div>
        <Link data-for="edit" data-tip="View" to={`/`}>
          <img
            className="inline-block headerImage"
            src={activeApp === "ptl" ? logo_top_ptl : logo_top_ptl_disabled}
            alt="PTL Planner"
            width={67}
            height={56}
            // styleName="vertical-align: bottom"
          />
        </Link>
      </div>
      <div>
        <Link data-for="edit" data-tip="View" to={`/consult`}>
          <img
            className="inline-block headerImage"
            src={
              activeApp === "consult"
                ? logo_top_consult
                : logo_top_consult_disabled
            }
            alt="Consultant Analysis"
            width={67}
            height={56}
            // styleName="vertical-align: bottom"
          />
        </Link>
      </div>
    </>
  );
};
export default HeaderButtons;
