import React from "react";
import { ErrorMessage, useField } from "formik";

// import MdTextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";

// Nice tutorial from:
// https://www.youtube.com/watch?v=u-CCnDayNJw

// For all functions look at HTML input info:
// https://www.w3schools.com/tags/tag_input.asp

const CheckBoxMD = ({ label, ...props }) => {
  const [field, meta] = useField(props);

  const handleChange = (event) => {
    // Call the existing onChange event handler, if it exists
    if (field.onChange) {
      field.onChange(event);
    }

    // Adding additional event
    if (props.onChangeExt) {
      props.onChangeExt(event);
    }
  };

  return (
    <div className="mb-2">
      <Checkbox
        autoComplete="off"
        className={`form-control shadow-none 
        ${meta.touched && meta.error && " is-invalid "} 
        ${props.type && props.type === "date" && " form-control-date "} 
        ${props.type && props.type === "number" && " form-control-number "}
        ${props.type && props.type === "checkbox" && " form-control-checkbox "}
        `}
        {...field}
        {...props}
        onChange={handleChange}
        variant="filled"
        size="medium"
      />
      {label}
      <ErrorMessage component="div" name={field.name} className="error" />
    </div>
  );
};

export default CheckBoxMD;
