/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { delSimTheatreType } from "../../../api/ApiPtlCalls";
import {
  Model01SimTheatreContext,
  Model01SimSettingsContext,
  Model01RefreshAllContext,
  Model01SimTheatreLinkContext,
  Model01SimAilmentsContext,
  Model01IsLocked,
} from "../Model01";
import ModalConfirm from "../../modal/ModalConfirm";
import TheatreType from "./TheatreType";
import styles from "../../../scss/style.module.scss";
import ModalLayout from "../../modal/Modal";
import { FaPlus, FaTrash } from "react-icons/fa";
import { GoPencil } from "react-icons/go";
import { validateAilmentAllocation } from "../../../common/validation";

const TheatreTypeGrid = ({ ctrlValid, ctrlNumber, displayType }) => {
  /* eslint-disable  no-unused-vars */
  const [data, writeSimSettings] = useContext(Model01SimSettingsContext);
  const [dsSimTheatreTypes, setDsSimTheatreTypes] = useContext(
    Model01SimTheatreContext
  );
  const [dsTheatreLink] = useContext(Model01SimTheatreLinkContext);
  const [dsAilments] = useContext(Model01SimAilmentsContext);
  const [isLocked] = useContext(Model01IsLocked);
  const [showLinkError, setShowLinkError] = useState(true);
  /* eslint-enable  no-unused-vars */
  const refreshAllNow = useContext(Model01RefreshAllContext);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [localRefresh, setLocalRefresh] = useState(false);
  // const [sim_id, setSim_id] = useState(0);
  const [popupTheatre, setpopupTheatre] = useState([]);
  const [theatreTypeId, settheatreTypeId] = useState("");

  function ValidateFormData() {
    ctrlValid(ctrlNumber, true);
    if (validateAilmentAllocation(dsAilments, dsTheatreLink)) {
      // Passed the test
      setShowLinkError(false);
    } else {
      // Failed the test
      setShowLinkError(true);
    }
  }

  // Automate the form validation
  useEffect(() => {
    ValidateFormData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dsSimTheatreTypes]);

  const rowDelete = () => {
    modalDeleteClose();

    delSimTheatreType(
      theatreTypeId,
      () => {
        // Remove the item from the array
        var myArray = dsSimTheatreTypes.filter((a) => {
          return a.id !== theatreTypeId;
        });
        setDsSimTheatreTypes(myArray);
        refreshAllNow();
        setLocalRefresh(!localRefresh);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const newRecord = () => {
    let newAilmentRecord = [];
    newAilmentRecord.simulation_id = data.simulation_id;
    newAilmentRecord.id = "00000000-0000-0000-0000-000000000000";

    setpopupTheatre([]);
    setpopupTheatre(newAilmentRecord);
    setIsNew(true);
    setShowModal(true);
  };

  const dataUpdated = (e) => {
    //alert(e);
    if (!isNew) {
      refreshAllNow();
      setLocalRefresh(!localRefresh);

      // // Ensure doc display correctly
      // setpopupTheatre(e);
      // let theatreArr = dsSimTheatreTypes.filter((a) => {
      //   return a.id !== e.id;
      // });
      // theatreArr.push(e);
      // setDsSimTheatreTypes(theatreArr);
    } else {
      // Get the array rebuild - the record has been added to the db
      refreshAllNow();
      setLocalRefresh(!localRefresh);
    }
    modalClose();
  };

  const updateRecord = async (recordId) => {
    var ail = dsSimTheatreTypes.filter((a) => {
      return a.id === recordId;
    });
    setpopupTheatre(ail[0]);
    setIsNew(false);
    setShowModal(true);
  };

  const modalClose = () => {
    setShowModal(false);
  };

  const viewSim = (simId) => {
    updateRecord(simId);
  };

  const modalDeleteClose = () => {
    setShowDeleteModal(false);
  };

  const sortStringTitles = (a, b) => {
    return String(a.title).localeCompare(b.title);
  };

  return (
    <>
      <div style={{ marginBottom: "25px" }} className={styles.flexRow}>
        <div
          style={{
            marginBottom: "0",
            display: "flex",
            flex: "1",
            justifyContent: "space-between",
          }}
          className={styles.subtitle}
        >
          <h3>Theatre Types</h3>
          {displayType && displayType === "main" && (
            <>
              <div className={styles.customButton}>
                <button
                  className={`btn btn-outline-primary ${
                    isLocked ? "d-none" : ""
                  }`}
                  onClick={newRecord}
                >
                  <FaPlus size={15} color="#ffffff" />
                  Create New
                </button>
              </div>
            </>
          )}
        </div>
      </div>
      <Table borderless hover>
        <thead>
          <tr className={styles.borderedHeadRow}>
            <th> Title</th>
            {displayType && displayType === "main" && (
              <>
                <th>Hrs/session</th>
                <th>Max. patients/session</th>
                <th>Cancellation prob. (%)</th>
                <th>Patient Groups</th>
              </>
            )}
            {displayType && displayType === "demand" && (
              <>
                <th>Proportion of new patients</th>
              </>
            )}
            <th></th>
          </tr>
        </thead>
        <tbody>
          {dsSimTheatreTypes &&
            dsSimTheatreTypes.length > 0 &&
            dsSimTheatreTypes.sort(sortStringTitles).map((sim) => (
              <tr
                key={sim.id}
                onClick={() => isLocked === false && viewSim(sim.id)}
              >
                <td className={styles.colourRow}>
                  <span
                    style={{
                      backgroundColor: sim.rpt_colour
                        ? sim.rpt_colour
                        : `#${Math.floor(Math.random() * 16777215).toString(
                            16
                          )}`,
                    }}
                    className={styles.dot}
                  ></span>
                  <span>{sim.title}</span>
                </td>
                <td>{sim.session_hours}</td>
                <td>{sim.patients_per_session}</td>
                <td>{sim.p_cancelled}%</td>
                <td>{sim.patient_groups}</td>
                <td>
                  <span
                    role="button"
                    onClick={() => {
                      updateRecord(sim.id);
                    }}
                    className={`${isLocked ? "d-none" : ""}`}
                  >
                    <GoPencil size={25} color="#008181" />
                  </span>
                  &nbsp;&nbsp;
                  <span
                    role="button"
                    onClick={(e) => {
                      e.stopPropagation();
                      settheatreTypeId(sim.id);
                      setShowDeleteModal(true);
                    }}
                    className={`${isLocked ? "d-none" : ""}`}
                  >
                    <FaTrash size={25} color="#7A2048" />
                  </span>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
      {showLinkError && (
        <div className="alert alert-warning">
          One or more of the patient groups are not linked to a theatre type
        </div>
      )}

      <ModalLayout
        title={isNew ? "Add new theatre type" : "Update theatre type"}
        show={showModal}
        handleClose={modalClose}
      >
        <TheatreType
          data={popupTheatre}
          isNew={isNew}
          dataUpdated={dataUpdated}
          simulation_id={data.simulation_id}
          displayType={displayType}
        />
      </ModalLayout>
      <ModalConfirm
        show={showDeleteModal}
        handleClose={modalDeleteClose}
        title="Please confirm you want to delete this theatre type"
        confirmed={rowDelete}
      ></ModalConfirm>
    </>
  );
};

export default TheatreTypeGrid;
