import React from "react";
import styles from "../../../../scss/style.module.scss";
import StandardsTable from "./StandardsTable";

const TabStandards = ({ tabNo }) => {
  return (
    <>
      <div>
        <h3>Standards</h3>
        <div className={styles.filterForm}>
          <StandardsTable />
        </div>
      </div>
    </>
  );
};

export default TabStandards;
