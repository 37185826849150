import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useRef,
} from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { getReportDataAll } from "../../../api/ApiPtlCalls";
import {
  Model01RptRefreshContext,
  Model01SimAilmentsContext,
} from "../Model01";
import { roundNumber } from "../../../common/math_functions";

require("highcharts/modules/accessibility")(Highcharts);

const RptPatientAverage = ({
  simulation_id,
  seriesHidden,
  setSeriesHidden,
  startDate,
}) => {
  // For sample on report: https://www.highcharts.com/demo/combo-multi-axes
  // Dots and dashes see: https://www.highcharts.com/demo/ios/accessible-line/brand-light

  const chartComponentRef = useRef(null);
  const [isRefreshNeeded] = useContext(Model01RptRefreshContext);
  const [ailments] = useContext(Model01SimAilmentsContext);
  const [options, setOptions] = useState({});
  const [myDataSeries, setMyDataSeries] = useState({});
  const [summaryData, setSummaryData] = useState({});
  const [simStartDate] = useState(startDate);

  const summaryText = "Total";

  useEffect(() => {
    const hideSeries = (stringArray) => {
      chartComponentRef &&
        chartComponentRef.current &&
        chartComponentRef.current.chart &&
        chartComponentRef.current.chart.series &&
        chartComponentRef.current.chart.series.forEach((element) => {
          const category = element.name;

          // If the cliicked element is the summary one, do nothing
          if (category !== summaryText) {
            if (stringArray.indexOf(category) > -1) {
              element.hide();
            } else {
              element.show();
            }
          }
        });
    };
    seriesHidden &&
      seriesHidden.length !== undefined &&
      hideSeries(seriesHidden);
  }, [ailments, seriesHidden]);

  // Get data dependant on day number
  useEffect(() => {
    getReportDataAll(
      simulation_id,
      4,
      (simData) => {
        // Work out distinct ailments

        // Build a dataset for each of the ailments & summary
        const buildDataSet = (ailment_title, summary) => {
          var dataSet = [];
          if (
            // Check we have data
            simData &&
            simData[0] &&
            simData[0].rptdata &&
            simData[0].rptdata.length > 0
          ) {
            // Loop through each record. One per day.
            simData[0].rptdata.forEach((data, index) => {
              var jsonRow = JSON.parse(data);
              var rpt_day = jsonRow.rpt_day;
              // Is this summary where we need to collate the numbers?
              if (summary === false) {
                // Not summary so work out the ailment numbers

                // type 0 is average wait time
                jsonRow.data.forEach((element) => {
                  if (element.ailment === ailment_title) {
                    dataSet.push({
                      y: roundNumber(element.patient_wait_average / 7, 2),
                      x: Date.parse(simStartDate) + rpt_day * 86400000,
                      // x: rpt_day,
                    });
                  }
                });
              } else {
                // We need to work out the summary figures here.
                var patientCount = 0;

                // type 0 is average wait time
                var averageCalc = 0;
                jsonRow.data.forEach((element) => {
                  if (element.ailment !== "Emergency") {
                    patientCount += element.patient_wait_count;
                    averageCalc +=
                      element.patient_wait_count *
                      (element.patient_wait_average / 7);
                  }
                });
                // Work out what the average is
                if (patientCount !== 0) {
                  dataSet.push({
                    y: roundNumber(averageCalc / patientCount, 2),
                    x: Date.parse(simStartDate) + rpt_day * 86400000,
                    // x: rpt_day,
                  });
                }
              }
            });
          }
          return dataSet;
        };

        // We loop through each of the ailments and add a data set for average and for patient count
        if (ailments && ailments.length > 0) {
          var aWaitAverages = ailments
            .filter((r) => r.emergency === false)
            .map((ailment) => ({
              name: ailment.title, // + suffixAverage,
              type: "spline",
              yAxis: 0,
              color: ailment.rpt_colour,
              data: buildDataSet(ailment.title, false),
              dataLabels: { enabled: false },
              pointWidth: 15,
              tooltip: {
                valueSuffix: " weeks",
              },
              marker: {
                enabled: false,
              },
            }));

          /*
          different dash-style
          "Dot"
          "ShortDot"
          "Dash"
          "ShortDashDot"
          "ShortDash"
          "DotDash"
          "LongDash"
          */

          aWaitAverages.push({
            name: summaryText, // + suffixAverage,
            type: "spline",
            yAxis: 0,
            color: "#808080",
            dashStyle: "LongDash", // "shortdot",
            data: buildDataSet(summaryText, true),
            dataLabels: { enabled: false },
            pointWidth: 15,
            tooltip: {
              valueSuffix: " weeks",
            },
            marker: {
              enabled: false,
            },
          });

          setMyDataSeries(aWaitAverages);
        }

        setSummaryData(simData);
      },
      (error) => {
        console.log("simData Error: ", error);
      }
    );
  }, [simulation_id, isRefreshNeeded, setSummaryData, ailments, simStartDate]);

  // Configure the chart
  const static_config = useCallback(() => {
    return {
      title: {
        text: "",
      },
      credits: {
        enabled: false,
      },
      chart: {
        type: "spline",
        style: {
          fontFamily: "Arial",
        },
      },
      // xAxis: {
      //   visible: true,
      // },
      xAxis: {
        type: "datetime",
        dateTimeLabelFormats: {
          // don't display the year
          month: "%b %y",
          year: "%b",
        },
      },
      yAxis: [
        // Primary y axis
        {
          title: {
            text: "Average Wait Tiime",
            style: {
              // color: Highcharts.getOptions().colors[0],
            },
          },
          labels: {
            align: "right",
            x: 0,
            y: 0, //-6
          },
          showLastLabel: true,
        },
        {
          visible: false,
        },
      ],
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
        footerFormat: "</table>",
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        series: {
          turboThreshold: 5000,
          events: {
            legendItemClick: function (event) {
              var ailment_title = this.name;
              var workingArray = [];
              if (this.visible) {
                // Add to hide list
                workingArray = [...seriesHidden, ailment_title];
              } else {
                // Remove from hide list
                workingArray = seriesHidden.filter((e) => e !== ailment_title);
              }
              setSeriesHidden(workingArray);
            },
          },
        },
      },
    };
  }, [seriesHidden, setSeriesHidden]);

  // Set the dynamic data based config here.
  useEffect(() => {
    if (summaryData && summaryData.length) {
      setOptions({
        ...static_config(),
        series: myDataSeries,
      });
    }
  }, [myDataSeries, static_config, summaryData]);

  return (
    <div>
      <HighchartsReact
        ref={chartComponentRef}
        highcharts={Highcharts}
        options={options}
      />
    </div>
  );
};

export default RptPatientAverage;
