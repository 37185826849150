import React, { useState } from "react";
// import { ModalSimInputContext } from "../../ConsultTabs";
import UploadJobPlan from "./UploadJobPlan";
import DownloadJobPlanTemplate from "./DownloadJobPlanTemplate";
import JobPlanTable from "./JobPlanTable";
// import JobPlanFilterView from "./JobPlanFilterView";
import JobPlanFilterEdit from "./JobPlanFilterEdit";
import styles from "../../../../scss/style.module.scss";
import ModalLayout from "../../../modal/Modal";
import { GoPencil } from "react-icons/go";
import JobPlanActivitiesTable from "./JobPlanActivitiesTable";

const TabJobPlan = ({ tabNo }) => {
  const [showModal, setshowModal] = useState(false);
  const modalClose = () => {
    setshowModal(false);
  };
  const modalOpen = () => {
    setshowModal(true);
  };

  return (
    <>
      <div className={styles.jp_main_container}>
        <div className={styles.jp_left_column}>
          <div className={styles.jp_row}>
            {" "}
            <h5>1. Upload Job Plans</h5>
            <div className={styles.flex_and_space}>
              <UploadJobPlan />
              <DownloadJobPlanTemplate />
            </div>
          </div>
          <div className={styles.jp_row}>
            <h5>2. Job Plan Activity Included</h5>
            <span
              className={""}
              style={{
                paddingBottom: "15px",
                paddingTop: "15px",
                display: "inline-block",
              }}
              role="button"
              onClick={() => {
                modalOpen();
              }}
              title="Edit"
            >
              <GoPencil size={25} color="#008181" />
              &#160;&#160;&#160; Filter Activities
            </span>
            <br />
            <JobPlanActivitiesTable />
          </div>
        </div>
        <div className={styles.jp_right_column}>
          <div className={styles.jp_row}>
            <h5>3. Consultant PAs</h5>
            <JobPlanTable />
          </div>
        </div>
      </div>

      <ModalLayout
        show={showModal}
        handleClose={modalClose}
        title={"Filter Job Plan"}
      >
        <JobPlanFilterEdit readonly={false} closeMe={modalClose} />
      </ModalLayout>
    </>
  );
};

export default TabJobPlan;
