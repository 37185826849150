import React from "react";
import { useState } from "react";
import Model1Links from "./Model01_links";
import AddNewSearch from "../AddNewSearch";
import NewModel1 from "./NewModel01";
import Model1List from "./Model01_list";
import { useNavigate } from "react-router-dom";
import styles from "../../index.module.scss";

const Model1Anchor = () => {
  const [searchText, setSearchText] = useState("");
  const [addModel, setAddModel] = useState(false);
  const [refreshNeeded, setRefreshNeeded] = useState(false);
  const navigate = useNavigate();

  // This determines ths screen mode - adding new or no.
  const AddNew = async () => {
    setAddModel(!addModel);
  };
  // Submit pressed so administer it
  const newModelComplete = (newAdded, newSimId) => {
    if (newAdded) {
      // Refresh the list - adding the new item
      setSearchText("");
      setRefreshNeeded(true);

      navigate("/md01/" + newSimId, { replace: true });
      // this.props.history.push("/md01/" + newSimId);
    }
    setAddModel(false);
  };
  // Text typed from seach box arrives here
  const SearchChanged = async (text) => {
    setSearchText(text);
  };
  // Used when list refreshed
  const RefreshedList = () => {
    setRefreshNeeded(false);
  };

  return (
    <div>
      <Model1Links selected="Dashboard" />

      <div className={styles.containerSub}>
        <AddNewSearch AddNew={AddNew} SetSearchText={SearchChanged} />
        <div>
          {addModel ? (
            <NewModel1 formComplete={newModelComplete} />
          ) : (
            <Model1List
              filter={searchText}
              refresh={refreshNeeded}
              refreshed={RefreshedList}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Model1Anchor;
