import React from "react";
import styles from "../indexOrg.module.scss";

const RadioField = ({ name, label, options, selectedOption, onChange }) => {
  const handleChange = (e) => {
    onChange(e.target.value);
  };

  return (
    <div>
      {label && <label>{label}</label>}
      <div className={styles.radio_button_container}>
        {options.map((option) => (
          <div key={option.value} className={styles.radio_button}>
            <input
              type="radio"
              id={option.value}
              name={name}
              value={option.value}
              checked={selectedOption === option.value}
              onChange={handleChange}
            />
            <label htmlFor={option.value}>&nbsp;&nbsp;{option.label}</label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RadioField;
