import React, { useContext } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import TextField from "../../TextField";
import { putPatientDemand, postPatientDemand } from "../../../api/ApiPtlCalls";
import { Model01IsDirty } from "../Model01";
import styles from "../../../scss/style.module.scss";

const PatiendDemand = ({ data, dataUpdated, isNew }) => {
  const [isDirty, setIsDirty] = useContext(Model01IsDirty); // eslint-disable-line no-unused-vars
  const frmSchema = Yup.object({
    simulation_id: Yup.number().integer().required(),
    demand_change_day: Yup.number()
      .integer()
      .min(0, "Must 0 or more")
      .required("Required"),
    new_patients_per_day: Yup.number()
      .min(0, "Must be 0 or more")
      .required("Required"),
  });

  const setData = (ailment) => {
    // Update the cached data now.
  };

  const SaveFormData = async (values) => {
    // Assemble the data
    var myDataCheck = { ...data, ...values };
    // Put it to the server
    if (!isNew) {
      await putPatientDemand(
        myDataCheck,
        (result) => {
          // Success
          if (result) {
            setData(myDataCheck);
            dataUpdated(myDataCheck);
            setIsDirty(true);
          } else {
            alert(
              "There has been a problem updating the data - the api did not respond correctly."
            );
          }
        },
        (error) => {
          // Failure
          alert("Error: " + JSON.stringify(error, null, 2));
        }
      );
    } else {
      await postPatientDemand(
        myDataCheck,
        (result) => {
          // Success
          if (result) {
            setData(myDataCheck);
            dataUpdated(myDataCheck);
            setIsDirty(true);
          } else {
            alert(
              "There has been a problem saving the data - the api did not respond correctly."
            );
          }
        },
        (error) => {
          // Failure
          alert("Error: " + JSON.stringify(error, null, 2));
        }
      );
    }
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          id: data["id"] || "",
          simulation_id: data["simulation_id"],
          demand_change_day: data["demand_change_day"] || 0,
          new_patients_per_day: data["new_patients_per_day"] || 0,
        }}
        validationSchema={frmSchema}
        onSubmit={async (values, { resetForm }) => {
          SaveFormData(values);
          resetForm({ values: "" });
          //alert(JSON.stringify(values, null, 2));
        }}
      >
        <div>
          <Form id="frmPatientDemand">
            <div className={styles.hours}>
              <TextField
                label="Demand change day"
                name="demand_change_day"
                type="number"
                maxLength="84"
              />
              <TextField
                label="New patients per day"
                name="new_patients_per_day"
                type="number"
                step="any"
                min="0"
              />
            </div>
          </Form>
        </div>
      </Formik>
      <div className={styles.buttonContainer}>
        <button
          className="btn btn-outline-primary submit"
          form="frmPatientDemand"
        >
          Submit
        </button>
      </div>
    </>
  );
};

export default PatiendDemand;
