export const removeTrailingSlash = (url) =>
  url.endsWith("/") ? url.substring(0, url.length - 1) : url;

export const removeLeadingSlash = (url) =>
  url.startsWith("/") ? url.substring(1) : url;

export const concatUrl = (partA, partB) =>
  `${removeTrailingSlash(partA)}/${removeLeadingSlash(partB)}`;

export const dayString = (day_number) => {
  var aDay = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  return aDay[day_number];
};
