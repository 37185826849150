import React, { useEffect, useRef, useState, useCallback } from "react";
import table from "../../../../table.module.scss";
import styles from "../../../../scss/style.module.scss";
import Table from "react-bootstrap/Table";
import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { dayString } from "../../../../common/urls";
import {
  roundNumber,
  hideIfZero,
  fixNumberString,
} from "../../../../common/math_functions";
import { GoEye } from "react-icons/go";
import ModalLayout from "../../../modal/Modal";
import ReportDataPopup from "./ReportDataPopup";
import IconSort from "../../../IconSort";
import moment from "moment";

const TableSingleConsult = ({
  form_group,
  selectedName,
  summaryData,
  sumData,
  updateSumDataItem,
  expGrossProRata,
  periodDays,
  useExpectedTotal,
  expectedTotal,
}) => {
  const updateSumDataItemRef = useRef(updateSumDataItem);
  const [popupJobPlanId, setPopupJobPlanId] = useState({ show: false, id: "" });
  const [orderColumn, setOrderColumn] = useState(
    selectedName && selectedName !== "*" && selectedName !== "%" ? 1 : 0
  );
  const [orderAsc, setOrderAsc] = useState(true);

  const modalClose = () => {
    setPopupJobPlanId({ show: false, id: "" });
  };

  const leaveAdjExpHours = useCallback(
    (expHrsGross) => {
      return (expHrsGross / 365) * periodDays * expGrossProRata;
    },
    [periodDays, expGrossProRata]
  ); // Add any variables it depends on here

  useEffect(() => {
    // Initialize totals
    let expectedSessions = 0;
    let expectedDuration = 0;
    let expectedDurationGross = 0;
    let expectedDurationLeaveAdj = 0;
    let expectedPA = 0;
    let sessionCount = 0;
    let totalExamTime = 0;
    let pa = 0;
    let rpt = 0;
    let paStd = 0;
    let paPrem = 0;

    // Iterate through the filtered data - Group specific
    summaryData &&
      summaryData.length > 0 &&
      summaryData
        .filter((item) => {
          return item.form_grouping === form_group;
        })
        .forEach((sim) => {
          expectedSessions += sim.expected_sessions;
          expectedDuration += sim.expected_duration;
          expectedDurationGross += sim.expected_duration_gross;
          expectedDurationLeaveAdj += leaveAdjExpHours(
            sim.expected_duration_gross
          );
          expectedPA += sim.expected_pa;
          sessionCount += sim.session_count;
          totalExamTime += sim.total_exam_time;
          pa += sim.actual_pa;
          rpt += sim.total_reports;
          paStd += sim.pa_standard;
          paPrem += sim.pa_premium;
        });

    // Update the state variables with the calculated totals
    updateSumDataItemRef.current(form_group, "expSess", expectedSessions);
    updateSumDataItemRef.current(form_group, "expHrs", expectedDuration);
    updateSumDataItemRef.current(
      form_group,
      "expHrsGross",
      expectedDurationGross
    );
    updateSumDataItemRef.current(
      form_group,
      "expHrsGrossLeaveAdj",
      expectedDurationLeaveAdj
    );

    updateSumDataItemRef.current(form_group, "expPa", expectedPA);
    updateSumDataItemRef.current(form_group, "actSess", sessionCount);
    updateSumDataItemRef.current(form_group, "actHrs", totalExamTime);
    updateSumDataItemRef.current(form_group, "actPa", pa);
    updateSumDataItemRef.current(form_group, "actRpt", rpt);
    updateSumDataItemRef.current(form_group, "paStandard", paStd);
    updateSumDataItemRef.current(form_group, "paPremium", paPrem);
  }, [
    summaryData,
    form_group,
    selectedName,
    updateSumDataItemRef,
    leaveAdjExpHours,
  ]);

  const columnColour = (exp, act) => {
    if (exp === undefined || exp === 0) return "";
    let percent = act / exp;
    if (percent >= 1) return "green";
    if (percent < 90) return "red";
    return "";
  };

  // Work out the colours
  function getColorForValue(value, isZeroHide) {
    // if (value === 0 || isNaN(value) || value === Infinity) return "#FFFFFF";
    if (form_group === 1 || form_group === 2) return "#32CD32"; // Green
    if (form_group === 3) return "#ffffff"; // Hide
    if (value === Infinity) return "#32CD32"; // Green
    if (isNaN(value)) {
      if (!isZeroHide) {
        return "#ffffff"; // Hide
      }
      return "#DC143C"; // Red
    }
    if (value < 70) {
      return "#DC143C"; // Red
    } else if (value < 90) {
      return "#ffc000"; // Amber
    } else {
      return "#32CD32"; // Green
    }
  }
  const cellStyle = (value, isZeroHide) => {
    return {
      color: "#ffffff",
      background: getColorForValue(value, isZeroHide),
      padding: "5px",
      borderRadius: 10,
      textAlign: "center",
      border: "3px solid white",
      alignItems: "center",
    };
  };

  // <Sorting of coluimns NAJ>
  const sortedTimes = (a, b) => {
    const timeA = moment(a, "HH:mm:ss");
    const timeB = moment(b, "HH:mm:ss");
    return timeA.valueOf() - timeB.valueOf();
  };
  const sortData = (a, b) => {
    if (orderColumn === 0 && orderAsc === true)
      return String(a.authoriser).localeCompare(b.authoriser);
    if (orderColumn === 0 && orderAsc === false)
      return String(b.authoriser).localeCompare(a.authoriser);

    if (orderColumn === 1 && orderAsc === true) {
      if (a.form_grouping === 2)
        return String(a.day_category).localeCompare(b.day_category);
      else return String(a.activity).localeCompare(b.activity);
    }
    if (orderColumn === 1 && orderAsc === false) {
      if (a.form_grouping === 2)
        return String(b.day_category).localeCompare(a.day_category);
      else return String(b.activity).localeCompare(a.activity);
    }

    if (orderColumn === 2 && orderAsc === true)
      return parseFloat(a.week_day) - parseFloat(b.week_day);
    if (orderColumn === 2 && orderAsc === false)
      return parseFloat(b.week_day) - parseFloat(a.week_day);

    // Time
    if (orderColumn === 3 && orderAsc === true)
      return sortedTimes(a.start_time, b.start_time);
    if (orderColumn === 3 && orderAsc === false)
      return sortedTimes(b.start_time, a.start_time);

    // Time
    if (orderColumn === 4 && orderAsc === true)
      return sortedTimes(a.end_time, b.end_time);
    if (orderColumn === 4 && orderAsc === false)
      return sortedTimes(b.end_time, a.end_time);

    if (orderColumn === 5 && orderAsc === true)
      return parseFloat(a.expected_pa) - parseFloat(b.expected_pa);
    if (orderColumn === 5 && orderAsc === false)
      return parseFloat(b.expected_pa) - parseFloat(a.expected_pa);

    if (orderColumn === 6 && orderAsc === true)
      return (
        parseFloat(a.expected_duration_gross) -
        parseFloat(b.expected_duration_gross)
      );
    if (orderColumn === 6 && orderAsc === false)
      return (
        parseFloat(b.expected_duration_gross) -
        parseFloat(a.expected_duration_gross)
      );

    if (orderColumn === 7 && orderAsc === true)
      return parseFloat(a.total_reports) - parseFloat(b.total_reports);
    if (orderColumn === 7 && orderAsc === false)
      return parseFloat(b.total_reports) - parseFloat(a.total_reports);

    if (orderColumn === 8 && orderAsc === true)
      return parseFloat(a.total_exam_time) - parseFloat(b.total_exam_time);
    if (orderColumn === 8 && orderAsc === false)
      return parseFloat(b.total_exam_time) - parseFloat(a.total_exam_time);

    if (orderColumn === 9 && orderAsc === true)
      if (useExpectedTotal !== 1) {
        return (
          parseFloat(
            a.total_exam_time / leaveAdjExpHours(a.expected_duration_gross)
          ) -
          parseFloat(
            b.total_exam_time / leaveAdjExpHours(b.expected_duration_gross)
          )
        );
      } else {
        return (
          parseFloat(a.total_exam_time / expectedTotal) -
          parseFloat(b.total_exam_time / expectedTotal)
        );
      }
    if (orderColumn === 9 && orderAsc === false)
      if (useExpectedTotal !== 1) {
        return (
          parseFloat(
            b.total_exam_time / leaveAdjExpHours(b.expected_duration_gross)
          ) -
          parseFloat(
            a.total_exam_time / leaveAdjExpHours(a.expected_duration_gross)
          )
        );
      } else {
        return (
          parseFloat(b.total_exam_time / expectedTotal) -
          parseFloat(a.total_exam_time / expectedTotal)
        );
      }
  };
  const onHeaderClick = (colNumber) => {
    if (colNumber === orderColumn) {
      // The column has been clicked again so simply change the order
      setOrderAsc(!orderAsc);
      return;
    }
    setOrderColumn(colNumber);
    setOrderAsc(true);
  };
  // </Sorting of coluimns NAJ>

  return (
    <React.Fragment>
      {selectedName && selectedName.length > 0 ? (
        <TableContainer>
          <Table className={table.dashboard} hover="true">
            <TableHead>
              <TableRow className={styles.borderedHeadRow}>
                {selectedName &&
                selectedName !== "*" &&
                selectedName !== "%" ? (
                  <React.Fragment></React.Fragment>
                ) : (
                  <React.Fragment>
                    <TableCell
                      className={styles.colChar128}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        onHeaderClick(0);
                      }}
                    >
                      Consultant{" "}
                      <IconSort
                        ThisColumnNumber={0}
                        OrderColumn={orderColumn}
                        OrderAsc={orderAsc}
                      />
                    </TableCell>
                  </React.Fragment>
                )}

                <TableCell
                  className={styles.colChar255}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    onHeaderClick(1);
                  }}
                >
                  {form_group === 2 ? "Segment" : "Activity"}{" "}
                  <IconSort
                    ThisColumnNumber={1}
                    OrderColumn={orderColumn}
                    OrderAsc={orderAsc}
                  />
                </TableCell>

                <TableCell
                  className={styles.colChar32}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    onHeaderClick(2);
                  }}
                >
                  Week day
                  <IconSort
                    ThisColumnNumber={2}
                    OrderColumn={orderColumn}
                    OrderAsc={orderAsc}
                  />
                </TableCell>

                <TableCell
                  className={styles.colTime}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    onHeaderClick(3);
                  }}
                >
                  Start
                  <IconSort
                    ThisColumnNumber={3}
                    OrderColumn={orderColumn}
                    OrderAsc={orderAsc}
                  />
                </TableCell>
                <TableCell
                  className={styles.colTime}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    onHeaderClick(4);
                  }}
                >
                  End
                  <IconSort
                    ThisColumnNumber={4}
                    OrderColumn={orderColumn}
                    OrderAsc={orderAsc}
                  />
                </TableCell>

                <TableCell
                  className={styles.colNumber}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    onHeaderClick(5);
                  }}
                >
                  PAs
                  <IconSort
                    ThisColumnNumber={5}
                    OrderColumn={orderColumn}
                    OrderAsc={orderAsc}
                  />
                </TableCell>
                <TableCell
                  className={styles.colNumber}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    onHeaderClick(6);
                  }}
                >
                  Exp. Hrs
                  <IconSort
                    ThisColumnNumber={6}
                    OrderColumn={orderColumn}
                    OrderAsc={orderAsc}
                  />
                </TableCell>

                <TableCell className={styles.colSeparator}></TableCell>
                <TableCell
                  className={styles.colNumber}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    onHeaderClick(7);
                  }}
                >
                  Rpt.
                  <IconSort
                    ThisColumnNumber={7}
                    OrderColumn={orderColumn}
                    OrderAsc={orderAsc}
                  />
                </TableCell>
                <TableCell
                  className={styles.colNumber}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    onHeaderClick(8);
                  }}
                >
                  Act. Hrs
                  <IconSort
                    ThisColumnNumber={8}
                    OrderColumn={orderColumn}
                    OrderAsc={orderAsc}
                  />
                </TableCell>
                <TableCell
                  className={styles.colNumberBig}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    onHeaderClick(9);
                  }}
                >
                  {form_group === 3 ? (
                    <></>
                  ) : (
                    <>
                      JP vs. Act.
                      <IconSort
                        ThisColumnNumber={9}
                        OrderColumn={orderColumn}
                        OrderAsc={orderAsc}
                      />
                    </>
                  )}
                </TableCell>
                <TableCell>Details</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {summaryData &&
                summaryData.length > 0 &&
                selectedName &&
                selectedName.length &&
                summaryData
                  .filter((item) => {
                    return item.form_grouping === form_group;
                  })
                  .sort(sortData)
                  .map((sim, index) => (
                    <TableRow key={index}>
                      {selectedName &&
                      selectedName !== "*" &&
                      selectedName !== "%" ? (
                        <React.Fragment></React.Fragment>
                      ) : (
                        <React.Fragment>
                          <TableCell>{sim.authoriser}</TableCell>
                        </React.Fragment>
                      )}
                      <TableCell>
                        {" "}
                        {sim.form_grouping === 2
                          ? sim.day_category
                          : sim.activity}
                      </TableCell>
                      <TableCell>{dayString(sim.week_day)}</TableCell>
                      <TableCell>{sim.start_time}</TableCell>
                      <TableCell>{sim.end_time}</TableCell>

                      {sim.form_grouping === 0 ||
                      sim.form_grouping === 1 ||
                      sim.form_grouping === 4 ? (
                        <>
                          <TableCell>
                            {roundNumber(sim.expected_pa, 2)}
                          </TableCell>
                          <TableCell>
                            {roundNumber(
                              leaveAdjExpHours(sim.expected_duration_gross),
                              2
                            )}
                          </TableCell>
                        </>
                      ) : (
                        <>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                        </>
                      )}
                      <TableCell></TableCell>
                      {sim.form_grouping !== 1 && sim.form_grouping !== 4 ? (
                        <>
                          <TableCell>
                            {roundNumber(sim.total_reports, 2)}
                          </TableCell>
                          <TableCell
                            style={{
                              color: columnColour(
                                sim.expected_duration,
                                sim.total_exam_time
                              ),
                            }}
                          >
                            {roundNumber(sim.total_exam_time, 2)}
                          </TableCell>
                          {useExpectedTotal === 1 ? (
                            <TableCell
                              style={cellStyle(
                                (sim.total_exam_time / expectedTotal) * 100
                              )}
                            >
                              {fixNumberString(
                                (sim.total_exam_time / expectedTotal) * 100,
                                1
                              )}
                            </TableCell>
                          ) : (
                            <TableCell
                              style={cellStyle(
                                (sim.total_exam_time /
                                  leaveAdjExpHours(
                                    sim.expected_duration_gross
                                  )) *
                                  100,
                                true
                              )}
                            >
                              {fixNumberString(
                                (sim.total_exam_time /
                                  leaveAdjExpHours(
                                    sim.expected_duration_gross
                                  )) *
                                  100,
                                1
                              )}
                            </TableCell>
                          )}
                          <TableCell>
                            {" "}
                            <span
                              style={{ margin: "0 10px" }}
                              role="button"
                              data-for="lock"
                              data-tip="Lock"
                              onClick={(e) => {
                                e.stopPropagation();
                                setPopupJobPlanId({
                                  show: true,
                                  id: sim.jobplan_id,
                                });
                              }}
                            >
                              <GoEye size={15} color="#7A2048" />
                            </span>
                          </TableCell>
                        </>
                      ) : (
                        <>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                        </>
                      )}
                    </TableRow>
                  ))}
              <TableRow key={"totals"}>
                {selectedName &&
                selectedName !== "*" &&
                selectedName !== "%" ? (
                  <></>
                ) : (
                  <>
                    <TableCell></TableCell>
                  </>
                )}
                <TableCell>
                  <b>Total</b>
                </TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell>
                  <b>{hideIfZero(roundNumber(sumData[form_group].expPa, 2))}</b>
                </TableCell>
                <TableCell>
                  <b>
                    {hideIfZero(
                      roundNumber(sumData[form_group].expHrsGrossLeaveAdj, 2)
                    )}
                  </b>
                </TableCell>

                <TableCell></TableCell>

                <TableCell>
                  <b>
                    {hideIfZero(roundNumber(sumData[form_group].actRpt, 2))}
                  </b>
                </TableCell>
                <TableCell>
                  <b>
                    {hideIfZero(roundNumber(sumData[form_group].actHrs, 2))}
                  </b>
                </TableCell>
                {useExpectedTotal === 1 ? (
                  <TableCell
                    style={cellStyle(
                      (sumData[form_group].actHrs / expectedTotal) * 100
                    )}
                  >
                    {hideIfZero(
                      fixNumberString(
                        (sumData[form_group].actHrs / expectedTotal) * 100,
                        1
                      )
                    )}
                  </TableCell>
                ) : (
                  <TableCell
                    style={cellStyle(
                      (sumData[form_group].actHrs /
                        sumData[form_group].expHrsGrossLeaveAdj) *
                        100
                    )}
                  >
                    {hideIfZero(
                      fixNumberString(
                        (sumData[form_group].actHrs /
                          sumData[form_group].expHrsGrossLeaveAdj) *
                          100,
                        1
                      )
                    )}
                  </TableCell>
                )}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <></>
      )}
      <ModalLayout
        title={"Detail"}
        show={popupJobPlanId.show}
        handleClose={modalClose}
        scrollbars={true}
        style={{ width: "1400px" }}
      >
        <ReportDataPopup
          id={popupJobPlanId.id}
          rptType={1}
          showAuthorisers={true}
        />
      </ModalLayout>
    </React.Fragment>
  );
};

export default TableSingleConsult;
