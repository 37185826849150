import React from "react";
import styles from "../indexOrg.module.scss";

const Footer = () => {
  return (
    <div className={styles.footerContainer}>
      <div className={styles.FooterBar1}></div>
      <div className={styles.FooterBar2}></div>
    </div>
  );
};

export default Footer;
