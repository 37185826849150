import React, { useContext } from "react";
import { ConsultIsLocked } from "../../Consult";
// import { GoCloudDownload } from "react-icons/go";
import { RiDownload2Fill } from "react-icons/ri";
import { convertJsonToCSV } from "../../../../common/export_csv";
import { ReportContext } from "./TabReports";
import { getJobPlanSelectedDetails } from "../../../../api/ApiConsultCalls";

const DownloadCSVTemplate = (summaryData) => {
  const [isLocked] = useContext(ConsultIsLocked);
  const [dataId, rptType, rptExamType, selectedName] =
    useContext(ReportContext);

  const DownLoadFileNow = () => {
    getJobPlanSelectedDetails(
      dataId,
      rptType,
      rptExamType,
      selectedName,
      (fullData) => {
        if (fullData) {
          const csvData = convertJsonToCSV(fullData);
          const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
          const url = URL.createObjectURL(blob);

          // Create a link and trigger the download
          const a = document.createElement("a");
          a.href = url;
          a.download = "data.csv"; // Default file name
          document.body.appendChild(a); // Required for Firefox
          a.click();
          document.body.removeChild(a);
          URL.revokeObjectURL(url); // Clean up
        }
      },
      (error) => {
        console.log("getJobPlanSelectedDetails Error: ", error);
      }
    );
  };

  return (
    <>
      <label
        className={`pointer ${
          isLocked || selectedName === "*" || selectedName === "%"
            ? "d-none"
            : ""
        }`}
        style={{ cursor: "pointer" }}
        onClick={DownLoadFileNow}
      >
        <RiDownload2Fill size={34} />
        &nbsp;{"  "}&nbsp; Download CSV
      </label>
    </>
  );
};

export default DownloadCSVTemplate;
