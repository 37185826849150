import { useEffect, useRef } from "react";

export function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export function debounceFunction() {
  let timeoutId;

  return (saveCallback) => {
    // Clear the previous timeout (if any)
    clearTimeout(timeoutId);

    // Set a new timeout to save after 2 seconds
    timeoutId = setTimeout(() => {
      saveCallback();
    }, 2000); // 2000 milliseconds = 2 seconds
  };
}
