import React, { useContext, useEffect, useState } from "react";
import styles from "../../../../scss/style.module.scss";
import table from "../../../../table.module.scss";
import { ConsultSettingsContext } from "../../Consult";
import { ConsultRefreshChildrenContext } from "../../Consult";
import Table from "react-bootstrap/Table";
import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { getJobPlanSummary } from "../../../../api/ApiConsultCalls";
import { fixNumberString } from "../../../../common/math_functions";

const JobPlanTable = () => {
  const [data] = useContext(ConsultSettingsContext); // eslint-disable-line no-unused-vars
  const refreshChild = useContext(ConsultRefreshChildrenContext);
  const [dsJobMap, setDsJobMap] = useState({});
  const [totalPAs, setTotalPAs] = useState(0);

  useEffect(() => {
    // Fetch the JobPlan details.
    if (data.id !== undefined) {
      getJobPlanSummary(
        data.id,
        (data) => {
          setDsJobMap(data);

          let tot = 0;

          // Iterate through the filtered data - Group specific
          data &&
            data.length > 0 &&
            data.forEach((sim) => {
              tot += sim.pa_standard + sim.pa_premium;
            });

          setTotalPAs(tot);
        },
        (error) => {}
      );
    }
  }, [data.id, refreshChild]);

  return (
    <>
      <div>
        <TableContainer>
          <Table className={table.dashboard} hover="true">
            <TableHead>
              <TableRow className={styles.borderedHeadRow}>
                <TableCell>Last Name</TableCell>
                <TableCell>First Name</TableCell>
                <TableCell>Normal PAs</TableCell>
                <TableCell>Premium PAs</TableCell>
                <TableCell>Total PAs</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dsJobMap &&
                dsJobMap.length > 0 &&
                dsJobMap.map((sim, index) => (
                  <TableRow key={index}>
                    <TableCell>{sim.last_name}</TableCell>
                    <TableCell>{sim.first_name}</TableCell>
                    <TableCell>{fixNumberString(sim.pa_standard, 2)}</TableCell>
                    <TableCell>{fixNumberString(sim.pa_premium, 2)}</TableCell>
                    <TableCell>
                      {fixNumberString(sim.pa_premium + sim.pa_standard, 2)}
                    </TableCell>
                  </TableRow>
                ))}

              {dsJobMap && dsJobMap.length > 0 && (
                <TableRow key={"total"}>
                  <TableCell>
                    <b>Total</b>
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell>
                    <b>{fixNumberString(totalPAs, 2)}</b>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};

export default JobPlanTable;
