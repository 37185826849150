import React, { useContext } from "react";
import { ConsultIsLocked } from "../../Consult";
// import { GoCloudDownload } from "react-icons/go";
import { RiDownload2Fill } from "react-icons/ri";
import { downloadExcelTemplateVetting } from "../../../../api/ApiConsultCalls";

const DownloadVettingTemplate = () => {
  const [isLocked] = useContext(ConsultIsLocked);
  const DownLoadFileNow = () => {
    downloadExcelTemplateVetting((error) => {});
  };

  return (
    <>
      <label
        className={`pointer ${isLocked ? "d-none" : ""}`}
        onClick={DownLoadFileNow}
      >
        <RiDownload2Fill size={34} />
        &nbsp;{"  "}&nbsp; Download Template
      </label>
    </>
  );
};

export default DownloadVettingTemplate;
