import React, { useState } from "react";

import { useNavigate } from "react-router-dom";

// Nice tutorial from:
// https://www.youtube.com/watch?v=u-CCnDayNJw
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Button } from "react-bootstrap";

import TextField from "./TextField";
import { userForgottenPassword } from "../api/ApiCoreCalls";
import styles from "../index.module.scss";
import DocLogo from "../images/doc_logo.png";
import PTLLogo from "../images/ptl_logo.png";
import { Spinner } from "react-bootstrap";
import progress from "./progress/progress.module.scss";
import { urlBase64Encode } from "../common/base64";

const LoginForgotten = ({ loggedIn }) => {
  const navigate = useNavigate();
  const [showSpinner, setShowSpinner] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [reminderSent, setReminderSent] = useState(false);

  const validate = Yup.object({
    eMail: Yup.string()
      .max(50, "Must be 50 chars or less")
      .required("Required"),
  });

  const FetchUser = async (values) => {
    //alert('made it here with: ' + JSON.stringify(values, null, 2));
    // Check the credentials
    setErrorMessage("");
    setShowSpinner(true);

    let decodedEmail = urlBase64Encode(values.eMail);

    // Work out the correct url for the email
    var urlForNewEmail =
      window.location.protocol + "//" + window.location.hostname;
    if (window.location.port > 0) {
      urlForNewEmail += ":" + window.location.port;
    }
    urlForNewEmail += "/api/newpassword";

    var myData = {
      eMail: decodedEmail,
      href: urlForNewEmail,
    };

    // Update the localStorage
    userForgottenPassword(
      myData,
      () => {
        // Success
        setReminderSent(true);
        setShowSpinner(false);
        setErrorMessage(
          "If your email was recognised then an email will have been sent."
        );
      },
      (error) => {
        // Failure
        setShowSpinner(false);
        if (error.message === "Unauthorized") {
          setErrorMessage("Login unsuccessful.");
        }
      }
    );

    // Finally redirect
    // navigate("/", { replace: true });
  };

  const returnToLogin = () => {
    navigate("/", { replace: true });
  };

  return (
    <div className={styles.containerBox}>
      <Formik
        initialValues={{
          eMail: "",
        }}
        validationSchema={validate}
        onSubmit={async (values) => {
          //console.log('Submit with these values:');
          //console.log(values)
          FetchUser(values);
          // setTimeout(() => {
          //   alert(JSON.stringify(values, null, 2));
          // }, 400);
        }}
      >
        {(formik) => (
          <div>
            <>
              <header className={styles.headerLogin}>
                <img
                  src={DocLogo}
                  alt="Doc Platform"
                  width={399 * 0.65}
                  height={157 * 0.65}
                />
                <br />
                <br />
                <img
                  src={PTLLogo}
                  alt="PTL Planning"
                  width={138 * 1.8}
                  height={112 * 1.8}
                />
                <br />
                <br />
              </header>
            </>
            <div className={styles.loginContainer}>
              Enter your email address and press reset.
              <br />
            </div>
            <br />
            <Form>
              <TextField
                label="Email"
                name="eMail"
                type="text"
                variant="outlined"
                maxLength="50"
                required="true"
              />

              {errorMessage && (
                <div className={styles.loginMessage}>{errorMessage}</div>
              )}

              <div className={styles.loginContainer}>
                {!reminderSent ? (
                  <Button className="btn float-end" type="submit">
                    Reset{" "}
                  </Button>
                ) : (
                  <>
                    <Button className="btn float-end" onClick={returnToLogin}>
                      Return to Login{" "}
                    </Button>
                  </>
                )}
              </div>

              {showSpinner && (
                <div className={styles.loginSpinner}>
                  <Spinner
                    as="span"
                    animation="border"
                    size="md"
                    role="status"
                    aria-hidden="true"
                    className={progress.spinner}
                  />
                </div>
              )}
            </Form>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default LoginForgotten;
