import { useTheme, IconButton } from "@mui/material";
import {
  ArrowLeft,
  ArrowRight,
  FirstPage,
  LastPage,
} from "@mui/icons-material";
import React from "react";
import tableStyles from "../table.module.scss";

const TablePaginationActions = (props) => {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  // span replacing Box from @mui/material - causes browser errors

  return (
    <span sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        className={tableStyles.paginationButton}
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPage /> : <FirstPage />}
      </IconButton>
      <IconButton
        className={tableStyles.paginationButton}
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? <ArrowRight /> : <ArrowLeft />}
      </IconButton>
      <IconButton
        className={tableStyles.paginationButton}
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? <ArrowLeft /> : <ArrowRight />}
      </IconButton>
      {page >= Math.ceil(count / rowsPerPage) - 1}
      <IconButton
        className={tableStyles.paginationButton}
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPage /> : <LastPage />}
      </IconButton>
    </span>
  );
};

export default TablePaginationActions;
