import React, { useState, useEffect, useContext, createContext } from "react";
import { useParams } from "react-router-dom"; // Used to prevent a re-load
import Model1Links from "./Model01_links";
import { Model1TabsInput } from "./Model01Tabs";
import {
  putSimulation,
  getSimulation,
  getSimAilments,
  getSimAcuity,
  getSimTheatreType,
  getSimTheatreSchedule,
  getSimTheatreLink,
} from "../../api/ApiPtlCalls";
import { ShallowEqual } from "../HelperFuctions";
import { HeaderTitle } from "../../App";

// Context used so changes cause refresh for all children attached
const Model01SimSettingsContext = React.createContext({}, () => {});
const Model01SimAilmentsContext = React.createContext({}, () => {});
const Model01SimAcuityContext = React.createContext({}, () => {});
const Model01SimTheatreContext = React.createContext({}, () => {});
const Model01SimTheatreLinkContext = React.createContext({}, () => {});
const Model01SimTheatreDiary = createContext({}, () => {});
const Model01RptRefreshContext = React.createContext({}, () => {});
const Model01PARptRefreshContext = React.createContext({}, () => {});
const Model01ScheduleRefreshContext = React.createContext({}, () => {});
const Model01RefreshAllContext = React.createContext(() => {});
const Model01IsDirty = React.createContext({}, () => {});
const Model01IsLocked = React.createContext({}, () => {});

const Model01 = () => {
  let { id } = useParams();
  // eslint-disable-next-line no-unused-vars
  const [simName, setSimName, lastSaved, setLastSaved] =
    useContext(HeaderTitle);

  // Setup State vars for the context
  const [dsSimSettings, setDsSimSettings] = useState({});
  const [dsSimAilments, setDsSimAilments] = useState({});
  const [dsSimAcuity, setDsSimAcuity] = useState({});
  const [diaryDetails, setSimTheatreSchedule] = useState({});
  const [dsSimTheatreTypes, setDsSimTheatreTypes] = useState({});
  const [dsSimTheatreLink, setDsSimTheatreLink] = useState({});
  const [toggleRptRefresh, setToggleRptRefresh] = useState(false);
  const [togglePAReportRefresh, setTogglePARptRefresh] = useState(false);
  const [toggleScheduleRefresh, setToggleScheduleRefresh] = useState(false);
  const [toggleRefreshAll, setToggleRefreshAll] = useState(false);
  const [sim_id, setSim_id] = useState(0);
  const [initialRunComplete, setInitialRunComplete] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [isLocked, setIsLocked] = useState(false);

  // Setup functions for writing the data
  const writeSimSettings = async (data) => {
    // At this level many child controls will be updated if the data changes
    // Make sure that this update only occurs when the data is difference

    // If the simulation is locked then it cannot be updated.
    if (!isLocked) {
      // console.log("Model01.writeSimSettings run");
      if (!ShallowEqual(data, dsSimSettings)) {
        // Write the changes to the single record
        if (data.id !== undefined) {
          await putSimulation(
            data,
            (result) => {
              // Success
              if (result) {
                // Update the state
                setDsSimSettings(data);
                setLastSaved(new Date().toISOString());
                if (sim_id !== dsSimSettings.simulation_id) {
                  setSim_id(dsSimSettings.simulation_id);
                }
              } else {
                alert(
                  "There has been a problem saving the data - the api did not respond correctly."
                );
              }
            },
            (error) => {
              // Failure
              alert("Error: " + JSON.stringify(error, null, 2));
            }
          );
        }
      }
    }
  };
  const refreshRptNow = () => {
    console.log("refreshRptNow run");
    setToggleRptRefresh(!toggleRptRefresh);
    setIsDirty(false); // This event gets fired when the report is run.
  };
  const refreshAllNow = () => {
    console.log("refreshAllNow run");
    setToggleRefreshAll(!toggleRefreshAll);
    refreshRptNow();
  };
  const refreshPARptNow = () => {
    console.log("refreshPARptNow run");
    setTogglePARptRefresh(!togglePAReportRefresh);
  };
  const refreshScheduleNow = () => {
    console.log("refreshScheduleNow run");
    setToggleScheduleRefresh(!toggleScheduleRefresh);
  };
  // Populate the state vars
  // Setup data and validation
  useEffect(() => {
    // Get the simulation data
    // console.log("fetchSingleSimulation run");
    const fetchSingleSimulation = async (sId) => {
      getSimulation(
        sId,
        (simData) => {
          // console.log(simData);
          setDsSimSettings(simData);
          if (sim_id !== simData.simulation_id) {
            setSim_id(simData.simulation_id);
            setInitialRunComplete(true);
            setIsDirty(simData.is_dirty);
            setSimName(simData.title);
            setIsLocked(simData.locked);
          }
        },
        (error) => {
          console.log(error);
        }
      );
    };

    fetchSingleSimulation(id);
  }, [id, sim_id, toggleRefreshAll, setSimName]);

  const fetchSchedule = async (sim_id) => {
    getSimTheatreSchedule(
      sim_id,
      (simData) => {
        // console.log("Acuity: ", simData);
        setSimTheatreSchedule(simData);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  // Get the child records data
  const fetchChildSimulation = async (sim_id) => {
    getSimTheatreLink(
      sim_id,
      (simData) => {
        // console.log("Acuity: ", simData);
        setDsSimTheatreLink(simData);
      },
      (error) => {
        console.log(error);
      }
    );
    getSimAcuity(
      sim_id,
      (simData) => {
        // console.log("Acuity: ", simData);
        setDsSimAcuity(simData);
      },
      (error) => {
        console.log(error);
      }
    );
    getSimTheatreType(
      sim_id,
      (simData) => {
        // console.log("Acuity: ", simData);
        setDsSimTheatreTypes(simData);
      },
      (error) => {
        console.log(error);
      }
    );
    getSimAilments(
      sim_id,
      (simData) => {
        // console.log("Ailments: ", simData);
        setDsSimAilments(simData);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  useEffect(() => {
    if (initialRunComplete) {
      // console.log("useEffect -> fetchChildSimulation run");
      fetchSchedule(sim_id);
      fetchChildSimulation(sim_id);
    }
  }, [toggleRefreshAll, sim_id, initialRunComplete]);

  useEffect(() => {
    if (initialRunComplete) {
      // console.log("useEffect -> fetchSchedule run");
      fetchSchedule(sim_id);
    }
  }, [sim_id, initialRunComplete, toggleScheduleRefresh]);

  return (
    <div>
      <Model01RefreshAllContext.Provider value={refreshAllNow}>
        <Model01IsDirty.Provider value={[isDirty, setIsDirty]}>
          <Model01IsLocked.Provider value={[isLocked, setIsLocked]}>
            <Model01SimSettingsContext.Provider
              value={[dsSimSettings, writeSimSettings]}
            >
              <Model01SimAilmentsContext.Provider
                value={[dsSimAilments, setDsSimAilments]}
              >
                <Model01SimTheatreContext.Provider
                  value={[dsSimTheatreTypes, setDsSimTheatreTypes]}
                >
                  <Model01SimTheatreDiary.Provider
                    value={[diaryDetails, setSimTheatreSchedule]}
                  >
                    <Model01SimTheatreLinkContext.Provider
                      value={[dsSimTheatreLink, setDsSimTheatreLink]}
                    >
                      <Model01SimAcuityContext.Provider
                        value={[dsSimAcuity, setDsSimAcuity]}
                      >
                        <Model01RptRefreshContext.Provider
                          value={[toggleRptRefresh, refreshRptNow]}
                        >
                          <Model01PARptRefreshContext.Provider
                            value={[togglePAReportRefresh, refreshPARptNow]}
                          >
                            <Model01ScheduleRefreshContext.Provider
                              value={[refreshScheduleNow]}
                            >
                              <Model1Links selected="Simulation" />

                              <Model1TabsInput simGuid={id} />
                            </Model01ScheduleRefreshContext.Provider>
                          </Model01PARptRefreshContext.Provider>
                        </Model01RptRefreshContext.Provider>
                      </Model01SimAcuityContext.Provider>
                    </Model01SimTheatreLinkContext.Provider>
                  </Model01SimTheatreDiary.Provider>
                </Model01SimTheatreContext.Provider>
              </Model01SimAilmentsContext.Provider>
            </Model01SimSettingsContext.Provider>
          </Model01IsLocked.Provider>
        </Model01IsDirty.Provider>
      </Model01RefreshAllContext.Provider>
    </div>
  );
};

export {
  Model01,
  Model01SimSettingsContext,
  Model01SimAilmentsContext,
  Model01SimAcuityContext,
  Model01SimTheatreContext,
  Model01SimTheatreLinkContext,
  Model01RptRefreshContext,
  Model01PARptRefreshContext,
  Model01ScheduleRefreshContext,
  Model01RefreshAllContext,
  Model01IsDirty,
  Model01IsLocked,
  Model01SimTheatreDiary,
};
