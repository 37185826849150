import React from "react";
import Table from "react-bootstrap/Table";
import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  numberWithCommas,
  roundNumber,
  hideIfZero,
} from "../../../../common/math_functions";
import styles from "../../../../scss/style.module.scss";
import table from "../../../../table.module.scss";

const ReportActEcpLeaveTable = ({ sumData }) => {
  return (
    <React.Fragment>
      <TableContainer className={table.page_middle}>
        <Table className={`${table.dashboard}`} hover="true">
          <TableHead>
            <TableRow className={styles.borderedHeadRow}>
              <TableCell className={styles.colChar32}></TableCell>
              <TableCell className={styles.colNumber}>Act.</TableCell>
              <TableCell className={styles.colNumber}>Exp.</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow key={0}>
              <TableCell>Annual Leave</TableCell>
              <TableCell>
                {numberWithCommas(roundNumber(sumData.actLeave, 1))}
              </TableCell>
              <TableCell>
                {hideIfZero(numberWithCommas(roundNumber(sumData.expLeave, 1)))}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
};

export default ReportActEcpLeaveTable;
