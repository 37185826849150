import { removeTrailingSlash } from "../common/urls";
import {
  get,
  put,
  post,
  del,
  add,
  // getText,
  getFileDownload,
  add_with_timeout,
} from "./FetchVerbs";

const api_url = removeTrailingSlash(process.env.REACT_APP_CONSULT_API_URL);

// *************************************************************************************
// Check
// *************************************************************************************

export const checkVettingDifferences = async (id, callback, failFunction) => {
  const url = api_url + "/api/check/vet/" + id;
  await get(url, "Error checking vetting data", callback, failFunction);
};

export const checkActivityDifferences = async (id, callback, failFunction) => {
  const url = api_url + "/api/check/act/" + id;
  await get(url, "Error checking activity data", callback, failFunction);
};

export const importRecordsIfNecessary = async (id, callback, failFunction) => {
  const url = api_url + "/api/analysishead/import/" + id;
  await post(
    url,
    "",
    "Error with import data for analysis:",
    callback,
    failFunction
  );
};

// *************************************************************************************
// Vetting
// *************************************************************************************

export const getVetting = async (id, callback, failFunction) => {
  const url = api_url + "/api/vetting/getall/" + id;
  await get(url, "Error getting vetting data", callback, failFunction);
};

export const postVettingFile = async (
  file,
  analysis_id,
  callback,
  failFunction
) => {
  const url = api_url + "/api/vetting";
  const params = {
    id: analysis_id,
  };
  add_with_timeout(
    file,
    params,
    url,
    "Error uploading vetting data file: ",
    callback,
    failFunction,
    10000
  );
};

// *************************************************************************************
// Leave
// *************************************************************************************

export const postLeaveFile = async (
  file,
  analysis_id,
  callback,
  failFunction
) => {
  const url = api_url + "/api/leavelist";
  const params = {
    id: analysis_id,
  };
  add(
    file,
    params,
    url,
    "Error uploading leave data file: ",
    callback,
    failFunction
  );
};

// Used to get the consultand names and the pa totals
export const getLeaveSummary = async (id, callback, failFunction) => {
  const url = api_url + "/api/leavelist/summ/" + id;
  await get(url, "Error getting leave summary", callback, failFunction);
};

export const getLeaveReporting = async (id, callback, failFunction) => {
  const url = api_url + "/api/leavelist/rpt/" + id;
  await get(url, "Error getting leave report", callback, failFunction);
};

export const downloadExcelTemplateLeave = async (failFunction) => {
  // Replace 'fileName' with the actual file name you want to download
  const fileName = "LeaveTemplate.xlsx";
  const url = api_url + "/api/template/" + fileName;
  await getFileDownload(
    url,
    "Error download template file",
    fileName,
    failFunction
  );
};

// *************************************************************************************
// Exam Types
// *************************************************************************************

export const getGlobalExamTypes = async (callback, failFunction) => {
  const url = api_url + "/api/examtype/getall";
  await get(
    url,
    "Error getting list of global exam types:",
    callback,
    failFunction
  );
};

export const getExamTypesAdditions = async (id, callback, failFunction) => {
  const url = api_url + "/api/examtypeaddition/getall/" + id;
  await get(
    url,
    "Error getting list of additional exam types:",
    callback,
    failFunction
  );
};

export const getExamTypesAdditionsFull = async (id, callback, failFunction) => {
  const url = api_url + "/api/examtypeaddition/getallfull/" + id;
  await get(
    url,
    "Error getting list of additional exam types:",
    callback,
    failFunction
  );
};

export const posttExamTypesAdditional = async (
  data,
  callback,
  failFunction
) => {
  const url = api_url + "/api/examtypeaddition/";
  await post(
    url,
    data,
    "Error with post time segment:",
    callback,
    failFunction
  );
};

// *************************************************************************************
// Time Segments
// *************************************************************************************

export const getTimeSegments = async (id, callback, failFunction) => {
  const url = api_url + "/api/timesegment/getall/" + id;
  await get(
    url,
    "Error getting list of time segments:",
    callback,
    failFunction
  );
};
export const putTimeSegment = async (data, callback, failFunction) => {
  const url = api_url + "/api/timesegment/" + data["id"];
  await put(url, data, "Error with put time segment:", callback, failFunction);
};
// Add New Record
export const postTimeSegment = async (data, callback, failFunction) => {
  const url = api_url + "/api/timesegment";
  await post(
    url,
    data,
    "Error with post new time segment:",
    callback,
    failFunction
  );
};
export const getTimeSegment = async (id, callback, failFunction) => {
  const url = api_url + "/api/timesegment/" + id;
  await get(
    url,
    "Error getting list of time segments:",
    callback,
    failFunction
  );
};
export const delTimeSegment = async (id, callback, failFunction) => {
  const url = api_url + "/api/timesegment/" + id;
  await del(url, "Error with delete time segment:", callback, failFunction);
};

// *************************************************************************************
// JobPlan activity duration
// *************************************************************************************

export const getJobPlanActivityDuration = async (
  id,
  callback,
  failFunction
) => {
  const url = api_url + "/api/jpactivity/list/" + id;
  await get(
    url,
    "Error getting job plan activity duration",
    callback,
    failFunction
  );
};

export const putJobPlanActivityDuration = async (
  data,
  callback,
  failFunction
) => {
  const url = api_url + "/api/jpactivity/" + data["id"];
  await put(
    url,
    data,
    "Error with put job plan activity duration changes:",
    callback,
    failFunction
  );
};

// *************************************************************************************
// JobPlan
// *************************************************************************************

export const getJobPlan = async (id, callback, failFunction) => {
  const url = api_url + "/api/simsessiondemand/getall/" + id;
  await get(
    url,
    "Error getting analysis sessiondemand:",
    callback,
    failFunction
  );
};

// Used to get the consultand names and the pa totals
export const getJobPlanSummary = async (id, callback, failFunction) => {
  const url = api_url + "/api/jobplanlist/summ/" + id;
  await get(url, "Error getting job plan summary", callback, failFunction);
};
// {{url_local_consult}}/api/jobplanlist/summ/{{id}}

export const postJobPlanFile = async (
  file,
  analysis_id,
  callback,
  failFunction
) => {
  const url = api_url + "/api/jobplanlist";
  const params = {
    id: analysis_id,
  };
  add(
    file,
    params,
    url,
    "Error uploading layer data file: ",
    callback,
    failFunction
  );
};

export const downloadExcelTemplateJobPlan = async (failFunction) => {
  // Replace 'fileName' with the actual file name you want to download
  const fileName = "JobPlanTemplate.xlsx";
  const url = api_url + "/api/template/" + fileName;
  await getFileDownload(
    url,
    "Error download template file",
    fileName,
    failFunction
  );
};

// Used to get the jobplan list of items and then the settings names and the pa totals
export const getJobPlanFilterOptions = async (id, callback, failFunction) => {
  const url = api_url + "/api/jobplanlist/filter/" + id;
  await get(url, "Error getting job plan filter data", callback, failFunction);
};

export const getJobPlanActivities = async (id, callback, failFunction) => {
  const url = api_url + "/api/jobplanlist/activites/" + id;
  await get(url, "Error getting job plan activity", callback, failFunction);
};

// Used to get the jobplan details for reporting
export const getJobPlanDetails = async (
  id,
  rptType,
  callback,
  failFunction
) => {
  const url = api_url + "/api/rptdata/getdetail/" + rptType + "/" + id;
  await get(url, "Error getting job plan details", callback, failFunction);
};

export const getJobPlanSelectedDetails = async (
  dataId,
  rptType,
  rptExamType,
  selectedName,
  callback,
  failFunction
) => {
  const url =
    api_url +
    "/api/rptdata/getselecteddetail/" +
    rptType +
    "/" +
    rptExamType +
    "/" +
    dataId +
    "/" +
    selectedName;
  await get(
    url,
    "Error getting job plan selection details",
    callback,
    failFunction
  );
};

// *************************************************************************************
// Activity
// *************************************************************************************

export const postActivityFile = async (
  file,
  analysis_id,
  callback,
  failFunction
) => {
  const url = api_url + "/api/activitylist";
  const params = {
    id: analysis_id,
  };
  add_with_timeout(
    file,
    params,
    url,
    "Error uploading layer data file: ",
    callback,
    failFunction,
    10000
  );
};

// Used to get the consultand names and the pa totals
export const getActivitySummary = async (id, callback, failFunction) => {
  const url = api_url + "/api/activitylist/summ/" + id;
  await get(url, "Error getting activity summary", callback, failFunction);
};

export const getActivityNoMatch = async (id, callback, failFunction) => {
  const url = api_url + "/api/activitylist/nomatch/" + id;
  await get(url, "Error getting activity filter", callback, failFunction);
};

// *************************************************************************************
// Reporting
// *************************************************************************************

export const getReportNICIPTable = async (
  id,
  rptType,
  selectedName,
  callback,
  failFunction
) => {
  const url =
    api_url +
    "/api/rptdata/getnicip/" +
    rptType +
    "/" +
    selectedName +
    "/" +
    id;
  await get(url, "Error getting nicip report", callback, failFunction);
};

export const getReportSummary = async (
  id,
  rptType,
  rptStandard,
  callback,
  failFunction
) => {
  const url =
    api_url +
    "/api/report/getresults/" +
    rptType +
    "/" +
    rptStandard +
    "/" +
    id;
  await get(url, "Error getting report summary", callback, failFunction);
};

// *************************************************************************************
// Settings
// *************************************************************************************

export const getProgress = async (id, callback, failFunction) => {
  const url = api_url + "/api/analysis_status/" + id;
  await get(url, "Error getting model status:", callback, failFunction);
};

export const getAnalysisList = async (callback, failFunction) => {
  const url = api_url + "/api/analysishead/getall";
  await get(url, "Error getting analysis list:", callback, failFunction);
};

export const getAnalysis = async (simGuid, callback, failFunction) => {
  const url = api_url + "/api/analysishead/" + simGuid;
  await get(url, "Error getting analysis:", callback, failFunction);
};
// Create New
export const postAnalysis = async (data, callback, failFunction) => {
  const url = api_url + "/api/analysishead";
  await post(
    url,
    data,
    "Error with post new analysis:",
    callback,
    failFunction
  );
};

// Create duplicate
export const duplicateAnalysis = async (simGuid, callback, failFunction) => {
  const url = api_url + "/api/analysishead/" + simGuid;
  await post(
    url,
    "",
    "Error with creating duplicate analysis:",
    callback,
    failFunction
  );
};

// Update existing
export const putAnalysis = async (data, callback, failFunction) => {
  const url = api_url + "/api/analysishead/" + data["id"];
  await put(
    url,
    data,
    "Error with put analysis changes:",
    callback,
    failFunction
  );
};

export const delAnalysis = async (dataId, callback, failFunction) => {
  const url = api_url + "/api/analysishead/" + dataId;
  await del(url, "Error with delete analysis:", callback, failFunction);
};

export const lockAnalysis = async (simGuid, callback, failFunction) => {
  const url = api_url + "/api/analysishead/lock/" + simGuid;
  await post(url, "", "Error with lock analysis:", callback, failFunction);
};

export const downloadExcelTemplateActivity = async (failFunction) => {
  // Replace 'fileName' with the actual file name you want to download
  const fileName = "ActivityTemplate.xlsx";
  const url = api_url + "/api/template/" + fileName;
  await getFileDownload(
    url,
    "Error download template file",
    fileName,
    failFunction
  );
};

export const downloadExcelTemplateVetting = async (failFunction) => {
  // Replace 'fileName' with the actual file name you want to download
  const fileName = "VettingTemplate.xlsx";
  const url = api_url + "/api/template/" + fileName;
  await getFileDownload(
    url,
    "Error download template file",
    fileName,
    failFunction
  );
};
