import React from "react";
import { IoPerson } from "react-icons/io5";
import index from '../indexOrg.module.scss';

const HeaderUser = ({ text }) => {
  return (
    <div className={index.header}>
      <IoPerson />
      {text}
    </div>
  );
};
export default HeaderUser;
