import React, { useContext, useState } from "react";
import AilmentGrid from "./AilmentGrid";
import PatientDemandGrid from "./PatientDemandGrid";
import Settings from "./Settings";
import { ModalSimInputContext } from "../Model01Tabs";
import styles from "../../../scss/style.module.scss";

const TabDemand = ({ tabNo }) => {
  const validateTab = useContext(ModalSimInputContext);
  const [ctlValidation, setCtlValidation] = useState([false, false]);
  const ctlValid = (controlNumber, value) => {
    ctlValidation[controlNumber] = value;
    setCtlValidation(ctlValidation);
    validateTab(
      tabNo,
      ctlValidation.every((element) => element === true)
    );
  };
  return (
    <>
      <div className={styles.flexColumn}>
        <div>
          <h3>New Patient Demand</h3>
          <Settings ctrlValid={ctlValid} ctrlNumber="0" displayType="demand" />
        </div>
      </div>

      <div style={{ marginBottom: "35px" }}>
        <h3>Proportion of new patients with each condition</h3>
        <AilmentGrid ctrlValid={ctlValid} ctrlNumber="1" displayType="demand" />
      </div>
      <div>
        <PatientDemandGrid ctrlValid={ctlValid} ctrlNumber="2" />
      </div>
    </>
  );
};

export default TabDemand;
