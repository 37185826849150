import React, { useContext, useEffect, useState } from "react";
import { Formik, Form, useFormikContext } from "formik";
import * as Yup from "yup";
import { ConsultSettingsContext } from "../../Consult";
import TextField from "../../../TextField";
import AutoSave from "../../../FormikAutoSave";
import { isEqualJson } from "../../../../common/json_compare";
import moment from "moment";
import RadioGroupCtrl from "../../../RadioGroupCtrl";
import { CheckErrors, CheckAlerts } from "../../Consult";
import ModalConfirm from "../../../modal/ModalConfirm";
import { importRecordsIfNecessary } from "../../../../api/ApiConsultCalls";
import {} from "../../Consult";
import { MdOutlineClose } from "react-icons/md";

import styles from "../../../../scss/style.module.scss";

const ConsultSettings = ({ ctrlValid, ctrlNumber, displayType }) => {
  const [data, writeSimSettings] = useContext(ConsultSettingsContext);
  const [checkApiErrors, setCheckApiErrors] = useContext(CheckErrors);
  const [alerts, setAlerts] = useContext(CheckAlerts);
  const [showImportPopup, setShowImportPopup] = useState(false);
  var schema = {};
  var initValues = {};

  schema = {
    title: Yup.string()
      .max(50, "Must be 50 chars or less")
      .required("Required"),
    start_date: Yup.string().required("Required"),
    end_date: Yup.string().required("Required"),
    automated_import: Yup.number()
      .integer()
      .min(0, "Must be positive")
      .max(2, "Must be 2 or less")
      .required(),
  };
  initValues = {
    title: data["title"] || "",
    start_date: data["start_date"] || "",
    end_date: data["end_date"] || "",
    automated_import: data["automated_import"] || 0,
  };

  const frmSchema = Yup.object(schema);

  const SaveFormData = async (values) => {
    values.start_time = moment(values.start_time, "HH:mm:ss").format(
      "HH:mm:ss"
    );
    values.end_time = moment(values.end_time, "HH:mm:ss").format("HH:mm:ss");
    // Assemble the data
    var myDataCheck = { ...data, ...values };
    // Check there is a difference.
    if (!isEqualJson(data, myDataCheck)) {
      // Send it up the tree
      writeSimSettings(myDataCheck);
    }
  };

  const FormikFormObserver = () => {
    // When data is changed validate it
    const { values } = useFormikContext();
    useEffect(() => {
      const result = frmSchema.isValid(values, { strict: false });
      result.then((value) => {
        if (ctrlValid !== undefined) {
          ctrlValid(ctrlNumber, value);
        }
      });
    }, [values]);
    return;
  };

  // Vetting / Import popup functions below
  useEffect(() => {
    if (
      checkApiErrors.includes("VettingImportErrors") ||
      checkApiErrors.includes("ActivityImportErrors")
    ) {
      setShowImportPopup(true);
    }
  }, [checkApiErrors]);

  const ImportRecords = () => {
    // Action an API refresh for the import records
    importRecordsIfNecessary(
      data.id,
      () => {
        // Close the popup
        modalImportClose();
        // Remove the entry from checkApiErrors
        // Update the state
        setCheckApiErrors(
          checkApiErrors.filter(
            (error) =>
              error !== "VettingImportErrors" &&
              error !== "ActivityImportErrors"
          )
        );
      },
      (error) => {
        console.log(error);
      }
    );
  };
  const modalImportClose = () => {
    setShowImportPopup(false);
  };
  const modalImportOpen = () => {
    setShowImportPopup(true);
  };

  const removeAlertMessage = (text) => {
    setAlerts(alerts.filter((a) => a !== text));
  };

  // Vetting / Import popup functions above

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initValues}
      validationSchema={frmSchema}
      onSubmit={async (values) => {
        SaveFormData(values);
        //alert(JSON.stringify(values, null, 2));
      }}
    >
      {(formik) => (
        <div>
          <Form>
            <div>
              <small
                className="float-end"
                style={{ color: "gray", fontSize: 11 }}
              >
                <AutoSave debounceMs={2000} />
              </small>
            </div>
            <FormikFormObserver frmSchema={frmSchema} />

            <>
              <div className={styles.form_halves}>
                <div className={styles.left_control_box}>
                  <h5>1. Choose how to enter activity data</h5>
                  <RadioGroupCtrl
                    label=""
                    name="automated_import"
                    options={[
                      { value: 0, label: "Manual upload" },
                      { value: 1, label: "Automated Reporting import" },
                      {
                        value: 2,
                        label: "Automated Reporting & Vetting import",
                      },
                    ]}
                  />
                </div>
                <div className={styles.right_control}></div>
              </div>

              <div className={styles.form_halves}>
                <div className={styles.left_control_box}>
                  <h5>
                    2. Create name of analysis and start/end dates of analysis
                    period
                  </h5>
                  <TextField
                    label="Analysis Name"
                    name="title"
                    type="text"
                    maxLength="256"
                  />
                  <TextField
                    label="Start Date"
                    name="start_date"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <TextField
                    label="End Date"
                    name="end_date"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div className={styles.right_control}></div>
              </div>

              <div className={styles.filterForm}></div>
            </>
          </Form>
          <div>
            {checkApiErrors && (
              <React.Fragment>
                <br />
                <span
                  className={`alert alert-danger pointer ${
                    checkApiErrors.includes("VettingImportErrors") ||
                    checkApiErrors.includes("ActivityImportErrors")
                      ? ""
                      : "d-none"
                  }`}
                  onClick={modalImportOpen}
                  style={{ cursor: "pointer" }}
                >
                  Click to see show error popup.
                </span>
              </React.Fragment>
            )}
          </div>

          {alerts &&
            alerts.map((msg, index) => (
              <div
                key={index}
                className="alert alert-info pointer"
                onClick={() => {
                  removeAlertMessage(msg);
                }}
              >
                {msg}
                <span
                  type="button"
                  className="close-button"
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent click from triggering the alert's onClick
                    removeAlertMessage(msg);
                  }}
                  style={{ float: "right", cursor: "pointer" }}
                >
                  <MdOutlineClose size={25} color="#008181" />
                </span>
              </div>
            ))}

          <ModalConfirm
            show={showImportPopup}
            handleClose={modalImportClose}
            title="Please confirm you want to append new information"
            desc="New records have been imported. Those used on the calculations are not up to date. Please confirm you would like to import these new records. Note that once imported the figures for this analysis will be re-calculated."
            confirmed={ImportRecords}
          ></ModalConfirm>
        </div>
      )}
    </Formik>
  );
};

export default ConsultSettings;
