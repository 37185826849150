import React, {
  useState,
  useContext,
  useRef,
  useEffect,
  useCallback,
} from "react";
import { postVettingFile, getProgress } from "../../../../api/ApiConsultCalls";
import {
  ConsultRefreshAllContext,
  ConsultSettingsContext,
  ConsultIsDirty,
  ConsultIsLocked,
} from "../../Consult";
import StatusOfProcessGeneric from "../../../progress/StatusOfProcessGeneric";
import { GoCloudUpload } from "react-icons/go";
import styles from "../../../../indexOrg.module.scss";
import stylesDiv from "../../../../scss/style.module.scss";
import progress from "../../../../scss/style.module.scss";
import DownloadVettingTemplate from "./DownloadVettingTemplate";
import UploadedFileName from "../../../UploadedFileName";
import VettingDuration from "./VettingDuration";

const UploadVetting = (disabled) => {
  const [data, writeSimSettings] = useContext(ConsultSettingsContext); // eslint-disable-line no-unused-vars
  const [isDirty, setIsDirty] = useContext(ConsultIsDirty); // eslint-disable-line no-unused-vars
  const [messages, setMessages] = useState([]);
  const [errors, setErrors] = useState([]);
  const [selectedFile, setSelectedFile] = useState();
  const [isSelected, setIsSelected] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isLocked] = useContext(ConsultIsLocked);
  const [hideUpload, setHideUpload] = useState(false);

  const fileInput = useRef(null);
  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    setIsSelected(true);
    // handleSubmission();
  };

  // eslint-disable-next-line no-unused-vars
  const [isSysAdmin, setIsSysAdmin] = useState(
    localStorage.getItem("isSysAdmin").replace(/['"]+/g, "")
  );

  // Grab the function for if there is a ptl upload
  // Refeshing all here as the analysis head gets updated with the activity dates during upload
  const refreshAll = useContext(ConsultRefreshAllContext);

  const handleSubmission = useCallback(() => {
    if (isSelected) {
      if (fileInput && fileInput.current && fileInput.current.files) {
        if (fileInput.current.files.length === 0) {
          alert("No files yet!");
        } else {
          setIsUploading(true);
          (async () => {
            await postVettingFile(
              selectedFile,
              data.id,
              (result) => {
                result.then(function (res) {
                  console.log(res); // "Some User token"
                  // Angus says users not interested in messages.
                  //setMessages(res.messages);
                  setErrors(res.errors);
                  refreshAll();
                  // if (errors.length === 0) {
                  //   refreshAll();
                  // }
                });

                // Success

                if (result) {
                  setIsDirty(true);
                  setSelectedFile();
                  setIsSelected(false);
                } else {
                  setMessages([
                    "There has been a problem updating the data - the api did not respond correctly. Please try again.",
                  ]);
                }
                setIsUploading(false);
              },
              (error) => {
                if (error === "Request timed out") {
                  // This is a handled error.
                  // The busy circle will monitor until 100%. There is no need to alert the user.
                  // setMessages([
                  //   "A timeout has been reached. The processing will continue but this must be a big file! Please give it a minute or two.",
                  // ]);
                } else {
                  // Failure
                  setIsUploading(false);
                  alert("Error: " + JSON.stringify(error, null, 2));
                }
              },
              360000 // Three minutes
            );
          })();
        }
      }
    }
    setHideUpload(data.automated_import === 2);
  }, [
    isSelected,
    data.id,
    data.automated_import,
    refreshAll,
    selectedFile,
    setIsDirty,
  ]);

  useEffect(() => {
    handleSubmission();
  }, [fileInput, handleSubmission]);

  const OneHundredPercent = (isEscape) => {
    console.log("Made it to 100% function");
    if (isEscape) {
      setMessages([
        "Escape has been pressed. Please manually refresh the form to update the activity table below when the upload processing is complete.",
      ]);
    }
    setIsUploading(false);
    setIsDirty(true);
    setSelectedFile();
    setIsSelected(false);
    refreshAll();
  };

  return (
    <div>
      {isUploading ? (
        <>
          <div className={progress.progressSpinner}>
            <StatusOfProcessGeneric
              isActive={isUploading}
              sim_guid={data.id}
              getModellingStatus={getProgress}
              oneHundredPercent={OneHundredPercent}
            />
          </div>
        </>
      ) : (
        <>
          <div className={progress.label_container}>
            <label
              className={`pointer ${isLocked || hideUpload ? "d-none" : ""}`}
            >
              <input
                type="file"
                name="file"
                className={styles.hidden}
                onChange={(e) => changeHandler(e)}
                // onChangeCapture={() => handleSubmission()}
                ref={fileInput}
              />
              <GoCloudUpload size={35} />
              &nbsp;{"  "}&nbsp; Upload Vetting List
            </label>{" "}
            {"  "}
            <DownloadVettingTemplate />
          </div>
          <div className={stylesDiv.form_halves_min}>
            <div className={styles.left_control_box}>
              <UploadedFileName data={data} upload_type={"vetting"} />
            </div>
            <div className={styles.left_control_box}>
              <br />
              <VettingDuration />
            </div>
          </div>
        </>
      )}

      {messages &&
        messages.map((msg, index) => (
          <div key={index} className="alert alert-success">
            {msg}
          </div>
        ))}

      {errors && errors.length > 0 && (
        <>
          <br />
          <br />
          There is one or more errors with the uploaded file.
        </>
      )}

      {errors &&
        errors.map((msg, index) => (
          <div key={index} className="alert alert-danger">
            {msg}
          </div>
        ))}
    </div>
  );
};

export default UploadVetting;
