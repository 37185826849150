import React, { createContext } from "react";
import { useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

import "./topLevel.css";
import Header from "./components/HeaderB";
// import { Model01 } from "./components/md_01/Model01";
import { Model01 } from "./components/md_01/Model01";
import { Consult } from "./components/consult/Consult";

// import Model01Anchor from "./components/md_01/Model01_anchor";
import Model1Anchor from "./components/md_01/Model01_anchor";
import ConsultAnchor from "./components/consult/Consult_anchor";
import Login from "./components/Login";
import LoginForgotten from "./components/LoginForgotten";
import NewPassword from "./components/NewPassword";
import CompanyGrid from "./components/admin/CompanyGrid";
import UserGrid from "./components/admin/UserGrid";
import Footer from "./common/Footer";
import styles from "./indexOrg.module.scss";
import ProductFruitCtrl from "./components/ProductFruitCtrl";
import { CurrentAppContextProvider } from "./components/contexts/CurrentAppContext";

const HeaderTitle = createContext({}, () => {});

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const [loggedIn, setLoggedIn] = useState(() => {
    const token = localStorage.getItem("token");
    // const initialValue = JSON.parse(token);
    //console.log("Token", token);
    var isLoggedIn = false;

    if ((token || "").length > 0) {
      isLoggedIn =
        localStorage.getItem("expiry") > Math.floor(Date.now() / 1000);
    }
    if (location.pathname.includes("/newpassword/")) {
      isLoggedIn = false;
    }

    return isLoggedIn;
  });

  // eslint-disable-next-line no-unused-vars
  const [urlToken, setUrlToken] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [newPassword, setNewPassword] = useState(() => {
    if (location === undefined || location.pathname === undefined) return false;
    if (!location.pathname.includes("/newpassword/")) return false;

    console.log(location.pathname);
    console.log("Index: ", location.pathname.includes("/newpassword/"));
    var pos = location.pathname.includes("/newpassword/");
    setUrlToken(location.pathname.substring(pos + 16));
    // Log out the user:
    localStorage.setItem("token", "");
    setLoggedIn(false);
    return true;
  });

  const [simName, setSimName] = useState("");
  const [lastSaved, setLastSaved] = useState("");
  const [backUrl, setBackUrl] = useState("/");
  const [sysAdmin, setSysAdmin] = useState(() => {
    return localStorage.getItem("isSysAdmin");
  });
  const [userName, setUserName] = useState(() => {
    return localStorage.getItem("userName");
  });
  const gsetLoggedIn = async (value) => {
    setLoggedIn(value);
    setSysAdmin(localStorage.getItem("isSysAdmin"));
    setUserName(localStorage.getItem("userName"));
  };
  // Log the user out and redirect to the home page. Calls to this come from the header - cross page
  const logout = async () => {
    localStorage.setItem("token", "");
    setLoggedIn(false);
    navigate("/", { replace: true });
  };

  // Nb the url /fpwd is for forgotten passwords. If you have a forgotton password you are not logged in.
  // This is why there is the filter below - !location.pathname.includes("/fpwd")
  // Even if this were intercepted the token will enforece integrity at the API side.
  return (
    <>
      {" "}
      <div className={styles.appContainer}>
        <CurrentAppContextProvider>
          <HeaderTitle.Provider
            value={[
              simName,
              setSimName,
              lastSaved,
              setLastSaved,
              backUrl,
              setBackUrl,
            ]}
          >
            <Header
              loggedIn={loggedIn}
              logout={logout}
              sysAdmin={sysAdmin}
              userName={userName}
            />
            {!loggedIn ? (
              <>
                {location.pathname.includes("/fpwd") ? (
                  <LoginForgotten />
                ) : !newPassword ? (
                  <Login loggedIn={gsetLoggedIn} />
                ) : (
                  <>
                    <NewPassword token={urlToken} />
                  </>
                )}
              </>
            ) : (
              <>
                <ProductFruitCtrl userId={userName} />
                <Routes>
                  {/* <Route exact path="/a" element={<Model01Anchor />} />
              <Route exact path="/a/md01/:id" element={<Model01 />} /> */}
                  <Route exact path="/" element={<Model1Anchor />} />
                  <Route exact path="/md01/:id" element={<Model01 />} />
                  <Route exact path="/consult" element={<ConsultAnchor />} />
                  <Route exact path="/cnslt/:id" element={<Consult />} />
                  <Route path="/fpwd" element={<LoginForgotten />} />
                  <Route path="/co" element={<CompanyGrid />} />
                  <Route path="/usr/:id" element={<UserGrid />} />
                </Routes>
                <Footer />
              </>
            )}
          </HeaderTitle.Provider>
        </CurrentAppContextProvider>
      </div>
    </>
  );
}
export { App, HeaderTitle };
