import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Model1Links from "../md_01/Model01_links";
import { getUsers, delUser } from "../../api/ApiCoreCalls";
import Table from "react-bootstrap/Table";
import ModalConfirm from "../modal/ModalConfirm";
import { BsCheck, BsX } from "react-icons/bs";

import { GoPencil } from "react-icons/go";
import { FaTrash } from "react-icons/fa";
import User from "./User";
import AddNewSearch from "../AddNewSearch";
import ModalLayout from "../modal/Modal";
import styles from "../../index.module.scss";
import table from "../../table.module.scss";
import style from "../../scss/style.module.scss";
import { TableBody, TableCell, TableHead, TableRow } from "@mui/material";

const UserGrid = () => {
  let { id } = useParams();
  const [simList, setsimList] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState({
    show: false,
    id: NaN,
    desc: "User will permanently be deleted",
  });
  const [showModal, setShowModal] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [popupRecord, setPopupRecord] = useState([]);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    const populateList = (id) => {
      getUsers(
        id,
        (result) => {
          // console.log(result);
          setsimList(result);
        },
        (error) => {
          console.log(error);
        }
      );
    };
    populateList(id);
  }, [id]);

  const rowDelete = () => {
    // ToDo: NAJ
    // alert("Under development id: " + showDeleteModal.id);
    modalDeleteClose();

    delUser(
      showDeleteModal.id,
      () => {
        // Remove the item from the array
        var myArray = simList.filter((a) => {
          return a.id !== showDeleteModal.id;
        });
        setsimList(myArray);
      },
      (error) => {
        console.log(error);
      }
    );
  };
  const modalDeleteClose = () => {
    setShowDeleteModal(false);
  };
  const modalClose = () => {
    setShowModal(false);
    setIsNew(false);
  };
  const updateRecord = async (recordId) => {
    var ail = simList.filter((a) => {
      return a.id === recordId;
    });
    setPopupRecord(ail[0]);
    setIsNew(false);
    setShowModal(true);
  };
  const dataUpdated = (e) => {
    //alert(e);
    if (!isNew) {
      // Ensure doc display correctly
      setPopupRecord(e);
      var myArray = simList.filter((a) => {
        return a.id !== e.id;
      });
      myArray.push(e);
      setsimList(myArray);
    } else {
      // We have a new record...
      var myArrayNew = simList;
      myArrayNew.push(e);
      setsimList(myArrayNew);
      // setsimList(myDataCheck);
    }
    modalClose();
  };
  const SearchChanged = async (text) => {
    setSearchText(text);
  };
  const AddNew = async () => {
    setIsNew(true);
    setShowModal(true);
  };
  function mySeachFilter(comp) {
    if (comp && comp.UserName) {
      return comp.UserName.toUpperCase().indexOf(searchText.toUpperCase()) > -1;
    } else return false;
  }

  return (
    <>
      <div>
        <Model1Links selected="false" />
        <h3>User Listings</h3>

        <div className={styles.containerSub}>
          <AddNewSearch AddNew={AddNew} SetSearchText={SearchChanged} />
          <Table className={table.userTable} hover="true">
            <TableHead>
              <TableRow className={style.borderedHeadRow}>
                <TableCell>User Name</TableCell>
                <TableCell>Telephone</TableCell>
                <TableCell>User Type</TableCell>
                <TableCell>Is Show Tips</TableCell>
                <TableCell>Enabled</TableCell>
                <TableCell>Full PTL</TableCell>
                <TableCell>PTL Expiry</TableCell>

                <TableCell>Full AE</TableCell>
                <TableCell>AE Expiry</TableCell>

                <TableCell>Full Consult</TableCell>
                <TableCell>Consult Expiry</TableCell>

                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {simList &&
                simList.length > 0 &&
                simList.filter(mySeachFilter).map((sim) => (
                  <TableRow key={sim.id}>
                    <TableCell>{sim.UserName}</TableCell>
                    <TableCell>{sim.Telephone}</TableCell>
                    <TableCell>
                      {sim.SecurityType === 1 && <>Standard</>}
                      {sim.SecurityType === 2 && <>Sales</>}
                      {sim.SecurityType === 4 && <>Support</>}
                    </TableCell>

                    <TableCell>
                      {sim.IsShowTips ? (
                        <BsCheck size={20} color="#008181" />
                      ) : (
                        <BsX size={20} color="#008181" />
                      )}
                    </TableCell>
                    <TableCell>
                      {sim.Enabled ? (
                        <BsCheck size={20} color="#008181" />
                      ) : (
                        <BsX size={20} color="#008181" />
                      )}
                    </TableCell>

                    <TableCell>
                      {sim.PtlEnabled ? (
                        <BsCheck size={20} color="#008181" />
                      ) : (
                        <BsX size={20} color="#008181" />
                      )}
                    </TableCell>
                    <TableCell>
                      {sim.PtlTrialExpiry
                        ? sim.PtlTrialExpiry.substring(0, 10)
                        : "None set"}
                    </TableCell>

                    <TableCell>
                      {sim.AeEnabled ? (
                        <BsCheck size={20} color="#008181" />
                      ) : (
                        <BsX size={20} color="#008181" />
                      )}
                    </TableCell>
                    <TableCell>
                      {sim.AeTrialExpiry
                        ? sim.AeTrialExpiry.substring(0, 10)
                        : "None set"}
                    </TableCell>

                    <TableCell>
                      {sim.ConsultEnabled ? (
                        <BsCheck size={20} color="#008181" />
                      ) : (
                        <BsX size={20} color="#008181" />
                      )}
                    </TableCell>
                    <TableCell>
                      {sim.ConsultTrialExpiry
                        ? sim.ConsultTrialExpiry.substring(0, 10)
                        : "None set"}
                    </TableCell>

                    <TableCell>
                      <span
                        role="button"
                        onClick={() => {
                          updateRecord(sim.id);
                        }}
                      >
                        <GoPencil size={25} color="#008181" />
                      </span>
                      &nbsp; &nbsp;&nbsp;&nbsp;
                      <span
                        role="button"
                        onClick={() => {
                          setShowDeleteModal({
                            show: true,
                            id: sim.id,
                            desc:
                              "User " +
                              sim.UserName +
                              " will permanently be deleted",
                          });
                        }}
                      >
                        <FaTrash size={30} color="#7A2048" />
                      </span>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </div>
      </div>
      <ModalLayout
        title={isNew ? "Add New User" : "Update User"}
        show={showModal}
        handleClose={modalClose}
      >
        <User
          data={popupRecord}
          dataUpdated={dataUpdated}
          isNew={isNew}
          companyId={id}
        />
      </ModalLayout>
      <ModalConfirm
        show={showDeleteModal.show}
        handleClose={modalDeleteClose}
        title="Please confirm you want to delete this user"
        desc={showDeleteModal.desc}
        confirmed={rowDelete}
      ></ModalConfirm>
    </>
  );
};
export default UserGrid;
