import React, {
  useState,
  useEffect,
  useContext,
  createContext,
  useCallback,
} from "react";
import { useParams } from "react-router-dom"; // Used to prevent a re-load
import Model1Links from "./Consult_links";
import { ConsultTabs } from "./ConsultTabs";
import {
  putAnalysis,
  getAnalysis,
  checkVettingDifferences,
  checkActivityDifferences,
} from "../../api/ApiConsultCalls";
import { ShallowEqual } from "../HelperFuctions";
import { HeaderTitle } from "../../App";

// Context used so changes cause refresh for all children attached
const ConsultSettingsContext = createContext({}, () => {});
const ConsultRefreshChildrenContext = createContext({});
const ConsultSetRefreshChildrenContext = createContext(() => {});
const ConsultRefreshAllContext = createContext(() => {});
const ConsultIsDirty = createContext({}, () => {});
const ConsultIsLocked = createContext({}, () => {});
const CheckErrors = createContext({}, () => {});
const CheckAlerts = createContext({}, () => {});

const Consult = () => {
  let { id } = useParams();
  // eslint-disable-next-line no-unused-vars
  const [simName, setSimName, lastSaved, setLastSaved] =
    useContext(HeaderTitle);

  // Setup State vars for the context
  const [dsAnalysisHeads, setDsAnalysisHeads] = useState({});
  const [toggleRefreshChildren, setToggleRefreshChildren] = useState(false);
  const [toggleRefreshAll, setToggleRefreshAll] = useState(false);
  const [an_id, setAn_id] = useState(0);
  const [initialRunComplete, setInitialRunComplete] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [isLocked, setIsLocked] = useState(false);
  const [errors, setErrors] = useState([]);
  const [alerts, setAlerts] = useState([]);
  const [oncePerAnalysisHead, setOncePerAnalysisHead] = useState([]);
  const [checkApiErrors, setCheckApiErrors] = useState([]);

  // Setup functions for writing the data
  const writeSimSettings = async (data) => {
    // At this level many child controls will be updated if the data changes
    // Make sure that this update only occurs when the data is difference

    let dateChangedForImport = false;

    // If the simulation is locked then it cannot be updated.
    if (!isLocked) {
      // Check to see if the start and end dates changed
      if (
        data.start_date !== dsAnalysisHeads.start_date ||
        data.end_date !== dsAnalysisHeads.end_date ||
        data.automated_import !== dsAnalysisHeads.automated_import
      ) {
        // We have a change of dates so we can assume the api will be refreshing the import data
        if (data.automated_import > 0) {
          dateChangedForImport = true;
        }
      }

      // console.log("Consult.writeSimSettings run");
      if (!ShallowEqual(data, dsAnalysisHeads)) {
        // Write the changes to the single record
        if (data.id !== undefined) {
          await putAnalysis(
            data,
            (result) => {
              // Success
              if (result) {
                // Update the state
                setDsAnalysisHeads(data);
                setLastSaved(new Date().toISOString());
                if (an_id !== dsAnalysisHeads.simulation_id) {
                  setAn_id(dsAnalysisHeads.simulation_id);
                }
                // If the dates have changed then the import records will have been updated
                if (dateChangedForImport) {
                  setAlerts([
                    "The analysis period or import type has been changed and the import data refreshed.",
                  ]);
                }
              } else {
                alert(
                  "There has been a problem saving the data - the api did not respond correctly."
                );
              }
            },
            (error) => {
              // Failure
              alert("Error: " + JSON.stringify(error, null, 2));
            }
          );
        }
      }
    }
  };
  const refreshChildrenNow = () => {
    console.log("refreshChildrenNow run");
    setToggleRefreshChildren(!toggleRefreshChildren);
    setIsDirty(false); // This event gets fired when the report is run.
  };
  const refreshAllNow = () => {
    console.log("refreshAllNow run");
    setToggleRefreshAll(!toggleRefreshAll);
    refreshChildrenNow();
  };

  // The section below is for running checks on the api and then reporting the -ve results
  // NB Agnus said he may want to move the place where these are shown.
  // To ensure it only gets writen once its here with a context
  const checkAnalysis = useCallback(
    (id, data) => {
      const checkOnce = (element) => {
        if (oncePerAnalysisHead.includes(id)) {
          return false;
        } else {
          setOncePerAnalysisHead([...oncePerAnalysisHead, id]);
          return true;
        }
      };

      const CheckAllApiTests = (id, data) => {
        // Has the automated vetting been kept up to date?
        if (data.automated_import === 2) {
          checkVettingDifferences(
            id,
            (data) => {
              if (data.difference) {
                setCheckApiErrors([...checkApiErrors, "VettingImportErrors"]);
                console.log("Vetting result: true");
              }
            },
            (errors) => {}
          );
        }
        if (data.automated_import > 0) {
          checkActivityDifferences(
            id,
            (data) => {
              if (data.difference) {
                setCheckApiErrors([...checkApiErrors, "ActivityImportErrors"]);
                console.log("Activity result: true");
              }
            },
            (errors) => {}
          );
        }
      };

      // Check if this analysis has been used before
      if (checkOnce(oncePerAnalysisHead, id)) {
        CheckAllApiTests(id, data);
      }
    },
    [oncePerAnalysisHead, checkApiErrors]
  );
  // The section above is for running checks on the api and then reporting the -ve results

  // Populate the state vars
  // Setup data and validation
  useEffect(() => {
    // Get the simulation data
    // console.log("fetchSingleSimulation run");
    const fetchSingleSimulation = async (sId) => {
      getAnalysis(
        sId,
        (simData) => {
          // console.log(simData);
          setDsAnalysisHeads(simData);
          if (an_id !== simData.simulation_id) {
            setAn_id(simData.simulation_id);
            setInitialRunComplete(true);
            setIsDirty(simData.is_dirty);
            setSimName(simData.title);
            setIsLocked(simData.locked);
          }
          // We have an analysis so check it out...
          checkAnalysis(id, simData);
        },
        (error) => {
          if (error.message === "Unauthorized") {
            // Reset to list.
            setErrors([
              'You are not authorised to view this. Try logging out and back in again. If this too fails then ask the administrator to add "Consultant Analysis" to your account.',
            ]);
          } else {
            console.log(error);
          }
        }
      );
    };

    fetchSingleSimulation(id);
  }, [id, an_id, toggleRefreshAll, setSimName, checkAnalysis]);

  // Get the child records data
  const fetchChildSimulation = async (sim_id) => {
    // getSimTheatreLink(
    //   sim_id,
    //   (simData) => {
    //     // console.log("Acuity: ", simData);
    //     setDsSimTheatreLink(simData);
    //   },
    //   (error) => {
    //     console.log(error);
    //   }
    // );
  };

  useEffect(() => {
    if (initialRunComplete) {
      // console.log("useEffect -> fetchChildSimulation run");
      fetchChildSimulation(an_id);
    }
  }, [toggleRefreshAll, an_id, initialRunComplete]);

  return (
    <div>
      {errors &&
        errors.map((msg, index) => (
          <div key={index} className="alert alert-danger">
            {msg}
          </div>
        ))}
      <ConsultRefreshAllContext.Provider value={refreshAllNow}>
        <ConsultIsDirty.Provider value={[isDirty, setIsDirty]}>
          <ConsultIsLocked.Provider value={[isLocked, setIsLocked]}>
            <CheckErrors.Provider value={[checkApiErrors, setCheckApiErrors]}>
              <CheckAlerts.Provider value={[alerts, setAlerts]}>
                <ConsultSettingsContext.Provider
                  value={[dsAnalysisHeads, writeSimSettings]}
                >
                  <ConsultRefreshChildrenContext.Provider
                    value={toggleRefreshChildren}
                  >
                    <ConsultSetRefreshChildrenContext.Provider
                      value={refreshChildrenNow}
                    >
                      <Model1Links selected="Simulation" />
                      <ConsultTabs simGuid={id} />
                    </ConsultSetRefreshChildrenContext.Provider>
                  </ConsultRefreshChildrenContext.Provider>
                </ConsultSettingsContext.Provider>
              </CheckAlerts.Provider>
            </CheckErrors.Provider>
          </ConsultIsLocked.Provider>
        </ConsultIsDirty.Provider>
      </ConsultRefreshAllContext.Provider>
    </div>
  );
};

export {
  Consult,
  ConsultSettingsContext,
  ConsultRefreshChildrenContext,
  ConsultSetRefreshChildrenContext,
  ConsultRefreshAllContext,
  ConsultIsDirty,
  ConsultIsLocked,
  CheckErrors,
  CheckAlerts,
};
