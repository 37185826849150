import React, { useContext } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import TextField from "../../TextField";
import SelectField from "../../FormikSeletField";
import { putAcuity, postAcuity } from "../../../api/ApiPtlCalls";
import { Model01SimAilmentsContext, Model01IsDirty } from "../Model01";
import styles from "../../../scss/style.module.scss";

// import { TiUpload } from "react-icons/ti";

const Acuity = ({ data, dataUpdated, isNew }) => {
  const [dsAilments, setDsSimAilments] = useContext(Model01SimAilmentsContext); // eslint-disable-line no-unused-vars
  const [isDirty, setIsDirty] = useContext(Model01IsDirty); // eslint-disable-line no-unused-vars
  const frmSchema = Yup.object({
    simulation_id: Yup.number().integer().required(),
    title: Yup.string()
      .max(84, "Must be 84 chars or less")
      .required("Required"),
    acuity: Yup.number()
      .integer()
      .min(0, "Must be more than 0")
      .required("Required"),
    percentage_likelihood: Yup.number()
      .min(0, "Must be more than 0")
      .required("Required"),
    fk_ailment_id: Yup.string().uuid().required("Required"),
  });

  const setData = (ailment) => {
    // Update the cached data now.
  };

  const SaveFormData = async (values) => {
    // Assemble the data
    var myDataCheck = { ...data, ...values };
    // Put it to the server
    if (!isNew) {
      await putAcuity(
        myDataCheck,
        (result) => {
          // Success
          if (result) {
            setData(myDataCheck);
            dataUpdated(myDataCheck);
            setIsDirty(true);
          } else {
            alert(
              "There has been a problem updating the data - the api did not respond correctly."
            );
          }
        },
        (error) => {
          // Failure
          alert("Error: " + JSON.stringify(error, null, 2));
        }
      );
    } else {
      await postAcuity(
        myDataCheck,
        (result) => {
          // Success
          if (result) {
            setData(myDataCheck);
            dataUpdated(myDataCheck);
            setIsDirty(true);
          } else {
            alert(
              "There has been a problem saving the data - the api did not respond correctly."
            );
          }
        },
        (error) => {
          // Failure
          alert("Error: " + JSON.stringify(error, null, 2));
        }
      );
    }
  };

  const sortStringTitles = (a, b) => {
    return (
      String(a.ailment_title).localeCompare(b.ailment_title) ||
      String(a.title).localeCompare(b.title)
    );
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          id: data["id"] || "",
          simulation_id: data["simulation_id"],
          title: data["title"] || "",
          acuity: data["acuity"] || 0,
          percentage_likelihood: data["percentage_likelihood"] || 0,
          fk_ailment_id: data["fk_ailment_id"] || "",
        }}
        validationSchema={frmSchema}
        onSubmit={async (values, { resetForm }) => {
          SaveFormData(values);
          // resetForm({ values: "" });
          //alert(JSON.stringify(values, null, 2));
        }}
      >
        <div>
          <Form id="frmAcuity">
            <SelectField
              name="fk_ailment_id"
              // onChange={EventAilmentChanged}
            >
              <option value="" label="Please select patient group">
                Select an ailment{" "}
              </option>
              {dsAilments &&
                dsAilments.length > 0 &&
                dsAilments
                  .sort(sortStringTitles)
                  .filter((r) => r.emergency === false)
                  .map((ail) => (
                    <option
                      key={ail.id}
                      value={ail.id}
                      label={ail.title}
                    ></option>
                  ))}
            </SelectField>
            <TextField label="Title" name="title" type="text" maxLength="84" />
            <div className={styles.hours}>
              <TextField label="Acuity" name="acuity" type="number" min="0" />
              <TextField
                label="Percentage likelihood for new patients"
                name="percentage_likelihood"
                type="number"
                min="0"
              />
            </div>
          </Form>
        </div>
      </Formik>
      <div className={styles.buttonContainer}>
        <button className="btn btn-outline-primary submit" form="frmAcuity">
          Submit
        </button>
      </div>
    </>
  );
};

export default Acuity;
