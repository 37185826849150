export function convertJsonToCSV(jsonData) {
  // Assuming jsonData is an array of objects
  if (jsonData) {
    if (!Array.isArray(jsonData) || jsonData.length === 0) {
      return ""; // Return an empty string if jsonData is not a valid array or is empty
    }

    // Extract column headers
    const headers = Object.keys(jsonData[0]).join(",") + "\n";

    // Convert each object in the array to a CSV row
    const rows = jsonData
      .map((obj) => {
        const values = Object.keys(jsonData[0]).map(
          (key) => `"${String(obj[key]).replace(/"/g, '""')}"`
        );
        return values.join(",");
      })
      .join("\n");

    return headers + rows;
  } else {
    return "";
  }
}
