import React, { useState } from "react";

import TabJobPlan from "./tabs/jobplan/TabJobPlan";
import TabActivity from "./tabs/activity/TabActivity";
import TabLeave from "./tabs/leave/TabLeave";
import { TabReports } from "./tabs/reports/TabReports";
import TabTime from "./tabs/timesegments/TabTime";
import TabSettings from "./tabs/settings/TabSettings";
import TabStandards from "./tabs/standards/TabStandards";

import { IoMdSettings } from "react-icons/io";
import { RiBubbleChartFill } from "react-icons/ri";
import { BsPeopleFill } from "react-icons/bs";
import { VscGraph } from "react-icons/vsc";
import { MdGavel } from "react-icons/md";
import { RiFlightTakeoffFill } from "react-icons/ri";

const ConsultTabs = ({ simGuid }) => {
  const [activeTab, setActiveTab] = useState("tab1");

  return (
    <>
      <ul className="nav">
        <li
          className={activeTab === "tab1" ? "active" : ""}
          onClick={() => {
            setActiveTab("tab1");
          }}
        >
          <IoMdSettings size={30} />
          <div className="checkboxContainer">Settings</div>
        </li>
        <li
          className={activeTab === "tab2" ? "active" : ""}
          onClick={() => {
            setActiveTab("tab2");
          }}
        >
          <IoMdSettings size={30} />
          <div className="checkboxContainer">Job Plan</div>
        </li>
        <li
          className={activeTab === "tab3" ? "active" : ""}
          onClick={() => {
            setActiveTab("tab3");
          }}
        >
          <RiBubbleChartFill size={30} />
          <div className="checkboxContainer">Activity</div>
        </li>

        <li
          className={activeTab === "tab4" ? "active" : ""}
          onClick={() => {
            setActiveTab("tab4");
          }}
        >
          <RiFlightTakeoffFill size={30} />
          <div className="checkboxContainer">Leave</div>
        </li>

        <li
          className={activeTab === "tab5" ? "active" : ""}
          onClick={() => {
            setActiveTab("tab5");
          }}
        >
          <MdGavel size={30} />
          <div className="checkboxContainer">Standards</div>
        </li>
        <li
          className={activeTab === "tab6" ? "active" : ""}
          onClick={() => {
            setActiveTab("tab6");
          }}
        >
          <BsPeopleFill size={30} />
          <div className="checkboxContainer">Time Segments</div>
        </li>
        <li
          className={activeTab === "tab7" ? "active" : ""}
          onClick={() => {
            setActiveTab("tab7");
          }}
        >
          <VscGraph size={30} />
          <div className="checkboxContainer">Results</div>
        </li>
      </ul>
      <br />
      <br />
      <div className={activeTab === "tab1" ? "" : "hiddenControl"}>
        <TabSettings tabNo={0} />
      </div>
      <div className={activeTab === "tab2" ? "" : "hiddenControl"}>
        <TabJobPlan tabNo={1} />
      </div>
      <div className={activeTab === "tab3" ? "" : "hiddenControl"}>
        <TabActivity tabNo={2} />
      </div>
      <div className={activeTab === "tab4" ? "" : "hiddenControl"}>
        <TabLeave tabNo={3} />
      </div>
      <div className={activeTab === "tab5" ? "" : "hiddenControl"}>
        <TabStandards tabNo={4} />
      </div>
      <div className={activeTab === "tab6" ? "" : "hiddenControl"}>
        <TabTime tabNo={5} />
      </div>
      <div className={activeTab === "tab7" ? "" : "hiddenControl"}>
        <TabReports tabNo={6} />
      </div>

      <br />
      <br />
    </>
  );
};

export { ConsultTabs };
