import React from "react";
import Table from "react-bootstrap/Table";
import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  numberWithCommas,
  roundNumber,
  hideIfZero,
} from "../../../../common/math_functions";
import styles from "../../../../scss/style.module.scss";
import table from "../../../../table.module.scss";

const ReportActEcpActTable = ({
  sumData,
  vettingSum,
  vettingDuration,
  rptVetting,
}) => {
  return (
    <React.Fragment>
      <TableContainer className={table.page_middle}>
        <Table className={`${table.dashboard}`} hover="true">
          <TableHead>
            <TableRow className={styles.borderedHeadRow}>
              <TableCell className={styles.colChar32}></TableCell>
              <TableCell className={styles.colNumber}>Act.</TableCell>
              <TableCell className={styles.colNumber}>Exp.</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow key={0}>
              <TableCell>Net Hours</TableCell>
              <TableCell>
                {numberWithCommas(
                  roundNumber(
                    sumData[0].actHrs +
                      sumData[1].actHrs +
                      sumData[2].actHrs +
                      sumData[4].actHrs +
                      (rptVetting === "1"
                        ? 0
                        : (vettingSum * vettingDuration) / 60),
                    1
                  )
                )}
              </TableCell>
              <TableCell>
                {hideIfZero(
                  numberWithCommas(
                    roundNumber(
                      sumData[0].expHrs +
                        sumData[1].expHrs +
                        sumData[2].expHrs +
                        sumData[4].expHrs,
                      1
                    )
                  )
                )}
              </TableCell>
            </TableRow>
            <TableRow key={1}>
              <TableCell>Net PAs</TableCell>
              <TableCell>
                {numberWithCommas(
                  roundNumber(
                    sumData[0].actPa +
                      sumData[1].actPa +
                      sumData[2].actPa +
                      sumData[4].actPa,
                    1
                  )
                )}
              </TableCell>
              <TableCell>
                {hideIfZero(
                  numberWithCommas(
                    roundNumber(
                      sumData[0].expPa +
                        sumData[1].expPa +
                        sumData[2].expPa +
                        sumData[4].expPa,
                      1
                    )
                  )
                )}
              </TableCell>
            </TableRow>
            <TableRow key={2}>
              <TableCell>Net Reports</TableCell>
              <TableCell>
                {numberWithCommas(
                  roundNumber(
                    sumData[0].actRpt +
                      sumData[1].actRpt +
                      sumData[2].actRpt +
                      sumData[4].actRpt,
                    1
                  )
                )}
              </TableCell>
              <TableCell>
                Not calculated.
                {/* {hideIfZero(
            numberWithCommas(roundNumber(
              sumData[0].expRpt + sumData[1].expRpt + sumData[2].expRpt,
              2
            )
          )} */}
              </TableCell>
            </TableRow>
            <TableRow key={3}>
              <TableCell>Net Sessions</TableCell>
              <TableCell>
                {numberWithCommas(
                  roundNumber(
                    sumData[0].actSess +
                      sumData[1].actSess +
                      sumData[2].actSess +
                      sumData[4].actSess,
                    1
                  )
                )}
              </TableCell>
              <TableCell>
                {hideIfZero(
                  numberWithCommas(
                    roundNumber(
                      sumData[0].expSess +
                        sumData[1].expSess +
                        sumData[2].expSess +
                        sumData[4].expSess,
                      1
                    )
                  )
                )}
              </TableCell>
            </TableRow>
            <TableRow key={4}>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow key={5}>
              <TableCell>WLI Hours</TableCell>
              <TableCell>
                {numberWithCommas(roundNumber(sumData[3].actHrs, 1))}
              </TableCell>
              <TableCell>
                {hideIfZero(
                  numberWithCommas(roundNumber(sumData[3].expHrs, 1))
                )}
              </TableCell>
            </TableRow>
            <TableRow key={6}>
              <TableCell>WLI PAs</TableCell>
              <TableCell>
                {numberWithCommas(roundNumber(sumData[3].actPa, 1))}
              </TableCell>
              <TableCell>
                {hideIfZero(numberWithCommas(roundNumber(sumData[3].expPa, 1)))}
              </TableCell>
            </TableRow>
            <TableRow key={7}>
              <TableCell>WLI Reports</TableCell>
              <TableCell>
                {numberWithCommas(roundNumber(sumData[3].actRpt, 1))}
              </TableCell>
              <TableCell>
                {hideIfZero(
                  numberWithCommas(roundNumber(sumData[3].expRpt, 1))
                )}
              </TableCell>
            </TableRow>
            <TableRow key={8}>
              <TableCell>WLI Sessions</TableCell>
              <TableCell>
                {numberWithCommas(roundNumber(sumData[3].actSess, 1))}
              </TableCell>
              <TableCell>
                {hideIfZero(
                  numberWithCommas(roundNumber(sumData[3].expSess, 1))
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
};

export default ReportActEcpActTable;
