import React, { useContext, useEffect, useState } from "react";
import styles from "../../../../scss/style.module.scss";
import table from "../../../../table.module.scss";
import { ConsultSettingsContext } from "../../Consult";
import { ConsultRefreshChildrenContext } from "../../Consult";
import Table from "react-bootstrap/Table";
import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { getLeaveSummary } from "../../../../api/ApiConsultCalls";

const LeaveTable = () => {
  const [data] = useContext(ConsultSettingsContext); // eslint-disable-line no-unused-vars
  const refreshChild = useContext(ConsultRefreshChildrenContext);
  const [dsLeave, setDsLeave] = useState({});

  useEffect(() => {
    // Fetch the JobPlan details.
    if (data.id !== undefined) {
      getLeaveSummary(
        data.id,
        (data) => {
          setDsLeave(data);
        },
        (error) => {}
      );
    }
  }, [data.id, refreshChild]);

  return (
    <React.Fragment>
      <TableContainer>
        <Table className={table.dashboard} hover="true">
          <TableHead>
            <TableRow className={styles.borderedHeadRow}>
              <TableCell>Name</TableCell>
              <TableCell>Total leave days</TableCell>
              <TableCell>Leave within period</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dsLeave &&
              dsLeave.length > 0 &&
              dsLeave.map((sim, index) => (
                <TableRow key={index}>
                  <TableCell>{sim.first_report_authoriser}</TableCell>
                  <TableCell>{sim.leave}</TableCell>
                  <TableCell>{sim.leave_within_range}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
};

export default LeaveTable;
