import React, { useContext, useEffect, useState } from "react";
import styles from "../../../../scss/style.module.scss";
import table from "../../../../table.module.scss";
import { ConsultSettingsContext } from "../../Consult";
import { ConsultRefreshChildrenContext } from "../../Consult";
import Table from "react-bootstrap/Table";
import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { getActivityNoMatch } from "../../../../api/ApiConsultCalls";

const ActivityNoMatchTable = () => {
  const [data] = useContext(ConsultSettingsContext); // eslint-disable-line no-unused-vars
  const refreshChild = useContext(ConsultRefreshChildrenContext);
  const [dsExamTypeNoMatch, setDsExamTypeNoMatch] = useState([]);

  useEffect(() => {
    // Fetch the JobPlan details.
    if (data.id !== undefined) {
      getActivityNoMatch(
        data.id,
        (data) => {
          setDsExamTypeNoMatch(data);
        },
        (error) => {}
      );
    }
  }, [data.id, refreshChild]);

  return (
    <React.Fragment>
      {dsExamTypeNoMatch && dsExamTypeNoMatch.length > 0 ? (
        <>
          <TableContainer>
            <Table className={table.dashboard} hover="true">
              <TableHead>
                <TableRow className={styles.borderedHeadRow}>
                  <TableCell>Examinations</TableCell>
                  <TableCell>Mins (NICIP)</TableCell>
                  <TableCell>Mins (Standard)</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dsExamTypeNoMatch &&
                  dsExamTypeNoMatch.length > 0 &&
                  dsExamTypeNoMatch.map((sim, index) => (
                    <TableRow key={index}>
                      <TableCell>{sim.examination}</TableCell>
                      <TableCell>{sim.nicip_duration}</TableCell>
                      <TableCell>{sim.standard_duration}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <></>
      )}
    </React.Fragment>
  );
};

export default ActivityNoMatchTable;
