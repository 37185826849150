import React from "react";
import { useField, useFormikContext } from "formik";
import {
  FormControl,
  FormLabel,
  RadioGroup as MaRadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
} from "@mui/material";

const RadioGroupCtrl = ({ label, name, options, ...props }) => {
  // Hook into Formik's state and helpers
  const [field, meta] = useField(name);
  const formikProps = useFormikContext();

  const handleChange = (event) => {
    console.log("handleChange fired");
    // Convert value back to integer before setting it
    if (event && event.target && event.target.value) {
      handleChange("handleChange about to set value: " + event.target.value);
      const value = parseInt(event.target.value, 10);
      formikProps.setFieldValue(name, value);
    }
  };

  const configRadioGroup = {
    ...field,
    ...props,
    onChange: handleChange,
    value: field.value.toString(), // Ensure the value is a string for the Radio component
  };

  return (
    <FormControl
      component="fieldset"
      error={meta.touched && Boolean(meta.error)}
    >
      <FormLabel component="legend">{label}</FormLabel>
      <MaRadioGroup {...configRadioGroup}>
        {/* ...field, ...props, */}
        {options.map((option) => (
          // Ensure the value we pass to the control is a string
          <FormControlLabel
            key={option.value}
            value={option.value.toString()}
            control={<Radio />}
            label={option.label}
          />
        ))}
      </MaRadioGroup>

      {meta.touched && meta.error ? (
        <FormHelperText>{meta.error}</FormHelperText>
      ) : null}
    </FormControl>
  );
};

export default RadioGroupCtrl;
