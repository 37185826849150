import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import moment from "moment";

/*  Example data summary:

{
    "max_patient_count": 91,
    "max_week_duration": 107,
    "model_run_days": 1096,
    "model_start_dtg": "2022-10-01T00:00:00",
    "targets": [
        {
            "ailment_type": "Procedure 2",
            "week_18_cleared": 1097,
            "week_52_cleared": 57,
            "week_78_cleared": null,
            "week_104_cleared": null
        },
        {
            "ailment_type": "Procedure 1",
            "week_18_cleared": 701,
            "week_52_cleared": null,
            "week_78_cleared": null,
            "week_104_cleared": null
        },
        {
            "ailment_type": "Procedure 3",
            "week_18_cleared": 1097,
            "week_52_cleared": 118,
            "week_78_cleared": 63,
            "week_104_cleared": 8
        }
    ]
}

*/

const RptModelSummary = ({
  simulation_id,
  set_day_number,
  show_ailments,
  summaryData,
  seriesHidden,
  setSeriesHidden,
}) => {
  const [visibleData, setVisibleData] = useState(summaryData);
  const dayShift = 1; // This is here to link all days as 0 is now used for the ptl. So, what is 1 needs to become 0 for the db lookups - the model starts on day 0.

  const sortStringType = (a, b) => {
    return String(a.ailment_type).localeCompare(b.ailment_type);
  };
  const setTabDay = (number) => {
    if (number < summaryData.model_run_days) {
      set_day_number(number);
    }
  };

  useEffect(() => {
    const filterData = () => {
      // var subSet = summaryData.targets.filter(
      //   (e) => !(seriesHidden.indexOf(e.ailment_type) > -1)
      // );
      // setVisibleData(subSet);
      setVisibleData(summaryData.targets);
    };
    summaryData && summaryData.targets && filterData();
  }, [summaryData, seriesHidden]);

  const daysToDate = (days) => {
    return moment(
      Date.parse(summaryData.model_start_dtg) + days * 86400000
    ).format("DD/MM/YYYY");
  };

  const ColourControl = (type, clearedDate, blnUnachievable) => {
    if (blnUnachievable === true) {
      return "red";
    }
    if (type === 78) {
      if (
        moment(
          Date.parse(summaryData.model_start_dtg) + clearedDate * 86400000
        ) <= moment("2023-03-31")
      ) {
        return "green";
      } else {
        return "red";
      }
    } else {
      if (
        moment(
          Date.parse(summaryData.model_start_dtg) + clearedDate * 86400000
        ) <= moment("2025-01-31")
      ) {
        return "green";
      } else {
        return "red";
      }
    }
  };

  return (
    <>
      <Table borderless="true">
        <thead>
          <tr>
            <th></th>
            <th>All &lt;78 weeks</th>
            <th>All &lt;52 weeks</th>
            <th>All &lt;18 Weeks</th>
          </tr>
        </thead>
        <tbody>
          {visibleData &&
            visibleData.length > 0 &&
            visibleData
              .filter((r) => r.ailment_type !== "_Emergency")
              .sort(sortStringType)
              .map((sim) => (
                <tr key={sim.ailment_type}>
                  <td style={{ color: "black" }}>{sim.ailment_type}</td>
                  <td>
                    {" "}
                    <span
                      role="button"
                      onClick={() => {
                        setTabDay(
                          sim.week_78_cleared === null
                            ? 0
                            : sim.week_78_cleared + dayShift
                        );
                      }}
                      title="Click to change date"
                      style={{
                        fontWeight: "bold",
                        fontSize: 14,
                        color: ColourControl(
                          78,
                          sim.week_78_cleared,
                          sim.week_78_cleared > summaryData.model_run_days
                        ),
                      }}
                    >
                      {sim.week_78_cleared > summaryData.model_run_days
                        ? "Unachievable"
                        : daysToDate(sim.week_78_cleared)}
                    </span>
                  </td>

                  <td>
                    {" "}
                    <span
                      role="button"
                      onClick={() => {
                        setTabDay(
                          sim.week_52_cleared === null
                            ? 0
                            : sim.week_52_cleared + dayShift
                        );
                      }}
                      title="Click to change date"
                      style={{
                        fontWeight: "bold",
                        fontSize: 14,
                        color: ColourControl(
                          52,
                          sim.week_52_cleared,
                          sim.week_52_cleared > summaryData.model_run_days
                        ),
                      }}
                    >
                      {sim.week_52_cleared > summaryData.model_run_days
                        ? "Unachievable"
                        : daysToDate(sim.week_52_cleared)}
                    </span>
                  </td>
                  <td>
                    <span
                      role="button"
                      onClick={() => {
                        setTabDay(
                          sim.week_18_cleared === null
                            ? 0
                            : sim.week_18_cleared + dayShift
                        );
                      }}
                      title="Click to change date"
                      style={{
                        fontWeight: "bold",
                        fontSize: 14,
                      }}
                    >
                      {sim.week_18_cleared > summaryData.model_run_days
                        ? "Unachievable"
                        : daysToDate(sim.week_18_cleared)}
                    </span>
                  </td>
                </tr>
              ))}
        </tbody>
      </Table>
    </>
  );
};

export default RptModelSummary;
