export const validateAilment = (dsAilments) => {
  var sumProbability = 0;
  if (dsAilments && dsAilments.length > 0) {
    dsAilments.forEach((sim, i) => {
      sumProbability = sumProbability + sim.percentage_likelihood;
    });
  }
  // Check they add up to 100%
  if (Math.abs(sumProbability - 100) < 0.0001) {
    // Passed the test
    return true;
  } else {
    // Failed the test
    return false;
  }
};

export const validateAcuity = (dsAcuity) => {
  var sumProbability = 0;
  var aAilments = [];
  if (dsAcuity && dsAcuity.length > 0) {
    dsAcuity.forEach((sim, i) => {
      if (!aAilments.includes(sim.fk_ailment_id)) {
        aAilments.push(sim.fk_ailment_id);
      }
      sumProbability = sumProbability + sim.percentage_likelihood;
    });
  }
  // Check they add up to 100%
  if (sumProbability / aAilments.length === 100) {
    // Passed the test
    return true;
  } else {
    // Failed the test
    return false;
  }
};

export const validateTimeSegments = (dsAcuity) => {
  // Check there are no over lapping time segments
  return true;

  // var sumProbability = 0;
  // var aAilments = [];
  // if (dsAcuity && dsAcuity.length > 0) {
  //   dsAcuity.forEach((sim, i) => {
  //     if (!aAilments.includes(sim.fk_ailment_id)) {
  //       aAilments.push(sim.fk_ailment_id);
  //     }
  //     sumProbability = sumProbability + sim.percentage_likelihood;
  //   });
  // }
  // // Check they add up to 100%
  // if (sumProbability / aAilments.length === 100) {
  //   // Passed the test
  //   return true;
  // } else {
  //   // Failed the test
  //   return false;
  // }
};

export const validateAilmentAllocation = (dsAilments, dsTheatreLink) => {
  const locateLinkRecord = (ailment_id) => {
    // Locate a record in the dsTheatreLink
    let results = false;
    if (dsTheatreLink && dsTheatreLink.length > 0) {
      dsTheatreLink
        .filter((r) => r.fk_sim_ailment_id === ailment_id)
        .forEach((r) => {
          results = true;
        });
      return results;
    } else {
      return false;
    }
  };

  let linked = true;
  let failing = [];
  if (dsAilments && dsAilments.length > 0) {
    dsAilments
      .filter((r) => r.emergency === false)
      .forEach((ail, i) => {
        // Workout if record is checked or not.
        let passed = locateLinkRecord(ail.id);
        linked = linked && passed;
        if (!passed) {
          failing.push(ail.title);
        }
      });
  }
  return { result: linked, list: failing };
};

export const validateAilmentMeanDuration = (
  dsAilments,
  dsTheatre,
  dsTheatreLink
) => {
  const locateLinkRecord = (ailment_id, ailment_mean_mins) => {
    // Locate a record in the dsTheatreLink

    if (dsTheatreLink && dsTheatreLink.length > 0) {
      let results = true;
      dsTheatreLink
        .filter((r) => r.fk_sim_ailment_id === ailment_id)
        .forEach((r) => {
          // Locate parent theatre and check the session length
          dsTheatre
            .filter((t) => t.id === r.fk_sim_theatre_type_id)
            .forEach((t) => {
              results = results && ailment_mean_mins <= t.session_hours * 60;
            });
        });
      return results;
    } else {
      // Passed as there are no links to this
      return true;
    }
  };

  let linked = true;
  let failing = [];
  if (dsAilments && dsAilments.length > 0) {
    dsAilments
      .filter((r) => r.emergency === false)
      .forEach((ail, i) => {
        // Workout if record is checked or not.
        let passed = locateLinkRecord(ail.id, ail.ptt_mean_mins);
        linked = linked && passed;
        if (!passed) {
          failing.push(ail.title);
        }
      });
  }

  return { result: linked, list: failing };
};

export const validateAilmentMaxDuration = (
  dsAilments,
  dsTheatre,
  dsTheatreLink
) => {
  const locateLinkRecord = (
    ailment_id,
    ptt_mean_mins,
    ptt_sd_mins,
    ptt_maximum_mins
  ) => {
    // Locate records in the dsTheatreLink
    var calcMaxFunction;
    calcMaxFunction = (t, ptt_mean_mins, ptt_sd_mins, ptt_maximum_mins) => {
      return ptt_maximum_mins <= t.session_hours * 60;
    };
    // Check there are threatre links
    if (dsTheatreLink && dsTheatreLink.length > 0) {
      let results = true;
      // Loop through each theatre link
      dsTheatreLink
        .filter((r) => r.fk_sim_ailment_id === ailment_id)
        .forEach((r) => {
          // Locate parent theatre and check the session length
          dsTheatre
            .filter((t) => t.id === r.fk_sim_theatre_type_id)
            .forEach((t) => {
              results =
                results &&
                calcMaxFunction(
                  t,
                  ptt_mean_mins,
                  ptt_sd_mins,
                  ptt_maximum_mins
                );
              // results = results && ptt_maximum_mins <= t.session_hours * 60;
            });
        });
      return results;
    } else {
      // Passed as there are no links to this
      return true;
    }
  };

  let linked = true;
  let failing = [];
  // Check there are ailments
  if (dsAilments && dsAilments.length > 0) {
    // Loop through each ailment
    dsAilments
      .filter((r) => r.emergency === false)
      .forEach((ail, i) => {
        // Workout if ailment will fit into all its sessions
        let passed = locateLinkRecord(
          ail.id,
          ail.ptt_mean_mins,
          ail.ptt_sd_mins,
          ail.ptt_maximum_mins
        );
        linked = linked && passed;
        if (!passed) {
          failing.push(ail.title);
        }
      });
  }
  return { result: linked, list: failing };
};

export const validateAilmentStandDevDuration = (
  dsAilments,
  dsTheatre,
  dsTheatreLink
) => {
  const locateLinkRecord = (
    ailment_id,
    ptt_mean_mins,
    ptt_sd_mins,
    ptt_maximum_mins
  ) => {
    // Locate records in the dsTheatreLink
    var calcMaxFunction;
    calcMaxFunction = (t, ptt_mean_mins, ptt_sd_mins, ptt_maximum_mins) => {
      if (ptt_maximum_mins !== 0) {
        // A maximum is set so no need to check the sd
        return true;
      }
      // 4 sd check covers 99.994%
      return ptt_mean_mins + 4 * ptt_sd_mins <= t.session_hours * 60;
    };
    // Check there are threatre links
    if (dsTheatreLink && dsTheatreLink.length > 0) {
      let results = true;
      // Loop through each theatre link
      dsTheatreLink
        .filter((r) => r.fk_sim_ailment_id === ailment_id)
        .forEach((r) => {
          // Locate parent theatre and check the session length
          dsTheatre
            .filter((t) => t.id === r.fk_sim_theatre_type_id)
            .forEach((t) => {
              results =
                results &&
                calcMaxFunction(
                  t,
                  ptt_mean_mins,
                  ptt_sd_mins,
                  ptt_maximum_mins
                );
              // results = results && ptt_maximum_mins <= t.session_hours * 60;
            });
        });
      return results;
    } else {
      // Passed as there are no links to this
      return true;
    }
  };

  let linked = true;
  let failing = [];
  // Check there are ailments
  if (dsAilments && dsAilments.length > 0) {
    // Loop through each ailment
    dsAilments
      .filter((r) => r.emergency === false)
      .forEach((ail, i) => {
        // Workout if ailment will fit into all its sessions
        let passed = locateLinkRecord(
          ail.id,
          ail.ptt_mean_mins,
          ail.ptt_sd_mins,
          ail.ptt_maximum_mins
        );
        linked = linked && passed;
        if (!passed) {
          failing.push(ail.title);
        }
      });
  }

  return { result: linked, list: failing };
};
