import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-tabs/style/react-tabs.css";
import "./index.module.scss";
import "./indexOrg.module.scss";
import "./tab_custom.scss";
import "./table.module.scss";
// import "./indexMaterialDes.css";
import { App } from "./App";
import { BrowserRouter as Router } from "react-router-dom";
//import reportWebVitals from './reportWebVitals';
//import 'bootstrap/dist/css/bootstrap.css';

import { createRoot } from "react-dom/client";
const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  // <React.StrictMode>
  <Router>
    <App />
  </Router>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log);
