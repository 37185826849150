import React, { useContext, useEffect, useState } from "react";
import styles from "../../../../scss/style.module.scss";
import table from "../../../../table.module.scss";
import { ConsultSettingsContext } from "../../Consult";
import { ConsultRefreshChildrenContext } from "../../Consult";
import Table from "react-bootstrap/Table";
import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { getActivitySummary } from "../../../../api/ApiConsultCalls";
import ActivityDate from "./ActivityDate";

const ActivityTable = () => {
  const [data] = useContext(ConsultSettingsContext); // eslint-disable-line no-unused-vars
  const refreshChild = useContext(ConsultRefreshChildrenContext);
  const [dsJobMap, setDsJobMap] = useState({});

  useEffect(() => {
    // Fetch the JobPlan details.
    if (data.id !== undefined) {
      getActivitySummary(
        data.id,
        (data) => {
          setDsJobMap(data);
        },
        (error) => {}
      );
    }
  }, [data.id, refreshChild]);

  return (
    <React.Fragment>
      <ActivityDate />
      <TableContainer>
        <Table className={table.dashboard} hover="true">
          <TableHead>
            <TableRow className={styles.borderedHeadRow}>
              <TableCell>Name</TableCell>
              <TableCell>Consultant</TableCell>
              <TableCell>Reports</TableCell>
              <TableCell>Vetting</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dsJobMap &&
              dsJobMap.length > 0 &&
              dsJobMap.map((sim, index) => (
                <TableRow key={index}>
                  <TableCell>{sim.first_report_authoriser}</TableCell>
                  <TableCell>{sim.scheduled_consultant ? "Yes" : ""}</TableCell>
                  <TableCell>{sim.reports}</TableCell>
                  <TableCell>{sim.vetted}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
};

export default ActivityTable;
