// https://webomnizz.com/create-simple-modal-pop-up-with-react/
import React from "react";
import { Modal } from "react-bootstrap";
import modalStyles from "./modal.module.scss";

// .modal-parent
const ModalLayout = ({ title, handleClose, show, children, scrollbars }) => {
  return (
    <Modal
      className={`${modalStyles.modal} ${modalStyles.wider_popup} ${
        scrollbars ? "modalStyles.modalScrollBars" : ""
      } ${modalStyles.modal_lg} `}
      show={show}
      onHide={handleClose}
    >
      <Modal.Header className={`${modalStyles.modalHeader}`} closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-container">{children}</Modal.Body>
    </Modal>
  );
};

export default ModalLayout;
