import React, { useContext, useState } from "react";
import { Model01SimAilmentsContext } from "../Model01";
import CheckBoxSimple from "../../CheckBox";

const TheatreLinkCtrl = ({ checkedStateJs, setCheckedStateJs }) => {
  /* eslint-disable  no-unused-vars */

  const [dsAilments, setDsSimAilments] = useContext(Model01SimAilmentsContext);
  const sortStringTitles = (a, b) => {
    return String(a.title).localeCompare(b.title);
  };
  const [first, setFirst] = useState(true);
  /* eslint-enable  no-unused-vars */

  const handleOnChange = (ailment_id) => {
    // Locate the value within the local array and updated it
    checkedStateJs[ailment_id] = !checkedStateJs[ailment_id];
    setCheckedStateJs(checkedStateJs);
    // Data all ok at this point but control not updating.
    // console.log("checkedStateJs[ailment_id]: ", checkedStateJs[ailment_id]);
    // Without the line below the array refresh does not work on the controls.
    // Its really strange. NAJ 20221202
    setFirst(!first);
  };

  return (
    <>
      <br />
      {dsAilments &&
        dsAilments.length > 0 &&
        checkedStateJs &&
        dsAilments
          .filter((r) => r.emergency === false)
          .sort(sortStringTitles)
          .map((ail) => (
            <span key={ail.id}>
              <CheckBoxSimple
                checked={checkedStateJs[ail.id] || false}
                onChange={() => handleOnChange(ail.id)}
              />{" "}
              <label
                htmlFor={`custom-checkbox-${ail.id}`}
                className="input-checkbox-text"
              >
                {ail.title}
              </label>
              <br />
            </span>
          ))}
    </>
  );
};

export default TheatreLinkCtrl;
