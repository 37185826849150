import React from "react";
import styles from "../index.module.scss";

const TextFieldUnCtl = ({ ...props }) => {
  return (
    <div className="mb-2">
      <input
        className={`form-control-small form-control shadow-none bold ${styles.inputBox}`}
        {...props}
        autoComplete="off"
      />
    </div>
  );
};

export default TextFieldUnCtl;
