import React, {
  useState,
  useContext,
  useRef,
  useEffect,
  useCallback,
} from "react";
import { postLeaveFile, getProgress } from "../../../../api/ApiConsultCalls";
import {
  ConsultRefreshAllContext,
  ConsultSettingsContext,
  ConsultIsDirty,
  ConsultIsLocked,
} from "../../Consult";
import StatusOfProcessGeneric from "../../../progress/StatusOfProcessGeneric";
import { GoCloudUpload } from "react-icons/go";
// import { VscFolderActive } from "react-icons/vsc";
import styles from "../../../../indexOrg.module.scss";
import progress from "../../../../scss/style.module.scss";
import UploadedFileName from "../../../UploadedFileName";

const UploadLeave = (disabled) => {
  const [data, writeSimSettings] = useContext(ConsultSettingsContext); // eslint-disable-line no-unused-vars
  const [isDirty, setIsDirty] = useContext(ConsultIsDirty); // eslint-disable-line no-unused-vars
  const [messages, setMessages] = useState([]);
  const [errors, setErrors] = useState([]);
  const [selectedFile, setSelectedFile] = useState();
  const [isSelected, setIsSelected] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isLocked] = useContext(ConsultIsLocked);

  const fileInput = useRef(null);
  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    setIsSelected(true);
    // handleSubmission();
  };

  // eslint-disable-next-line no-unused-vars
  const [isSysAdmin, setIsSysAdmin] = useState(
    localStorage.getItem("isSysAdmin").replace(/['"]+/g, "")
  );

  // Grab the function for if there is a ptl upload
  // Refeshing all here as the analysis head gets updated with the activity dates during upload
  const refreshAll = useContext(ConsultRefreshAllContext);

  const handleSubmission = useCallback(() => {
    if (isSelected) {
      if (fileInput && fileInput.current && fileInput.current.files) {
        if (fileInput.current.files.length === 0) {
          alert("No files yet!");
        } else {
          setIsUploading(true);
          (async () => {
            await postLeaveFile(
              selectedFile,
              data.id,
              (result) => {
                console.log("Upload complete within control");

                result.then(function (res) {
                  console.log(res); // "Some User token"
                  // Angus says users not interested in messages.
                  //setMessages(res.messages);
                  setErrors(res.errors);
                  refreshAll();
                  // if (errors.length === 0) {
                  //   refreshAll();
                  // }
                });

                // Success

                if (result) {
                  setIsDirty(true);
                  setSelectedFile();
                  setIsSelected(false);
                } else {
                  setMessages([
                    "There has been a problem updating the data - the api did not respond correctly. Please try again.",
                  ]);
                }
                setIsUploading(false);
              },
              (error) => {
                // Failure
                setIsUploading(false);
                alert("Error: " + JSON.stringify(error, null, 2));
              }
            );
          })();
        }
      }
    }
  }, [isSelected, data.id, refreshAll, selectedFile, setIsDirty]);

  useEffect(() => {
    handleSubmission();
  }, [fileInput, handleSubmission]);

  return (
    <div>
      {isUploading ? (
        <>
          <div className={progress.progressSpinner}>
            <StatusOfProcessGeneric
              isActive={isUploading}
              sim_guid={data.id}
              getModellingStatus={getProgress}
            />
          </div>
        </>
      ) : (
        <>
          <label className={`pointer ${isLocked ? "d-none" : ""}`}>
            <input
              type="file"
              name="file"
              className={styles.hidden}
              onChange={(e) => changeHandler(e)}
              // onChangeCapture={() => handleSubmission()}
              ref={fileInput}
            />
            <GoCloudUpload size={35} />
            &nbsp;{"  "}&nbsp; Upload Leave List
          </label>
          <UploadedFileName data={data} upload_type={"leave"} />
        </>
      )}

      {messages &&
        messages.map((msg, index) => (
          <div key={index} className="alert alert-success">
            {msg}
          </div>
        ))}

      {errors && errors.length > 0 && (
        <>
          <br />
          <br />
          There is one or more errors with the uploaded file.
        </>
      )}

      {errors &&
        errors.map((msg, index) => (
          <div key={index} className="alert alert-danger">
            {msg}
          </div>
        ))}
    </div>
  );
};

export default UploadLeave;
