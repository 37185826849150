/* eslint-disable no-unused-vars */
import React, { useState, useContext, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TextField from "../../TextField";
import {
  putTheatreType,
  postTheatreType,
  putTheatreLink,
  postTheatreLink,
  getSimTheatreLink,
} from "../../../api/ApiPtlCalls";
import {
  Model01IsDirty,
  Model01SimAilmentsContext,
  Model01ScheduleRefreshContext,
} from "../Model01";
import TheatreLinkCtrl from "./TheatreLinkCtrl";
import ColourChooser from "../../ColourChooser";

const TheatreType = ({ data, dataUpdated, isNew, displayType }) => {
  const [displayTypeLocal, setDisplayType] = useState(displayType);
  const [isDirty, setIsDirty] = useContext(Model01IsDirty);
  const [checkedStateJs, setCheckedStateJs] = useState({});
  const [dsAilments, setDsSimAilments] = useContext(Model01SimAilmentsContext);
  const [refreshScheduleNow] = useContext(Model01ScheduleRefreshContext);
  const [toggleRefresh, setToggleRefresh] = useState(false);

  let schema = {};
  let initValues = {};

  const sortStringTitles = (a, b) => {
    return String(a.title).localeCompare(b.title);
  };

  const RefreshData = () => {
    setToggleRefresh(!toggleRefresh);
    refreshScheduleNow();
  };

  useEffect(() => {
    const fetchLinkTableRecords = (sim_id) => {
      getSimTheatreLink(
        sim_id,
        (simData) => {
          const locateLinkRecord = (ailment_id) => {
            // Locate a record in the dsTheatreLink
            let results = false;
            if (simData && simData.length > 0) {
              simData
                .filter(
                  (r) =>
                    r.fk_sim_theatre_type_id === data.id &&
                    r.fk_sim_ailment_id === ailment_id
                )
                .forEach((r) => {
                  results = true;
                });
              return results;
            } else {
              return false;
            }
          };

          let resultJs = {};
          if (dsAilments && dsAilments.length > 0) {
            dsAilments
              .filter((r) => r.emergency === false)
              .sort(sortStringTitles)
              .forEach((ail, i) => {
                // Workout if record is checked or not.
                resultJs[ail.id] = locateLinkRecord(ail.id);
              });
          }
          setCheckedStateJs(resultJs);
        },
        (error) => {
          console.log(error);
        }
      );
    };

    if (data && data.simulation_id != null) {
      fetchLinkTableRecords(data.simulation_id);
    }
  }, [data, toggleRefresh, dsAilments]);

  /*
  main
  */
  if (isNew) {
    schema = {
      simulation_id: Yup.number().integer().required(),
      title: Yup.string()
        .max(84, "Must be 84 chars or less")
        .required("Required"),
      sessions_monday: Yup.number()
        .integer()
        .min(0, "Must be more than 0")
        .required("Required"),
      sessions_tuesday: Yup.number()
        .integer()
        .min(0, "Must be more than 0")
        .required("Required"),
      sessions_wednesday: Yup.number()
        .integer()
        .min(0, "Must be more than 0")
        .required("Required"),
      sessions_thursday: Yup.number()
        .integer()
        .min(0, "Must be more than 0")
        .required("Required"),
      sessions_friday: Yup.number()
        .integer()
        .min(0, "Must be more than 0")
        .required("Required"),
      sessions_saturday: Yup.number()
        .integer()
        .min(0, "Must be more than 0")
        .required("Required"),
      sessions_sunday: Yup.number()
        .integer()
        .min(0, "Must be more than 0")
        .required("Required"),
      session_hours: Yup.number()
        .integer()
        .min(0, "Must be more than 0")
        .required("Required"),
      p_cancelled: Yup.number().min(0, "Must be positive").required("Required"),
      theatre_priority: Yup.number()
        .integer()
        .min(0, "Must be positive")
        .required("Required"),
    };
    initValues = {
      id: data["id"] || "",
      simulation_id: data["simulation_id"],
      title: data["title"] || "",
      sessions_monday: data["sessions_monday"] || 0,
      sessions_tuesday: data["sessions_tuesday"] || 0,
      sessions_wednesday: data["sessions_wednesday"] || 0,
      sessions_thursday: data["sessions_thursday"] || 0,
      sessions_friday: data["sessions_friday"] || 0,
      sessions_saturday: data["sessions_saturday"] || 0,
      sessions_sunday: data["sessions_sunday"] || 0,
      session_hours: data["session_hours"] || 0,
      patients_per_session: data["patients_per_session"] || 0,
      p_cancelled: data["p_cancelled"] || 0,
      no_of_concurrent_sessions: data["no_of_concurrent_sessions"] || 0,
      theatre_priority: data["theatre_priority"] || 0,
    };
  } else if (displayTypeLocal === "main") {
    schema = {
      simulation_id: Yup.number().integer().required(),
      title: Yup.string()
        .max(84, "Must be 84 chars or less")
        .required("Required"),
      sessions_monday: Yup.number()
        .integer()
        .min(0, "Must be more than 0")
        .required("Required"),
      sessions_tuesday: Yup.number()
        .integer()
        .min(0, "Must be more than 0")
        .required("Required"),
      sessions_wednesday: Yup.number()
        .integer()
        .min(0, "Must be more than 0")
        .required("Required"),
      sessions_thursday: Yup.number()
        .integer()
        .min(0, "Must be more than 0")
        .required("Required"),
      sessions_friday: Yup.number()
        .integer()
        .min(0, "Must be more than 0")
        .required("Required"),
      sessions_saturday: Yup.number()
        .integer()
        .min(0, "Must be more than 0")
        .required("Required"),
      sessions_sunday: Yup.number()
        .integer()
        .min(0, "Must be more than 0")
        .required("Required"),
      session_hours: Yup.number()
        .integer()
        .min(0, "Must be more than 0")
        .required("Required"),
      p_cancelled: Yup.number().min(0, "Must be positive").required("Required"),
      theatre_priority: Yup.number()
        .integer()
        .min(0, "Must be positive")
        .required("Required"),
    };
    initValues = {
      id: data["id"] || "",
      simulation_id: data["simulation_id"],
      title: data["title"] || "",
      sessions_monday: data["sessions_monday"] || 0,
      sessions_tuesday: data["sessions_tuesday"] || 0,
      sessions_wednesday: data["sessions_wednesday"] || 0,
      sessions_thursday: data["sessions_thursday"] || 0,
      sessions_friday: data["sessions_friday"] || 0,
      sessions_saturday: data["sessions_saturday"] || 0,
      sessions_sunday: data["sessions_sunday"] || 0,
      session_hours: data["session_hours"] || 0,
      patients_per_session: data["patients_per_session"] || 0,
      p_cancelled: data["p_cancelled"] || 0,
      no_of_concurrent_sessions: data["no_of_concurrent_sessions"] || 0,
      theatre_priority: data["theatre_priority"] || 0,
      rpt_colour: data["rpt_colour"] || "#000000",
    };
  }
  const frmSchema = Yup.object(schema);

  const setData = (ailment) => {
    // Update the cached data now.
  };

  const SaveFormData = async (values) => {
    // console.log(
    //   "TheatreType.SaveFormData.localTheatreLnkdRecords:",
    //   checkedStateJs
    // );

    // Assemble the data
    let myDataCheck = { ...data, ...values };
    // Put it to the server
    if (!isNew) {
      await putTheatreType(
        myDataCheck,
        (result) => {
          // Success
          if (result) {
            setData(myDataCheck);
            dataUpdated(myDataCheck);
            setIsDirty(true);
            putTheatreLink(
              data.id,
              checkedStateJs,
              (result) => {
                // Success
                if (result) {
                  RefreshData();
                  setIsDirty(true);
                  // setTimeout(function () {
                  //   RefreshData();
                  //   setIsDirty(true);
                  // }, 2000);
                } else {
                  alert(
                    "There has been a problem updating the data - the api did not respond correctly."
                  );
                }
              },
              (error) => {
                // Failure
                alert("Error: " + error);
              }
            );
          } else {
            alert(
              "There has been a problem updating the data - the api did not respond correctly."
            );
          }
        },
        (error) => {
          // Failure
          alert("Error: " + error);
        }
      );
    } else {
      await postTheatreType(
        myDataCheck,
        (result) => {
          // Success
          if (result) {
            setData(myDataCheck);
            dataUpdated(myDataCheck);
            setIsDirty(true);

            postTheatreLink(
              result.id,
              checkedStateJs,
              (result) => {
                // Success
                if (result) {
                  RefreshData();
                  setIsDirty(true);
                } else {
                  alert(
                    "There has been a problem saving the data - the api did not respond correctly."
                  );
                }
              },
              (error) => {
                // Failure
                alert("Error: " + error);
              }
            );
          } else {
            alert(
              "There has been a problem saving the data - the api did not respond correctly."
            );
          }
        },
        (error) => {
          // Failure
          alert("Error: " + error);
        }
      );
    }
  };
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initValues}
        validationSchema={frmSchema}
        onSubmit={async (values) => {
          SaveFormData(values);
          //alert(JSON.stringify(values, null, 2));
        }}
      >
        {(formik) => (
          <div>
            <Form id={displayType + isNew.toString()}>
              <Row>
                <Col>
                  <TextField
                    label="Title"
                    name="title"
                    type="text"
                    maxLength="84"
                  />
                </Col>
              </Row>
              {displayTypeLocal && (displayTypeLocal === "main" || isNew) && (
                <>
                  <Row>
                    <Col md="6">
                      <TextField
                        label="Hours per session"
                        name="session_hours"
                        type="number"
                        min="0"
                      />
                      <TextField
                        label="Max patients per session"
                        name="patients_per_session"
                        type="number"
                        min="0"
                      />
                    </Col>
                    <Col md="6">
                      <TextField
                        label="Probablility patient will cancel"
                        name="p_cancelled"
                        type="number"
                        min="0"
                        step="any"
                      />
                      <TextField
                        label="Priority"
                        name="theatre_priority"
                        type="number"
                        min="0"
                      />
                    </Col>
                  </Row>
                  <Row style={{ padding: "15px 0" }}>
                    <h5 style={{ paddingBottom: "5px" }}>Associated Colour</h5>
                    <ColourChooser label="Colour" name="rpt_colour" />
                  </Row>
                  <Row style={{ paddingTop: "20px", marginBottom: "20px" }}>
                    <h5 style={{ paddingBottom: "5px" }}>Patient Groups</h5>
                    <TheatreLinkCtrl
                      checkedStateJs={checkedStateJs}
                      setCheckedStateJs={setCheckedStateJs}
                    />
                  </Row>
                </>
              )}
            </Form>
          </div>
        )}
      </Formik>
      <button
        className="btn btn-outline-primary submit"
        form={displayType + isNew.toString()}
      >
        Submit
      </button>
    </>
  );
};

export default TheatreType;
