import React, { useState } from "react";

import { Link, useNavigate } from "react-router-dom";

// Nice tutorial from:
// https://www.youtube.com/watch?v=u-CCnDayNJw
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Button } from "react-bootstrap";

import TextField from "./TextField";
import { Login_Admin } from "../common/login_token";
import styles from "../index.module.scss";
import DocLogo from "../images/doc_logo.png";
import PTLLogo from "../images/ptl_logo.png";
import { Spinner } from "react-bootstrap";
import progress from "./progress/progress.module.scss";

const Login = ({ loggedIn }) => {
  const navigate = useNavigate();
  const [showSpinner, setShowSpinner] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const validate = Yup.object({
    userName: Yup.string()
      .max(50, "Must be 50 chars or less")
      .required("Required"),
    password: Yup.string()
      .max(20, "Must be 20 characters or less")
      .min(6, "Must be 6 chars or longer")
      .required("Required"),
  });

  const FetchUser = async (values) => {
    //alert('made it here with: ' + JSON.stringify(values, null, 2));
    // Check the credentials
    setErrorMessage("");
    setShowSpinner(true);

    // Update the localStorage
    Login_Admin(
      values.userName,
      values.password,
      values.rememberMe,
      () => {
        // Success
        loggedIn(true);
        setShowSpinner(false);
        setErrorMessage("");
      },
      (error) => {
        // Failure
        // loggedIn(false);
        setShowSpinner(false);
        if (error.message === "Unauthorized" || error.message === "") {
          setErrorMessage("Login unsuccessful.");
        } else {
          setErrorMessage(error.message);
        }
      }
    );

    // Finally redirect
    navigate("/", { replace: true });
  };

  return (
    <div className={styles.containerBox}>
      <Formik
        initialValues={{
          userName: "",
          password: "",
          rememberMe: false,
        }}
        validationSchema={validate}
        onSubmit={async (values) => {
          //console.log('Submit with these values:');
          //console.log(values)
          FetchUser(values);
          // setTimeout(() => {
          //   alert(JSON.stringify(values, null, 2));
          // }, 400);
        }}
      >
        {(formik) => (
          <div>
            <>
              <header className={styles.headerLogin}>
                <img
                  src={DocLogo}
                  alt="Doc Platform"
                  width={399 * 0.65}
                  height={157 * 0.65}
                />
                <br />
                <br />
                <img
                  src={PTLLogo}
                  alt="PTL Planning"
                  width={138 * 1.8}
                  height={112 * 1.8}
                />
                <br />
                <br />
              </header>
            </>
            <Form>
              <TextField
                label="Username"
                name="userName"
                type="text"
                variant="outlined"
                maxLength="50"
                required={true}
              />
              <TextField
                label="Password"
                name="password"
                type="password"
                variant="outlined"
                maxLength="20"
                required={true}
              />
              {errorMessage && (
                <div className={styles.loginMessage}>{`${errorMessage}`}</div>
              )}
              {/* <TextField
                label="Remember me"
                name="rememberMe"
                type="checkbox"
                // className="form-control form-check-input form-control-checkbox"
              /> */}
              <div className={styles.loginContainer}>
                <Button className="btn float-end" type="submit">
                  Login{" "}
                </Button>
              </div>
            </Form>
          </div>
        )}
      </Formik>
      <Link to={"/fpwd"}>Reset password</Link>
      {showSpinner && (
        <div className={styles.loginSpinner}>
          <Spinner
            as="span"
            animation="border"
            size="md"
            role="status"
            aria-hidden="true"
            className={progress.spinner}
          />
        </div>
      )}
    </div>
  );
};

export default Login;
