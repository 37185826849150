import React from "react";
import { useFormikContext } from "formik";
import debounce from "just-debounce-it";
//https://itnext.io/formik-introduction-autosave-react-19d4c15cfb90
// https://codesandbox.io/s/formik-autosave-example-wfcb6?file=/src/AutoSavingForm.tsx:166-892

const AutoSave = ({ debounceMs }) => {
  const formik = useFormikContext();
  const [lastSaved, setLastSaved] = React.useState(null);
  // eslint-disable-next-line
  const debouncedSubmit = React.useCallback(
    debounce(
      () =>
        formik.submitForm().then(() => setLastSaved(new Date().toISOString())),
      debounceMs
    ),
    [debounceMs, formik.submitForm]
  );

  React.useEffect(() => {
    debouncedSubmit();
  }, [debouncedSubmit, formik.values]);

  let result = null;

  if (!!formik.isSubmitting) {
    result = "saving...";
  } else if (Object.keys(formik.errors).length > 0) {
    result = `ERROR: please check the form`;
  } else if (lastSaved !== null) {
    result = ``;
  }
  return <>{result}</>;
};

export default AutoSave;
