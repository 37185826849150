import React, { useState, useContext, useEffect } from "react";
import {
  getJobPlanFilterOptions,
  getJobPlanActivityDuration,
  putJobPlanActivityDuration,
} from "../../../../api/ApiConsultCalls";
import {
  ConsultSettingsContext,
  ConsultRefreshChildrenContext,
  ConsultSetRefreshChildrenContext,
} from "../../Consult";
import SelectableList from "../../../SelectableList";
import styles from "../../../../scss/style.module.scss";
import JobPlanSelectableActivityList from "./JobPlanSelectableActivityList";

// This control filters. It gets all options from the call getJobPlanFilterOptions
// It saves the filter settings to the main an_head; filter_activity, filter_benchmark, filter_category, is_filtered
const JobPlanFilterEdit = ({ readonly, showEdit, closeMe }) => {
  // Contexts
  const [data, writeSimSettings] = useContext(ConsultSettingsContext); // eslint-disable-line no-unused-vars
  const refreshChild = useContext(ConsultRefreshChildrenContext);
  const refreshChildren = useContext(ConsultSetRefreshChildrenContext);

  // Form dataset
  const [dsOptionsActivity, setdsOptionsActivity] = useState({});
  const [dsOptionsBenchmark, setdsOptionsBenchmark] = useState({});
  const [dsOptionsCategory, setdsOptionsCategory] = useState({});
  const [dsRelationship, setDsRelationship] = useState({});

  // List of durations from job plans
  const [dsActivityDuration, setDsActivityDuration] = useState({});

  // Form Vars
  const [checkedStateJsActivity, setcheckedStateJsActivity] = useState({});
  const [checkedStateJsBenchmark, setcheckedStateJsBenchmark] = useState({});
  const [checkedStateJsCategory, setcheckedStateJsCategory] = useState({});

  // This enables saving after two seconds of last change.
  // const saveRequest = debounceFunction();

  const sortStringTitles = (a, b) => {
    return String(a).localeCompare(b);
  };

  // Query the data and admin it for the refreshing
  useEffect(() => {
    // Breakdown the settings from the data head
    const adminFilterField = (filter_field) => {
      return filter_field && filter_field.length > 0
        ? filter_field.split("|").filter((element) => element !== "")
        : [];
    };
    // Here we build an array based upon selection
    const adminFilterOptions = (dsData, selectionArray) => {
      let resultJs = {};
      if (dsData && dsData.length > 0) {
        dsData.sort(sortStringTitles).forEach((ail, i) => {
          // Workout if record is checked or not.
          // const isSelected = activitySelected.includes(ail);
          resultJs[ail] = selectionArray.includes(ail);
        });
      }
      return resultJs;
    };

    // Fetch the JobPlan details.
    if (data.id !== undefined) {
      getJobPlanFilterOptions(
        data.id,
        (optionData) => {
          // Store the results
          setdsOptionsActivity(optionData.activity);
          setdsOptionsBenchmark(optionData.benchmark);
          setdsOptionsCategory(optionData.category);
          setDsRelationship(optionData.relationship);

          // Selections
          const activitySelected = adminFilterField(data.filter_activity);
          const benchmarkSelected = adminFilterField(data.filter_benchmark);
          const categorySelected = adminFilterField(data.filter_category);

          setcheckedStateJsActivity(
            adminFilterOptions(optionData.activity, activitySelected)
          );
          setcheckedStateJsBenchmark(
            adminFilterOptions(optionData.benchmark, benchmarkSelected)
          );
          setcheckedStateJsCategory(
            adminFilterOptions(optionData.category, categorySelected)
          );

          getJobPlanActivityDuration(
            data.id,
            (dataDuration) => {
              setDsActivityDuration(dataDuration);
            },
            (error) => {}
          );
        },
        (error) => {}
      );
    }
  }, [
    data.id,
    data.filter_activity,
    data.filter_benchmark,
    data.filter_category,
    refreshChild,
  ]);

  const UpdateData = () => {
    // Gather the items set to true and make them pipe delimited.
    // Note that with the nature of text and their comparison (especially within the sql queries) pipes are needed beginning and end
    const AdminJson = (data) => {
      let pipeDelimitedText = "|";
      for (const key in data) {
        if (data[key] === true) {
          pipeDelimitedText += key + "|";
        }
      }
      if (pipeDelimitedText === "|") {
        pipeDelimitedText = "";
      }
      return pipeDelimitedText;
    };

    // Build the settings then upload them

    var newData = {
      filter_activity: AdminJson(checkedStateJsActivity),
      filter_benchmark: AdminJson(checkedStateJsBenchmark),
      filter_category: AdminJson(checkedStateJsCategory),
    };

    // console.log(JSON.stringify(newData));

    var myDataCheck = { ...data, ...newData };
    // Send it up the tree
    writeSimSettings(myDataCheck);

    // Update the job plan activity durations
    dsActivityDuration.forEach((element) => {
      if (element.changed) {
        putJobPlanActivityDuration(
          element,
          () => {},
          () => {}
        );
        // alert("An update to be made");
      }
    });

    refreshChildren();
  };

  const BenchmankChanged = (element, isChecked) => {
    setcheckedStateJsBenchmark((prevCheckedStateJsBenchmark) => ({
      ...prevCheckedStateJsBenchmark,
      [element]: isChecked,
    }));

    const elementUsed = new Set();

    // Update the settings for checkedStateJsActivity
    setcheckedStateJsActivity((prevCheckedStateJsActivity) => {
      const updatedCheckedStateJsActivity = { ...prevCheckedStateJsActivity };

      dsRelationship.forEach((item) => {
        const parts = item.split("|");
        if (parts.length >= 3 && parts[1] === element) {
          const thirdParameter = parts[2];
          if (!elementUsed.has(thirdParameter)) {
            updatedCheckedStateJsActivity[thirdParameter] = isChecked;
            elementUsed.add(thirdParameter);
          }
        }
      });

      return updatedCheckedStateJsActivity;
    });
  };

  const onBenchmarkOnChange = (element) => {
    let isChecked = checkedStateJsBenchmark[element];
    BenchmankChanged(element, isChecked);
  };

  const onCategoryOnChange = (element) => {
    let isChecked = checkedStateJsCategory[element];
    var elementUsed = "|";

    // Loop through the data, find the categories and update the benchmarks
    dsRelationship.forEach((item) => {
      const parts = item.split("|");
      if (parts.length >= 2) {
        if (parts[0] === element) {
          // Get the entry
          if (element === parts[0]) {
            const secondParameter = parts[1];
            // Check its not been used already
            if (elementUsed.indexOf("|" + secondParameter + "|") === -1) {
              // Fire an benchmark event
              BenchmankChanged(secondParameter, isChecked);
              elementUsed += secondParameter + "|";
            }
          }
        }
      }
    });
  };

  return (
    <React.Fragment>
      <div className={""}>
        <table>
          <tbody>
            <tr>
              <td className={styles.tables_three}>
                <h5>Category</h5>
                <SelectableList
                  dsData={dsOptionsCategory}
                  checkedStateJs={checkedStateJsCategory}
                  setCheckedStateJs={(selections) => {
                    setcheckedStateJsCategory(selections);
                    // RequestSave();
                  }}
                  readonly={readonly}
                  onChange={onCategoryOnChange}
                />
              </td>
              <td className={styles.tables_three}>
                <h5>Benchmark</h5>
                <SelectableList
                  dsData={dsOptionsBenchmark}
                  checkedStateJs={checkedStateJsBenchmark}
                  setCheckedStateJs={(selections) => {
                    setcheckedStateJsBenchmark(selections);
                    // RequestSave();
                  }}
                  readonly={readonly}
                  onChange={onBenchmarkOnChange}
                />
              </td>
              <td className={styles.tables_three}>
                <JobPlanSelectableActivityList
                  dsData={dsOptionsActivity}
                  checkedStateJs={checkedStateJsActivity}
                  setCheckedStateJs={(selections) => {
                    setcheckedStateJsActivity(selections);
                    // RequestSave();
                  }}
                  readonly={readonly}
                  dsActivityDuration={dsActivityDuration}
                  setDsActivityDuration={setDsActivityDuration}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className={styles.buttonContainer}>
        <button
          className={!readonly ? "btn btn-outline-primary" : "d-none"}
          onClick={() => {
            // alert("Submit now");
            UpdateData();
            if (closeMe !== undefined) {
              closeMe();
            }
          }}
        >
          Submit
        </button>
      </div>
    </React.Fragment>
  );
};

export default JobPlanFilterEdit;
