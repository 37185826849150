import React from "react";
import UploadActivity from "./UploadActivity";
import UploadVetting from "./UploadVetting";
import ActivityTable from "./ActivityTable";
import styles from "../../../../scss/style.module.scss";
import ActivityNoMatchTable from "./ActivityNonMatchTable";

// There is a user alert that appears on the activy upload if there is a data mismatch.
// This is hosted in the ActivityTable control and is on the ActivityDate control where all data is known after the update.

const TabActivity = ({ tabNo }) => {
  return (
    <>
      <div>
        <div className={styles.form_halves}>
          <div className={styles.left_control_box}>
            <h5>1. Reporting Activity *</h5>
            <UploadActivity />
          </div>
          <div className={styles.right_control_box}>
            <h5>2. Vetting data?</h5>
            <UploadVetting />
          </div>
        </div>
        <div className={styles.form_halves}>
          <div className={styles.left_control_box}>
            <h5>3. Summary of Activity Data</h5>
            <ActivityTable />
          </div>
          <div className={styles.right_control_box}>
            <h5>4. Exceptions: Clinical Images Not Recognised</h5>
            <ActivityNoMatchTable />
          </div>
        </div>
      </div>
    </>
  );
};

export default TabActivity;
