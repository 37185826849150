/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable-line react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  getSimSessionDemand,
  postSessionDemand,
  addToTheatreSchedule,
} from "../../../api/ApiPtlCalls";
import { Formik, Form } from "formik";
import progress from "../../progress/progress.module.scss";
import {
  Model01SimSettingsContext,
  Model01SimTheatreContext,
  Model01SimTheatreDiary,
  Model01IsLocked,
} from "../Model01";
import style from "../../../scss/style.module.scss";
import ModalLayout from "../../modal/Modal";
import DnDResource from "./calendar/Calendar";
import { momentLocalizer } from "react-big-calendar";
import moment from "moment";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TextField from "../../TextField";
import { FaPlus } from "react-icons/fa";
import { Spinner } from "react-bootstrap";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";

const SessionDemandGrid = ({ ctrlValid, ctrlNumber }) => {
  /* eslint-disable  no-unused-vars */
  const [data, writeSimSettings] = useContext(Model01SimSettingsContext);
  const [dsSimTheatreTypes, setDsSimTheatreTypes] = useContext(
    Model01SimTheatreContext
  );
  const [isLocked] = useContext(Model01IsLocked);
  const [key, setKey] = useState(0);
  const [diaryDetails, setSimTheatreSchedule] = useContext(
    Model01SimTheatreDiary
  );

  const [popupRecord, setPopupRecord] = useState([]);
  const [showModal, setShowModal] = useState(false);
  let refLength = useRef(0);
  const [dsRecords, setdsRecords] = useState([]);
  let [value, setValue] = useState(false);
  let [isLoading, setIsLoading] = useState(false);
  moment.locale("en-gb", {
    week: {
      dow: 1,
    },
  });

  const localizer = momentLocalizer(moment);

  // Get the simulation data
  const fetchRecords = async (simId) => {
    const sessions = await getSimSessionDemand(
      simId,
      (simData) => {
        setdsRecords(simData);
        // Data retieved myst be valid
        ctrlValid(ctrlNumber, true);
        return simData;
      },
      (error) => {
        console.log(error);
      }
    );
    return sessions;
  };

  const SaveFormData = async (values) => {
    // Assemble the data
    var myDataCheck = { ...data, ...values };
    // Put it to the server
    let formData;
    await postSessionDemand(
      myDataCheck,
      async (result) => {
        // Success
        if (result) {
          formData = result;
          return fetchRecords(data["simulation_id"]);
        } else {
          alert(
            "There has been a problem saving the data - the api did not respond correctly."
          );
        }
      },
      (error) => {
        // Failure
        alert("Error: " + JSON.stringify(error, null, 2));
      }
    );
    return formData;
  };

  const modalClose = () => {
    setShowModal(false);
  };

  // Initial data population
  useEffect(() => {
    if (data["simulation_id"] !== undefined) {
      fetchRecords(data["simulation_id"]);
      setValue(0);
    }
  }, [data]);

  const newRecord = async () => {
    setPopupRecord([]);
    setShowModal(true);
  };

  const saveRecord = async (values) => {
    setIsLoading(true);
    setShowModal(false);
    var newAilmentRecord = [];
    newAilmentRecord.simulation_id = data.simulation_id;
    newAilmentRecord.id = "00000000-0000-0000-0000-000000000000";
    newAilmentRecord.fk_sim_theatre_type_id =
      "00000000-0000-0000-0000-000000000000";
    newAilmentRecord.demand_change_day = values.demand_change_day;
    const newSession = await SaveFormData(newAilmentRecord);
    let prevEvents = diaryDetails.demand[value].diary;
    const diaryArr = [];

    const elem = {
      id: newSession.id,
      no_of_theatres: data["no_of_theatres"],
      diary: {},
    };
    await Promise.all(
      prevEvents.map(async (elementEvent) => {
        delete elementEvent.id;
        elementEvent.fk_sim_session_demand_id = newSession.id;
        await addToTheatreSchedule(elementEvent, (result) => {
          if (result) {
            elementEvent.id = result.id;
            diaryArr.push(elementEvent);

            return result;
          } else {
            alert("There has been a problem updating the schedule.");
          }
        });
      })
    );
    elem.diary = diaryArr;
    const updatedDiaryDetails = diaryDetails;
    updatedDiaryDetails.demand.push(elem);
    setSimTheatreSchedule(updatedDiaryDetails);
    if (refLength.current !== dsRecords.length) {
      if (refLength.current !== 0) {
        value = dsRecords.length - 1;
        setValue(value);
        setIsLoading(false);
      }
      refLength.current = dsRecords.length;
    }
  };

  if (refLength.current !== dsRecords.length) {
    if (refLength.current !== 0) {
      value = dsRecords.length - 1;
      setValue(value);
    }
    refLength.current = dsRecords.length;
  }

  const calcChangeDate = (changeDay) => {
    const myDate = new Date(data["model_start_dtg"]);
    myDate.setDate(myDate.getDate() + changeDay);
    return myDate.toISOString().split("T")[0];
  };

  const calcChangeDateLabel = (changeDay) => {
    const myDate = new Date(data["model_start_dtg"]);
    myDate.setDate(myDate.getDate() + changeDay);
    return moment(myDate).format("DD-MM-YYYY");
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function TabPanel(props) {
    const { children, value, index } = props;

    return <div>{value === index && children}</div>;
  }

  let theme = useTheme();

  theme = createTheme(theme, {
    components: {
      MuiTab: {
        styleOverrides: {
          root: {
            textAlign: "unset",
            alignItems: "unset",
            color: "#dadada",
            "&.Mui-selected": {
              backgroundColor: "#eeeeee !important",
              color: "#1E2761 !important",
            },
          },
        },
      },
    },
  });
  return (
    <div>
      {!!diaryDetails && (
        <>
          <div style={{ marginBottom: "25px" }} className={style.flexRow}>
            <div
              style={{
                marginBottom: "0",
                display: "flex",
                flex: "1",
                justifyContent: "space-between",
              }}
              className={style.subtitle}
            >
              <h3>Theatre Schedule </h3>
              <div className={style.customButton}>
                <button
                  className={`btn btn-outline-primary ${
                    isLocked ? "d-none" : ""
                  }`}
                  onClick={newRecord}
                >
                  <FaPlus size={15} color="#ffffff" />
                  Duplicate Schedule
                </button>
              </div>
            </div>
          </div>
          {dsRecords[value] && (
            <ModalLayout
              show={showModal}
              handleClose={modalClose}
              title={`Duplicate Schedule ${calcChangeDate(
                dsRecords[value].demand_change_day
              )}`}
            >
              <Formik
                initialValues={{
                  id: data["id"] || "",
                  demand_change_day: data["demand_change_day"] || 0,
                }}
                validator={() => ({})}
                onSubmit={async (values, { resetForm }) => {
                  saveRecord(values);
                  resetForm({ values: "" });
                }}
              >
                <div>
                  <Form id="frmSessionDemand">
                    <TextField
                      label="Activity Change Day"
                      name="demand_change_day"
                      type="number"
                      maxLength="84"
                    />
                  </Form>
                </div>
              </Formik>
              <div className={style.buttonContainer}>
                <button
                  className="btn btn-outline-primary submit"
                  form="frmSessionDemand"
                >
                  Submit
                </button>
              </div>
            </ModalLayout>
          )}
          <>
            <ThemeProvider theme={theme}>
              <Tabs
                value={value}
                onChange={handleChange}
                TabIndicatorProps={{ style: { backgroundColor: "#d97d54" } }}
                classes={{
                  indicator: style.indicator,
                  root: style.root,
                }}
              >
                {dsRecords &&
                  dsRecords.map((session, index) => {
                    const myTag = calcChangeDate(session.demand_change_day);
                    const label = calcChangeDateLabel(
                      session.demand_change_day
                    );
                    return (
                      <Tab
                        onSelect={(k) => setKey(k)}
                        key={index}
                        className={`${style.tab} ${
                          key === index ? style.activeTab : ""
                        }`}
                        tag={myTag}
                        label={label}
                      />
                    );
                  })}
              </Tabs>
            </ThemeProvider>
          </>
          {dsRecords &&
            dsRecords.map((record, index) => {
              return (
                <TabPanel key={index} value={value} index={index}>
                  {!isLoading ? (
                    <DnDResource
                      startDate={data["model_start_dtg"]}
                      numberOfTheatres={data["no_of_theatres"]}
                      index={index}
                      localizer={localizer}
                    />
                  ) : (
                    <div className={style.progressSpinner}>
                      <div className={progress.activeSpinner}>
                        <div>
                          <div className={progress.spinnerIcon}>
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                              className={progress.spinner}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </TabPanel>
              );
            })}
        </>
      )}
    </div>
  );
};

export default SessionDemandGrid;
