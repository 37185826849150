import React, { createContext, useState } from "react";

// This is used to monitor which application the user is using
// Currently "none" "ptl", "consult"

export const CurrentApplicationShowing = createContext();

export function CurrentAppContextProvider({ children }) {
  const [activeApp, setActiveApp] = useState("ptl");
  return (
    <CurrentApplicationShowing.Provider value={{ activeApp, setActiveApp }}>
      {children}
    </CurrentApplicationShowing.Provider>
  );
}
// Usage:
//   // eslint-disable-next-line no-unused-vars
//   const { activeApp, setActiveApp } = useContext(CurrentApplicationShowing);
