import React, { useState, useEffect, useContext } from "react";
import Model1Links from "../md_01/Model01_links";
import { getCompanyList, delCompany } from "../../api/ApiCoreCalls";
import Table from "react-bootstrap/Table";
import ModalConfirm from "../modal/ModalConfirm";
import { GoPencil } from "react-icons/go";
import { FaTrash } from "react-icons/fa";
import { BsCheck, BsX, BsPeopleFill } from "react-icons/bs";
import { HeaderTitle } from "../../App";
import Company from "./Company";
import AddNewSearch from "../AddNewSearch";
import { Link } from "react-router-dom";
import ModalLayout from "../modal/Modal";
import table from "../../table.module.scss";
import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import indexStyle from "../../index.module.scss";
import style from "../../scss/style.module.scss";

const CompanyGrid = () => {
  const [headerTitle, setHeaderTitle] = useContext(HeaderTitle); // eslint-disable-line no-unused-vars
  const [simList, setsimList] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState({
    show: false,
    id: NaN,
    desc: "Company will permanently be deleted",
  });
  const [showModal, setShowModal] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [popupRecord, setPopupRecord] = useState([]);
  const [searchText, setSearchText] = useState("");

  // setHeaderTitle("Companies");

  const populateList = () => {
    getCompanyList(
      (result) => {
        // console.log(result);
        setsimList(result);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  useEffect(() => {
    populateList();
    setHeaderTitle("Trusts");
  }, [setHeaderTitle]);

  const rowDelete = () => {
    // ToDo: NAJ
    // alert("Under development id: " + showDeleteModal.id);
    modalDeleteClose();

    delCompany(
      showDeleteModal.id,
      () => {
        // Remove the item from the array
        var myArray = simList.filter((a) => {
          return a.id !== showDeleteModal.id;
        });
        setsimList(myArray);
      },
      (error) => {
        console.log(error);
      }
    );
  };
  const modalDeleteClose = () => {
    setShowDeleteModal(false);
  };
  const modalClose = () => {
    setShowModal(false);
    setIsNew(false);
  };
  const updateRecord = async (recordId) => {
    var ail = simList.filter((a) => {
      return a.id === recordId;
    });
    setPopupRecord(ail[0]);
    setIsNew(false);
    setShowModal(true);
  };
  const dataUpdated = (e) => {
    //alert(e);
    if (!isNew) {
      // Ensure doc display correctly
      setPopupRecord(e);
      var myArray = simList.filter((a) => {
        return a.id !== e.id;
      });
      myArray.push(e);
      setsimList(myArray);
    } else {
      // We have a new record...
      var myArrayNew = simList;
      myArrayNew.push(e);
      setsimList(myArrayNew);
      // setsimList(myDataCheck);
    }
    modalClose();
  };
  const SearchChanged = async (text) => {
    setSearchText(text);
  };
  const AddNew = async () => {
    setIsNew(true);
    setShowModal(true);
  };
  function mySeachFilter(comp) {
    if (comp && comp.CompanyName) {
      return (
        comp.CompanyName.toUpperCase().indexOf(searchText.toUpperCase()) > -1
      );
    } else return false;
  }

  const userLinkClick = (coName) => {
    // setHeaderTitle(coName + " / Users");
  };

  return (
    <>
      <div>
        <Model1Links selected="false" />
        <div className={indexStyle.containerSub}>
          <div>
            <AddNewSearch AddNew={AddNew} SetSearchText={SearchChanged} />
            <TableContainer>
              <Table className={table.companyTable} hover="true">
                <TableHead>
                  <TableRow className={style.borderedHeadRow}>
                    <TableCell>Trust Name</TableCell>
                    <TableCell>Contact</TableCell>
                    <TableCell>Telephone</TableCell>
                    <TableCell>Admin Email</TableCell>

                    <TableCell>Enabled</TableCell>
                    <TableCell>Expiry date</TableCell>

                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {simList &&
                    simList.length > 0 &&
                    simList.filter(mySeachFilter).map((sim) => (
                      <TableRow key={sim.id}>
                        <TableCell>{sim.CompanyName}</TableCell>
                        <TableCell>{sim.Contact}</TableCell>
                        <TableCell>{sim.Telephone}</TableCell>
                        <TableCell>{sim.AdminEMail}</TableCell>
                        <TableCell>
                          {sim.Enabled ? (
                            <BsCheck size={20} color="#008181" />
                          ) : (
                            <BsX size={20} color="#008181" />
                          )}
                        </TableCell>
                        <TableCell>
                          {sim.Expiry
                            ? sim.Expiry.substring(0, 10)
                            : "None set"}
                        </TableCell>

                        {/* <TableCell>
                          {sim.IsTrial ? (
                            <BsCheck size={20} color="#008181" />
                          ) : (
                            <BsX size={20} color="#008181" />
                          )}
                        </TableCell> */}

                        <TableCell>
                          <span
                            role="button"
                            onClick={() => {
                              updateRecord(sim.id);
                            }}
                          >
                            <GoPencil size={25} color="#008181" />
                          </span>
                          <span
                            role="button"
                            onClick={() => {
                              setShowDeleteModal({
                                show: true,
                                id: sim.id,
                                desc:
                                  "Company " +
                                  sim.CompanyName +
                                  ", contact " +
                                  sim.Contact +
                                  " will permanently be deleted",
                              });
                            }}
                          >
                            <FaTrash size={25} color="#7A2048" />
                          </span>

                          <Link
                            to={`/usr/${sim.id}`}
                            onClick={userLinkClick(sim.CompanyName)}
                          >
                            <BsPeopleFill size={25} color="#008181" />
                          </Link>

                          {/* <span
                            role="button"
                            onClick={() => {
                              updateRecord(sim.id);
                            }}
                            title="Users"
                          >
                            <BsPeopleFill size={25} color="#008181" />
                          </span>
                          {"  "}
                          <span
                            role="button"
                            onClick={() => {
                              updateRecord(sim.id);
                            }}
                            title="Add new user"
                          >
                            <MdPersonAddAlt1 size={25} color="#008181" />
                          </span> */}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
      <ModalLayout
        title={isNew ? "Add New Trust" : "Update Trust"}
        show={showModal}
        handleClose={modalClose}
      >
        <Company data={popupRecord} dataUpdated={dataUpdated} isNew={isNew} />
      </ModalLayout>
      <ModalConfirm
        show={showDeleteModal.show}
        handleClose={modalDeleteClose}
        title="Please confirm you want to delete this company"
        desc={showDeleteModal.desc}
        confirmed={rowDelete}
      ></ModalConfirm>
    </>
  );
};
export default CompanyGrid;
