import React, { useContext, useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { GoPencil } from "react-icons/go";
import { FaTrash } from "react-icons/fa";
import SelectField from "../../SeletField";
import style from "../../../scss/style.module.scss";

import { delSimAcuity } from "../../../api/ApiPtlCalls";
import {
  Model01SimAcuityContext,
  Model01SimAilmentsContext,
  Model01SimSettingsContext,
  Model01RefreshAllContext,
  Model01IsLocked,
} from "../Model01";
import ModalConfirm from "../../modal/ModalConfirm";
import Acuity from "./Acuity";
import ModalLayout from "../../modal/Modal";
import { validateAcuity } from "../../../common/validation";

const AcuityGridMean = ({ tabNo, ctrlValid, ctrlNumber }) => {
  const [dsAilments, setDsSimAilments] = useContext(Model01SimAilmentsContext); // eslint-disable-line no-unused-vars
  const [dsAcuity, setDsSimAcuity] = useContext(Model01SimAcuityContext); // eslint-disable-line no-unused-vars
  const [data, writeSimSettings] = useContext(Model01SimSettingsContext); // eslint-disable-line no-unused-vars
  const refreshAllNow = useContext(Model01RefreshAllContext);
  const [isLocked] = useContext(Model01IsLocked);
  //const [dsRecords, setdsRecords] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [popupRecord, setPopupRecord] = useState([]);
  const [recordId, setRecordId] = useState("");
  const [showSumError, setShowSumError] = useState(false);
  const [ailmentFilter, setAilmentFilter] = useState("");

  function ValidateFormData() {
    // Check they add up to 100%
    if (validateAcuity(dsAcuity)) {
      // Passed the test
      if (ctrlValid !== undefined) {
        ctrlValid(ctrlNumber, true);
      }
      // Do not show the error
      setShowSumError(false);
    } else {
      // Failed the test
      if (ctrlValid !== undefined) {
        ctrlValid(ctrlNumber, false);
      }
      // only show the error if there is at least one error
      setShowSumError(dsAcuity.length > 0);
    }
  }

  // Automate the form validation
  useEffect(() => {
    ValidateFormData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dsAcuity]);

  const rowDelete = () => {
    modalDeleteClose();

    delSimAcuity(
      recordId,
      () => {
        // Remove the item from the array
        var myArray = dsAcuity.filter((a) => {
          return a.id !== recordId;
        });
        setDsSimAcuity(myArray);
        refreshAllNow();
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const newRecord = () => {
    var newAilmentRecord = [];
    newAilmentRecord.simulation_id = data.simulation_id;
    newAilmentRecord.id = "00000000-0000-0000-0000-000000000000";

    setPopupRecord([]);
    setPopupRecord(newAilmentRecord);
    setIsNew(true);
    setShowModal(true);
  };

  const updateRecord = async (recordId) => {
    var ail = dsAcuity.filter((a) => {
      return a.id === recordId;
    });
    setPopupRecord(ail[0]);
    setIsNew(false);
    setShowModal(true);
  };

  const dataUpdated = (e) => {
    if (!isNew) {
      //alert(e);
      var myArray = dsAcuity.filter((a) => {
        return a.id !== e.id;
      });
      myArray.push(e);
      setDsSimAcuity(myArray);
    } else {
      refreshAllNow();
    }
    modalClose();
  };

  const modalClose = () => {
    setShowModal(false);
  };

  const modalDeleteClose = () => {
    setShowDeleteModal(false);
  };

  const EventAilmentChanged = (e) => {
    // console.log("EventAilmentChanged ", e.target.value);
    setAilmentFilter(e.target.value);
  };

  const sortStringTitles = (a, b) => {
    return (
      String(a.ailment_title).localeCompare(b.ailment_title) ||
      String(a.title).localeCompare(b.title)
    );
  };

  const viewSim = (simId) => {
    updateRecord(simId);
  };

  return (
    <div>
      <SelectField name="ailment_id" onChange={EventAilmentChanged}>
        <option value="" label="All conditions selected">
          Select an ailment{" "}
        </option>
        {dsAilments &&
          dsAilments.length > 0 &&
          dsAilments
            .sort(sortStringTitles)
            .sort(sortStringTitles)
            .map((ail) => (
              <option key={ail.id} value={ail.id} label={ail.title}></option>
            ))}
      </SelectField>
      <Table borderless="true" hover="true">
        <thead>
          <tr className={style.borderedHeadRow}>
            <th>Patient group</th>
            <th>Acuity</th>
            <th>Acuity score</th>
            <th>New patient %</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {dsAcuity &&
            dsAcuity.length > 0 &&
            dsAcuity
              .filter(
                (a) => a.fk_ailment_id === ailmentFilter || ailmentFilter === ""
              )
              .sort(sortStringTitles)
              .map((sim) => (
                <tr
                  className={style.blackRow}
                  key={sim.id}
                  onClick={() => isLocked === false && viewSim(sim.id)}
                >
                  <td>{sim.ailment_title}</td>
                  <td>{sim.title}</td>
                  <td>{sim.acuity}</td>
                  <td>{sim.percentage_likelihood}%</td>
                  <td>
                    &nbsp; &nbsp;&nbsp;&nbsp;
                    <span
                      role="button"
                      onClick={(e) => {
                        e.stopPropagation();
                        setRecordId(sim.id);
                        setShowDeleteModal(true);
                      }}
                      className={`${isLocked ? "d-none" : ""}`}
                    >
                      <FaTrash size={25} color="#7A2048" />
                    </span>
                    <span
                      role="button"
                      onClick={() => {
                        updateRecord(sim.id);
                      }}
                      className={`${isLocked ? "d-none" : ""}`}
                    >
                      <GoPencil size={25} color="#008181" />
                    </span>
                  </td>
                </tr>
              ))}
        </tbody>
      </Table>
      {showSumError && (
        <div className="alert alert-danger">
          The sum of likelihood for a new patient needs to add up to 100% for
          each ailment. Please add an additional acuity or modify existing ones.
        </div>
      )}
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <button
          className={`btn btn-outline-primary ${isLocked ? "d-none" : ""}`}
          onClick={newRecord}
        >
          Create New
        </button>
      </div>
      <ModalLayout
        show={showModal}
        handleClose={modalClose}
        title={isNew ? "Add new acuity" : "Update acuity"}
      >
        <Acuity
          data={popupRecord}
          isNew={isNew}
          dataUpdated={dataUpdated}
          simulation_id={data.simulation_id}
        />
      </ModalLayout>
      <ModalConfirm
        show={showDeleteModal}
        handleClose={modalDeleteClose}
        title="Please confirm you want to delete this acuity"
        confirmed={rowDelete}
      ></ModalConfirm>
    </div>
  );
};

export default AcuityGridMean;
