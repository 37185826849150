import { useNavigate } from "react-router-dom";
import { removeTrailingSlash } from "../common/urls";

// THIS THE THE CORE API - taken from the .env file
const api_url = removeTrailingSlash(process.env.REACT_APP_CORE_API_URL);

// If a call gets a 401 error then the user is trying to do something they should not.
// If the 401 is from the core api then log out else continue silently.
const Logout = async (apicall) => {
  // Was this a core call?
  if (apicall.includes(api_url)) {
    // Remove the token and log out
    localStorage.setItem("token", "");
  }
  // The token has been remove if necessary so navigating to / resets the program
  // or prompts the user to login.
  const navigate = useNavigate();
  navigate("/", { replace: true });
};

export const get = async (apicall, errorMessage, callback, failFunction) => {
  fetch(apicall, {
    method: "GET",
    mode: "cors",
    headers: {
      Authorization:
        "Bearer " + localStorage.getItem("token").replace(/['"]+/g, ""),
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      let clone = response.clone();
      if (response.ok) {
        return clone.text();
        // try {
        //   return clone.json();
        // } catch (error) {
        //   return "";
        // }

        // try {
        //   returningResponse = JSON.parse(returningResponse);
        //   return returningResponse;
        // } catch {
        //   return returningResponse;
        // }
      } else {
        if (response.status === 401) {
          // User is not permitted so log them out...and send them to log in
          Logout(apicall);
          throw Error("Unauthorized");
        } else throw Error(response.statusText);
      }
    })
    .then((data) => {
      if (typeof callback !== "undefined") {
        // callback(data);
        callback(data ? JSON.parse(data) : {});
      }
    })
    .catch((error) => {
      console.error(errorMessage, error);
      if (typeof failFunction !== "undefined") {
        failFunction(error);
      }
    });
};

export const getText = async (
  apicall,
  errorMessage,
  callback,
  failFunction
) => {
  fetch(apicall, {
    method: "GET",
    mode: "cors",
    headers: {
      Authorization:
        "Bearer " + localStorage.getItem("token").replace(/['"]+/g, ""),
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      if (response.ok) {
        return response.text();
      } else {
        if (response.status === 401) {
          // User is not permitted so log them out...and send them to log in
          Logout(apicall);
          throw Error("Unauthorized");
        } else throw Error(response.statusText);
      }
    })
    .then((data) => {
      if (typeof callback !== "undefined") {
        callback(data);
      }
    })
    .catch((error) => {
      console.error(errorMessage, error);
      if (typeof failFunction !== "undefined") {
        failFunction(error);
      }
    });
};

export const getFileDownload = async (
  apicall,
  errorMessage,
  fileName,
  failFunction
) => {
  fetch(apicall, {
    method: "GET",
    mode: "cors",
    headers: {
      Authorization:
        "Bearer " + localStorage.getItem("token").replace(/['"]+/g, ""),
      "Content-Type": "application/octet-stream",
    },
  })
    .then((response) => response.blob())
    .then((blob) => {
      // Create a temporary URL for the file blob
      const url = window.URL.createObjectURL(blob);
      // Create a temporary <a> element to initiate the download
      const a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      a.click();
      // Cleanup the temporary URL and element
      window.URL.revokeObjectURL(url);
      a.remove();
    })
    .catch((error) => {
      console.error(errorMessage, error);
      if (typeof failFunction !== "undefined") {
        failFunction(error);
      }
    });
};

export const post = async (
  apicall,
  data,
  errorMessage,
  callback,
  failFunction,
  contentType = "application/json"
) => {
  const bodyContent = JSON.stringify(data, null, 2);
  return fetch(apicall, {
    method: "POST",
    mode: "cors",
    headers: {
      Authorization:
        "Bearer " + (localStorage.getItem("token") || "").replace(/['"]+/g, ""),
      "Content-Type": contentType,
    },
    body: bodyContent,
  })
    .then((response) => {
      let clone = response.clone();
      if (response.ok) {
        try {
          return clone.json();
          // return data;
          // return response.json();
        } catch {
          return "";
        }
      } else {
        if (response.status === 401) {
          // User is not permitted so log them out...and send them to log in
          Logout(apicall);
          throw Error("Unauthorized");
        } else throw Error(response.statusText);
      }
    })
    .then((responseJson) => {
      if (typeof callback !== "undefined") {
        callback(responseJson);
      }
    })
    .catch((error) => {
      console.error(errorMessage, error);
      if (typeof failFunction !== "undefined") {
        failFunction(error);
      }
    });
};

export const put = async (
  apicall,
  data,
  errorMessage,
  callback,
  failFunction,
  contentType = "application/json"
) => {
  const bodyContent = JSON.stringify(data, null, 2);
  fetch(apicall, {
    method: "PUT",
    mode: "cors",
    headers: {
      Authorization:
        "Bearer " + (localStorage.getItem("token") || "").replace(/['"]+/g, ""),
      "Content-Type": contentType,
    },
    body: bodyContent,
  })
    .then((response) => {
      if (response.ok) {
        return true;
      } else {
        if (response.status === 401) {
          // User is not permitted so log them out...and send them to log in
          Logout(apicall);
          throw Error("Unauthorized");
        } else throw Error(response.statusText);
      }
    })
    .then((data) => {
      if (typeof callback !== "undefined") {
        callback(data);
      }
    })
    .catch((error) => {
      console.error(errorMessage, error);
      if (typeof failFunction !== "undefined") {
        failFunction(error);
      }
    });
};

export const del = async (apicall, errorMessage, callback, failFunction) => {
  fetch(apicall, {
    method: "DELETE",
    mode: "cors",
    headers: {
      Authorization:
        "Bearer " + localStorage.getItem("token").replace(/['"]+/g, ""),
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      if (response.ok) {
        return true;
      } else {
        if (response.status === 401) {
          // User is not permitted so log them out...and send them to log in
          Logout(apicall);
          throw Error("Unauthorized");
        } else throw Error(response.statusText);
      }
    })
    .then((data) => {
      if (typeof callback !== "undefined") {
        callback(data);
      }
    })
    .catch((error) => {
      console.error(errorMessage, error);
      if (typeof failFunction !== "undefined") {
        failFunction(error);
      }
    });
};

export const add = (
  file,
  params,
  apicall,
  errorMessage,
  callback,
  failFunction
) => {
  let formData = new FormData();
  formData.append("DataFile", file);
  formData.append("Params", JSON.stringify(params));
  fetch(apicall, {
    method: "POST",
    mode: "cors",
    headers: {
      Authorization:
        "Bearer " + (localStorage.getItem("token") || "").replace(/['"]+/g, ""),
      Accept: "multipart/form-data",
    },
    body: formData,
  })
    .then((response) => {
      if (response.ok) {
        if (typeof callback !== "undefined") {
          callback(response.json());
        }
      } else {
        if (response.status === 400) {
          if (typeof callback !== "undefined") {
            callback(response.json());
          } else {
            return response.json();
          }
        } else {
          if (response.status === 401) {
            // User is not permitted so log them out...and send them to log in
            Logout(apicall);
            throw Error("Unauthorized");
          } else throw Error(response.statusText);
        }
      }
    })
    .then((data) => {
      if (data !== undefined) {
        // We only get here is the status is 400 or there has been an error that is returning some info,
        throw Error(data);
      }
    })
    .catch((error) => {
      console.error(errorMessage, error);
      if (typeof failFunction !== "undefined") {
        failFunction(error);
      }
    });
};

export const add_with_timeout = (
  file,
  params,
  apicall,
  errorMessage,
  callback,
  failFunction,
  timeoutMs = 10000 // Default timeout of 10 seconds
) => {
  let formData = new FormData();
  formData.append("DataFile", file);
  formData.append("Params", JSON.stringify(params));

  const timeoutPromise = new Promise((_, reject) =>
    setTimeout(() => reject(failFunction("Request timed out")), timeoutMs)
  );

  const fetchPromise = fetch(apicall, {
    method: "POST",
    mode: "cors",
    headers: {
      Authorization:
        "Bearer " + (localStorage.getItem("token") || "").replace(/['"]+/g, ""),
      Accept: "multipart/form-data",
    },
    body: formData,
  })
    .then((response) => {
      if (response.ok) {
        if (typeof callback !== "undefined") {
          callback(response.json());
        }
      } else {
        if (response.status === 400) {
          if (typeof callback !== "undefined") {
            callback(response.json());
          } else {
            return response.json();
          }
        } else {
          if (response.status === 401) {
            // User is not permitted so log them out...and send them to log in
            Logout(apicall);
            throw Error("Unauthorized");
          } else throw Error(response.statusText);
        }
      }
    })
    .then((data) => {
      if (data !== undefined) {
        // We only get here is the status is 400 or there has been an error that is returning some info,
        throw Error(data);
      }
    })
    .catch((error) => {
      console.error(errorMessage, error);
      if (typeof failFunction !== "undefined") {
        failFunction(error);
      }
    });

  // Race between the fetch and the timeout promise
  return Promise.race([timeoutPromise, fetchPromise]);
};
