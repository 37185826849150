import React, { useState, useEffect, useCallback, useRef } from "react";
import moment from "moment";
import TextFieldUnCtl from "../../TextFieldUnCtl";
import AudioControls from "../../AudioControls";

// import { daysInWeek } from "date-fns";

const CtrlAudioWrapper = ({ simulation_id, viewDay, setDay, summaryData }) => {
  // const [currentDateText, setCurrentDateText] = useState(Date());
  const [controlText, setControlText] = useState(
    moment().format("YYYY-MM-DD")
    // moment(Date()).format("YYYY-MM-DD")
  );
  const [DayStep, setDayStep] = useState(1);
  const dayShift = 1; // This is here to link all days as 0 is now used for the ptl. So, what is 1 needs to become 0 for the db lookups - the model starts on day 0.
  const timerIdRef = useRef(null);

  // Button events for working the day
  const setDayNumber = useCallback(
    (day_number) => {
      // Send msg up the tree
      setDay(day_number, true);
    },
    [setDay]
  );

  const firstDay = useCallback(() => {
    setDayNumber(0);
  }, [setDayNumber]);
  const nextDay = useCallback(() => {
    var newDay = parseInt(DayStep, 10) + viewDay;
    if (newDay <= summaryData.model_run_days + dayShift) {
      setDayNumber(newDay);
    } else {
      setDayNumber(summaryData.model_run_days + dayShift);
    }
  }, [viewDay, summaryData.model_run_days, setDayNumber, DayStep]);
  const previousDay = useCallback(() => {
    if (viewDay - DayStep > 0) {
      setDayNumber(viewDay - DayStep);
    } else {
      setDayNumber(0);
    }
  }, [viewDay, setDayNumber, DayStep]);
  const lastDay = useCallback(() => {
    setDayNumber(summaryData.model_run_days + dayShift);
  }, [summaryData.model_run_days, setDayNumber]);

  // Set the current date text based upon a change in day Number
  useEffect(() => {
    if (summaryData.model_start_dtg !== undefined) {
      // setCurrentDateText(
      //   moment(summaryData.model_start_dtg)
      //     // .add(viewDay - 1, "days")
      //     .add(viewDay - dayShift, "days")
      //     .format("YYYY-MM-DD")
      // );
      setControlText(
        moment(summaryData.model_start_dtg)
          // .add(viewDay - 1, "days")
          .add(viewDay - dayShift, "days")
          .format("YYYY-MM-DD")
      );
    }
  }, [viewDay, summaryData.model_start_dtg, DayStep]);

  // When the control changes the date this will fire
  const onChangeControlDate = (e) => {
    // Set the control text
    setControlText(e.target.value);

    // Reset any timed updates
    if (timerIdRef.current) {
      clearTimeout(timerIdRef.current);
    }

    // Set a timer in place to administer the control change
    timerIdRef.current = setTimeout(function () {
      // This will be run two seconds after the change has been made. This is so the user can type in a full date.
      const newDate = moment(Date.parse(e.target.value));
      var local_days = Math.round(
        (newDate - new Date(summaryData.model_start_dtg)) /
          (1000 * 60 * 60 * 24)
      );
      local_days = local_days + dayShift;
      setDayNumber(local_days);
    }, 1500);
  };

  const handleStepChange = (event) => {
    setDayStep(event.target.value);
  };

  return (
    <table>
      <thead></thead>
      <tbody>
        <tr>
          <td>
            {" "}
            {/* <TextFieldUnCtl */}
            <TextFieldUnCtl
              name="model_start_dtg"
              type="date"
              autoComplete="off"
              // value={moment(currentDateText).format("YYYY-MM-DD")}
              value={controlText}
              min={moment(
                Date.parse(summaryData.model_start_dtg) - dayShift * 86400000
              ).format("YYYY-MM-DD")}
              max={moment(
                Date.parse(summaryData.model_start_dtg) +
                  (summaryData.model_run_days + dayShift) * 86400000
              ).format("YYYY-MM-DD")}
              onChange={onChangeControlDate}
              variant="standard"
            />
          </td>

          <td>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <AudioControls
              firstDay={firstDay}
              previousDay={previousDay}
              nextDay={nextDay}
              lastDay={lastDay}
              // variable_for_callback={viewDay}
            />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <label>
              <b>Step:</b>&nbsp;&nbsp;
              <select
                value={DayStep}
                onChange={handleStepChange}
                style={{ fontWeight: "bold" }}
              >
                <option value="1">1</option>
                <option value="7">7</option>
                <option value="31">31</option>
              </select>
            </label>
          </td>
          <td>
            <b>&nbsp;&nbsp;&nbsp;&nbsp;Day {viewDay}</b>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default CtrlAudioWrapper;
