import React, { useContext, useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { FaTrash } from "react-icons/fa";
import { GoPencil } from "react-icons/go";

import { delSimAilments } from "../../../api/ApiPtlCalls";
import {
  Model01SimAilmentsContext,
  Model01SimSettingsContext,
  Model01RefreshAllContext,
  Model01IsLocked,
} from "../Model01";
import SessionPerDayDisplay from "../../SessionPerDayDisplay";
import ModalConfirm from "../../modal/ModalConfirm";
import Ailment from "./Ailment";
import style from "../../../scss/style.module.scss";
import ModalLayout from "../../modal/Modal";
import indexStyle from "../../../indexOrg.module.scss";
import { validateAilment } from "../../../common/validation";

const AilmentGrid = ({ ctrlValid, ctrlNumber, displayType }) => {
  const [dsAilments, setDsSimAilments] = useContext(Model01SimAilmentsContext); // eslint-disable-line no-unused-vars
  const [data, writeSimSettings] = useContext(Model01SimSettingsContext); // eslint-disable-line no-unused-vars
  const refreshAllNow = useContext(Model01RefreshAllContext);
  const [isLocked] = useContext(Model01IsLocked);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [popupAilment, setPopupAilment] = useState([]);
  const [ailmentId, setAilmentId] = useState("");
  const [showSumError, setShowSumError] = useState(false);

  function ValidateFormData() {
    if (displayType === "demand") {
      if (validateAilment(dsAilments)) {
        // Passed the test
        if (ctrlValid !== undefined) {
          ctrlValid(ctrlNumber, true);
        }
        // Do not show the error
        setShowSumError(false);
      } else {
        // Failed the test
        if (ctrlValid !== undefined) {
          ctrlValid(ctrlNumber, false);
        }
        // only show the error if there is at least one error
        setShowSumError(dsAilments.length > 0);
      }
    } else {
      if (ctrlValid !== undefined) {
        ctrlValid(ctrlNumber, true);
      }
    }
  }

  // Automate the form validation
  useEffect(() => {
    ValidateFormData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dsAilments]);

  const rowDelete = () => {
    modalDeleteClose();

    delSimAilments(
      ailmentId,
      () => {
        // Remove the item from the array
        var myArray = dsAilments.filter((a) => {
          return a.id !== ailmentId;
        });
        setDsSimAilments(myArray);
        refreshAllNow();
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const newRecord = () => {
    var newAilmentRecord = [];
    newAilmentRecord.simulation_id = data.simulation_id;
    newAilmentRecord.id = "00000000-0000-0000-0000-000000000000";

    setPopupAilment([]);
    setPopupAilment(newAilmentRecord);
    setIsNew(true);
    setShowModal(true);
  };

  const updateRecord = async (recordId) => {
    var ail = dsAilments.filter((a) => {
      return a.id === recordId;
    });
    setPopupAilment(ail[0]);
    setIsNew(false);
    setShowModal(true);
  };

  const dataUpdated = (e) => {
    //alert(e);
    if (!isNew) {
      // Ensure doc display correctly
      setPopupAilment(e);
      var myArray = dsAilments.filter((a) => {
        return a.id !== e.id;
      });
      myArray.push(e);
      setDsSimAilments(myArray);
    } else {
      // Get the array rebuild - the record has been added to the db
      refreshAllNow();
    }
    modalClose();
  };

  const modalClose = () => {
    setShowModal(false);
  };

  const modalDeleteClose = () => {
    setShowDeleteModal(false);
  };

  const sortStringTitles = (a, b) => {
    return String(a.title).localeCompare(b.title);
  };

  const viewSim = (simId) => {
    updateRecord(simId);
  };

  return (
    <>
      <Table borderless="true" hover="true">
        <thead>
          <tr className={style.borderedHeadRow}>
            <th>Patient group</th>
            {displayType && displayType === "ptl" && (
              <>
                <th>
                  Av. procedure time
                  <span className={indexStyle.smallRegular}> (mins)</span>
                </th>
                <th>
                  Std. dev.
                  <span className={indexStyle.smallRegular}> (mins)</span>
                </th>
                <th>
                  Min. procedure time
                  <span className={indexStyle.smallRegular}> (mins)</span>
                </th>
                <th>
                  Max. procedure time
                  <span className={indexStyle.smallRegular}> (mins)</span>
                </th>
              </>
            )}
            {displayType && displayType === "activity" && (
              <>
                <th>Weekly sessions</th>
                <th>Hrs/session</th>
                <th>Max. patients/session</th>
                <th>Max. concurrent sessions</th>
                <th>Cancellation prob.</th>
                <th>Priority</th>
              </>
            )}
            {displayType && displayType === "demand" && (
              <>
                <th>Proportion of new patients</th>
              </>
            )}
            <th></th>
          </tr>
        </thead>
        <tbody>
          {dsAilments &&
            dsAilments.length > 0 &&
            dsAilments
              .filter(
                (data) => displayType === "ptl" || data.emergency === false
              )
              .sort(sortStringTitles)
              .map((sim) => (
                <tr
                  className={style.blackRow}
                  key={sim.id}
                  onClick={() => isLocked === false && viewSim(sim.id)}
                >
                  <td>{sim.title}</td>
                  {displayType && displayType === "ptl" && (
                    <>
                      <td>{sim.ptt_mean_mins}</td>
                      <td>{sim.ptt_sd_mins}</td>
                      <td>{sim.ptt_minimum_mins}</td>
                      <td>{sim.ptt_maximum_mins}</td>
                    </>
                  )}
                  {displayType && displayType === "activity" && (
                    <>
                      <td>
                        <SessionPerDayDisplay
                          sessions_monday={sim.sessions_monday}
                          sessions_tuesday={sim.sessions_tuesday}
                          sessions_wednesday={sim.sessions_wednesday}
                          sessions_thursday={sim.sessions_thursday}
                          sessions_friday={sim.sessions_friday}
                          sessions_saturday={sim.sessions_saturday}
                          sessions_sunday={sim.sessions_sunday}
                        />
                      </td>
                      <td>{sim.session_hours}</td>
                      <td>{sim.patients_per_session}</td>
                      <td>{sim.no_of_concurrent_sessions}</td>
                      <td>{sim.p_cancelled}%</td>
                      <td>{sim.ailment_priority}</td>
                    </>
                  )}
                  {displayType && displayType === "demand" && (
                    <>
                      <td>{sim.percentage_likelihood}%</td>
                    </>
                  )}
                  <td>
                    {displayType &&
                      displayType === "ptl" &&
                      sim.emergency === false && (
                        <span
                          role="button"
                          onClick={(e) => {
                            e.stopPropagation();
                            setAilmentId(sim.id);
                            setShowDeleteModal(true);

                            //rowDelete(sim.id);
                          }}
                          className={`${isLocked ? "d-none" : ""}`}
                        >
                          <FaTrash size={25} color="#7a2048" />
                        </span>
                      )}
                    <span
                      role="button"
                      onClick={() => {
                        updateRecord(sim.id);
                      }}
                      className={`${isLocked ? "d-none" : ""}`}
                    >
                      <GoPencil size={25} color="#7a2048" />
                    </span>
                  </td>
                </tr>
              ))}
        </tbody>
      </Table>
      {showSumError && (
        <div className="alert alert-danger">
          The sum of likelihood for a new patient needs to add up to 100%.
          Please add additional ailments or modify existing ones.
        </div>
      )}
      {displayType && displayType === "ptl" && (
        <>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <button
              className={`btn btn-outline-primary ${isLocked ? "d-none" : ""}`}
              onClick={newRecord}
            >
              Create New
            </button>
          </div>
        </>
      )}
      <ModalLayout
        title={isNew ? "Add new patient group" : "Update patient group"}
        show={showModal}
        handleClose={modalClose}
      >
        <Ailment
          data={popupAilment}
          isNew={isNew}
          dataUpdated={dataUpdated}
          simulation_id={data.simulation_id}
          displayType={displayType}
        />
      </ModalLayout>
      <ModalConfirm
        show={showDeleteModal}
        handleClose={modalDeleteClose}
        title="Please confirm you want to delete this ailment"
        confirmed={rowDelete}
      ></ModalConfirm>
    </>
  );
};

export default AilmentGrid;
