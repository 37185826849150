import React, { useState, useEffect } from "react";
import ReportActEcpActTable from "./ReportActEcpActTable";
import ReportActEcpLeaveTable from "./ReportActEcpLeaveTable";
import ReportLeaveAdjustedTable from "./ReportLeaveAdjustedTable";
import { roundNumber } from "../../../../common/math_functions";
import styles from "../../../../scss/style.module.scss";
import table from "../../../../table.module.scss";
import ChartSvg from "../../../ChartSvg";
import ReportLeaveChart from "./ReportLeaveChart";

const ReportCharts = ({
  sumData,
  activeTab,
  leaveData,
  selectedName,
  vettingSum,
  vettingDuration,
  rptVetting,
  hiddenConsultants,
  doctorCount,
  calcData,
}) => {
  const [percentValue, setPercentValue] = useState(0);

  const [colour, setColour] = useState("#DC143C");
  const [valueAdjusted, setValueAdjusted] = useState(percentValue);
  const [colourAdjusted, setColourAdjusted] = useState("#DC143C");

  const [showCharts, setShowCharts] = useState(
    leaveData && leaveData.length > 0
  );

  useEffect(() => {
    const getPercentage = (sunData) => {
      return roundNumber(
        ((sumData[0].actHrs +
          sumData[1].actHrs +
          sumData[2].actHrs +
          sumData[4].actHrs +
          (rptVetting === "1" ? 0 : (vettingSum * vettingDuration) / 60)) /
          (sumData[0].expHrs +
            sumData[1].expHrs +
            sumData[2].expHrs +
            sumData[4].expHrs)) *
          100,
        2
      );
    };
    setPercentValue(getPercentage(sumData));
  }, [sumData, vettingSum, vettingDuration, rptVetting]);

  useEffect(() => {
    if (percentValue < 70) {
      setColour("#DC143C"); // REd
    } else if (percentValue < 90) {
      setColour("#ffc000"); // Amber
    } else {
      setColour("#32CD32"); // Green
    }
  }, [percentValue]);

  useEffect(() => {
    if (valueAdjusted === undefined || valueAdjusted === null) return;
    if (valueAdjusted < 70) {
      setColourAdjusted("#DC143C"); // REd
    } else if (valueAdjusted < 90) {
      setColourAdjusted("#ffc000"); // Amber
    } else {
      setColourAdjusted("#32CD32"); // Green
    }
  }, [valueAdjusted]);

  useEffect(() => {
    setShowCharts(leaveData && leaveData.length > 0);
  }, [leaveData]);

  return (
    <React.Fragment>
      <div className={""}>
        <table>
          <tbody>
            <tr>
              <td
                className={`${styles.tables_three} ${styles.right_margin_columns}`}
              >
                <div>
                  <h2 className={styles.rptHeaders}>
                    Actual Vs Expected Activity
                  </h2>
                  <h5>(Excl. WLI hours*)</h5>

                  <div className={table.rpt_dashboard_left}>
                    <div>
                      <ChartSvg percentage={percentValue} color={colour} />
                    </div>
                  </div>

                  <div className={table.rpt_dashboard_left}>
                    <ReportActEcpActTable
                      sumData={sumData}
                      vettingSum={vettingSum}
                      vettingDuration={vettingDuration}
                      rptVetting={rptVetting}
                    />
                  </div>
                </div>
              </td>
              <td
                className={`${styles.tables_three}  ${
                  styles.right_margin_columns
                } ${showCharts ? "" : "d-none"}`}
              >
                {/* <td className={styles.tables_three}> */}{" "}
                <div>
                  <h2 className={styles.rptHeaders}>
                    Actual Vs Expected Leave
                  </h2>

                  <div className={table.rpt_dashboard_left}>
                    <div>
                      <ReportLeaveChart calcData={calcData} />
                    </div>
                  </div>

                  <div className={table.rpt_dashboard_left}>
                    <ReportActEcpLeaveTable sumData={calcData} />
                  </div>
                </div>
              </td>
              <td
                className={`${styles.tables_three} ${
                  showCharts ? "" : "d-none"
                }`}
              >
                {" "}
                <div>
                  <h2 className={styles.rptHeaders}>
                    Actual Vs Expected Leave
                  </h2>
                  <h5>(Adj. for Annual Leave)</h5>
                  <div className={table.rpt_dashboard_left}>
                    <div>
                      <ChartSvg
                        percentage={valueAdjusted}
                        color={colourAdjusted}
                      />
                    </div>
                  </div>
                  <div className={table.rpt_dashboard_left}>
                    <ReportLeaveAdjustedTable
                      sumData={sumData}
                      calcData={calcData}
                      setValueAdjusted={setValueAdjusted}
                      vettingSum={vettingSum}
                      vettingDuration={vettingDuration}
                      rptVetting={rptVetting}
                      doctorCount={doctorCount}
                    />
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
};

export default ReportCharts;
