import React, { useState, useEffect, useContext } from "react";
import TableSingleConsult from "./TableSingleConsult";
import {
  roundNumber,
  hideIfZero,
  fixNumberString,
} from "../../../../common/math_functions";
import styles from "../../../../scss/style.module.scss";
import table from "../../../../table.module.scss";
import Table from "react-bootstrap/Table";
import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { ConsultSettingsContext } from "../../Consult";
import moment from "moment";

// Possible graph:
// https://www.highcharts.com/demo/highcharts/gauge-solid

const ReportSingleConsult = ({
  summaryData,
  sumData,
  updateSumDataItem,
  selectedName,
  vettingSum,
  vettingDuration,
  rptVetting,
  calcData,
  doctorCount,
}) => {
  const [data] = useContext(ConsultSettingsContext);

  const [showOther, setShowOther] = useState(true);
  const [showFlexible, setShowFlexible] = useState(true);
  const [showWLI, setShowWLI] = useState(true);

  // const [showActivityDates, setShowActivityDates] = useState(false);
  const [expectedTotalHrs, setExpectedTotalHrs] = useState(0);

  // const [actRptTotal, setActRptTotal] = useState(0);
  const [expGrossProRata, setExpGrossProRata] = useState(1);
  useEffect(() => {
    let proRataWorkingDays = ((260 * doctorCount) / 365) * calcData.periodDays;
    let holidayAdjustment =
      (proRataWorkingDays - calcData.actLeave) / proRataWorkingDays;
    setExpGrossProRata(holidayAdjustment);
    // (actualHours / (expectedHoursGrossPeriod * holidayAdjustment)) * 100,
  }, [calcData, doctorCount]);

  useEffect(() => {
    // Initialize totals
    // let rpt = 0;

    // Iterate through the filtered data
    if (summaryData && summaryData.length > 0) {
      // summaryData.forEach((sim) => {
      //   rpt += sim.total_reports;
      // });

      // Update the state variables with the calculated totals
      // setActRptTotal(rpt);
      // Count the total reports
      setShowOther(summaryData.some((item) => item.form_grouping === 1));
      setShowFlexible(summaryData.some((item) => item.form_grouping === 4));
      setShowWLI(summaryData.some((item) => item.form_grouping === 3));
    }
  }, [summaryData, selectedName]);

  // useEffect(() => {
  //   setShowActivityDates(data.activity_start && data.activity_end);
  // }, [data]);

  useEffect(() => {
    setExpectedTotalHrs(
      sumData[0].expHrsGrossLeaveAdj +
        sumData[1].expHrsGrossLeaveAdj +
        sumData[2].expHrsGrossLeaveAdj +
        sumData[4].expHrsGrossLeaveAdj
    );
  }, [sumData]);

  // Work out the colours
  function getColorForValue(value) {
    if (value === 0 || isNaN(value)) return "#FFFFFF";
    if (value < 70) {
      return "#DC143C"; // Red
    } else if (value < 90) {
      return "#ffc000"; // Amber
    } else {
      return "#32CD32"; // Green
    }
  }
  const cellStyle = (value) => {
    return {
      color: "#ffffff",
      background: getColorForValue(value),
      padding: "5px",
      borderRadius: 10,
      textAlign: "center",
      border: "3px solid white",
      alignItems: "center",
    };
  };

  const tileName = () => {
    if (selectedName === "*") return "All staff";
    if (selectedName === "%") return "All consultants";
    return selectedName;
  };

  return (
    <>
      <div>
        {" "}
        <table>
          <tbody>
            <tr>
              <td
                className={`${styles.tables_three} ${styles.right_margin_columns}`}
              >
                <h3 className={styles.rptHeadersBold}>{tileName()}</h3>
              </td>
              <td
                className={`${styles.tables_three} ${styles.right_margin_columns}`}
              >
                <h3 className={styles.rptHeaders}>
                  {moment(data.start_date).format("DD/MM/YYYY")} -{"  "}{" "}
                  {moment(data.end_date).format("DD/MM/YYYY")}
                </h3>
              </td>
              <td
                className={`${styles.tables_three} ${styles.right_margin_columns}`}
              >
                <h3 className={styles.rptHeaders}>
                  Days{"  "} {calcData.periodDays}
                </h3>
              </td>
              <td
                className={`${styles.tables_three} ${styles.right_margin_columns}`}
              >
                <h3 className={styles.rptHeaders}>
                  Actual Leave Days{"  "} {calcData.actLeave}
                </h3>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div>
        <br />
      </div>
      <div>
        {selectedName && selectedName.length > 0 ? (
          <React.Fragment>
            <h2 className={styles.rptHeadersBold}>Scheduled Activity</h2>
            <TableSingleConsult
              form_group={0}
              selectedName={selectedName}
              summaryData={summaryData}
              sumData={sumData}
              updateSumDataItem={updateSumDataItem}
              expGrossProRata={expGrossProRata}
              periodDays={calcData.periodDays}
              useExpectedTotal={0}
              expectedTotal={0}
            />
            <br />
            {showFlexible ? (
              <React.Fragment>
                <h2 className={styles.rptHeadersBold}>Flexible Activity</h2>
                <TableSingleConsult
                  form_group={4}
                  selectedName={selectedName}
                  summaryData={summaryData}
                  sumData={sumData}
                  updateSumDataItem={updateSumDataItem}
                  expGrossProRata={expGrossProRata}
                  periodDays={calcData.periodDays}
                  useExpectedTotal={0}
                  expectedTotal={0}
                />
                <br />
              </React.Fragment>
            ) : (
              <React.Fragment></React.Fragment>
            )}
            {showOther ? (
              <React.Fragment>
                <h2 className={styles.rptHeadersBold}>
                  Other Job Plan Activity
                </h2>
                <TableSingleConsult
                  form_group={1}
                  selectedName={selectedName}
                  summaryData={summaryData}
                  sumData={sumData}
                  updateSumDataItem={updateSumDataItem}
                  expGrossProRata={expGrossProRata}
                  periodDays={calcData.periodDays}
                  useExpectedTotal={1}
                  expectedTotal={expectedTotalHrs}
                />
                <br />
              </React.Fragment>
            ) : (
              <React.Fragment></React.Fragment>
            )}
            <h2 className={styles.rptHeadersBold}>Additional Activity</h2>
            <TableSingleConsult
              form_group={2}
              selectedName={selectedName}
              summaryData={summaryData}
              sumData={sumData}
              updateSumDataItem={updateSumDataItem}
              expGrossProRata={expGrossProRata}
              periodDays={calcData.periodDays}
              useExpectedTotal={1}
              expectedTotal={expectedTotalHrs}
            />
            <br />
            <div className={`${rptVetting === "1" ? "d-none" : ""}`}>
              <h2 className={styles.rptHeadersBold}>Vetting</h2>
              <TableContainer>
                <Table className={table.dashboard} hover="true">
                  <TableHead>
                    <TableRow className={styles.borderedHeadRow}>
                      <TableCell className={styles.colChar255}></TableCell>

                      <TableCell className={styles.colChar32}></TableCell>

                      <TableCell className={styles.colTime}></TableCell>
                      <TableCell className={styles.colTime}></TableCell>

                      <TableCell className={styles.colNumber}>PAs</TableCell>
                      <TableCell className={styles.colNumber}>
                        Exp. Hrs
                      </TableCell>

                      <TableCell className={styles.colSeparator}></TableCell>
                      <TableCell className={styles.colNumber}>Rpt.</TableCell>
                      <TableCell className={styles.colNumber}>
                        Act. Hrs
                      </TableCell>
                      <TableCell className={styles.colNumberBig}>
                        JP vs. Act.
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow key="100">
                      <TableCell>
                        <b>Total</b>
                      </TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>

                      <TableCell>
                        <b>{hideIfZero(roundNumber(vettingSum, 2))}</b>
                      </TableCell>
                      <TableCell>
                        <b>
                          {hideIfZero(
                            roundNumber((vettingSum * vettingDuration) / 60, 2)
                          )}
                        </b>
                      </TableCell>
                      <TableCell style={cellStyle(100)}>
                        {hideIfZero(
                          fixNumberString(
                            ((vettingSum * vettingDuration) /
                              60 /
                              expectedTotalHrs) *
                              100,
                            1
                          )
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <br />
            </div>
            <h2 className={styles.rptHeadersBold}> </h2>
            <TableContainer>
              <Table className={table.dashboard} hover="true">
                <TableHead>
                  <TableRow className={styles.borderedHeadRow}>
                    <TableCell className={styles.colChar255}></TableCell>
                    <TableCell className={styles.colChar32}></TableCell>

                    <TableCell className={styles.colTime}></TableCell>
                    <TableCell className={styles.colTime}></TableCell>

                    <TableCell className={styles.colNumber}>PAs</TableCell>
                    <TableCell className={styles.colNumber}>Exp. Hrs</TableCell>

                    <TableCell className={styles.colSeparator}></TableCell>
                    <TableCell className={styles.colNumber}>Rpt.</TableCell>
                    <TableCell className={styles.colNumber}>Act. Hrs</TableCell>
                    <TableCell className={styles.colNumberBig}>
                      JP vs. Act.
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key="100">
                    <TableCell>
                      <h2 className={styles.rptHeadersBold}>
                        Actual Vs. Job Plan
                      </h2>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                      <b>
                        {hideIfZero(
                          roundNumber(
                            sumData[0].expPa +
                              sumData[1].expPa +
                              sumData[2].expPa +
                              sumData[4].expPa,
                            2
                          )
                        )}
                      </b>
                    </TableCell>
                    <TableCell>
                      <b>
                        {hideIfZero(
                          roundNumber(
                            sumData[0].expHrsGrossLeaveAdj +
                              sumData[1].expHrsGrossLeaveAdj +
                              sumData[2].expHrsGrossLeaveAdj +
                              sumData[4].expHrsGrossLeaveAdj,
                            2
                          )
                        )}
                      </b>
                    </TableCell>

                    <TableCell></TableCell>
                    <TableCell>
                      <b>
                        {hideIfZero(
                          roundNumber(
                            sumData[0].actRpt +
                              sumData[1].actRpt +
                              sumData[2].actRpt +
                              sumData[4].actRpt +
                              (rptVetting === "1" ? 0 : vettingSum),
                            2
                          )
                        )}
                      </b>
                    </TableCell>
                    <TableCell>
                      <b>
                        {hideIfZero(
                          roundNumber(
                            sumData[0].actHrs +
                              sumData[1].actHrs +
                              sumData[2].actHrs +
                              sumData[4].actHrs +
                              (rptVetting === "1"
                                ? 0
                                : vettingSum * vettingDuration) /
                                60,
                            2
                          )
                        )}
                      </b>
                    </TableCell>
                    <TableCell
                      style={cellStyle(
                        ((sumData[0].actHrs +
                          sumData[1].actHrs +
                          sumData[2].actHrs +
                          sumData[4].actHrs +
                          (rptVetting === "1"
                            ? 0
                            : vettingSum * vettingDuration) /
                            60) /
                          (sumData[0].expHrsGrossLeaveAdj +
                            sumData[1].expHrsGrossLeaveAdj +
                            sumData[2].expHrsGrossLeaveAdj +
                            sumData[4].expHrsGrossLeaveAdj)) *
                          100
                      )}
                    >
                      {hideIfZero(
                        fixNumberString(
                          ((sumData[0].actHrs +
                            sumData[1].actHrs +
                            sumData[2].actHrs +
                            sumData[4].actHrs +
                            (rptVetting === "1"
                              ? 0
                              : vettingSum * vettingDuration) /
                              60) /
                            (sumData[0].expHrsGrossLeaveAdj +
                              sumData[1].expHrsGrossLeaveAdj +
                              sumData[2].expHrsGrossLeaveAdj +
                              sumData[4].expHrsGrossLeaveAdj)) *
                            100,
                          1
                        )
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <br />
            {showWLI ? (
              <React.Fragment>
                <h2 className={styles.rptHeadersBold}>WLI Reports</h2>
                <TableSingleConsult
                  form_group={3}
                  selectedName={selectedName}
                  summaryData={summaryData}
                  sumData={sumData}
                  updateSumDataItem={updateSumDataItem}
                  expGrossProRata={expGrossProRata}
                  periodDays={calcData.periodDays}
                  useExpectedTotal={0}
                  expectedTotal={0}
                />
                <br />
              </React.Fragment>
            ) : (
              <React.Fragment></React.Fragment>
            )}
          </React.Fragment>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default ReportSingleConsult;
