import { urlBase64Decode, urlBase64Encode } from "./base64";
import { postLogin, writePasswordForUser } from "../api/ApiCoreCalls";

export function getDataFromToken(token) {
  var data = {};
  if (typeof token !== "undefined") {
    var encoded = token.split(".")[1];
    data = JSON.parse(urlBase64Decode(encoded));
  }
  return data;
}

export function Logout_Admin() {
  RemoveFromStorage();
}

function GetTokenData(token) {
  try {
    var data = getDataFromToken(token);
    return data;
  } catch (e) {
    return "";
  }
}

function RemoveFromStorage() {
  localStorage.removeItem("token");
  localStorage.removeItem("companyId");
  localStorage.removeItem("companyName");
  localStorage.removeItem("isSysAdmin");
  localStorage.removeItem("userId");
  localStorage.removeItem("userName");
  localStorage.removeItem("expiry");
  localStorage.removeItem("ssSupport");
  localStorage.removeItem("ssSales");
  localStorage.removeItem("standardUser");
  localStorage.removeItem("modules");
  localStorage.removeItem("userLoggedIn");
  localStorage.removeItem("global_exam_types");
}

export function Login_Admin(username, password, rememberMe, success, fail) {
  // Login request requires clean up.
  RemoveFromStorage();

  var par = username.length + "|" + username + password;
  par = urlBase64Encode(par);
  var data = {
    key: par,
    persist: rememberMe,
  };

  postLogin(
    data,
    (response) => {
      localStorage.setItem("token", response.token);

      var userData = GetTokenData(response.token);

      localStorage.setItem("companyId", userData.CompanyId.replace('"', ""));
      localStorage.setItem("companyName", userData.CompanyName);
      localStorage.setItem("isSysAdmin", userData.IsSysAdmin);
      localStorage.setItem("userId", userData.UserId);
      localStorage.setItem("userName", userData.UserName);
      localStorage.setItem("expiry", userData.exp); // Comes from DateTimeOffset.UtcNow.AddHours(3).ToUnixTimeSeconds()

      localStorage.setItem("ssSupport", userData.ssSupport);
      localStorage.setItem("ssSales", userData.ssSales);
      localStorage.setItem("standardUser", userData.standardUser);
      localStorage.setItem("modules", userData.modules);

      localStorage.setItem("userLoggedIn", true);

      success(true);
    },
    (error) => {
      // success(false);
      // Failure
      fail(error);
    }
  );
}

export function NewPassword_Admin(password, token, success, fail) {
  var data = {
    token: token,
    password: password,
  };
  console.log("Posting data:", data);
  writePasswordForUser(data, success, fail);
  console.log("Made it with " + password + " and " + token);
}
