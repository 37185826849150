import React, { useState, useEffect } from "react";
import CheckBoxSimple from "../../../CheckBox";
// import styles from "../../../../scss/style.module.scss";
import styles from "../../../../index.module.scss";
import { getActivityDuration } from "./FnShared";
import TextFieldBasic from "../../../TextFieldBasic";

const JobPlanSelectableActivityList = ({
  dsData,
  checkedStateJs,
  setCheckedStateJs,
  readonly,
  dsActivityDuration,
  setDsActivityDuration,
  hideUnselected = false,
  onChange,
  setNumbersStateJs,
}) => {
  /* eslint-disable  no-unused-vars */

  const sortStringTitles = (a, b) => {
    return String(a.title).localeCompare(b.title);
  };
  const [first, setFirst] = useState(true);
  /* eslint-enable  no-unused-vars */

  const handleOnChange = (element) => {
    if (!readonly) {
      // Locate the value within the local array and updated it
      checkedStateJs[element] = !checkedStateJs[element];
      setCheckedStateJs(checkedStateJs);
      setFirst(!first);
      if (onChange !== undefined) {
        onChange(element);
      }
    }
  };

  const handleActivityDurationOnChange = (e, item, standard) => {
    if (!readonly) {
      let validated_value = e.target.value.replace(/[^0-9.]/g, "");

      const updateData = [...dsActivityDuration];
      for (let a = 0; a < updateData.length; a++) {
        const element = updateData[a];
        if (element.activity.trim() === item.trim()) {
          if (standard) {
            updateData[a] = {
              ...updateData[a],
              pa_standard_hrs: validated_value,
              changed: true,
            };
          } else {
            updateData[a] = {
              ...updateData[a],
              pa_premium_hrs: validated_value,
              changed: true,
            };
          }
        }
      }
      setDsActivityDuration(updateData);
    }
  };

  useEffect(() => {
    // Re-render control is the state changes
  }, [checkedStateJs, dsActivityDuration]);

  return (
    <>
      <table>
        <thead>
          <tr>
            <td>
              <h5>Activity</h5>
            </td>
            <td>
              <h5>Normal</h5>
            </td>
            <td>
              <h5>Premium</h5>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {"  "}
              <br />
            </td>
          </tr>
          {dsData &&
            dsData.length > 0 &&
            checkedStateJs &&
            dsData
              .filter((item) => item !== null)
              .sort(sortStringTitles)
              .map((item, key) => (
                <tr key={key}>
                  <td>
                    <div
                      key={"div_" + item.id + key}
                      className={
                        !hideUnselected
                          ? ""
                          : checkedStateJs[item]
                          ? ""
                          : "d-none"
                      }
                    >
                      <span key={item.id + key}>
                        <CheckBoxSimple
                          checked={checkedStateJs[item] || false}
                          onChange={() => handleOnChange(item)}
                        />{" "}
                        <label
                          htmlFor={`custom-checkbox-${item}`}
                          className="input-checkbox-text"
                        >
                          {item}
                        </label>
                      </span>
                    </div>
                  </td>
                  {!readonly ? (
                    <td>
                      <TextFieldBasic
                        value={getActivityDuration(
                          dsActivityDuration,
                          item,
                          true
                        )}
                        onChange={(e) => {
                          handleActivityDurationOnChange(e, item, true);
                        }}
                        type="number"
                        inputProps={{
                          className: `form-control form-control-small shadow-none bold ${styles.inputBox} ${styles.small_text_box}`,
                        }}
                        // className={`form-control form-control-small shadow-none bold ${styles.inputBox}`}
                      />
                    </td>
                  ) : (
                    <td>
                      <span>
                        {getActivityDuration(dsActivityDuration, item, true)}
                      </span>
                    </td>
                  )}
                  {!readonly ? (
                    <td>
                      <TextFieldBasic
                        value={getActivityDuration(
                          dsActivityDuration,
                          item,
                          false
                        )}
                        onChange={(e) => {
                          handleActivityDurationOnChange(e, item, false);
                        }}
                        type="number"
                        inputProps={{
                          className: `form-control form-control-small shadow-none bold ${styles.inputBox} ${styles.small_text_box}`,
                        }}
                      />
                    </td>
                  ) : (
                    <td>
                      <span>
                        {getActivityDuration(dsActivityDuration, item, false)}
                      </span>
                    </td>
                  )}
                </tr>
              ))}
        </tbody>
      </table>
    </>
  );
};

export default JobPlanSelectableActivityList;
