import React, { useContext } from "react";
import PropTypes from "prop-types";
import Stack from "react-bootstrap/Stack";
import HeaderTopLink from "./HeaderTopLinks";
import HeaderUser from "./HeaderUser";
import HeaderBarLink from "./HeaderBarLink";
import moment from "moment";

import SSButton from "./SSButton";
import HeaderButtons from "./HeaderButtons";

import SysAdminDropdown from "./SysAdminDropdown";
import { HeaderTitle } from "../App";
import styles from "../indexOrg.module.scss";

const Header = ({ loggedIn, logout, sysAdmin, userName }) => {
  /* eslint-disable */
  const [
    headerTitle,
    setHeaderTitle,
    lastSaved,
    setLastSaved,
    backUrl,
    setBackUrl,
  ] = useContext(HeaderTitle);
  /* eslint-enable */
  const onClick = (e) => {
    console.log("Button clicked");
    if (loggedIn) {
      logout();
    }
  };

  if (loggedIn) {
    return (
      <div className={styles.headerContainer}>
        <Stack direction="horizontal" gap={1}>
          <HeaderButtons />
          <div>
            <HeaderTopLink text="" link="/b" selected={true} />
          </div>
          <div className="ms-auto">
            <HeaderUser text={userName} />
          </div>
          <div>{sysAdmin && sysAdmin === "true" && <SysAdminDropdown />}</div>
          <div>
            <SSButton text="Logout" onClick={onClick} />
          </div>
        </Stack>
        <div className={styles.headerBar}>
          <>
            <Stack direction="horizontal" gap={1}>
              <div>
                <HeaderBarLink text={headerTitle} link={backUrl} />
              </div>
              <div className="ms-auto small-regular">
                {lastSaved &&
                  "Last saved: " +
                    moment(lastSaved).format("YYYY-MM-DD HH:mm:ss")}
              </div>
            </Stack>
          </>
        </div>
      </div>
    );
  }
};

Header.defaultProps = {
  loggedIn: false,
};

Header.propTypes = {
  loggedIn: PropTypes.bool,
  logout: PropTypes.func.isRequired,
};

export default Header;
