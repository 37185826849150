import React from "react";
import { FaArrowLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import styles from "../indexOrg.module.scss";
const HeaderBarLink = ({ text, link, props }) => {
  return (
    <>
      {text === "" && <span className={styles.headerBarLink}>Dashboard</span>}
      {text !== "" && (
        <>
          <Link className={[styles.headerBarLink]} to={link} {...props}>
            <FaArrowLeft size={23} color="#ffffff" />
          </Link>
          <span className={styles.headerBarLink}>{text}</span>
        </>
      )}
    </>
  );
};
export default HeaderBarLink;
