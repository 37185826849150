import React, { useState, useEffect } from "react";
import { getJobPlanDetails } from "../../../../api/ApiConsultCalls";
import styles from "../../../../scss/style.module.scss";
import table from "../../../../table.module.scss";
import moment from "moment";
import { Spinner } from "react-bootstrap";
import styles2 from "../../../../index.module.scss";
import progress from "../../../progress/progress.module.scss";
import { Table } from "@mui/material";

/// Push all dates for this jobplan ID onto a form so the user can see the numbers.
const ReportDataPopup = ({ id, rptType, showAuthorisers = false }) => {
  const [dsDetails, setDsDetails] = useState({});
  const [showWait, setshowWait] = useState(true);

  const sortDateObjects = (a, b) => {
    if (a.authorised_date < b.authorised_date) {
      return -1;
    } else {
      return 1;
    }
  };

  useEffect(() => {
    const populateList = (id, rptType) => {
      if (id) {
        getJobPlanDetails(
          id,
          rptType,
          (result) => {
            setshowWait(false);
            setDsDetails(result);
          },
          (error) => {
            console.log(error);
          }
        );
      }
    };

    populateList(id, rptType);
  }, [id, rptType]);

  return (
    <div>
      <div className={`${showWait ? styles2.loginSpinner : "d-none"}`}>
        <Spinner
          as="span"
          animation="border"
          size="md"
          role="status"
          aria-hidden="true"
          className={progress.spinner}
        />
      </div>
      <div
        className={`${styles.filled_form_container} ${
          showAuthorisers && styles.filled_form_container_wider
        }`}
      >
        <Table className={table.dashboard} hover="true">
          <thead>
            <tr>
              <th className="table_text_small_col">Date</th>
              <th className="table_text_col">Activity</th>
              <th className="table_text_small_col">Modality</th>
              <th className="table_text_small_col">NICIP code</th>
              <th className="table_text_small_col">NICIP duration</th>
              {showAuthorisers && <th>First Authoriser</th>}
              {showAuthorisers && <th>Second Authoriser</th>}
            </tr>
          </thead>
          <tbody>
            {dsDetails &&
              dsDetails.length > 0 &&
              dsDetails.sort(sortDateObjects).map((sim, index) => (
                <tr key={sim.id}>
                  <td>
                    {moment(sim.authorised_date).format("YYYY-MM-DD HH:mm:ss")}
                  </td>
                  <td>{sim.examination}</td>
                  <td>{sim.exam_modality}</td>
                  <td>{sim.nicip_code}</td>
                  <td>{sim.nicip_duration}</td>
                  {showAuthorisers && <td>{sim.first_report_authoriser}</td>}
                  {showAuthorisers && <td>{sim.first_report_author_2}</td>}
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default ReportDataPopup;
