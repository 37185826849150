import React, { useRef, useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsMore from "highcharts/highcharts-more";
import styles from "../../../../scss/style.module.scss";
// import { roundNumber } from "../../../../common/math_functions";

const ReportLeaveChart = ({ calcData }) => {
  const [expected, setExpected] = useState(1.5);
  HighchartsMore(Highcharts);
  //   const sample = {
  //     actLeave: 1,
  //     expLeave: 2,
  //     actStrike: 3,
  //     expStrike: 4,
  //     actHours: 5,
  //     expHours: 6,
  //   };

  const chartRef = useRef(null);

  function updatePlotLineAndScaleYAxis(newPlotLineValue) {
    if (chartRef.current) {
      const chart = chartRef.current.chart;
      // Find the maximum value in your data series
      const maxDataValue = Math.max(
        ...chart.series[0].data.map((point) => point.y)
      );

      // Calculate the new maximum for the y-axis
      const newYAxisMax = Math.max(newPlotLineValue, maxDataValue) + 1; // Add some padding

      // Update the plot line and the y-axis maximum
      chart.yAxis[0].update({
        plotLines: [
          {
            color: "black",
            dashStyle: "dot",
            width: 2,
            value: newPlotLineValue,
            label: {
              rotation: 0,
              y: 15,
              style: {
                fontStyle: "italic",
              },
              text: "expected leave",
            },
            zIndex: 3,
          },
        ],
        max: newYAxisMax, // Set the new maximum for the y-axis
      });
    }
  }

  useEffect(() => {
    if (chartRef.current) {
      const chart = chartRef.current.chart;

      // Check if the chart is fully initialized before updating
      if (
        chart.series &&
        chart.series[0] &&
        chart.series[0].points &&
        chart.series[0].points[0]
      ) {
        if (calcData.actLeave !== Infinity)
          chart.series[0].points[0].update(calcData.actLeave);
        else chart.series[0].points[0].update(0);
      }
      // if (
      //   chart.series &&
      //   chart.series[1] &&
      //   chart.series[1].points &&
      //   chart.series[1].points[0]
      // ) {
      //   if (calcData.actStrike !== Infinity)
      //     chart.series[1].points[0].update(calcData.actStrike);
      //   else chart.series[1].points[0].update(0);
      // }

      // Update the plot lines
      //   let total = calcData.expLeave + calcData.expStrike;
      setExpected(calcData.expLeave + calcData.expStrike);
      //   chart.yAxis[0].update({
      //     plotLines: [
      //       {
      //         color: "black",
      //         dashStyle: "dot",
      //         width: 2,
      //         value: calcData.expLeave + calcData.expStrike,
      //         label: {
      //           rotation: 0,
      //           y: 15,
      //           style: {
      //             fontStyle: "italic",
      //           },
      //           text: "expected leave",
      //         },
      //         zIndex: 3,
      //       },
      //     ],
      //   });
      updatePlotLineAndScaleYAxis(calcData.expLeave + calcData.expStrike);
    }
  }, [calcData]);

  const options = {
    chart: {
      type: "column",
      marginTop: 50,
      marginRight: 50,
      width: 400,
      style: {
        color: "#408EC6",
        fontWeight: "bold",
        fontFamily: "'Poppins', 'Helvetica Neue', sans-serif",
      },
    },
    title: { text: "", marginTop: 0 },
    xAxis: {
      crosshair: true,
      labels: {
        enabled: false,
      },
      tickWidth: 0,
      tickLength: 0,
      lineWidth: 0,
    },
    tooltip: {
      enabled: false,
    },
    yAxis: {
      min: 0,
      title: {
        text: "Total Days Leave",
      },
      plotLines: [
        {
          color: "black",
          dashStyle: "dot",
          width: 2,
          value: expected,
          label: {
            rotation: 0,
            y: 15,
            style: {
              fontStyle: "italic",
            },
            text: "expected leave",
          },
          zIndex: 3,
        },
      ],
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
      },
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        name: "Annual Leave",
        data: [calcData.actLeave],
        color: "#4472c4",
      },
    ],
  };

  return (
    <div className={styles.tables_three}>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        ref={chartRef}
      />
    </div>
  );
};

export default ReportLeaveChart;
