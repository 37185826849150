import React, { useState } from "react";

import TabGeneral from "./tabs/TabGeneral";
import TabPTLConditions from "./tabs/TabPTLConditions";
import TabDemand from "./tabs/TabDemand";
import TabActivity from "./tabs/TabActivity";
import RunSim from "./tabs/TabRunSim";
import TabRptBar from "./tabs/TabRptBar";

import { IoMdSettings } from "react-icons/io";
import { RiBubbleChartFill } from "react-icons/ri";
import { BsPeopleFill } from "react-icons/bs";
import { GiPersonInBed } from "react-icons/gi";

import { BiRun } from "react-icons/bi";

import { VscGraph } from "react-icons/vsc";

const ModalSimInputContext = React.createContext(() => {});

const Model01Tabs = ({ simGuid }) => {
  const [activeTab, setActiveTab] = useState("tab1");
  // NB I initially had an array here but the change detection of an element was not robuts
  const [valTab0, setValTab0] = useState(false);
  const [valTab1, setValTab1] = useState(false);
  const [valTab2, setValTab2] = useState(false);
  const [valTab3, setValTab3] = useState(false);
  const [valTab4, setValTab4] = useState(false);
  // const [valTab5, setValTab5] = useState(true);
  // const [valTab6, setValTab6] = useState(true);

  // Setup the checkbox validation parameters
  const validateTab = (tabNo, valid) => {
    switch (tabNo) {
      case 0:
        setValTab0(valid);
        break;
      case 1:
        setValTab1(valid);
        break;
      case 2:
        setValTab2(valid);
        break;
      case 3:
        setValTab3(valid);
        break;
      case 4:
        setValTab4(valid);
        break;
      // case 5:
      //   setValTab5(valid);
      //   break;
      // case 6:
      //   setValTab6(valid);
      //   break;

      default:
        break;
    }
    // console.log("ValidateTab:", tabNo, valid);
  };
  const setTabToResults = () => {
    setActiveTab("tab6");
  };
  // Tabs juggled but last lot of code came from:
  // https://blog.logrocket.com/how-to-build-tab-component-react/

  // ***********************************
  // Style sheets found in tab_custom.css
  // ***********************************

  return (
    <ModalSimInputContext.Provider value={validateTab}>
      <ul className="nav">
        <li
          className={activeTab === "tab1" ? "active" : ""}
          onClick={() => {
            setActiveTab("tab1");
          }}
        >
          <IoMdSettings size={30} />
          <div className="checkboxContainer">Settings</div>
        </li>
        <li
          className={activeTab === "tab2" ? "active" : ""}
          onClick={() => {
            setActiveTab("tab2");
          }}
        >
          <RiBubbleChartFill size={30} />
          <div className="checkboxContainer">Patient Groups</div>
        </li>
        <li
          className={activeTab === "tab3" ? "active" : ""}
          onClick={() => {
            setActiveTab("tab3");
          }}
        >
          <BsPeopleFill size={30} />
          <div className="checkboxContainer">Demand</div>
        </li>
        <li
          className={activeTab === "tab4" ? "active" : ""}
          onClick={() => {
            setActiveTab("tab4");
          }}
        >
          <GiPersonInBed size={30} />
          <div className="checkboxContainer">Activity</div>
        </li>
        <li
          className={activeTab === "tab5" ? "active" : ""}
          onClick={() => {
            setActiveTab("tab5");
          }}
        >
          <BiRun size={30} />
          <div className="checkboxContainer">Run</div>
        </li>
        <li
          className={activeTab === "tab6" ? "active" : ""}
          onClick={() => {
            setActiveTab("tab6");
          }}
        >
          <VscGraph size={30} />
          <div className="checkboxContainer">Results</div>
        </li>
      </ul>
      <br />
      <br />
      <div className={activeTab === "tab1" ? "" : "hiddenControl"}>
        <TabGeneral tabNo={0} />
      </div>
      <div className={activeTab === "tab2" ? "" : "hiddenControl"}>
        <TabPTLConditions tabNo={1} />
      </div>
      <div className={activeTab === "tab3" ? "" : "hiddenControl"}>
        <TabDemand tabNo={2} />
      </div>
      <div className={activeTab === "tab4" ? "" : "hiddenControl"}>
        <TabActivity tabNo={3} />
      </div>
      <div className={activeTab === "tab5" ? "" : "hiddenControl"}>
        <RunSim
          tabNo={4}
          valTab0={valTab0}
          valTab1={valTab1}
          valTab2={valTab2}
          valTab3={valTab3}
          valTab4={valTab4}
          setTabToResults={() => {
            setTabToResults();
          }}
        />
      </div>
      <div className={activeTab === "tab6" ? "" : "hiddenControl"}>
        <TabRptBar tabNo={5} simulation_id={simGuid} />
      </div>

      <br />
      <br />
    </ModalSimInputContext.Provider>
  );
};

export { Model01Tabs as Model1TabsInput, ModalSimInputContext };
