import React, { useContext, useState } from "react";
import AilmentGrid from "./AilmentGrid";
import AcuityGridMean from "./AcuityGridMean";
import { ModalSimInputContext } from "../Model01Tabs";

const TabPTLConditions = ({ tabNo }) => {
  const validateTab = useContext(ModalSimInputContext);
  const [ctlValidation, setCtlValidation] = useState([false, false]);
  const ctlValid = (controlNumber, value) => {
    ctlValidation[controlNumber] = value;
    setCtlValidation(ctlValidation);
    validateTab(
      tabNo,
      ctlValidation.every((element) => element === true)
    );
  };

  return (
    <>
      <div style={{ marginBottom: "35px" }}>
        <h3>Surgical Procedure Times</h3>
        <AilmentGrid
          ctrlValid={ctlValid}
          ctrlNumber="0"
          value={tabNo}
          displayType="ptl"
        />
      </div>
      <div>
        <h3>Patient Groups Acuities</h3>
        <AcuityGridMean ctrlValid={ctlValid} ctrlNumber="1" value={tabNo} />
      </div>
    </>
  );
};

export default TabPTLConditions;
