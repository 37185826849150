/*
Given the activity duration array and the activity string locate it.
Once it found return the standard pa hours Or the premium hrs.
*/

export const getActivityDuration = (dsActivityDuration, activity, standard) => {
  if (dsActivityDuration && dsActivityDuration.length > 0) {
    let activity_trim = activity.trim();
    let row = dsActivityDuration.filter(
      (item) => item.activity.trim() === activity_trim
    );
    if (row.length === 1) {
      if (standard) {
        return row[0].pa_standard_hrs;
      } else {
        return row[0].pa_premium_hrs;
      }
    }
  }
};
