/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  Fragment,
  useState,
  useCallback,
  useEffect,
  useContext,
} from "react";
import PropTypes from "prop-types";
import { Calendar, Views, DateLocalizer } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/css/react-big-calendar.css";
import CalendarToolbar from "./Toolbar";
import "../../../../scss/calendar.scss";
import moment from "moment";
import {
  addToTheatreSchedule,
  delTheatreSession,
  updateTheatreSchedule,
} from "../../../../api/ApiPtlCalls";
import { Model01SimTheatreDiary } from "../../Model01";
import { Col, Row } from "react-bootstrap";
import style from "../../../../scss/style.module.scss";
import ModalConfirm from "../../../modal/ModalConfirm";

import { Model01SimTheatreContext, Model01IsLocked } from "../../Model01";
const DragAndDropCalendar = withDragAndDrop(Calendar);
let beginningOfWeek = moment().startOf("isoweek");

export const getPostData = (data) => {
  const fk_sim_theatre_type_id =
    data.event !== undefined
      ? data.event.fk_sim_theatre_type_id
      : data.fk_sim_theatre_type_id;

  const backgroundColor = !data.calc_rpt_colour
    ? data.event.backgroundColor
    : data.calc_rpt_colour;

  const postData = {
    id: data.event.id,
    calc_rpt_colour: backgroundColor,
    fk_sim_session_demand_id: data.event.fk_sim_session_demand_id,
    fk_sim_theatre_type_id: fk_sim_theatre_type_id,
    theatre_number: data.resourceId - 1,
    duration: data.duration,
    start_time: moment(data.start).format("HH:mm:ss"),
    day: moment(data.start).diff(moment(beginningOfWeek), "days"),
  };

  return postData;
};
const DnDResource = ({ numberOfTheatres, index, localizer }) => {
  let resourceMap = [];
  const [myEvents, setMyEvents] = useState([]);
  const [updateEvents, setUpdateEvents] = useState(false);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedSessionId, setSelectedSessionId] = useState("");
  const [dsSimTheatreTypes, setDsSimTheatreTypes] = useContext(
    Model01SimTheatreContext
  );
  const [isLocked] = useContext(Model01IsLocked);
  const [diaryDetails, setSimTheatreSchedule] = useContext(
    Model01SimTheatreDiary
  );

  const handleDragStart = useCallback((event) => setDraggedEvent(event), []);
  for (let i = 0; i < numberOfTheatres; i++) {
    resourceMap.push({ resourceId: i + 1, resourceTitle: `${i + 1}` });
  }
  const [draggedEvent, setDraggedEvent] = useState({});
  const newEvent = useCallback(
    (event) => {
      setMyEvents((prev) => {
        const idList = prev.map((item) => item.id);
        const newId = Math.max(...idList) + 1;
        return [...prev, { ...event, id: newId }];
      });
    },
    [setMyEvents]
  );

  const onDropFromOutside = useCallback(
    (data) => {
      if (isLocked) return false; // When locked do nothing
      const { fk_sim_theatre_type_id, duration, backgroundColor } =
        draggedEvent;
      const event = {
        start: data.start,
        end: data.end,
        resourceId: data.resource,
        duration,
        calc_rpt_colour: backgroundColor,
        event: {
          fk_sim_session_demand_id: diaryDetails.demand[index].id,
          fk_sim_theatre_type_id: fk_sim_theatre_type_id,
          day: moment(data.start).diff(moment(beginningOfWeek), "days"),
        },
      };
      const postData = getPostData(event);
      addToTheatreSchedule(postData, (result) => {
        if (result) {
          postData.id = result.id;
          const updatedDiaryDetails = diaryDetails;
          // Below was added as the diary will be null when new
          if (updatedDiaryDetails.demand[index].diary == null) {
            updatedDiaryDetails.demand[index].diary = [];
          }
          updatedDiaryDetails.demand[index].diary.push(postData);
          setSimTheatreSchedule(updatedDiaryDetails);
          newEvent(result);
          setDraggedEvent(null);
          setUpdateEvents(result.id);
        } else {
          alert("There has been a problem updating the schedule.");
        }
      });
    },
    [draggedEvent, setDraggedEvent, newEvent]
  );

  useEffect(() => {
    if (diaryDetails.demand && diaryDetails?.demand[index]?.diary) {
      const events = diaryDetails && diaryDetails?.demand[index]?.diary;
      const formattedEvents = formatEvents(events);
      getNumberOfSessions();
      setMyEvents(formattedEvents);
    }
  }, [updateEvents, diaryDetails]);

  const formatEvents = (events) => {
    return events?.map((event) => {
      const monday = moment().startOf("isoweek");
      const theatreDay = monday.add("days", event.day).format("YYYY-MM-DD");
      let theatreStart = moment(`${theatreDay} ${event.start_time}`);
      let theatreEnd = moment(theatreStart)
        .add(event.duration, "hours")
        .format("YYYY-MM-DD HH:mm:ss");
      theatreStart = new Date(theatreStart);
      theatreEnd = new Date(theatreEnd);
      const eventObj = {
        id: event.id,
        resourceId: event.theatre_number + 1,
        start: theatreStart,
        end: theatreEnd,
        day: event.day,
        duration: event.duration,
        backgroundColor: event.calc_rpt_colour,
        fk_sim_session_demand_id: event.fk_sim_session_demand_id,
        fk_sim_theatre_type_id: event.fk_sim_theatre_type_id,
      };
      return eventObj;
    });
  };

  const setDiaryDetails = (postData) => {
    const diaryIndex = diaryDetails.demand[index].diary.findIndex(
      (x) => x.id === postData.id
    );
    diaryDetails.demand[index].diary[diaryIndex] = postData;
    setSimTheatreSchedule(diaryDetails);
  };

  const moveEvent = (data) => {
    if (isLocked) return false; // When locked do nothing
    data.duration = moment(data.end).diff(moment(data.start), "minutes") / 60;
    const postData = getPostData(data);

    updateTheatreSchedule(data.event.id, postData, (result) => {
      if (result) {
        setDiaryDetails(postData);
        setMyEvents((prev) => {
          const existing = prev.find((ev) => ev.id === data.event.id) ?? {};
          const filtered = prev.filter((ev) => ev.id !== data.event.id);
          return [
            ...filtered,
            {
              ...existing,
              start: data.start,
              end: data.end,
              resourceId: data.resourceId,
            },
          ];
        });
      } else {
        alert("There has been a problem updating the schedule.");
      }
    });
  };

  const resizeEvent = (data) => {
    if (isLocked) return false; // When locked do nothing
    data.duration = moment(data.end).diff(moment(data.start), "minutes") / 60;
    const postData = getPostData(data);
    updateTheatreSchedule(data.event.id, postData, (result) => {
      if (result) {
        setDiaryDetails(postData);
        getNumberOfSessions();
        setMyEvents((prev) => {
          const existing = prev.find((ev) => ev.id === data.event.id) ?? {};
          const filtered = prev.filter((ev) => ev.id !== data.event.id);
          return [
            ...filtered,
            { ...existing, start: data.start, end: data.end },
          ];
        });
      } else {
        alert("There has been a problem updating the schedule.");
      }
    });
  };

  const getNumberOfSessions = () => {
    Object.keys(dsSimTheatreTypes).map((theatre) => {
      const noOfSessions = diaryDetails?.demand[index]?.diary.reduce(
        (n, diaryEntry) => {
          return (
            n +
            (diaryEntry.calc_rpt_colour ===
              dsSimTheatreTypes[theatre].rpt_colour)
          );
        },
        0
      );
      const noOfHours = diaryDetails?.demand[index]?.diary.reduce(
        (n, diaryEntry) => {
          if (
            diaryEntry.calc_rpt_colour === dsSimTheatreTypes[theatre].rpt_colour
          ) {
            n = n + diaryEntry.duration;
          }
          return n;
        },
        0
      );
      dsSimTheatreTypes[theatre].totalHours = noOfHours;
      dsSimTheatreTypes[theatre].noOfSessions = noOfSessions;
      setDsSimTheatreTypes(dsSimTheatreTypes);
      return noOfSessions;
    });
  };

  const handleSelectEvent = (event) => {
    if (isLocked) return false; // When locked do nothing
    setSelectedSessionId(event.id);
    setShowDeleteModal(true);
  };

  const modalDeleteClose = () => {
    setShowDeleteModal(false);
  };

  const rowDelete = () => {
    if (isLocked) return false; // When locked do nothing
    modalDeleteClose();

    delTheatreSession(selectedSessionId, () => {
      const newArr = myEvents.filter((event) => {
        return event.id !== selectedSessionId;
      });

      const diaryIndex = diaryDetails.demand[index].diary.findIndex(
        (x) => x.id === selectedSessionId
      );
      diaryDetails.demand[index].diary.splice(diaryIndex, 1);
      setSimTheatreSchedule(diaryDetails);
      setMyEvents(newArr);
      getNumberOfSessions();
    });
  };

  const minTime = new Date();
  minTime.setHours(7, 0, 0);
  const maxTime = new Date();
  maxTime.setHours(23, 0, 0);

  return (
    <Fragment>
      <div>
        {/* Calendar below */}
        {!!myEvents && (
          <DragAndDropCalendar
            defaultView={Views.WEEK}
            events={myEvents}
            localizer={localizer}
            onEventDrop={moveEvent}
            onEventResize={resizeEvent}
            onSelectEvent={handleSelectEvent}
            onDropFromOutside={onDropFromOutside}
            isDraggable="true"
            resizable
            resourceIdAccessor="resourceId"
            resources={resourceMap}
            resourceTitleAccessor="resourceTitle"
            selectable
            showMultiDayTimes={true}
            min={minTime}
            max={maxTime}
            dayLayoutAlgorithm="no-overlap"
            eventPropGetter={(myEvents) => {
              const backgroundColor = myEvents.backgroundColor
                ? myEvents.backgroundColor
                : `#000000`;
              return { style: { backgroundColor } };
            }}
            defaultDate={moment().startOf("isoweek")}
            components={{
              toolbar: CalendarToolbar,
            }}
            step={30}
          />
        )}
      </div>
      <div>
        {/* List below diary */}
        {diaryDetails && (
          <>
            <Row style={{ marginTop: "20px" }}>
              {Object.keys(dsSimTheatreTypes).map((theatre) => {
                return (
                  <Col
                    md="3"
                    draggable="true"
                    style={{ cursor: "pointer" }}
                    key={dsSimTheatreTypes[theatre].title}
                    onDragStart={() =>
                      handleDragStart({
                        fk_sim_theatre_type_id: dsSimTheatreTypes[theatre].id,
                        duration: dsSimTheatreTypes[theatre].session_hours,
                        backgroundColor: dsSimTheatreTypes[theatre].rpt_colour,
                      })
                    }
                  >
                    <div className={`${style.colourRow} ${style.colourKey}`}>
                      <div>
                        <span
                          style={{
                            backgroundColor: dsSimTheatreTypes[theatre]
                              .rpt_colour
                              ? dsSimTheatreTypes[theatre].rpt_colour
                              : `#${Math.floor(
                                  Math.random() * 16777215
                                ).toString(16)}`,
                          }}
                          className={style.dot}
                        ></span>
                      </div>
                      <div>{dsSimTheatreTypes[theatre].title}</div>
                      <div>
                        ({dsSimTheatreTypes[theatre].totalHours} hours split
                        over {dsSimTheatreTypes[theatre].noOfSessions} sessions)
                      </div>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </>
        )}
      </div>
      <ModalConfirm
        show={showDeleteModal}
        handleClose={modalDeleteClose}
        title="Please confirm you want to delete this theatre session"
        confirmed={rowDelete}
      ></ModalConfirm>
    </Fragment>
  );
};
DnDResource.propTypes = {
  localizer: PropTypes.instanceOf(DateLocalizer),
};
export default DnDResource;
