import React, { useContext, useEffect } from "react";
import { Formik, Form, useFormikContext } from "formik";
import * as Yup from "yup";

import {
  Model01SimSettingsContext,
  Model01IsDirty,
  Model01IsLocked,
} from "../Model01";
import TextField from "../../TextField";
// import TimePickerField from "../../TimePickerField";
import TextFieldFormat from "../../TextFieldFormat";
import RptPaRefreh from "./RptPaRefreh";
// import TextFieldTime from "../../TextFieldTime";
import DropdownMonth from "../../DropdownMonth";

import AutoSave from "../../FormikAutoSave";
import { isEqualJson } from "../../../common/json_compare";
import UploadPTL from "./UploadPTL";
import styles from "../../../scss/style.module.scss";
import DownloadTemplate from "./DownloadTemplate";

// import WeekCheckBoxes from "../../FormikWeekCheckBoxes";

const Settings = ({ ctrlValid, ctrlNumber, displayType }) => {
  const [data, writeSimSettings] = useContext(Model01SimSettingsContext);
  const [isDirty, setIsDirty] = useContext(Model01IsDirty); // eslint-disable-line no-unused-vars
  const [isLocked] = useContext(Model01IsLocked); // eslint-disable-line no-unused-vars
  var schema = {};
  var initValues = {};
  /*
  gerneral
  ptl
  demand
  activity
  run
  */
  if (displayType === "general") {
    schema = {
      title: Yup.string()
        .max(256, "Must be 256 chars or less")
        .required("Required"),
      model_start_dtg: Yup.date("Must be a date").required("Required"),
      model_end_dtg: Yup.date("Must be a date")
        .required("Required")
        .min(Yup.ref("model_start_dtg"), "End date can't be before Start date"),
    };
    initValues = {
      title: data["title"] || "",
      model_start_dtg: data["model_start_dtg"] || "",
      model_end_dtg: data["model_end_dtg"] || "",
    };
  } else if (displayType === "ptl") {
    schema = {
      emergency_time_mean_mins: Yup.number()
        .min(0, "Must be positive")
        .required("Required"),
      emergency_time_sd_mins: Yup.number()
        .min(0, "Must be positive")
        .required("Required"),
      emergency_time_minimum_mins: Yup.number()
        .min(0, "Must be positive")
        .required("Required"),
    };
    initValues = {
      emergency_time_mean_mins: data["emergency_time_mean_mins"] || 0,
      emergency_time_sd_mins: data["emergency_time_sd_mins"] || 0,
      emergency_time_minimum_mins: data["emergency_time_minimum_mins"] || 0,
    };
  } else if (displayType === "demand") {
    schema = {
      new_patients_per_day: Yup.number()
        .min(0, "Must be positive")
        .required("Required"),
      emergency_patient_mean: Yup.number()
        .min(0, "Must be positive")
        .required("Required"),
    };
    initValues = {
      new_patients_per_day: data["new_patients_per_day"] || 0,
      emergency_patient_mean: data["emergency_patient_mean"] || 0,
    };
  } else if (displayType === "run") {
    schema = {
      no_runs: Yup.number()
        .integer()
        .min(1, "Must be more than 0")
        .required("Required"),
    };
    initValues = {
      no_runs: data["no_runs"] || 0,
    };
  } else if (displayType === "activity") {
    schema = {
      no_of_theatres: Yup.number()
        .integer()
        .min(1, "Must be more than 0")
        .required("Required"),
      work_hours_after: Yup.number()
        .integer()
        .min(0, "Must not be negative")
        .required("Required"),
      theatre_down_time_days: Yup.number()
        .integer()
        .min(0, "Must not be negative")
        .required("Required"),
      theatre_down_time_month: Yup.number()
        .integer()
        .min(0, "Must not be negative")
        .required("Required"),
    };
    initValues = {
      no_of_theatres: data["no_of_theatres"] || 0,
      work_hours_after: data["work_hours_after"] || 0,
      theatre_down_time_days: data["theatre_down_time_days"] || 0,
      theatre_down_time_month: data["theatre_down_time_month"] || 0,
    };
  } else if (displayType === "pa") {
    schema = {
      pa_percentage_clinical_time: Yup.number()
        .min(0, "Must not be negative")
        .max(100, "Must not be more than 100")
        .required("Required"),
      pa_working_weeks_per_year: Yup.number()
        .integer()
        .min(0, "Must not be negative")
        .max(52, "Must not be more than 52")
        .required("Required"),
      pa_additional_session_time_mins: Yup.number()
        .integer()
        .min(0, "Must not be negative")
        .max(600, "Must not be more than 600")
        .required("Required"),
    };
    initValues = {
      pa_percentage_clinical_time: data["pa_percentage_clinical_time"] || 0,
      pa_working_weeks_per_year: data["pa_working_weeks_per_year"] || 0,
      pa_additional_session_time_mins:
        data["pa_additional_session_time_mins"] || 0,
    };
  }

  const frmSchema = Yup.object(schema);

  const SaveFormData = async (values) => {
    // Assemble the data
    var myDataCheck = { ...data, ...values };
    // Check there is a difference.
    if (!isEqualJson(data, myDataCheck)) {
      // Send it up the tree
      writeSimSettings(myDataCheck);
      setIsDirty(true);
    }
  };

  const FormikFormObserver = () => {
    // When data is changed validate it
    const { values } = useFormikContext();
    useEffect(() => {
      const result = frmSchema.isValid(values, { strict: false });
      result.then((value) => {
        if (ctrlValid !== undefined) {
          ctrlValid(ctrlNumber, value);
        }
      });
    }, [values]);
    return;
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initValues}
      validationSchema={frmSchema}
      onSubmit={async (values) => {
        SaveFormData(values);
        //alert(JSON.stringify(values, null, 2));
      }}
    >
      {(formik) => (
        <div>
          <Form>
            <div>
              <small
                className="float-end"
                style={{ color: "gray", fontSize: 11 }}
              >
                <AutoSave debounceMs={2000} />
              </small>
            </div>
            <FormikFormObserver frmSchema={frmSchema} />
            {displayType && displayType === "general" && (
              <>
                <div className={styles.filterForm}>
                  <TextFieldFormat
                    label="Simulation Name"
                    // className="mdc-text-field--with-internal-counter"
                    name="title"
                    type="text"
                    inputProps={{ maxLength: 55 }}
                    disabled={isLocked}
                  />
                  <TextFieldFormat
                    label="PTL Start Date"
                    name="model_start_dtg"
                    type="date"
                    disabled={isLocked}
                  />
                  <TextFieldFormat
                    label="PTL End date"
                    name="model_end_dtg"
                    type="date"
                    disabled={isLocked}
                  />

                  <UploadPTL disabled={isLocked} />
                  <DownloadTemplate />
                </div>
              </>
            )}
            {displayType && displayType === "ptl" && (
              <>
                <TextField
                  label="Average procedure time"
                  name="emergency_time_mean_mins"
                  type="number"
                  min="0"
                  disabled={isLocked}
                />
                <TextField
                  label="Std Dev procedure time"
                  name="emergency_time_sd_mins"
                  type="number"
                  min="0"
                  disabled={isLocked}
                />
                <TextField
                  label="Minimum procedure duration"
                  name="emergency_time_minimum_mins"
                  type="number"
                  min="0"
                  disabled={isLocked}
                />
              </>
            )}
            {displayType && displayType === "demand" && (
              <>
                <div className={`${styles.generalForm} ${styles.flexColumn}`}>
                  <div className={`${styles.flexRow}`}>
                    <TextField
                      label="New patients per day"
                      name="new_patients_per_day"
                      type="number"
                      step="any"
                      min="0"
                      disabled={isLocked}
                    />
                    <TextField
                      label="Emergency patients per day (mean)"
                      name="emergency_patient_mean"
                      type="number"
                      step="any"
                      min="0"
                      disabled={isLocked}
                    />
                  </div>
                </div>
              </>
            )}
            {displayType && displayType === "activity" && (
              <>
                <div className={`${styles.generalForm} ${styles.flexColumn}`}>
                  <div
                    className={`${styles.flexRow}`}
                    style={{ alignItems: "unset" }}
                  >
                    <TextField
                      label="Number of theatres"
                      name="no_of_theatres"
                      type="number"
                      min="0"
                      disabled={isLocked}
                    />
                    <TextField
                      label="Down Time (days)"
                      name="theatre_down_time_days"
                      type="number"
                      min="0"
                      disabled={isLocked}
                    />
                    <DropdownMonth
                      label="Month for down time"
                      fieldName="theatre_down_time_month"
                      disabled={isLocked}
                    />
                  </div>
                </div>
              </>
            )}
            {displayType && displayType === "run" && (
              <>
                <TextField
                  label="Number of runs"
                  name="no_runs"
                  type="number"
                  min="0"
                  disabled={isLocked}
                />
              </>
            )}
            {displayType && displayType === "pa" && (
              <>
                <br />
                <div className={`${styles.generalForm} ${styles.flexColumn}`}>
                  <div className={`${styles.flexRow}`}>
                    <TextFieldFormat
                      label="% Clinical time"
                      name="pa_percentage_clinical_time"
                      type="number"
                      min="0"
                      size="400px"
                      disabled={isLocked}
                    />
                    <br />
                    <TextFieldFormat
                      label="Ann. worked weeks"
                      name="pa_working_weeks_per_year"
                      type="number"
                      step="any"
                      min="0"
                      disabled={isLocked}
                    />
                    <br />
                    <TextFieldFormat
                      label="Add session time (mins)"
                      name="pa_additional_session_time_mins"
                      type="number"
                      step="any"
                      min="0"
                      disabled={isLocked}
                    />
                    <div>
                      <RptPaRefreh simulation_id={data.simulation_id} />
                    </div>
                  </div>
                </div>
              </>
            )}
          </Form>
        </div>
      )}
    </Formik>
  );
};

export default Settings;
