import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import moment from "moment";

import TextField from "../../../TextField";
// import { putAilment, postAilment } from "../../../../api/ApiPtlCalls";
import {
  putTimeSegment,
  postTimeSegment,
} from "../../../../api/ApiConsultCalls";
import styles from "../../../../scss/style.module.scss";

const TimeSegment = ({ data, dataUpdated, isNew }) => {
  var schema = {};
  var initValues = {};

  schema = {
    segment_description: Yup.string()
      .max(16, "Must be 16 chars or less")
      .required("Required"),
    start_time: Yup.string().required("Start time cannot be blank"),
    end_time: Yup.string()
      .required("End time cannot be blank")
      .test(
        "is-greater",
        "End time should be after Start time",
        function (value) {
          const { start_time } = this.parent;
          return moment(value, "HH:mm").isSameOrAfter(
            moment(start_time, "HH:mm")
          );
        }
      ),
  };
  initValues = {
    id: data["id"] || "",
    segment_description: data["segment_description"] || "",
    start_time: data["start_time"] || 0,
    end_time: data["end_time"] || 0,
  };

  const frmSchema = Yup.object(schema);

  const setData = (ailment) => {
    // Update the cached data now.
  };

  const SaveFormData = async (values) => {
    // Assemble the data

    values.start_time = moment(values.start_time, "HH:mm:ss").format(
      "HH:mm:ss"
    );
    values.end_time = moment(values.end_time, "HH:mm:ss").format("HH:mm:ss");

    var myDataCheck = { ...data, ...values };
    // Put it to the server
    if (!isNew) {
      // Put is an update
      await putTimeSegment(
        myDataCheck,
        (result) => {
          // Success
          if (result) {
            setData(myDataCheck);
            dataUpdated(myDataCheck);
          } else {
            alert(
              "There has been a problem updating the data - the api did not respond correctly."
            );
          }
        },
        (error) => {
          // Failure
          alert("Error: " + error);
        }
      );
    } else {
      // Post is new
      await postTimeSegment(
        myDataCheck,
        (result) => {
          // Success
          if (result) {
            setData(myDataCheck);
            dataUpdated(myDataCheck);
          } else {
            alert(
              "There has been a problem saving the data - the api did not respond correctly."
            );
          }
        },
        (error) => {
          // Failure
          alert("Error: " + error);
        }
      );
    }
  };

  // console.log("initValues: ", initValues);

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initValues}
        validationSchema={frmSchema}
        onSubmit={async (values) => {
          SaveFormData(values);
          //alert(JSON.stringify(values, null, 2));
        }}
      >
        {(formik) => (
          <div>
            <Form id={"time_segment_" + isNew.toString()}>
              <TextField
                label="Title"
                name="segment_description"
                type="text"
                maxLength="16"
              />
              <TextField label="Start Time" name="start_time" type="time" />
              <TextField label="End Time" name="end_time" type="time" />
            </Form>
          </div>
        )}
      </Formik>
      <div className={styles.buttonContainer}>
        <button
          className="btn btn-outline-primary submit"
          form={"time_segment_" + isNew.toString()}
        >
          Submit
        </button>
      </div>
    </>
  );
};

export default TimeSegment;
