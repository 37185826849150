import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useRef,
} from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { getReportData, getReportDataSummary } from "../../../api/ApiPtlCalls";
import ColoursList from "../../ColoursList";
import ColouredTextBox from "../../ColouredTextBox";
import {
  Model01RptRefreshContext,
  Model01SimAilmentsContext,
} from "../Model01";
import {
  workOutBackGroundColor,
  workOutColor,
} from "../../../common/colour_selector";
import { numberWithCommas } from "../../../common/math_functions";
import styles from "../../../indexOrg.module.scss";

require("highcharts/modules/accessibility")(Highcharts);

const RptModelResults = ({
  simulation_id,
  viewDay,
  setTabDayNumber,
  summaryData,
  seriesHidden,
  setSeriesHidden,
}) => {
  const chartComponentRef = useRef(null);
  const [isRefreshNeeded, refreshNeeded] = useContext(Model01RptRefreshContext); // eslint-disable-line no-unused-vars
  const [options, setOptions] = useState({});
  const [myDataSeries, setMyDataSeries] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [dsSimAilments, setDsSimAilments] = useContext(
    Model01SimAilmentsContext
  );
  const [BoxColour, setBoxColour] = useState("lightgrey"); // eslint-disable-line no-unused-vars
  const [BoxFontColour, setBoxFontColour] = useState("black"); // eslint-disable-line no-unused-vars
  const [strAvgWaitNumber, setStrAvgWaitNumber] = useState(0);
  const [strPTLNumber, setStrPTLNumber] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const setBoxes = (ailmentDetails) => {
    // Data retrieved too....
    setBoxColour("#ffff00");
    setBoxFontColour("#fa5403;");
  };
  const dayShift = 1; // This is here to link all days as 0 is now used for the ptl. So, what is 1 needs to become 0 for the db lookups - the model starts on day 0.

  useEffect(() => {
    const hideSeries = (stringArray) => {
      chartComponentRef &&
        chartComponentRef.current &&
        chartComponentRef.current.chart &&
        chartComponentRef.current.chart.series &&
        chartComponentRef.current.chart.series.forEach((element) => {
          if (stringArray.indexOf(element.name) > -1) {
            element.hide();
          } else {
            element.show();
          }
        });
    };
    seriesHidden &&
      seriesHidden.length !== undefined &&
      hideSeries(seriesHidden);
    setBoxColour(workOutBackGroundColor(dsSimAilments, seriesHidden));
    setBoxFontColour(workOutColor(dsSimAilments, seriesHidden));
  }, [dsSimAilments, seriesHidden]);

  // const [summaryData, setSummaryData] = useState({});

  // Configure the chart - static settings here, one unchanging callback instance.
  // Dynamic ones set below within a data dependant useEffect
  const static_config = useCallback(() => {
    return {
      title: {
        text: "",
      },
      credits: {
        enabled: false,
      },
      chart: {
        type: "column",
        style: {
          fontFamily: "Arial",
        },
      },
      yAxis: {
        title: { text: "Patient Count" },
      },
      xAxis: {
        start: 0,
        min: 0,
        plotLines: [
          {
            color: "black",
            dashStyle: "dot",
            width: 2,
            value: 18,
            label: {
              rotation: 0,
              y: 15,
              style: {
                fontStyle: "italic",
              },
              text: "18 wks",
            },
            zIndex: 3,
          },
          {
            color: "black",
            dashStyle: "dot",
            width: 2,
            value: 52,
            label: {
              rotation: 0,
              y: 15,
              style: {
                fontStyle: "italic",
              },
              text: "52 wks",
            },
            zIndex: 3,
          },
          {
            color: "black",
            dashStyle: "dot",
            width: 2,
            value: 78,
            label: {
              rotation: 0,
              y: 15,
              style: {
                fontStyle: "italic",
              },
              text: "78 wks",
            },
            zIndex: 3,
          },
          {
            color: "black",
            dashStyle: "dot",
            width: 2,
            value: 104,
            label: {
              rotation: 0,
              y: 15,
              style: {
                fontStyle: "italic",
              },
              text: "104 wks",
            },
            zIndex: 3,
          },
        ],
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
        footerFormat: "</table>",
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        column: {
          events: {
            legendItemClick: function (event) {
              var ailment_title = this.name;
              // var existing_state = this.visible; // eslint-disable-line no-unused-vars
              // var chart = this.chart; // eslint-disable-line no-unused-vars
              // var chart2 = event.target.chart; // eslint-disable-line no-unused-vars
              var workingArray = [];
              if (this.visible) {
                // Add to hide list
                workingArray = [...seriesHidden, ailment_title];
              } else {
                // Remove from hide list
                workingArray = seriesHidden.filter((e) => e !== ailment_title);
              }
              setSeriesHidden(workingArray);
            },
          },
        },
      },
    };
  }, [seriesHidden, setSeriesHidden]);

  // Get graph data dependant on day number
  useEffect(() => {
    const processDayData = (simData) => {
      // Build a dataset for each of the ailments
      var ailmentNumber = 0;
      dsSimAilments &&
        dsSimAilments.length > 0 &&
        simData &&
        simData.length > 0 &&
        setMyDataSeries(
          dsSimAilments
            .filter((r) => r.emergency === false)
            .map((ailment) => ({
              name: ailment.title,
              color: ailment.rpt_colour
                ? ailment.rpt_colour
                : ColoursList(ailmentNumber++),
              data: simData
                .filter((data) => {
                  // Older data does not have the ailment_id so use the title
                  if (data.fk_sim_ailment_id)
                    return data.fk_sim_ailment_id === ailment.id;
                  else return data.ailment === ailment.title;
                })
                .map((data) => ({
                  y: data.patient_count,
                  x: data.current_week_duration,
                })),
              dataLabels: { enabled: false },
              pointWidth: 15,
            }))
        );
    };
    if (viewDay > 0) {
      // This is for the results from the model
      getReportData(
        simulation_id,
        1,
        viewDay - dayShift,
        (simData) => {
          // Work out distinct ailments
          // var ailments = [];
          // try {
          //   ailments = [...new Set(simData.map((item) => item.ailment))].sort();
          // } catch (error) {}
          processDayData(simData);
        },
        (error) => {
          console.log("simData Error: ", error);
        }
      );
    } else {
      // This is for the PTL list - NOT results
      getReportData(
        simulation_id,
        2,
        0,
        (simData) => {
          // console.log("simData: ", simData);
          processDayData(simData);
        },
        (error) => {
          console.log("simData Error: ", error);
        }
      );
    }
  }, [viewDay, simulation_id, isRefreshNeeded, dsSimAilments]);

  // Set the dynamic data based config here.
  useEffect(() => {
    if (summaryData && summaryData.max_patient_count) {
      setOptions({
        ...static_config(),
        yAxis: { ...static_config().yAxis, max: summaryData.max_patient_count },
        xAxis: {
          ...static_config().xAxis,
          end: summaryData.max_week_duration,
          max: summaryData.max_week_duration,
        },
        series: myDataSeries,
      });
    }
  }, [myDataSeries, static_config, summaryData]);

  // *************************************************************************************************************
  // Patient number + Average wait time
  // *************************************************************************************************************
  useEffect(() => {
    if (viewDay > 0) {
      // Rpt 3 patient numbers
      getReportData(
        simulation_id,
        3,
        viewDay - 1,
        (simData) => {
          let result = 0;
          simData &&
            simData.forEach((e) => {
              if (!(seriesHidden.indexOf(e.ailment) > -1)) {
                result += e.ptl;
              }
            });
          setStrPTLNumber(numberWithCommas(Math.trunc(result)));
        },
        (error) => {
          console.log("simData Error: ", error);
        }
      );
      // Rpt 4 avg wait time
      getReportData(
        simulation_id,
        4,
        viewDay - 1,
        (simData) => {
          let result = 0;
          // patient_wait_average
          // patient_wait_count
          if (simData && simData.length > 0) {
            let subData = simData.filter(
              (e) => !(seriesHidden.indexOf(e.ailment) > -1)
            );
            var total_N = 0;
            var total_N_Avg = 0;
            subData.forEach((e) => {
              total_N += e.patient_wait_count;
              total_N_Avg += e.patient_wait_average * e.patient_wait_count;
            });
            // Work out the week
            result = (total_N_Avg / total_N / 7).toFixed(1);
            if (!isNaN(result)) {
              setStrAvgWaitNumber(result);
            } else {
              setStrAvgWaitNumber(0);
            }
          } else {
            setStrAvgWaitNumber(0);
          }
        },
        (error) => {
          console.log("simData Error: ", error);
        }
      );
    } else {
      // This is the ptl - diff calcs
      getReportDataSummary(
        simulation_id,
        2,
        (simData) => {
          // console.log("simData: ", simData);
          if (simData && simData.targets && simData.targets.length > 0) {
            let subData = simData.targets.filter(
              (e) => !(seriesHidden.indexOf(e.ailment) > -1)
            );
            //setStrAvgWaitNumber(123);
            //setStrPTLNumber(321);
            var total_N = 0;
            var total_N_Avg = 0;
            subData.forEach((e) => {
              total_N += e.patient_wait_count;
              total_N_Avg += e.patient_wait_average * e.patient_wait_count;
            });
            // Work out the week
            var result = (total_N_Avg / total_N / 7).toFixed(1);
            if (!isNaN(result)) {
              setStrAvgWaitNumber(result);
            } else {
              setStrAvgWaitNumber(0);
            }
            setStrPTLNumber(numberWithCommas(total_N));
          } else {
            setStrAvgWaitNumber(0);
            setStrPTLNumber(0);
          }
        },
        (error) => {
          console.log("simDataSummary Error: ", error);
        }
      );
    }
  }, [viewDay, simulation_id, isRefreshNeeded, seriesHidden]);
  // *************************************************************************************************************

  return (
    <>
      <div>
        <div>
          <HighchartsReact
            ref={chartComponentRef}
            highcharts={Highcharts}
            options={options}
          />
        </div>
      </div>
      <div className={styles.rowRpt}>
        <div style={{ marginRight: "25px" }}>
          <ColouredTextBox
            // backgroundColor={BoxColour}
            backgroundColor="transparent"
            color="#408EC6"
            number={strPTLNumber}
            text={"Total Patients"}
            boarder="true"
            boarderColour="#408EC6"
          />
        </div>
        <div style={{ marginLeft: "25px" }}>
          <ColouredTextBox
            // backgroundColor={BoxColour}
            backgroundColor="transparent"
            color="#408EC6"
            number={strAvgWaitNumber}
            text={"Av. Wait Time (weeks)"}
            // unitText="weeks"
            boarder="true"
            boarderColour="#408EC6"
          />
        </div>
      </div>
    </>
  );
};

export default RptModelResults;
