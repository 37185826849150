import React, { useState, useCallback, useEffect } from "react";
// import { useRef } from "react";
import ProgressBarWithLabel from "../ProgressBarWithLabel";
import { Spinner } from "react-bootstrap";
import { getModellingStatus } from "../../api/ApiPtlCalls";
import progress from "./progress.module.scss";

/// This is specific to a simulation
const StatusOfProcess = ({ sim_guid, isActive }) => {
  const [active, setActive] = useState(isActive); // eslint-disable-line no-unused-vars
  // const active = useRef(isActive);
  const [modelPercent, setModelPercent] = useState(1);
  const [messages, setMessages] = useState([]);
  // useCallback causes this to be remembered during the cycling
  // It is used in this context in combination with useEffect
  const localAddMessage = useCallback(
    (messageResult) => {
      try {
        if (!messages.includes(messageResult.model_processing_status)) {
          setMessages([messageResult.model_processing_status]);
        }
        setModelPercent(Math.round(messageResult.percentage));
      } catch (error) {
        setMessages();
        setModelPercent(Math.round(0));
      }
    },
    [messages, setModelPercent, setMessages]
  );
  // When the model code is running this should run to return the progress
  useEffect(() => {
    // if (active.current) {
    if (active) {
      const interval = setInterval(() => {
        try {
          // Get the status for this simulation.
          getModellingStatus(
            sim_guid,
            (result) => {
              //Success
              localAddMessage(result);
            },
            (error) => {
              localAddMessage(JSON.stringify(error, null, 2));
              setMessages(["There was an error: " + error]);
            }
          );
        } catch (e) {
          setMessages(["There was an error: " + e.message]);
        }
      }, 2000);
      return () => clearInterval(interval);
    }
  }, [active, localAddMessage, sim_guid]);

  return (
    <div className={progress.activeSpinner}>
      {active && (
        <div>
          <div className={progress.spinnerIcon}>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              className={progress.spinner}
            />
          </div>
          <h1>Processing...</h1>
          {modelPercent && modelPercent !== "0" && (
            <ProgressBarWithLabel value={modelPercent} />
          )}
        </div>
      )}
      {messages &&
        messages.map((msg, index) => (
          <div key={index} className="alert alert-success">
            {msg}
          </div>
        ))}
    </div>
  );
};
export default StatusOfProcess;
