import React from "react";
import styles from "../scss/chart.module.scss";

const ChartSvg = ({ percentage = 1, color = "#ff00ff" }) => {
  if (isNaN(percentage) || typeof percentage === "undefined") {
    percentage = 0;
  }
  const radius = 30; // Radius of the circle
  const circumference = 2 * Math.PI * radius;

  //   const adjustedPercentage = percentage;
  const dasharray = `${(percentage / 100) * circumference} ${circumference}`;
  const text = percentage.toString() + "%";

  return (
    <React.Fragment>
      <div
        data-testid="chartsvg-1"
        className={`${styles.diagram} ${styles.diagram_container}`}
      >
        <svg
          viewBox="0 0 70 70"
          xmlns="http://www.w3.org/2000/svg"
          className={`${styles.rotate_me}`}
        >
          <circle
            cx="35"
            cy="35"
            r={radius}
            className={`${styles.diagram__path} ${styles.diagram__path_full}`}
          ></circle>
          <circle
            cx="35"
            cy="35"
            r={radius}
            // stroke-dashoffset={0}
            strokeDashoffset={0}
            // stroke-dasharray={dasharray}
            strokeDasharray={dasharray}
            className={`${styles.diagram__path} ${styles.diagram__path_progress}`}
            stroke={color}
          ></circle>
        </svg>
        <div className={styles.diagram__text}>{text}</div>
      </div>
    </React.Fragment>
  );
};

export default ChartSvg;
