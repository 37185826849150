import React, { useState, useContext, useEffect, useRef } from "react";
import {
  Model01RptRefreshContext,
  Model01IsDirty,
  Model01PARptRefreshContext,
} from "../Model01";
import { getReportDataSummary } from "../../../api/ApiPtlCalls";
import Settings from "./Settings";
import { ModalSimInputContext } from "../Model01Tabs";
import style from "../../../scss/style.module.scss";
import Table from "react-bootstrap/Table";
import styles from "../../../scss/style.module.scss";
// import table from "../../../table.module.scss";

const TabRptBarPA = ({ tabNo, simulation_id }) => {
  const validateTab = useContext(ModalSimInputContext);
  const [ctlValidation, setCtlValidation] = useState([false]);
  const ctlValid = (controlNumber, value) => {
    ctlValidation[controlNumber] = value;
    setCtlValidation(ctlValidation);
    validateTab(
      tabNo,
      ctlValidation.every((element) => element === true)
    );
  };

  const [isRefreshNeeded, refreshNeeded] = useContext(Model01RptRefreshContext); // eslint-disable-line no-unused-vars
  // eslint-disable-next-line no-unused-vars
  const [isPARefreshNeeded, paRefreshNeeded] = useContext(
    Model01PARptRefreshContext
  );
  const [isDirty, setIsDirty] = useContext(Model01IsDirty); // eslint-disable-line no-unused-vars
  const [reportData8, setReportData8] = useState({});
  const [colCount, setColCount] = useState(0); // eslint-disable-line no-unused-vars

  const theatreSessions = useRef([]);

  // Get the unique theatres sessions for reporting.
  function addTheatreSessionIfNotExist(id, title) {
    if (theatreSessions.current.length === 0) {
      theatreSessions.current.push({ id, title });
    } else {
      const objIndex = theatreSessions.current.findIndex(
        (obj) => obj.id === id
      );

      if (objIndex === -1) {
        theatreSessions.current.push({ id, title });
      }
    }
  }

  useEffect(() => {
    getReportDataSummary(
      simulation_id,
      8,
      (simData) => {
        // Validate the results - if there is a blank year at the start remove it.

        if (simData && simData[0]) {
          if (
            !(
              simData[0].data &&
              simData[0].data.length &&
              simData[0].data.length > 0
            )
          ) {
            // We have a blank column
            simData.shift();
          }
        }

        setReportData8(simData);
        const yearCount = simData.length;
        setColCount(yearCount);
        for (let index = 0; index < yearCount; index++) {
          const element = simData[index];
          element.data.forEach((r) => {
            // addTheatreSession(r.fk_sim_theatre_type_id, r.title);
            addTheatreSessionIfNotExist(r.fk_sim_theatre_type_id, r.title);
          });
        }
      },
      (error) => {
        console.log("simDataSummary Error: ", error);
      }
    );
  }, [simulation_id, isRefreshNeeded, isPARefreshNeeded]);

  const colHeader = (startDate) => {
    let year = startDate.substring(2, 4);
    let result = year.toString() + "/" + (parseInt(year) + 1).toString();
    return result;
  };

  const colRowCount = (row, title, fieldName) => {
    var result = 0;
    if (Array.isArray(row.data) && row.data.length > 0) {
      row.data.forEach((r) => {
        if (r.title === title) {
          result = parseFloat(r[fieldName]);
        }
      });
    }
    return roundCheck(result);
  };

  const colRowCountTwo = (row, title, fieldName, fieldNameTwo) => {
    var result = 0;
    if (Array.isArray(row.data) && row.data.length > 0) {
      row.data.forEach((r) => {
        if (r.title === title) {
          result = parseFloat(r[fieldName]) + parseFloat(r[fieldNameTwo]);
        }
      });
    }
    return roundCheck(result);
  };

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  const roundCheck = (x) => {
    var decimal = x - Math.floor(x);
    if (decimal > 0) return Number.parseFloat(x).toFixed(2);
    else return numberWithCommas(x);
  };

  const colTotal = (row, fieldName) => {
    var result = 0;
    if (Array.isArray(row.data) && row.data.length > 0) {
      row.data.forEach((r) => {
        result += parseFloat(r[fieldName]);
      });
    }
    return roundCheck(result);
  };

  const colTotalTwo = (row, fieldName, fieldNameTwo) => {
    var result = 0;
    if (Array.isArray(row.data) && row.data.length > 0) {
      row.data.forEach((r) => {
        result += parseFloat(r[fieldName]) + parseFloat(r[fieldNameTwo]);
      });
    }
    return roundCheck(result);
  };

  return (
    <>
      <div className={styles.flexColumn}>
        <div>
          <Settings ctrlValid={ctlValid} ctrlNumber="0" displayType="pa" />
        </div>
      </div>

      <div
        className={`${style.flexRow} ${style.fullWidth}`}
        style={{ marginBottom: "35px" }}
      >
        <div
          // className={style.colouredBlock}
          style={{ width: "calc(100% - 10px)", overflowX: "scroll" }}
        >
          <h3>PAs</h3>
          <Table borderless="true" className="table table-fixed">
            <thead>
              <tr>
                <th style={{ minWidth: "450px" }}></th>
                {reportData8 &&
                  reportData8.length > 0 &&
                  reportData8.map((msg, index) => (
                    <React.Fragment key={index}>
                      <th style={{ minWidth: "50px" }}></th>
                      <th style={{ minWidth: "50px" }}></th>
                      <th style={{ minWidth: "50px", paddingRight: "150px" }}>
                        {colHeader(msg.start_date)}
                      </th>
                    </React.Fragment>
                  ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Weeks</td>
                {reportData8 &&
                  reportData8.length > 0 &&
                  reportData8.map((msg, index) => (
                    <React.Fragment key={index}>
                      <td></td>
                      <td></td>
                      <td>{msg.period_weeks}</td>
                    </React.Fragment>
                  ))}
              </tr>
              <tr>
                <td></td>
              </tr>
              <tr>
                <td>
                  <b>Theatre Sessions (number)</b>
                </td>
              </tr>

              {theatreSessions &&
                Array.isArray(theatreSessions.current) &&
                theatreSessions.current.map((msg, index) => (
                  <React.Fragment key={index}>
                    <tr>
                      <td>{msg.title}</td>
                      {reportData8.map((r, index) => (
                        <React.Fragment key={index}>
                          <td></td>
                          <td></td>
                          <td>{colRowCount(r, msg.title, "session_count")}</td>
                        </React.Fragment>
                      ))}
                    </tr>
                  </React.Fragment>
                ))}

              <tr>
                <td>Total</td>
                {reportData8 &&
                  reportData8.length > 0 &&
                  reportData8.map((r, index) => (
                    <React.Fragment key={index}>
                      <td></td>
                      <td></td>
                      <td>{colTotal(r, "session_count")}</td>
                    </React.Fragment>
                  ))}
              </tr>

              <tr>
                <td></td>
              </tr>
              <tr>
                <td>
                  <b>Theatre Sessions (hours)</b>
                </td>
                {reportData8 &&
                  reportData8.length > 0 &&
                  reportData8.map((msg, index) => (
                    <React.Fragment key={index}>
                      <td>Norm</td>
                      <td>Prem</td>
                      <td>
                        <b>Total</b>
                      </td>
                    </React.Fragment>
                  ))}
              </tr>

              {theatreSessions &&
                Array.isArray(theatreSessions.current) &&
                theatreSessions.current.map((msg, index) => (
                  <React.Fragment key={index}>
                    <tr>
                      <td>{msg.title}</td>
                      {reportData8.map((r, index) => (
                        <React.Fragment key={index}>
                          <td>
                            {colRowCount(r, msg.title, "total_normal_hours")}
                          </td>
                          <td>
                            {colRowCount(r, msg.title, "total_premier_hours")}
                          </td>
                          <td>
                            {colRowCountTwo(
                              r,
                              msg.title,
                              "total_normal_hours",
                              "total_premier_hours"
                            )}
                          </td>
                        </React.Fragment>
                      ))}
                    </tr>
                  </React.Fragment>
                ))}

              <tr>
                <td>Total</td>
                {reportData8 &&
                  reportData8.length > 0 &&
                  reportData8.map((r, index) => (
                    <React.Fragment key={index}>
                      <td>{colTotal(r, "total_normal_hours")}</td>
                      <td>{colTotal(r, "total_premier_hours")}</td>
                      <td>
                        {colTotalTwo(
                          r,
                          "total_normal_hours",
                          "total_premier_hours"
                        )}
                      </td>
                    </React.Fragment>
                  ))}
              </tr>

              <tr>
                <td></td>
              </tr>
              <tr>
                <td>
                  <b>Total Actual PAs</b>
                </td>
              </tr>

              {theatreSessions &&
                Array.isArray(theatreSessions.current) &&
                theatreSessions.current.map((msg, index) => (
                  <React.Fragment key={index}>
                    <tr>
                      <td>{msg.title}</td>
                      {reportData8.map((r, index) => (
                        <React.Fragment key={index}>
                          <td>{colRowCount(r, msg.title, "normal_pa")}</td>
                          <td>{colRowCount(r, msg.title, "premier_pa")}</td>
                          <td>
                            {colRowCountTwo(
                              r,
                              msg.title,
                              "normal_pa",
                              "premier_pa"
                            )}
                          </td>
                        </React.Fragment>
                      ))}
                    </tr>
                  </React.Fragment>
                ))}

              <tr>
                <td>Total</td>
                {reportData8 &&
                  reportData8.length > 0 &&
                  reportData8.map((r, index) => (
                    <React.Fragment key={index}>
                      <td>{colTotal(r, "normal_pa")}</td>
                      <td>{colTotal(r, "premier_pa")}</td>
                      <td>{colTotalTwo(r, "normal_pa", "premier_pa")}</td>
                    </React.Fragment>
                  ))}
              </tr>

              <tr>
                <td></td>
              </tr>
              <tr>
                <td>
                  <b>Average Weekly Contracted PAs</b>
                </td>
              </tr>
              {theatreSessions &&
                Array.isArray(theatreSessions.current) &&
                theatreSessions.current.map((msg, index) => (
                  <React.Fragment key={index}>
                    <tr>
                      <td>{msg.title}</td>
                      {reportData8.map((r, index) => (
                        <React.Fragment key={index}>
                          <td>
                            {colRowCount(
                              r,
                              msg.title,
                              "normal_pa_contract_weekly"
                            )}
                          </td>
                          <td>
                            {colRowCount(
                              r,
                              msg.title,
                              "premier_pa_contract_weekly"
                            )}
                          </td>
                          <td>
                            {colRowCountTwo(
                              r,
                              msg.title,
                              "normal_pa_contract_weekly",
                              "premier_pa_contract_weekly"
                            )}
                          </td>
                        </React.Fragment>
                      ))}
                    </tr>
                  </React.Fragment>
                ))}
              <tr>
                <td>Total</td>
                {reportData8 &&
                  reportData8.length > 0 &&
                  reportData8.map((r, index) => (
                    <React.Fragment key={index}>
                      <td>{colTotal(r, "normal_pa_contract_weekly")}</td>
                      <td>{colTotal(r, "premier_pa_contract_weekly")}</td>
                      <td>
                        {colTotalTwo(
                          r,
                          "normal_pa_contract_weekly",
                          "premier_pa_contract_weekly"
                        )}
                      </td>
                    </React.Fragment>
                  ))}
              </tr>

              <tr>
                <td></td>
              </tr>
              <tr>
                <td>
                  <b>Consultant FTEs</b>
                </td>
              </tr>
              {theatreSessions &&
                Array.isArray(theatreSessions.current) &&
                theatreSessions.current.map((msg, index) => (
                  <React.Fragment key={index}>
                    <tr>
                      <td>{msg.title}</td>
                      {reportData8.map((r, index) => (
                        <React.Fragment key={index}>
                          <td>
                            {colRowCount(r, msg.title, "normal_consultant")}
                          </td>
                          <td>
                            {colRowCount(r, msg.title, "premier_consultant")}
                          </td>
                          <td>
                            {colRowCountTwo(
                              r,
                              msg.title,
                              "normal_consultant",
                              "premier_consultant"
                            )}
                          </td>
                        </React.Fragment>
                      ))}
                    </tr>
                  </React.Fragment>
                ))}
              <tr>
                <td>Total</td>
                {reportData8 &&
                  reportData8.length > 0 &&
                  reportData8.map((r, index) => (
                    <React.Fragment key={index}>
                      <td>{colTotal(r, "normal_consultant")}</td>
                      <td>{colTotal(r, "premier_consultant")}</td>
                      <td>
                        {colTotalTwo(
                          r,
                          "normal_consultant",
                          "premier_consultant"
                        )}
                      </td>
                    </React.Fragment>
                  ))}
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
};

export default TabRptBarPA;
