// https://webomnizz.com/create-simple-modal-pop-up-with-react/
import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import modalStyles from "./modal.module.scss";
const ModalConfirm = ({
  handleClose,
  show,
  title,
  desc,
  confirmed,
  OkOnly = false,
}) => {
  return (
    <Modal
      className={modalStyles.modal}
      size="lg"
      centered="true"
      show={show}
      onHide={handleClose}
    >
      <Modal.Header className={modalStyles.modalHeader} closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{ whiteSpace: "pre-line" }}
        className={`${modalStyles.modalBody} ${modalStyles.scrollable}`}
      >
        {desc}
      </Modal.Body>
      <Modal.Footer className={modalStyles.modalFooter}>
        {OkOnly === false ? (
          <>
            <Button variant="light" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={confirmed}>
              Confirm
            </Button>
          </>
        ) : (
          <Button variant="light" onClick={handleClose}>
            OK
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default ModalConfirm;
