import { newPasswordEMailUser } from "../api/ApiCoreCalls";

export function emailUserForPassword(userId, eMail) {
  console.log("Made it with id: " + userId + "  " + eMail);
  // Work out the correct url for the email
  var urlForNewEmail =
    window.location.protocol + "//" + window.location.hostname;
  if (window.location.port > 0) {
    urlForNewEmail += ":" + window.location.port;
  }
  urlForNewEmail += "/api/newpassword";

  var myData = {
    userId,
    href: urlForNewEmail,
  };
  newPasswordEMailUser(
    myData,
    (result) => {
      console.log(result);
    },
    (error) => {
      console.log(error);
    }
  );
  return true;
}
