import React, { useState, useEffect } from "react";
import table from "../../../../table.module.scss";
import styles from "../../../../scss/style.module.scss";
import Table from "react-bootstrap/Table";
import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { dayString } from "../../../../common/urls";
import { roundNumber, hideIfZero } from "../../../../common/math_functions";
import { GoEye } from "react-icons/go";
import ModalLayout from "../../../modal/Modal";
import ReportDataPopup from "./ReportDataPopup";
import DownloadCSVTemplate from "./DownloadCSVTemplate";

const ReportResultsTable = ({ summaryData, rptType }) => {
  const [ct_reports, setCt_reports] = useState(0);
  const [mri_reports, setMri_reports] = useState(0);
  const [radiology, setRadiology] = useState(0);
  const [other, setOther] = useState(0);
  const [total, setTotal] = useState(0);
  const [countSession, setCountSession] = useState(0);
  const [exam, setExam] = useState(0);
  const [pa_standard, setPa_standard] = useState(0);
  const [pa_premium, setPa_premium] = useState(0);

  const [popupJobPlanId, setPopupJobPlanId] = useState({ show: false, id: "" });
  const modalClose = () => {
    setPopupJobPlanId({ show: false, id: "" });
  };

  useEffect(() => {
    // Initialize totals
    let ct_reports = 0;
    let mri_reports = 0;
    let radiology_reports = 0;
    let other_reports = 0;
    let total_reports = 0;
    let session_count = 0;
    let total_exam_time = 0;

    let pa_standard = 0;
    let pa_premium = 0;

    // Iterate through the filtered data
    summaryData &&
      summaryData.length > 0 &&
      summaryData
        // .filter((item) => {
        //   if (selectedName === "*") {
        //     // Allow all names for wildcard placeholders
        //     return item.form_grouping === form_group;
        //   } else if (selectedName === "%") {
        //     return (
        //       item.scheduled_consultant === true &&
        //       item.form_grouping === form_group
        //     );
        //   } else {
        //     // Check for an exact   match
        //     return (
        //       item.authoriser === selectedName &&
        //       item.form_grouping === form_group
        //     );
        //   }
        // })
        .forEach((sim) => {
          ct_reports += sim.ct_reports;
          mri_reports += sim.mri_reports;
          radiology_reports += sim.radiology_reports;
          other_reports += sim.other_reports;
          total_reports += sim.total_reports;
          session_count += sim.session_count;
          total_exam_time += sim.total_exam_time;

          pa_standard += sim.pa_standard;
          pa_premium += sim.pa_premium;
        });

    // Update the state variables with the calculated totals
    setCt_reports(ct_reports);
    setMri_reports(mri_reports);
    setRadiology(radiology_reports);
    setOther(other_reports);
    setTotal(total_reports);
    setCountSession(session_count);
    setExam(total_exam_time);
    setPa_standard(pa_standard);
    setPa_premium(pa_premium);
  }, [summaryData]);

  return (
    <>
      <div>
        <div className={styles.filterForm}>
          <div></div>
          <DownloadCSVTemplate summaryData={summaryData} />
        </div>
        <TableContainer>
          <Table className={table.dashboard} hover="true">
            <TableHead>
              <TableRow className={styles.borderedHeadRow}>
                <TableCell>Consultant</TableCell>
                <TableCell>Week day</TableCell>
                <TableCell>Category</TableCell>
                <TableCell>Start time</TableCell>
                <TableCell>End time</TableCell>
                <TableCell>Ct reports</TableCell>
                <TableCell>Mri reports</TableCell>
                <TableCell>Radiology reports</TableCell>
                <TableCell>Other reports</TableCell>
                <TableCell>Total reports</TableCell>
                <TableCell>Session count</TableCell>
                <TableCell>Total exam time</TableCell>
                <TableCell>Average session time</TableCell>
                <TableCell>Pa standard</TableCell>
                <TableCell>Pa premium</TableCell>
                <TableCell>WLI</TableCell>
                <TableCell>Details</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {summaryData &&
                summaryData.length > 0 &&
                summaryData.map((sim, index) => (
                  <TableRow key={index}>
                    <TableCell>{sim.authoriser}</TableCell>
                    <TableCell>{dayString(sim.week_day)}</TableCell>
                    <TableCell>{sim.day_category}</TableCell>
                    <TableCell>{sim.start_time}</TableCell>
                    <TableCell>{sim.end_time}</TableCell>
                    <TableCell>{sim.ct_reports}</TableCell>
                    <TableCell>{sim.mri_reports}</TableCell>
                    <TableCell>{sim.radiology_reports}</TableCell>
                    <TableCell>{sim.other_reports}</TableCell>
                    <TableCell>{sim.total_reports}</TableCell>
                    <TableCell>{sim.session_count}</TableCell>
                    <TableCell>{roundNumber(sim.total_exam_time, 3)}</TableCell>
                    <TableCell>
                      {roundNumber(sim.average_session_time, 3)}
                    </TableCell>
                    <TableCell>{sim.pa_standard}</TableCell>
                    <TableCell>{sim.pa_premium}</TableCell>
                    <TableCell>
                      {sim.waiting_list_initiative ? "Yes" : ""}
                    </TableCell>
                    <TableCell>
                      <span
                        style={{ margin: "0 10px" }}
                        role="button"
                        data-for="lock"
                        data-tip="Lock"
                        onClick={(e) => {
                          e.stopPropagation();
                          setPopupJobPlanId({ show: true, id: sim.jobplan_id });
                        }}
                      >
                        <GoEye size={15} color="#7A2048" />
                      </span>
                    </TableCell>
                  </TableRow>
                ))}

              <TableRow className={styles.borderedHeadRow}>
                <TableCell>
                  <b>Totals</b>
                </TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell>
                  <b>{ct_reports}</b>
                </TableCell>
                <TableCell>
                  <b>{mri_reports}</b>
                </TableCell>
                <TableCell>
                  <b>{radiology}</b>
                </TableCell>
                <TableCell>
                  <b>{other}</b>
                </TableCell>
                <TableCell>
                  <b>{total}</b>
                </TableCell>
                <TableCell>
                  <b>{countSession}</b>
                </TableCell>
                <TableCell>
                  <b>{hideIfZero(roundNumber(exam, 4))}</b>
                </TableCell>
                <TableCell></TableCell>
                <TableCell>
                  <b>{hideIfZero(roundNumber(pa_standard, 4))}</b>
                </TableCell>
                <TableCell>
                  <b>{hideIfZero(roundNumber(pa_premium, 4))}</b>
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <ModalLayout
          title={"Detail"}
          show={popupJobPlanId.show}
          handleClose={modalClose}
          scrollbars={true}
        >
          <ReportDataPopup id={popupJobPlanId.id} rptType={rptType} />
        </ModalLayout>
      </div>
    </>
  );
};

export default ReportResultsTable;
