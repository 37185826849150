import React, { useState, useEffect } from "react";
import {
  MdSkipPrevious,
  MdOutlineChevronLeft,
  MdPlayArrow,
  MdOutlinePause,
  MdOutlineChevronRight,
  MdSkipNext,
} from "react-icons/md";

function AudioControls({ firstDay, previousDay, nextDay, lastDay }) {
  const [isPlay, setIsPlay] = useState(false);

  // Here is the timer for the play button
  useEffect(() => {
    const interval = setInterval(() => {
      if (isPlay) nextDay();
    }, 500);
    return () => clearInterval(interval);
  }, [isPlay, nextDay]);

  return (
    <>
      <span
        role="button"
        onClick={() => {
          firstDay();
        }}
        title="First"
      >
        <MdSkipPrevious size={25} color="#008181" />
      </span>
      <span
        role="button"
        onClick={() => {
          previousDay();
        }}
        title="Previous"
      >
        <MdOutlineChevronLeft size={25} color="#008181" />
      </span>
      <span
        className={isPlay === false ? "" : "d-none"}
        role="button"
        onClick={() => {
          setIsPlay(!isPlay);
        }}
        title="Play"
      >
        <MdPlayArrow size={25} color="#008181" />
      </span>
      <span
        className={isPlay === true ? "" : "d-none"}
        role="button"
        onClick={() => {
          setIsPlay(!isPlay);
        }}
        title="Pause"
      >
        <MdOutlinePause size={25} color="#008181" />
      </span>
      <span
        role="button"
        onClick={() => {
          nextDay();
        }}
        title="Next"
      >
        <MdOutlineChevronRight size={25} color="#008181" />
      </span>
      <span
        role="button"
        onClick={() => {
          lastDay();
        }}
        title="End"
      >
        <MdSkipNext size={25} color="#008181" />
      </span>
    </>
  );
}

export default AudioControls;
