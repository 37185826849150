import React, { useState, useContext, useEffect } from "react";
import { Model01RptRefreshContext } from "../Model01";
import { getReportDataSummary } from "../../../api/ApiPtlCalls";
import RptSummaryGrid from "./RptSummaryGrid";
import RptSumTheatreTypeGrid from "./RptSumTheatreTypeGrid";
import style from "../../../scss/style.module.scss";

const TabRptBarSum = ({ tabNo, simulation_id }) => {
  const [localRptSummaryData, setRptSummaryData] = useState({}); // This is the daily patient cound, avg, weekly sessions
  const [localRptSumTheatreTypeData, setRptSumTheatreTypeData] = useState({}); // This is the daily patient cound, avg, weekly sessions
  const [isRefreshNeeded, refreshNeeded] = useContext(Model01RptRefreshContext); // eslint-disable-line no-unused-vars
  // eslint-disable-next-line no-unused-vars

  useEffect(() => {
    getReportDataSummary(
      simulation_id,
      5,
      (simData) => {
        setRptSummaryData(simData);
      },
      (error) => {
        console.log("simRptDataSummary Error: ", error);
      }
    );

    getReportDataSummary(
      simulation_id,
      7,
      (simData) => {
        setRptSumTheatreTypeData(simData);
      },
      (error) => {
        console.log("simRptDataSummary Error: ", error);
      }
    );
  }, [simulation_id, isRefreshNeeded, isRefreshNeeded]);

  return (
    <>
      <div
        className={`${style.flexRowTop} ${style.fullWidth}`}
        style={{ marginBottom: "35px" }}
      >
        <div
          // className={style.colouredBlock}
          style={{ width: "calc(50% - 10px)" }}
        >
          <h3>Summary</h3>
          <RptSummaryGrid summaryData={localRptSummaryData} />
        </div>

        <div
          // className={style.colouredBlock}
          style={{ width: "calc(50% - 10px)" }}
        >
          <h3>Summary - Theatre Type</h3>
          <RptSumTheatreTypeGrid summaryData={localRptSumTheatreTypeData} />
        </div>
      </div>
    </>
  );
};

export default TabRptBarSum;
