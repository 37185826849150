import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  numberWithCommas,
  roundNumber,
  hideIfZero,
} from "../../../../common/math_functions";
import styles from "../../../../scss/style.module.scss";
import table from "../../../../table.module.scss";

const ReportLeaveAdjustedTable = ({
  sumData,
  calcData,
  setValueAdjusted,
  vettingSum,
  vettingDuration,
  rptVetting,
  doctorCount,
}) => {
  const [expHours, setExpHours] = useState(0);
  const [expHoursGross, setExpHoursGross] = useState(0);
  const [expHoursGrossPeriod, setExpHoursGrossPeriod] = useState(0);
  const [expHoursAdj, setExpHoursAdj] = useState(0);
  const [actHours, setActHours] = useState(0);
  const [proRata, setProRata] = useState(0);
  const [holidayAdj, setHolidayAdj] = useState(0);

  let showDevNumbers = true;

  useEffect(() => {
    let actualHours =
      sumData[0].actHrs +
      sumData[1].actHrs +
      sumData[2].actHrs +
      sumData[4].actHrs +
      (rptVetting === "1" ? 0 : (vettingSum * vettingDuration) / 60);
    setActHours(actualHours);

    let expectedHours =
      sumData[0].expHrs +
      sumData[1].expHrs +
      sumData[2].expHrs +
      sumData[4].expHrs;
    setExpHours(expectedHours);

    let expectedHoursGross =
      sumData[0].expHrsGross +
      sumData[1].expHrsGross +
      sumData[2].expHrsGross +
      sumData[4].expHrsGross;

    setExpHoursGross(expectedHoursGross);

    let expectedHoursGrossPeriod =
      (expectedHoursGross / 365) * calcData.periodDays;
    setExpHoursGrossPeriod(expectedHoursGrossPeriod);

    // let proRataWorkingDays = 260 * (calcData.periodDays / 365) * doctorCount;
    let proRataWorkingDays = ((260 * doctorCount) / 365) * calcData.periodDays;
    setProRata(proRataWorkingDays);

    let holidayAdjustment =
      (proRataWorkingDays - calcData.actLeave) / proRataWorkingDays;

    setHolidayAdj(holidayAdjustment);
    setExpHoursAdj(expectedHoursGrossPeriod * holidayAdjustment);

    // let diff = calcData.expLeave - calcData.actLeave;

    setValueAdjusted(
      numberWithCommas(
        roundNumber(
          (actualHours / (expectedHoursGrossPeriod * holidayAdjustment)) * 100,
          2
        )
      )
    );
  }, [
    sumData,
    calcData,
    // setNewExpected,
    setValueAdjusted,
    vettingSum,
    vettingDuration,
    rptVetting,
    setActHours,
    setExpHours,
    doctorCount,
  ]);
  return (
    <React.Fragment>
      <TableContainer className={table.page_middle}>
        <Table className={`${table.dashboard}`} hover="true">
          <TableHead>
            <TableRow className={styles.borderedHeadRow}>
              <TableCell className={styles.colChar32}></TableCell>
              <TableCell className={styles.colNumber}>Act.</TableCell>
              <TableCell className={styles.colNumber}>Exp.</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow key={0}>
              <TableCell>Hours</TableCell>
              <TableCell>
                {numberWithCommas(roundNumber(actHours, 1))}
              </TableCell>
              <TableCell>
                {hideIfZero(numberWithCommas(roundNumber(expHoursAdj, 1)))}
              </TableCell>
            </TableRow>
            <TableRow key={-1} className={`${showDevNumbers ? "" : "d-none"}`}>
              <TableCell>
                <b>Development figures visible</b>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow
              key={1.01}
              className={`${showDevNumbers ? "" : "d-none"}`}
            >
              <TableCell>Expected Hours:</TableCell>
              <TableCell>
                {" "}
                {numberWithCommas(roundNumber(expHours, 1))}
              </TableCell>
            </TableRow>
            <TableRow key={1.5} className={`${showDevNumbers ? "" : "d-none"}`}>
              <TableCell>Expected Hours Gross (52 weeks):</TableCell>
              <TableCell>
                {" "}
                {numberWithCommas(roundNumber(expHoursGross, 2))}
              </TableCell>
            </TableRow>
            <TableRow
              key={1.48}
              className={`${showDevNumbers ? "" : "d-none"}`}
            >
              <TableCell>Period days</TableCell>
              <TableCell>
                {" "}
                {numberWithCommas(roundNumber(calcData.periodDays, 2))}
              </TableCell>
            </TableRow>
            <TableRow
              key={1.51}
              className={`${showDevNumbers ? "" : "d-none"}`}
            >
              <TableCell>
                Expected Hours Gross ({calcData.periodDays} days):
              </TableCell>
              <TableCell>
                {" "}
                {numberWithCommas(roundNumber(expHoursGrossPeriod, 2))}
              </TableCell>
            </TableRow>

            <TableRow key={1.4} className={`${showDevNumbers ? "" : "d-none"}`}>
              <TableCell>Multiplied by holiday adjustment:</TableCell>
              <TableCell>
                {" "}
                {numberWithCommas(roundNumber(holidayAdj, 2))}
              </TableCell>
            </TableRow>

            <TableRow key={1.2} className={`${showDevNumbers ? "" : "d-none"}`}>
              <TableCell>Expected Leave:</TableCell>
              <TableCell>
                {" "}
                {numberWithCommas(roundNumber(calcData.expLeave, 2))}
              </TableCell>
            </TableRow>

            <TableRow key={1.3} className={`${showDevNumbers ? "" : "d-none"}`}>
              <TableCell>Actual Leave:</TableCell>
              <TableCell>
                {" "}
                {numberWithCommas(roundNumber(calcData.actLeave, 2))}
              </TableCell>
            </TableRow>

            <TableRow
              key={1.405}
              className={`${showDevNumbers ? "" : "d-none"}`}
            >
              <TableCell>Pro rata:</TableCell>
              <TableCell>
                {" "}
                {numberWithCommas(roundNumber(proRata, 2))}
              </TableCell>
            </TableRow>

            <TableRow
              key={1.45}
              className={`${showDevNumbers ? "" : "d-none"}`}
            >
              <TableCell>
                &#160;&#160;&#160;&#160;((260 * {doctorCount}) / 365) *{" "}
                {calcData.periodDays}
              </TableCell>
              <TableCell>
                {" "}
                {numberWithCommas(roundNumber(proRata, 2))}
              </TableCell>
            </TableRow>

            <TableRow
              key={1.455}
              className={`${showDevNumbers ? "" : "d-none"}`}
            >
              <TableCell>Doctor Count:</TableCell>
              <TableCell>
                {" "}
                {numberWithCommas(roundNumber(doctorCount, 2))}
              </TableCell>
            </TableRow>

            <TableRow
              key={1.46}
              className={`${showDevNumbers ? "" : "d-none"}`}
            >
              <TableCell>
                {numberWithCommas(roundNumber(expHoursGrossPeriod, 1))} * (({" "}
                {numberWithCommas(roundNumber(proRata, 1))} -
                {numberWithCommas(roundNumber(calcData.actLeave, 1))}) /{" "}
                {numberWithCommas(roundNumber(proRata, 1))} )
              </TableCell>
              <TableCell>
                {" "}
                {numberWithCommas(roundNumber(expHoursAdj, 2))}
              </TableCell>
            </TableRow>

            <TableRow
              key={1.47}
              className={`${showDevNumbers ? "" : "d-none"}`}
            >
              <TableCell>
                {numberWithCommas(roundNumber(actHours, 2))} /{" "}
                {numberWithCommas(roundNumber(expHoursAdj, 2))}
              </TableCell>
              <TableCell>
                {" "}
                {numberWithCommas(
                  roundNumber((actHours / expHoursAdj) * 100, 2)
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
};

export default ReportLeaveAdjustedTable;
