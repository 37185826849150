import React, { useEffect, useState, useContext, useRef } from "react";
import { Link, useNavigate } from "react-router-dom"; // Used to prevent a re-load
import { GoEye } from "react-icons/go";
import { FaTrash, FaLock, FaLockOpen } from "react-icons/fa";
import { IoDuplicateOutline } from "react-icons/io5";
import table from "../../table.module.scss";
import { CurrentApplicationShowing } from "../contexts/CurrentAppContext";
import {
  getSimulationList,
  delSimulation,
  duplicateSimulation,
  lockSimulation,
} from "../../api/ApiPtlCalls";
import { getUsersList } from "../../api/ApiCoreCalls";
import ModalConfirm from "../modal/ModalConfirm";
import { HeaderTitle } from "../../App";
import ReactTooltip from "react-tooltip";
import { TableRow, Table, TablePagination, TableFooter } from "@mui/material";
import TablePaginationActions from "../TablePagination";
import ModalLayout from "../modal/Modal";

import moment from "moment";

const Model1_list = ({ filter, refresh, refreshed }) => {
  let navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const [simName, setSimName, lastSaved, setLastSaved, backUrl, setBackUrl] =
    useContext(HeaderTitle); // eslint-disable-line no-unused-vars

  // eslint-disable-next-line no-unused-vars
  const { activeApp, setActiveApp } = useContext(CurrentApplicationShowing);
  const [simList, setsimList] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState({
    show: false,
    id: NaN,
    desc: "Simuation will permanently be deleted",
  });
  const [showLockModal, setShowLockModal] = useState({
    show: false,
    id: NaN,
    desc: "Simuation will be locked and can only be locked by you",
    locked: false,
  });
  const [showErrorModal, setShowErrorModal] = useState({
    show: false,
    title: "",
    desc: "Simuation will be locked and can only be locked by you",
  });

  // <Setings for the user formatable grid 20240206 NAJ>
  // Initialize state with value from localStorage if available
  const [rowsPerPage, setRowsPerPage] = useState(() => {
    const saved = localStorage.getItem("md1RowsPerPage");
    return saved !== null ? JSON.parse(saved) : -1;
  });
  const [page, setPage] = useState(() => {
    const saved = localStorage.getItem("md1Page");
    return saved !== null ? JSON.parse(saved) : 0;
  });
  // Update localStorage when rowsPerPage changes
  useEffect(() => {
    localStorage.setItem("md1RowsPerPage", JSON.stringify(rowsPerPage));
  }, [rowsPerPage]);
  useEffect(() => {
    localStorage.setItem("md1Page", JSON.stringify(page));
  }, [page]);
  // </Setings for the user formatable grid 20240206 NAJ>

  const jsonResults = useRef({});

  const simListToDisplay = simList
    ?.filter((s) => s.title.toUpperCase().indexOf(filter.toUpperCase()) > -1)
    ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const viewSim = (simId) => {
    let path = `/md01/${simId}`;
    navigate(path);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Get a list of unique userId so their names can be looked up.
  const GetUserIdArray = (result) => {
    const uniqueUserIds = new Set();

    // Iterate over each object in the JSON list
    result.forEach((obj) => {
      // Add the fkuserid value to the uniqueUserIds set
      uniqueUserIds.add(obj.FkUserId);
    });

    // Convert the set to an array and return it
    return Array.from(uniqueUserIds);
  };

  // Using the userDictionary
  function appendUserName(simJson, userNameDictionary) {
    simJson.forEach((obj) => {
      const userId = obj.FkUserId;
      const user = userNameDictionary[userId];

      // Add the 'UserName' field to the object
      obj.UserName = user;
    });
    return simJson;
  }

  // We use an array of userId to get a username dictionary
  const populateListWithUserDetails = (results) => {
    // Work out the Ids
    let aUserIds = GetUserIdArray(results);
    // Lookup the names
    getUsersList(
      aUserIds,
      (userNameDictionary) => {
        let debugView = appendUserName(jsonResults.current, userNameDictionary);
        setsimList(debugView);
        setSimName("");
        refreshed();
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const populateList = () => {
    getSimulationList(
      (result) => {
        // Setting this here in case there are errors on the user look up.
        jsonResults.current = result;
        populateListWithUserDetails(result);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  useEffect(() => {
    setActiveApp("ptl");
    populateList();
    setBackUrl("/");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const rowDelete = () => {
    // ToDo: NAJ
    // alert("Under development id: " + showDeleteModal.id);
    modalDeleteClose();

    delSimulation(
      showDeleteModal.id,
      () => {
        // Remove the item from the array
        var myArray = simList.filter((a) => {
          return a.id !== showDeleteModal.id;
        });
        setsimList(myArray);
      },
      (error) => {
        console.log(error);
      }
    );
  };
  const modalDeleteClose = () => {
    setShowDeleteModal(false);
  };
  const modalLockClose = () => {
    setShowLockModal(false);
  };
  const modalClose = () => {
    setShowErrorModal(false);
  };
  const onDuplicateSimulation = (guid) => {
    // alert("Duplicate it now " + guid);
    duplicateSimulation(
      guid,
      (res) => {
        // Refresh this form
        populateList();
        if (res && res.id) viewSim(res.id);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const rowLock = () => {
    modalLockClose();
    lockSimulation(
      showLockModal.id,
      (response) => {
        // { "Result": "Failure", "Message": "Only user angus@staffingscience.co.uk, who locked the simulation, can unlock it." }
        if (response.Result === "Success") {
          // Update the item from the array
          var myArray = simList.map((a) => {
            if (a.id !== showLockModal.id) return a;
            else a.locked = !showLockModal.locked;
            return a;
          });
          setsimList(myArray);
          populateList();
        } else {
          setShowErrorModal({
            show: true,
            title: "Lock action not successful",
            desc: response.Message,
          });
          // alert(response.Message);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  function roundNumber(num) {
    let roundedNum = parseFloat(num.toFixed(1));
    return Number.isInteger(roundedNum)
      ? numberWithCommas(roundedNum.toFixed(0))
      : numberWithCommas(roundedNum);
  }

  return (
    <>
      <div>
        {refresh && populateList()}
        <Table className={table.dashboard} hover="true">
          <thead>
            <tr>
              <th className="table_date_col">Last Updated</th>
              <th className="table_text_small_col">Creator</th>
              <th className="table_text_col">Title</th>
              <th className="table_text_col">Patient Groups</th>
              <th className="table_date_col">Start Date</th>
              <th className="table_date_col">End Date</th>
              <th className="table_number_col">Av Start Wait</th>
              <th className="table_number_col">Av End Wait</th>
              <th className="table_number_col">Start Size</th>
              <th className="table_number_col">End Size</th>
              <th className="table_two_buttons_col"></th>
            </tr>
          </thead>
          <tbody>
            {simListToDisplay &&
              simListToDisplay.map((sim) => (
                <tr key={sim.id} onClick={() => viewSim(sim.id)}>
                  <td style={{ width: "200px" }}>
                    {sim.last_updated_dtg
                      ? moment(sim.last_updated_dtg).format("DD-MM-YYYY")
                      : "New"}
                  </td>
                  <td>{sim.UserName}</td>
                  <td>{sim.title}</td>
                  <td>{sim.patient_groups}</td>
                  <td>{moment(sim.model_start_dtg).format("DD-MM-YYYY")}</td>
                  <td>{moment(sim.model_end_dtg).format("DD-MM-YYYY")}</td>
                  <td>{roundNumber(sim.rpt_start_wait)}</td>
                  <td>{roundNumber(sim.rpt_end_wait)}</td>
                  <td>{roundNumber(sim.rpt_start_size)}</td>
                  <td>{roundNumber(sim.rpt_end_size)}</td>

                  <td>
                    <ReactTooltip
                      id="edit"
                      place="top"
                      backgroundColor="#7a2048"
                    />
                    <Link
                      data-for="edit"
                      data-tip="View"
                      to={`/md01/${sim.id}`}
                    >
                      <GoEye size={20} color="#008181" />
                    </Link>

                    <ReactTooltip
                      id="lock"
                      place="top"
                      backgroundColor="#7a2048"
                    />
                    {sim.locked ? (
                      <>
                        <span
                          style={{ margin: "0 10px" }}
                          role="button"
                          data-for="lock"
                          data-tip={"Locked by: " + sim.locked_by_user_name}
                          onClick={(e) => {
                            e.stopPropagation();
                            var description =
                              "Simuation " +
                              sim.title +
                              ", last updated " +
                              sim.last_updated_dtg.substring(0, 10);
                            description += " will be un-locked and editable";
                            setShowLockModal({
                              show: true,
                              id: sim.id,
                              desc: description,
                              locked: sim.locked,
                            });
                          }}
                        >
                          <FaLock size={20} color="#7A2048" />
                        </span>
                      </>
                    ) : (
                      <>
                        <span
                          style={{ margin: "0 10px" }}
                          role="button"
                          data-for="lock"
                          data-tip="Lock"
                          onClick={(e) => {
                            e.stopPropagation();
                            var description =
                              "Simuation " +
                              sim.title +
                              ", last updated " +
                              sim.last_updated_dtg.substring(0, 10);
                            description += " will be locked and read-only";

                            setShowLockModal({
                              show: true,
                              id: sim.id,
                              desc: description,
                              locked: sim.locked,
                            });
                          }}
                        >
                          <FaLockOpen size={20} color="#7A2048" />
                        </span>
                      </>
                    )}
                    <ReactTooltip
                      id="delete"
                      place="top"
                      backgroundColor="#7a2048"
                    />
                    <span
                      style={{ margin: "0 10px" }}
                      role="button"
                      data-for="delete"
                      data-tip="Delete"
                      onClick={(e) => {
                        e.stopPropagation();
                        if (!sim.locked) {
                          setShowDeleteModal({
                            show: true,
                            id: sim.id,
                            desc:
                              "Simuation " +
                              sim.title +
                              ", last updated " +
                              sim.last_updated_dtg.substring(0, 10) +
                              " will permanently be deleted",
                          });
                        } else {
                          setShowErrorModal({
                            title: "Cannot delete.",
                            desc: "This simulation cannot be deleted as it is currently locked.",
                            show: true,
                          });
                        }
                      }}
                    >
                      <FaTrash size={20} color="#7A2048" />
                    </span>
                    <ReactTooltip
                      id="duplicate"
                      place="top"
                      backgroundColor="#7a2048"
                    />
                    <span
                      // className="d-none"
                      data-for="duplicate"
                      data-tip="Duplicate"
                      role="button"
                      onClick={(e) => {
                        e.stopPropagation();
                        onDuplicateSimulation(sim.id);
                      }}
                    >
                      <IoDuplicateOutline size={20} color="#008181" />
                    </span>
                  </td>
                </tr>
              ))}
          </tbody>
          <TableFooter className={table.reportTableFooter}>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50, { label: "All", value: -1 }]}
                count={simList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </div>
      <ModalConfirm
        show={showDeleteModal.show}
        handleClose={modalDeleteClose}
        title="Please confirm you want to delete this simulation"
        desc={showDeleteModal.desc}
        confirmed={rowDelete}
      ></ModalConfirm>
      <ModalConfirm
        show={showLockModal.show}
        handleClose={modalLockClose}
        title="Please confirm the lock action"
        desc={showLockModal.desc}
        confirmed={rowLock}
      ></ModalConfirm>
      <ModalLayout
        title={showErrorModal.title}
        show={showErrorModal.show}
        handleClose={modalClose}
      >
        <span>{showErrorModal.desc}</span>
      </ModalLayout>
    </>
  );
};

export default Model1_list;
