import React from "react";

// Nice tutorial from:
// https://www.youtube.com/watch?v=u-CCnDayNJw
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Button } from "react-bootstrap";

import TextField from "../TextField";
import { postSimulation } from "../../api/ApiPtlCalls";

const NewModel1 = ({ formComplete }) => {
  const validate = Yup.object({
    title: Yup.string()
      .max(50, "Must be 50 chars or less")
      .required("Required"),
    model_start_dtg: Yup.string().required("Required"),
  });

  // Close this form, cancel has been pressed
  const CancelForm = () => {
    formComplete(false);
  };

  const calcChangeDate = (startDate) => {
    var date = new Date(startDate);
    const dateCopy = new Date(date.getTime());
    dateCopy.setFullYear(date.getFullYear() + 1);
    return dateCopy.toISOString().split("T")[0];
  };

  // Submit has been pressed so administer it.
  const SaveFormData = async (values) => {
    // alert("made it here with: " + JSON.stringify(values, null, 2));
    var newDetails = {
      model_end_dtg: calcChangeDate(values.model_start_dtg),
      no_of_theatres: 1,
      no_runs: 1,
      pa_start_time: "07:00:00",
      pa_end_time: "19:00:00",
      pa_percentage_clinical_time: 50,
      pa_working_weeks_per_year: 42,
    };
    var myDataCheck = { ...newDetails, ...values };
    await postSimulation(
      myDataCheck,
      (result) => {
        // Success
        // Grab the new id.
        formComplete(true, result.id);
      },
      (error) => {
        // Failure
        alert("Error: " + JSON.stringify(error, null, 2));
      }
    );
  };

  return (
    <>
      <Formik
        initialValues={{
          title: "",
          model_start_dtg: "",
        }}
        validationSchema={validate}
        onSubmit={async (values) => {
          SaveFormData(values);
        }}
      >
        {(formik) => (
          <div>
            <h3 className="font-weight-bold-display-4">Add New Model</h3>
            <Form>
              <TextField
                label="PTL Name"
                name="title"
                type="text"
                maxLength="256"
              />
              <TextField
                label="Start Date"
                name="model_start_dtg"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <Button className="btn " type="submit">
                Save
              </Button>{" "}
              <Button className="btn " type="button" onClick={CancelForm}>
                Cancel
              </Button>
            </Form>
          </div>
        )}
      </Formik>
    </>
  );
};

export default NewModel1;
