import React from "react";
import ConsultSettings from "./ConsultSettings";

const TabSettings = ({ tabNo }) => {
  return (
    <>
      <ConsultSettings />
    </>
  );
};

export default TabSettings;
