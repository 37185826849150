import React, { useContext, useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { FaTrash } from "react-icons/fa";
import { GoPencil } from "react-icons/go";
import moment from "moment";
import {
  getTimeSegments,
  delTimeSegment,
} from "../../../../api/ApiConsultCalls";
import {
  ConsultSetRefreshChildrenContext,
  ConsultSettingsContext,
  ConsultIsLocked,
} from "../../Consult";
import ModalConfirm from "../../../modal/ModalConfirm";
import TimeSegment from "./TimeSegment";
import ModalLayout from "../../../modal/Modal";
import { validateTimeSegments } from "../../../../common/validation";
import table from "../../../../table.module.scss";

const TimeSegmentGrid = ({ ctrlValid, ctrlNumber }) => {
  const [dsTimeSegments, setDsTimeSegments] = useState({});
  const [data] = useContext(ConsultSettingsContext);
  const refreshAllNow = useContext(ConsultSetRefreshChildrenContext);
  const [isLocked] = useContext(ConsultIsLocked);

  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [popupTimeSegment, setPopupTimeSegment] = useState([]);
  const [timeSegmentId, setTimeSegmentId] = useState("");
  const [showSumError, setShowSumError] = useState(false);

  useEffect(() => {
    if (data.id !== undefined) {
      getTimeSegments(
        data.id,
        (simData) => {
          // console.log("Ailments: ", simData);
          setDsTimeSegments(simData);
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }, [data.id, setDsTimeSegments]);

  function ValidateFormData() {
    if (validateTimeSegments(dsTimeSegments)) {
      // Passed the test
      if (ctrlValid !== undefined) {
        ctrlValid(ctrlNumber, true);
      }
      // Do not show the error
      setShowSumError(false);
    } else {
      // Failed the test
      if (ctrlValid !== undefined) {
        ctrlValid(ctrlNumber, false);
      }
      // only show the error if there is at least one error
      setShowSumError(dsTimeSegments.length > 0);
    }
  }

  // Automate the form validation
  useEffect(() => {
    ValidateFormData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dsTimeSegments]);

  const rowDelete = () => {
    modalDeleteClose();

    delTimeSegment(
      timeSegmentId,
      () => {
        // Remove the item from the array
        var myArray = dsTimeSegments.filter((a) => {
          return a.id !== timeSegmentId;
        });
        setDsTimeSegments(myArray);
        refreshAllNow();
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const newRecord = () => {
    var newAilmentRecord = [];
    newAilmentRecord.fk_analysis_id = data.id;
    newAilmentRecord.id = "00000000-0000-0000-0000-000000000000";

    setPopupTimeSegment([]);
    setPopupTimeSegment(newAilmentRecord);
    setIsNew(true);
    setShowModal(true);
  };

  const updateRecord = async (recordId) => {
    var ail = dsTimeSegments.filter((a) => {
      return a.id === recordId;
    });
    setPopupTimeSegment(ail[0]);
    setIsNew(false);
    setShowModal(true);
  };

  const dataUpdated = (e) => {
    //alert(e);
    if (!isNew) {
      // Ensure doc display correctly
      setPopupTimeSegment(e);
      var myArray = dsTimeSegments.filter((a) => {
        return a.id !== e.id;
      });
      myArray.push(e);
      setDsTimeSegments(myArray);
    } else {
      // Get the array rebuild - the record has been added to the db
      // refreshAllNow();
      var myArrayNew = dsTimeSegments;
      myArrayNew.push(e);
      setDsTimeSegments(myArrayNew);
    }
    modalClose();
    // Date has been changed so update the forms:
    refreshAllNow();
  };

  const modalClose = () => {
    setShowModal(false);
  };

  const modalDeleteClose = () => {
    setShowDeleteModal(false);
  };

  const sortTimeObjects = (a, b) => {
    const timeA = moment(a.start_time, "HH:mm");
    const timeB = moment(b.start_time, "HH:mm");

    if (timeA.isBefore(timeB)) {
      return -1;
    } else if (timeA.isAfter(timeB)) {
      return 1;
    } else {
      return 0;
    }
  };

  const viewSim = (simId) => {
    updateRecord(simId);
  };

  const formatTime = (time) => {
    return time.substring(0, 5);
  };

  return (
    <>
      <Table className={table.dashboard} hover="true">
        <thead>
          <tr>
            <>
              <th className="table_text_col">Segment Title</th>
              <th className="table_text_col">Start Time</th>
              <th className="table_text_col">End Time</th>
            </>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {dsTimeSegments &&
            dsTimeSegments.length > 0 &&
            dsTimeSegments.sort(sortTimeObjects).map((sim) => (
              <tr
                key={sim.id}
                onClick={() => isLocked === false && viewSim(sim.id)}
              >
                <td>{sim.segment_description}</td>
                <td>{formatTime(sim.start_time)}</td>
                <td>{formatTime(sim.end_time)}</td>
                <td>
                  <span
                    role="button"
                    onClick={(e) => {
                      e.stopPropagation();
                      setTimeSegmentId(sim.id);
                      setShowDeleteModal(true);
                    }}
                    className={`${isLocked ? "d-none" : ""}`}
                  >
                    <FaTrash size={25} color="#7a2048" />
                  </span>

                  <span
                    role="button"
                    onClick={() => {
                      updateRecord(sim.id);
                    }}
                    className={`${isLocked ? "d-none" : ""}`}
                  >
                    <GoPencil size={25} color="#7a2048" />
                  </span>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
      {showSumError && (
        <div className="alert alert-danger">
          The sum of likelihood for a new patient needs to add up to 100%.
          Please add additional ailments or modify existing ones.
        </div>
      )}
      <>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <button
            className={`btn btn-outline-primary ${isLocked ? "d-none" : ""}`}
            onClick={newRecord}
          >
            Create New
          </button>
        </div>
      </>
      <ModalLayout
        title={isNew ? "Add new time segment" : "Update time segment"}
        show={showModal}
        handleClose={modalClose}
      >
        <TimeSegment
          data={popupTimeSegment}
          isNew={isNew}
          dataUpdated={dataUpdated}
          simulation_id={data.simulation_id}
        />
      </ModalLayout>
      <ModalConfirm
        show={showDeleteModal}
        handleClose={modalDeleteClose}
        title="Please confirm you want to delete this segment"
        confirmed={rowDelete}
      ></ModalConfirm>
    </>
  );
};

export default TimeSegmentGrid;
