import React from "react";
import { FaSort, FaSortDown, FaSortUp } from "react-icons/fa";
// FaSortDown, FaSortUp

const IconSort = ({ ThisColumnNumber, OrderColumn, OrderAsc }) => {
  return (
    <React.Fragment>
      {ThisColumnNumber === OrderColumn ? (
        OrderAsc === true ? (
          <FaSortUp />
        ) : (
          <FaSortDown />
        )
      ) : (
        <FaSort />
      )}
    </React.Fragment>
  );
};

export default IconSort;
