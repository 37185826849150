import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

function SysAdminDropdown() {
  return (
    <DropdownButton id="dropdown-basic-button" title="System Admin">
      <Dropdown.Item href="/Co">Trusts</Dropdown.Item>
    </DropdownButton>
  );
}

export default SysAdminDropdown;
