import React, {
  useState,
  useContext,
  useRef,
  useEffect,
  useCallback,
} from "react";
import { postPTLFile } from "../../../api/ApiPtlCalls";
import {
  Model01RefreshAllContext,
  Model01SimSettingsContext,
  Model01IsDirty,
  Model01IsLocked,
} from "../Model01";
import StatusOfProcess from "../../progress/StatusOfProcess";
import { GoCloudUpload } from "react-icons/go";
import { VscFolderActive } from "react-icons/vsc";
import styles from "../../../indexOrg.module.scss";
import progress from "../../../scss/style.module.scss";

const UploadPTL = (disabled) => {
  const [data, writeSimSettings] = useContext(Model01SimSettingsContext); // eslint-disable-line no-unused-vars
  const [isDirty, setIsDirty] = useContext(Model01IsDirty); // eslint-disable-line no-unused-vars
  const [fileName, setFileName] = useState("");
  const [messages, setMessages] = useState([]);
  const [errors, setErrors] = useState([]);
  const [selectedFile, setSelectedFile] = useState();
  const [isSelected, setIsSelected] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);
  const [isLocked] = useContext(Model01IsLocked);

  const fileInput = useRef(null);
  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    setFileName(event.target.files[0].name);
    setIsSelected(true);
    // handleSubmission();
  };

  // eslint-disable-next-line no-unused-vars
  const [isSysAdmin, setIsSysAdmin] = useState(
    localStorage.getItem("isSysAdmin").replace(/['"]+/g, "")
  );

  // Grab the function for if there is a ptl upload
  const refreshAll = useContext(Model01RefreshAllContext);

  const handleSubmission = useCallback(() => {
    if (isSelected) {
      if (fileInput && fileInput.current && fileInput.current.files) {
        if (fileInput.current.files.length === 0) {
          alert("No files yet!");
        } else {
          setIsUploading(true);
          (async () => {
            await postPTLFile(
              selectedFile,
              data.simulation_id,
              (result) => {
                result.then(function (res) {
                  console.log(res); // "Some User token"
                  // Angus says users not interested in messages.
                  //setMessages(res.messages);
                  setErrors(res.errors);
                  if (errors.length === 0) {
                    refreshAll();
                  }
                });

                // Success

                if (result) {
                  setIsDirty(true);
                  setSelectedFile();
                  setIsSelected(false);
                  setIsUploaded(true);
                } else {
                  setMessages([
                    "There has been a problem updating the data - the api did not respond correctly. Please try again.",
                  ]);
                }
                setIsUploading(false);
              },
              (error) => {
                // Failure
                setIsUploading(false);
                alert("Error: " + JSON.stringify(error, null, 2));
              }
            );
          })();
        }
      }
    }
  }, [
    isSelected,
    data.simulation_id,
    errors,
    refreshAll,
    selectedFile,
    setIsDirty,
  ]);

  useEffect(() => {
    handleSubmission();
  }, [fileInput, handleSubmission]);

  return (
    <div>
      {isUploading ? (
        <>
          <div className={progress.progressSpinner}>
            <StatusOfProcess isActive={isUploading} sim_guid={data.id} />
          </div>
        </>
      ) : (
        <>
          <label className={`pointer ${isLocked ? "d-none" : ""}`}>
            <input
              type="file"
              name="file"
              className={styles.hidden}
              onChange={(e) => changeHandler(e)}
              // onChangeCapture={() => handleSubmission()}
              ref={fileInput}
            />
            <GoCloudUpload size={35} />
            &nbsp;{"  "}&nbsp; Upload Existing PTL
          </label>
          {isUploaded && (
            <>
              <VscFolderActive size={35} />
              &nbsp;{"  "}&nbsp; {fileName}
            </>
          )}
        </>
      )}

      {messages &&
        messages.map((msg, index) => (
          <div key={index} className="alert alert-success">
            {msg}
          </div>
        ))}
      {errors &&
        errors.map((msg, index) => (
          <div key={index} className="alert alert-danger">
            {msg}
          </div>
        ))}
    </div>
  );
};

export default UploadPTL;
