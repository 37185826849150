import React, { useEffect, useState, useContext, createContext } from "react";
import { Spinner } from "react-bootstrap";
import ReportResultsTable from "./ReportResultsTable";
import { ConsultSettingsContext } from "../../Consult";
import { ConsultRefreshChildrenContext } from "../../Consult";
import progress from "../../../progress/progress.module.scss";
import {
  getReportSummary,
  getLeaveReporting,
  getVetting,
} from "../../../../api/ApiConsultCalls";
import ReportSingleConsult from "./ReportSingleConsult";
import SelectField from "../../../SeletField";
import RadioField from "../../../RadioField";
import ReportCharts from "./ReportCharts";
import styles from "../../../../scss/style.module.scss";
import ReportNicipTable from "./ReportNicipTable";
import ToggleControl from "../../../ToggleControl";
import DepartmentRptSummaryTable from "./DepartmentRptSummaryTable";

const ReportContext = createContext({}, {}, {}, {});

const TabReports = ({ tabNo }) => {
  const [activeTab, setActiveTab] = useState("tab1");

  const [summaryData, setSummaryData] = useState({});
  const [leaveData, setLeaveData] = useState({});
  const [dsSummaryData, setDsSummaryData] = useState({});
  const [dsSummaryDataFull, setDsSummaryDataFull] = useState({});
  const [dsVetting, setDsVetting] = useState({});
  const [hiddenConsultants, setHiddenConsultants] = useState([]);

  const [data] = useContext(ConsultSettingsContext);
  const refreshChild = useContext(ConsultRefreshChildrenContext);
  const [isManPolling, setIsManPolling] = useState(false);
  const [rptType, setRptType] = useState("0");
  const [rptExamType, setRptExamType] = useState("0");
  const [rptVetting, setRptVetting] = useState("0");
  const [dsConsultantNames, setDsConsultantNames] = useState({});
  const [selectedName, setSelectedName] = useState("");
  const [selectedWordIndex, setSelectedWordIndex] = useState(0);
  const [locateDoctorsToHide, setLocateDoctorsToHide] = useState([]);

  const [vettingSum, setVettingSum] = useState(0);
  const [vettingDuration, setVettingDuration] = useState(2); // eslint-disable-line no-unused-vars
  const [doctorCount, setDoctorCount] = useState(0);

  // This is here to allow the data to be updated by child objects
  // It is simpler than a collection of useState's
  // [0] = Scheduled Vs. Actual Activity
  // [1] = Other Job Plan Activity
  // [2] = Other Actual Activity
  // [3] = Waiting List Initiative Payments
  // NB this is a nice implementation but requires
  //      const updateSumDataItemRef = useRef(updateSumDataItem);
  //      on the child form for use within a useEffect to prevent automatic refreshes
  const [sumData, setSumData] = useState([
    {
      // Scheduled Vs. Actual Activity
      expSess: 0,
      expHrs: 0,
      expHrsGross: 0,
      expPa: 0,
      actSess: 0,
      actHrs: 0,
      actPa: 0,
      actRpt: 0,
      paStandard: 0,
      paPremium: 0,
      expHrsGrossLeaveAdj: 0,
    },
    {
      // Other Job Plan Activity
      expSess: 0,
      expHrs: 0,
      expHrsGross: 0,
      expPa: 0,
      actSess: 0,
      actHrs: 0,
      actPa: 0,
      actRpt: 0,
      paStandard: 0,
      paPremium: 0,
      expHrsGrossLeaveAdj: 0,
    },
    {
      // Other Actual Activity
      expSess: 0,
      expHrs: 0,
      expHrsGross: 0,
      expPa: 0,
      actSess: 0,
      actHrs: 0,
      actPa: 0,
      actRpt: 0,
      paStandard: 0,
      paPremium: 0,
      expHrsGrossLeaveAdj: 0,
    },
    {
      // Waiting List Initiative Payments
      expSess: 0,
      expHrs: 0,
      expHrsGross: 0,
      expPa: 0,
      actSess: 0,
      actHrs: 0,
      actPa: 0,
      actRpt: 0,
      paStandard: 0,
      paPremium: 0,
      expHrsGrossLeaveAdj: 0,
    },
    {
      // Flexible
      expSess: 0,
      expHrs: 0,
      expHrsGross: 0,
      expPa: 0,
      actSess: 0,
      actHrs: 0,
      actPa: 0,
      actRpt: 0,
      paStandard: 0,
      paPremium: 0,
      expHrsGrossLeaveAdj: 0,
    },
  ]);

  const [calcData, setCalcData] = useState({
    actLeave: 1,
    expLeave: 2,
    actStrike: 3,
    expStrike: 4,
    actHours: 5,
    expHours: 6,
    periodDays: 7,
  });

  // Work out the leave numbers
  useEffect(() => {
    // Iterate through the data using the selectedName filter.
    let leave_actual = 0;
    let leave_expected = 0;
    let strike_actual = 0;
    let strike_expected = 0;
    let period_days = 0;
    // Iterate through the filtered data
    leaveData &&
      leaveData.length > 0 &&
      leaveData
        .filter((item) => {
          if (selectedName === "*") {
            // Allow all names for wildcard placeholders
            return true;
          } else if (selectedName === "%") {
            return (
              item.scheduled_consultant === true &&
              (!hiddenConsultants ||
                !hiddenConsultants.includes(item.authoriser))
            );
          } else {
            // Check for an exact   match
            return item.first_report_authoriser === selectedName;
          }
        })
        .forEach((lv) => {
          leave_actual += lv.act_leave;
          leave_expected += lv.exp_leave;
          strike_actual += lv.act_strike;
          strike_expected += lv.exp_strike;
          period_days = lv.period_days; // On all records
        });

    setCalcData({
      actLeave: leave_actual,
      expLeave: leave_expected,
      actStrike: strike_actual,
      expStrike: strike_expected,
      actHours: 1234,
      expHours: 123,
      periodDays: period_days,
    });
  }, [leaveData, selectedName, hiddenConsultants]);

  // Now you can access and update each state variable like this:
  const updateSumDataItem = (index, field, value) => {
    const newData = [...sumData];
    newData[index][field] = value;
    setSumData(newData);
  };

  const countVettings = (records) => {
    const vettingCounts = {};

    records.forEach((record) => {
      const vettedBy = record.vetted_by;

      if (vettingCounts[vettedBy]) {
        vettingCounts[vettedBy]++;
      } else {
        vettingCounts[vettedBy] = 1;
      }
    });

    return Object.keys(vettingCounts).map((vettedBy) => ({
      vetted_by: vettedBy,
      count: vettingCounts[vettedBy],
    }));
  };

  useEffect(() => {
    const locateDoctorsToHide = (simData, lvData) => {
      // Locate
      const nameToZeroReports = simData.reduce((acc, item) => {
        // If the name is already in the accumulator, update it only if the current total_reports is non-zero
        if (acc[item.authoriser] !== undefined) {
          acc[item.authoriser] =
            acc[item.authoriser] && item.total_reports === 0;
        } else {
          // If the name is not in the accumulator, add it with the value of the current total_reports check
          acc[item.authoriser] = item.total_reports === 0;
        }
        return acc;
      }, {});

      // Filter the names where all reports are zero
      const namesWithAllZeroReports = Object.keys(nameToZeroReports).filter(
        (authoriser) => nameToZeroReports[authoriser]
      );

      const nameHaveHoliday = lvData.reduce((acc, item) => {
        // If the name is already in the accumulator, update it only if the current total_reports is non-zero
        if (acc[item.first_report_authoriser] !== undefined) {
          acc[item.first_report_authoriser] =
            acc[item.first_report_authoriser] && item.act_leave > 0;
        } else {
          // If the name is not in the accumulator, add it with the value of the current total_reports check
          acc[item.first_report_authoriser] = item.act_leave > 0;
        }
        return acc;
      }, {});

      // Filter the names where all reports are zero
      const namesWithHoliday = Object.keys(nameHaveHoliday).filter(
        (authoriser) => nameHaveHoliday[authoriser] === true
      );

      // Remove those will holiday from the hidden list.s
      const filteredNames = namesWithAllZeroReports.filter(
        (name) => !namesWithHoliday.includes(name)
      );

      return filteredNames;
    };

    if (data.id !== undefined) {
      setIsManPolling(true);
      getReportSummary(
        data.id,
        rptType,
        rptExamType,
        (simData) => {
          // console.log("useEffect 02");
          setSummaryData(simData);
          setDsSummaryDataFull(simData);

          // Get the leave data
          getLeaveReporting(
            data.id,
            (lvData) => {
              setLeaveData(lvData);
              let results = locateDoctorsToHide(simData, lvData);
              // console.log("Should not be included: ", results.toString());
              setLocateDoctorsToHide(results);
            },
            (error) => {
              console.log("getReportSummary Error: ", error);
            }
          );

          getVetting(
            data.id,
            (vtData) => {
              setDsVetting(countVettings(vtData));
            },
            (error) => {
              console.log("getReportSummary Error: ", error);
            }
          );

          setIsManPolling(false);
        },
        (error) => {
          console.log("getReportSummary Error: ", error);
        }
      );
    }

    // Vetting hard coded default
    setVettingDuration(
      data.vetting_duration === undefined || data.vetting_duration === null
        ? 2
        : data.vetting_duration
    );
  }, [data.id, rptType, refreshChild, rptExamType, data.vetting_duration]);

  // Populate the names list
  useEffect(() => {
    // Extract distinct names from the summaryData

    // Initialize an empty dictionary to store distinct values
    const distinctValues = {};
    let hidden = [];

    // Iterate through summaryData
    if (summaryData && summaryData.length > 0) {
      summaryData.forEach((item) => {
        const key = `${item.authoriser}-${item.scheduled_consultant}`;

        // Check if the combination of authoriser and scheduled_consultant is not already in distinctValues
        if (!distinctValues[key]) {
          distinctValues[key] = {
            authoriser: item.authoriser,
            scheduled_consultant: item.scheduled_consultant,
            hide: locateDoctorsToHide.includes(item.authoriser),
          };
          if (distinctValues[key].hide === true) {
            hidden.push(item.authoriser);
          }
        }
      });
    }

    // Convert the values of distinctValues object into an array
    const uniqueNamesArray = Object.values(distinctValues);

    // Now uniqueNamesArray contains objects with both authoriser and scheduled_consultant fields, and they are distinct
    setDsConsultantNames(uniqueNamesArray);
    setHiddenConsultants(hidden);

    // Filter from the summaryData
    if (summaryData && summaryData.length > 0) {
      setDsSummaryData(
        summaryData.filter((item) => {
          if (selectedName === "*") {
            // Allow all names for wildcard placeholders
            return true;
          } else if (selectedName === "%") {
            return (
              item.scheduled_consultant === true &&
              !hidden.includes(item.authoriser)
              // &&
              // !locateDoctorsToHide.includes(item.authoriser)
            );
          } else {
            // Check for an exact   match
            return item.authoriser === selectedName;
          }
        })
      );
    }

    // Assume a single doctor
    let selectionCount = 1;
    if (selectedName === "*") {
      // All doctors
      selectionCount = uniqueNamesArray.length;
    } else if (selectedName === "%") {
      //Consultants only
      let count = 0;
      Object.values(distinctValues).forEach((value) => {
        if (value.scheduled_consultant === true && value.hide === false) {
          count++;
        }
      });
      selectionCount = count;
    }
    setDoctorCount(selectionCount);
  }, [summaryData, selectedName, locateDoctorsToHide, setDoctorCount]);

  const handleRadioTypeChange = (value) => {
    setRptType(value);
  };

  const handleRadioExamTypeChange = (value) => {
    setRptExamType(value);
  };

  const handleVettingChange = (value) => {
    setRptVetting(value);
  };

  // Consultant dropdown
  const handleNameChange = (event) => {
    setSelectedName(event.target.value);
  };

  // The authoriser has been changed: set to individual and update dropdown list
  const setAuthoriser = (authoriser) => {
    // Reset the form tabs
    setSelectedWordIndex(1);
    // Set the dropdown
    setSelectedName(authoriser);
  };

  useEffect(() => {
    // Work out the vetting totals
    let total_vetted = 0;

    if (selectedName === "*") {
      // Allow all names for wildcard placeholders
      for (var i = 0; i < dsVetting.length; i++) {
        total_vetted += dsVetting[i].count;
      }
    } else if (selectedName === "%") {
      for (var k = 0; k < dsVetting.length; k++) {
        let vetted_by = dsVetting[k].vetted_by;
        let consultant = dsConsultantNames.filter(
          (item) => item.authoriser === vetted_by
        );
        if (
          consultant &&
          consultant.length > 0 &&
          consultant[0].scheduled_consultant
        ) {
          total_vetted += dsVetting[k].count;
        }
      }
    } else {
      // Check for an exact   match
      for (var j = 0; j < dsVetting.length; j++) {
        if (dsVetting[j].vetted_by === selectedName) {
          total_vetted += dsVetting[j].count;
        }
      }
    }

    setVettingSum(total_vetted);
  }, [dsVetting, selectedName, hiddenConsultants, dsConsultantNames]);

  return (
    <>
      <div>
        <h3>Reports</h3>
        <>
          <ReportContext.Provider
            value={[data.id, rptType, rptExamType, selectedName]}
          >
            {isManPolling && (
              <div style={{ marginLeft: "10px" }}>
                <Spinner
                  as="span"
                  animation="border"
                  size="md"
                  role="status"
                  aria-hidden="true"
                  className={progress.spinner}
                />
              </div>
            )}
            {!isManPolling && (
              <React.Fragment>
                <div className={styles.form_halves}>
                  <div className={styles.left_control}>
                    <RadioField
                      // label="Select a report type:"
                      name="rdoAuthorisers"
                      options={[
                        {
                          value: "0",
                          label: "First and Secondary Authorisers",
                        },
                        { value: "1", label: "First Authorisers Only" },
                        { value: "2", label: "Second Authorisers Only" },
                      ]}
                      selectedOption={rptType}
                      onChange={handleRadioTypeChange}
                    />
                  </div>

                  <div className={styles.left_control}>
                    <RadioField
                      // label="Select a report type:"
                      name="rdoVetting"
                      options={[
                        { value: "0", label: "Include Vetting" },
                        { value: "1", label: "Not Include Vetting" },
                      ]}
                      selectedOption={rptVetting}
                      onChange={handleVettingChange}
                    />
                  </div>

                  <div className={styles.left_control}>
                    <RadioField
                      // label="Select a report type:"
                      name="rdoStandards"
                      options={[
                        { value: "0", label: "Generic standards" },
                        { value: "1", label: "NICIP standards" },
                      ]}
                      selectedOption={rptExamType}
                      onChange={handleRadioExamTypeChange}
                    />
                  </div>
                </div>

                <div>
                  <ToggleControl
                    words={["Departmental", "Individual"]}
                    selectedItem={selectedWordIndex}
                    onSelect={(index) => {
                      setSelectedWordIndex(index);
                    }}
                  />{" "}
                </div>
                <hr />
              </React.Fragment>
            )}
            <React.Fragment>
              <div className={`${selectedWordIndex === 1 ? "d-none" : ""}`}>
                <br />
                <DepartmentRptSummaryTable
                  data={data}
                  dsConsultantNames={dsConsultantNames}
                  summaryData={dsSummaryDataFull}
                  leaveData={leaveData}
                  setAuthoriser={setAuthoriser}
                  dsVetting={dsVetting}
                  rptVetting={rptVetting}
                  hiddenConsultants={hiddenConsultants}
                />
              </div>
            </React.Fragment>
            <React.Fragment>
              <div className={`${selectedWordIndex === 0 ? "d-none" : ""}`}>
                <SelectField value={selectedName} onChange={handleNameChange}>
                  <option value="">Select a consultant</option>
                  <option value="%">All consultants</option>
                  <option value="*">All staff</option>
                  {dsConsultantNames &&
                    dsConsultantNames.length > 0 &&
                    dsConsultantNames.map((name, index) => (
                      <option key={index} value={name.authoriser}>
                        {name.authoriser}{" "}
                        {name.scheduled_consultant &&
                          name.scheduled_consultant === true &&
                          "*"}{" "}
                        {name.hide && name.hide === true && " (excluded)"}
                      </option>
                    ))}
                </SelectField>
                <br />
                <ul style={{ paddingTop: "15px" }} className="nav">
                  <li
                    className={activeTab === "tab1" ? "active" : ""}
                    onClick={() => {
                      setActiveTab("tab1");
                    }}
                  >
                    <div className="checkboxContainerLeft">Summary</div>
                  </li>
                  <li
                    className={activeTab === "tab2" ? "active" : ""}
                    onClick={() => {
                      setActiveTab("tab2");
                    }}
                  >
                    <div
                      className="checkboxContainerLeft"
                      style={{ textAlign: "left" }}
                    >
                      <div className="checkboxContainerLeft">
                        Comparison Breakdown
                      </div>
                    </div>
                  </li>
                  <li
                    className={activeTab === "tab3" ? "active" : ""}
                    onClick={() => {
                      setActiveTab("tab3");
                    }}
                  >
                    <div
                      className="checkboxContainerLeft"
                      style={{ textAlign: "left" }}
                    >
                      <div className="checkboxContainerLeft">
                        Reports & Standards
                      </div>
                    </div>
                  </li>
                  <li
                    className={activeTab === "tab4" ? "active" : ""}
                    onClick={() => {
                      setActiveTab("tab4");
                    }}
                  >
                    <div
                      className="checkboxContainerLeft"
                      style={{ textAlign: "left" }}
                    >
                      <div className="checkboxContainerLeft">Data</div>
                    </div>
                  </li>
                </ul>
                <div
                  style={{ paddingTop: "15px" }}
                  className={activeTab === "tab1" ? "" : "hiddenControl"}
                >
                  <ReportCharts
                    sumData={sumData}
                    activeTab={activeTab}
                    leaveData={leaveData}
                    selectedName={selectedName}
                    vettingSum={vettingSum}
                    vettingDuration={vettingDuration}
                    rptVetting={rptVetting}
                    dsConsultantNames={dsConsultantNames}
                    doctorCount={doctorCount}
                    calcData={calcData}
                  />
                </div>
                <div
                  style={{ paddingTop: "15px" }}
                  className={activeTab === "tab2" ? "" : "hiddenControl"}
                >
                  <ReportSingleConsult
                    summaryData={dsSummaryData}
                    sumData={sumData}
                    updateSumDataItem={updateSumDataItem}
                    selectedName={selectedName}
                    vettingSum={vettingSum}
                    vettingDuration={vettingDuration}
                    rptVetting={rptVetting}
                    calcData={calcData}
                    doctorCount={doctorCount}
                  />
                </div>
                <div
                  style={{ paddingTop: "15px" }}
                  className={activeTab === "tab3" ? "" : "hiddenControl"}
                >
                  <ReportNicipTable
                    id={data.id}
                    rptType={rptType}
                    selectedName={selectedName}
                  />
                </div>
                <div
                  style={{ paddingTop: "15px" }}
                  className={activeTab === "tab4" ? "" : "hiddenControl"}
                >
                  <ReportResultsTable
                    summaryData={dsSummaryData}
                    rptType={rptType}
                  />
                </div>
              </div>
            </React.Fragment>
            <br />

            <br />
          </ReportContext.Provider>
        </>
      </div>
    </>
  );
};

export { TabReports, ReportContext };
