import React from "react";
import style from "../scss/style.module.scss";

function ToggleControl({ words, selectedItem, onSelect }) {
  const handleWordClick = (index) => {
    onSelect(index);
  };

  return (
    <div className={style.toggle_container}>
      {words.map((word, index) => (
        <div
          key={index}
          className={`${style.toggle_word} ${
            index === selectedItem ? style.toggle_selected : ""
          }`}
          onClick={() => handleWordClick(index)}
        >
          {word}
        </div>
      ))}
    </div>
  );
}
export default ToggleControl;
