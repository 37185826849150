import React, { useState, useContext } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import TextField from "../../TextField";
import { putAilment, postAilment } from "../../../api/ApiPtlCalls";
import { Model01IsDirty, Model01SimSettingsContext } from "../Model01";
import styles from "../../../scss/style.module.scss";

const Ailment = ({ data, dataUpdated, isNew, displayType }) => {
  const [displayTypeLocal, setDisplayType] = useState(displayType); // eslint-disable-line no-unused-vars
  const [isDirty, setIsDirty] = useContext(Model01IsDirty); // eslint-disable-line no-unused-vars
  const [simData, setSimData] = useContext(Model01SimSettingsContext);
  var schema = {};
  var initValues = {};
  /*
  gerneral
  ptl
  demand
  activity
  run
  */
  if (isNew) {
    schema = {
      simulation_id: Yup.number().integer().required(),
      title: Yup.string()
        .max(84, "Must be 84 chars or less")
        .required("Required"),
      ptt_mean_mins: Yup.number()
        .integer()
        .min(1, "Must be more than 0")
        .required("Required"),
      ptt_sd_mins: Yup.number()
        .integer()
        .min(1, "Must be more than 0")
        .required("Required"),
      ptt_minimum_mins: Yup.number()
        .integer()
        .min(0, "Must be positive")
        .required("Required"),
      ptt_maximum_mins: Yup.number()
        .integer()
        .min(0, "Must be positive")
        .required("Required"),
      percentage_likelihood: Yup.number()
        .min(0, "Must be more than 0")
        .required("Required"),
      ailment_priority: Yup.number()
        .integer()
        .min(0, "Must be positive")
        .required("Required"),
    };
    initValues = {
      id: data["id"] || "",
      simulation_id: data["simulation_id"],
      title: data["title"] || "",
      ptt_mean_mins: data["ptt_mean_mins"] || 0,
      ptt_sd_mins: data["ptt_sd_mins"] || 0,
      ptt_minimum_mins: data["ptt_minimum_mins"] || 0,
      ptt_maximum_mins: data["ptt_maximum_mins"] || 0,
      percentage_likelihood: data["percentage_likelihood"] || 0,
      ailment_priority: data["ailment_priority"] || 0,
    };
  } else if (displayTypeLocal === "ptl") {
    schema = {
      simulation_id: Yup.number().integer().required(),
      title: Yup.string()
        .max(84, "Must be 84 chars or less")
        .required("Required"),
      ptt_mean_mins: Yup.number()
        .integer()
        .min(1, "Must be more than 0")
        .required("Required"),
      ptt_sd_mins: Yup.number()
        .integer()
        .min(1, "Must be more than 0")
        .required("Required"),
      ptt_minimum_mins: Yup.number()
        .integer()
        .min(0, "Must be positive")
        .required("Required"),
      ptt_maximum_mins: Yup.number()
        .integer()
        .min(0, "Must be positive")
        .required("Required"),
    };
    initValues = {
      id: data["id"] || "",
      simulation_id: data["simulation_id"],
      title: data["title"] || "",
      ptt_mean_mins: data["ptt_mean_mins"] || 0,
      ptt_sd_mins: data["ptt_sd_mins"] || 0,
      ptt_minimum_mins: data["ptt_minimum_mins"] || 0,
      ptt_maximum_mins: data["ptt_maximum_mins"] || 0,
    };
  } else if (displayTypeLocal === "demand") {
    schema = {
      simulation_id: Yup.number().integer().required(),
      title: Yup.string()
        .max(84, "Must be 84 chars or less")
        .required("Required"),
      percentage_likelihood: Yup.number()
        .min(0, "Must be more than 0")
        .required("Required"),
    };
    initValues = {
      id: data["id"] || "",
      simulation_id: data["simulation_id"],
      title: data["title"] || "",
      percentage_likelihood: data["percentage_likelihood"] || 0,
    };
  } else if (displayTypeLocal === "activity") {
    schema = {
      simulation_id: Yup.number().integer().required(),
      title: Yup.string()
        .max(84, "Must be 84 chars or less")
        .required("Required"),

      no_of_concurrent_sessions: Yup.number()
        .integer()
        .min(1, "Must be more than 0")
        .required("Required"),
      ailment_priority: Yup.number()
        .integer()
        .min(0, "Must be positive")
        .required("Required"),
    };
    initValues = {
      id: data["id"] || "",
      simulation_id: data["simulation_id"],
      title: data["title"] || "",

      ailment_priority: data["ailment_priority"] || 0,
    };
  }

  const frmSchema = Yup.object(schema);

  const setData = (ailment) => {
    // Update the cached data now.
  };

  const SaveFormData = async (values) => {
    // Assemble the data
    var myDataCheck = { ...data, ...values };
    // Put it to the server
    if (!isNew) {
      if (myDataCheck.emergency === false) {
        await putAilment(
          myDataCheck,
          (result) => {
            // Success
            if (result) {
              setData(myDataCheck);
              dataUpdated(myDataCheck);
              setIsDirty(true);
            } else {
              alert(
                "There has been a problem updating the data - the api did not respond correctly."
              );
            }
          },
          (error) => {
            // Failure
            alert("Error: " + error);
          }
        );
      } else {
        // We have an emergency "record" update
        // ToDo: NAJ continue here
        // Update the main sim_settings table
        simData.emergency_time_mean_mins = values.ptt_mean_mins;
        simData.emergency_time_sd_mins = values.ptt_sd_mins;
        simData.emergency_time_minimum_mins = values.ptt_minimum_mins;
        simData.emergency_time_maximum_mins = values.ptt_maximum_mins;
        setSimData(simData);

        setData(myDataCheck);
        dataUpdated(myDataCheck);
        setIsDirty(true);
      }
    } else {
      await postAilment(
        myDataCheck,
        (result) => {
          // Success
          if (result) {
            setData(myDataCheck);
            dataUpdated(myDataCheck);
            setIsDirty(true);
          } else {
            alert(
              "There has been a problem saving the data - the api did not respond correctly."
            );
          }
        },
        (error) => {
          // Failure
          alert("Error: " + error);
        }
      );
    }
  };

  // console.log("initValues: ", initValues);

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initValues}
        validationSchema={frmSchema}
        onSubmit={async (values) => {
          SaveFormData(values);
          //alert(JSON.stringify(values, null, 2));
        }}
      >
        {(formik) => (
          <div>
            <Form id={displayType + isNew.toString()}>
              <TextField
                label="Title"
                name="title"
                type="text"
                maxLength="84"
              />
              <div className={styles.hours}>
                {displayTypeLocal && (displayTypeLocal === "ptl" || isNew) && (
                  <>
                    <TextField
                      label="Average treatment time (mins)"
                      name="ptt_mean_mins"
                      type="number"
                      min="0"
                    />
                    <TextField
                      label="Standard dev. treatment time (mins)"
                      name="ptt_sd_mins"
                      type="number"
                      min="0"
                    />
                    <TextField
                      label="Minimum treatment time (mins)"
                      name="ptt_minimum_mins"
                      type="number"
                      min="0"
                    />
                    <TextField
                      label="Maximum treatment time (mins)"
                      name="ptt_maximum_mins"
                      type="number"
                      min="0"
                    />
                  </>
                )}
                {displayTypeLocal &&
                  (displayTypeLocal === "demand" || isNew) && (
                    <>
                      <TextField
                        label="Percentage likelihood for new patients"
                        name="percentage_likelihood"
                        type="number"
                        min="0"
                      />
                    </>
                  )}

                {displayTypeLocal &&
                  (displayTypeLocal === "activity" || isNew) && (
                    <>
                      <TextField
                        label="Priority"
                        name="ailment_priority"
                        type="number"
                        min="0"
                      />
                    </>
                  )}
              </div>
            </Form>
          </div>
        )}
      </Formik>
      <div className={styles.buttonContainer}>
        <button
          className="btn btn-outline-primary submit"
          form={displayType + isNew.toString()}
        >
          Submit
        </button>
      </div>
    </>
  );
};

export default Ailment;
