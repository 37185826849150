const ColoursList = (colour_number) => {
  const colours = [
    "#416fa1",
    "#F28e2b",
    "#0d1398",
    "#58508d",
    "#bc5090",
    "#ff6361",
    "#ffa600",
    "#FF0000",
    "#FFD700",
    "#7FFF00",
    "#808000",
    "#20B2AA",
    "#0000FF",
    "#8A2BE2",
    "#C71585",
    "#DC143C",
    "#FFA500",
    "#32CD32",
    "#3CB371",
    "#40E0D0",
    "#1E90FF",
    "#FF00FF",
    "#FF1493",
    "#F08080",
    "#FF8C00",
    "#008000",
    "#90EE90",
    "#00FFFF",
    "#00BFFF",
    "#EE82EE",
    "#FF69B4",
  ];

  return colours[colour_number];
};

export default ColoursList;
