import * as React from "react";
import { useState } from "react";
import FormControl from "@mui/material/FormControl";
import { useFormikContext } from "formik";
import { CompactPicker } from "react-color";
import styles from "../index.module.scss";
// Template for a custom control - for a Formik control
// For more complex control remember use to use ErrorControl
export default function ColourChooser(label, props) {
  const formikProps = useFormikContext();
  const [fName] = useState(label.name);

  // ***** Manually update the formik field *****
  const handleColourChange = (newcolour) => {
    formikProps.setFieldValue(fName, newcolour.hex);
    formikProps.setFieldTouched(fName, true);
  };

  return (
    <div>
      <FormControl variant="standard" sx={{ minWidth: 120 }}>
        <CompactPicker
          className={styles.colourPicker}
          color={formikProps.values[label.name]}
          onChange={handleColourChange}
        />
      </FormControl>
    </div>
  );
}
