import React from "react";

const CheckBoxSimple = ({ checked, onChange }) => {
  return (
    <>
      <input
        type="checkbox"
        className="input-checkbox-larger"
        checked={checked}
        onChange={onChange}
      />
    </>
  );
};

export default CheckBoxSimple;
