import { removeTrailingSlash, concatUrl } from "../common/urls";
import { get, getText, post } from "./FetchVerbs";

// THIS THE THE CORE API - taken from the .env file
const api_url = removeTrailingSlash(process.env.REACT_APP_FN_URL);

// *************************************************************************************
// model  NB use the FN url
// *************************************************************************************

export const runModelCelery = async (
  sim_id,
  run_id,
  operation,
  callback,
  failFunction
) => {
  const url = concatUrl(api_url, "/ss_fn");
  const params = {
    sim_id: sim_id,
    run_id: run_id,
    operation: operation,
  };
  await post(
    url,
    params,
    "Error running the model (celery)",
    callback,
    failFunction
  );
};

export const runModelTaskStatus = async (task_id, callback, failFunction) => {
  const url = concatUrl(api_url, "/task_status/" + task_id);
  await getText(
    url,
    "Error getting result from a task (celery)",
    callback,
    failFunction
  );
};

export const runModelTaskResult = async (task_id, callback, failFunction) => {
  const url = concatUrl(api_url, "/task_result/" + task_id);
  await get(
    url,
    "Error getting result from a task (celery)",
    callback,
    failFunction
  );
};

export const runModelTaskTerminate = async (
  task_id,
  callback,
  failFunction
) => {
  const url = concatUrl(api_url, "/task_terminate/" + task_id);
  await getText(
    url,
    "Error terminating a task (celery)",
    callback,
    failFunction
  );
};
