import React, { useState, useEffect } from "react";
// https://help.productfruits.com/en/article/developers-integration-react-nextjs
// See notes-product-fruit.txt
import { ProductFruits } from "react-product-fruits";

const ProductFruitCtrl = ({ userId }) => {
  const [fruitUser, setFruitUser] = useState({
    username: userId,
  });

  useEffect(() => {
    setFruitUser({
      username: userId,
    });
  }, [userId]);

  return (
    <>
      <ProductFruits
        user={fruitUser}
        workspaceCode="QJAvgzWAenB49NK8"
        language="en"
      />
    </>
  );
};

export default ProductFruitCtrl;
