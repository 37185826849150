import React, { useContext, useEffect } from "react";
import { Formik, Form, useFormikContext } from "formik";
import * as Yup from "yup";
import {
  ConsultSettingsContext,
  ConsultRefreshAllContext,
} from "../../Consult";
import TextField from "../../../TextField";
import AutoSave from "../../../FormikAutoSave";
import { isEqualJson } from "../../../../common/json_compare";

import styles from "../../../../scss/style.module.scss";

const VettingDuration = ({ ctrlValid, ctrlNumber, displayType }) => {
  const [data, writeSimSettings] = useContext(ConsultSettingsContext);
  const refreshAll = useContext(ConsultRefreshAllContext);
  var schema = {};
  var initValues = {};

  schema = {
    vetting_duration: Yup.number().required("Required"),
  };
  initValues = {
    vetting_duration: data["vetting_duration"] || 2,
  };

  const frmSchema = Yup.object(schema);

  const SaveFormData = async (values) => {
    // Assemble the data
    var myDataCheck = { ...data, ...values };
    // Check there is a difference.
    if (!isEqualJson(data, myDataCheck)) {
      // Send it up the tree
      writeSimSettings(myDataCheck);
      // Fresh the whole form
      refreshAll();
    }
  };

  const FormikFormObserver = () => {
    // When data is changed validate it
    const { values } = useFormikContext();
    useEffect(() => {
      const result = frmSchema.isValid(values, { strict: false });
      result.then((value) => {
        if (ctrlValid !== undefined) {
          ctrlValid(ctrlNumber, value);
        }
      });
    }, [values]);
    return;
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initValues}
      validationSchema={frmSchema}
      onSubmit={async (values) => {
        SaveFormData(values);
      }}
    >
      {(formik) => (
        <div>
          <Form>
            <div>
              <small
                className="float-end"
                style={{ color: "gray", fontSize: 11 }}
              >
                <AutoSave debounceMs={2000} />
              </small>
            </div>
            <FormikFormObserver frmSchema={frmSchema} />

            <>
              <div className={styles.filterForm}>
                <TextField
                  label="Vetting Duration"
                  name="vetting_duration"
                  type="number"
                />
              </div>
            </>
          </Form>
        </div>
      )}
    </Formik>
  );
};

export default VettingDuration;
