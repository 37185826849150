import React, { useContext, useEffect, useState } from "react";
import { ConsultSettingsContext } from "../../Consult";
import { ConsultRefreshChildrenContext } from "../../Consult";
import { getReportNICIPTable } from "../../../../api/ApiConsultCalls";
import Table from "react-bootstrap/Table";
import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import styles from "../../../../scss/style.module.scss";
import table from "../../../../table.module.scss";
import { hideIfZero, roundNumber } from "../../../../common/math_functions";
import IconSort from "../../../IconSort";

const ReportNicipTable = ({ id, rptType, selectedName }) => {
  const [data] = useContext(ConsultSettingsContext); // eslint-disable-line no-unused-vars
  const refreshChild = useContext(ConsultRefreshChildrenContext);
  const [dsNICIP, setDsNICIP] = useState({});

  const [reports_total, setreports_total] = useState(0);
  const [nicip_hours_total, setnicip_hours_total] = useState(0);
  const [generic_hours_total, setgeneric_hours_total] = useState(0);
  const [orderColumn, setOrderColumn] = useState(0);
  const [orderAsc, setOrderAsc] = useState(true);

  useEffect(() => {
    const fnCalcTotals = (data) => {
      let nicip_hours = 0;
      let generic_hours = 0;
      let reports = 0;
      // Iterate through the filtered data
      data &&
        data.length > 0 &&
        data.forEach((sim) => {
          reports += sim.reports;
          nicip_hours += sim.nicip_hours;
          generic_hours += sim.generic_hours;
        });

      // Update the state variables with the calculated totals
      setreports_total(reports);
      setnicip_hours_total(nicip_hours);
      setgeneric_hours_total(generic_hours);
    };
    // Fetch the JobPlan details.
    if (
      data.id !== undefined &&
      selectedName !== undefined &&
      selectedName !== ""
    ) {
      getReportNICIPTable(
        data.id,
        rptType,
        selectedName,
        (data) => {
          setDsNICIP(data);
          fnCalcTotals(data);
        },
        (error) => {
          setDsNICIP({});
          fnCalcTotals({});
        }
      );
    }
  }, [data.id, rptType, selectedName, refreshChild]);

  // <Sorting of coluimns NAJ>
  const sortData = (a, b) => {
    if (orderColumn === 0 && orderAsc === true)
      return parseFloat(a.reports) - parseFloat(b.reports);
    if (orderColumn === 0 && orderAsc === false)
      return parseFloat(b.reports) - parseFloat(a.reports);

    if (orderColumn === 1 && orderAsc === true)
      return String(a.nicip_code).localeCompare(b.nicip_code);
    if (orderColumn === 1 && orderAsc === false)
      return String(b.nicip_code).localeCompare(a.nicip_code);

    if (orderColumn === 2 && orderAsc === true)
      return String(a.examination).localeCompare(b.examination);
    if (orderColumn === 2 && orderAsc === false)
      return String(b.examination).localeCompare(a.examination);

    if (orderColumn === 3 && orderAsc === true)
      return String(a.exam_modality).localeCompare(b.exam_modality);
    if (orderColumn === 3 && orderAsc === false)
      return String(b.exam_modality).localeCompare(a.exam_modality);

    if (orderColumn === 4 && orderAsc === true)
      return parseFloat(a.nicip_standard) - parseFloat(b.nicip_standard);
    if (orderColumn === 4 && orderAsc === false)
      return parseFloat(b.nicip_standard) - parseFloat(a.nicip_standard);

    if (orderColumn === 5 && orderAsc === true)
      return parseFloat(a.generic_standard) - parseFloat(b.generic_standard);
    if (orderColumn === 5 && orderAsc === false)
      return parseFloat(b.generic_standard) - parseFloat(a.generic_standard);

    if (orderColumn === 6 && orderAsc === true)
      return parseFloat(a.nicip_hours) - parseFloat(b.nicip_hours);
    if (orderColumn === 6 && orderAsc === false)
      return parseFloat(b.nicip_hours) - parseFloat(a.nicip_hours);

    if (orderColumn === 7 && orderAsc === true)
      return parseFloat(a.generic_hours) - parseFloat(b.generic_hours);
    if (orderColumn === 7 && orderAsc === false)
      return parseFloat(b.generic_hours) - parseFloat(a.generic_hours);
  };
  const onHeaderClick = (colNumber) => {
    if (colNumber === orderColumn) {
      // The column has been clicked again so simply change the order
      setOrderAsc(!orderAsc);
      return;
    }
    setOrderColumn(colNumber);
    setOrderAsc(true);
  };
  // </Sorting of coluimns NAJ>

  return (
    <div>
      <h2 className={styles.rptHeaders}>NICIP Reports</h2>
      <TableContainer>
        <Table className={table.dashboard} hover="true">
          <TableHead>
            <TableRow className={styles.borderedHeadRow}>
              <TableCell
                style={{ cursor: "pointer" }}
                onClick={() => {
                  onHeaderClick(0);
                }}
              >
                Reports{" "}
                <IconSort
                  ThisColumnNumber={0}
                  OrderColumn={orderColumn}
                  OrderAsc={orderAsc}
                />
              </TableCell>
              <TableCell
                style={{ cursor: "pointer" }}
                onClick={() => {
                  onHeaderClick(1);
                }}
              >
                Exam Code{" "}
                <IconSort
                  ThisColumnNumber={1}
                  OrderColumn={orderColumn}
                  OrderAsc={orderAsc}
                />
              </TableCell>
              <TableCell
                style={{ cursor: "pointer" }}
                onClick={() => {
                  onHeaderClick(2);
                }}
              >
                Exam description{" "}
                <IconSort
                  ThisColumnNumber={2}
                  OrderColumn={orderColumn}
                  OrderAsc={orderAsc}
                />
              </TableCell>
              <TableCell
                style={{ cursor: "pointer" }}
                onClick={() => {
                  onHeaderClick(3);
                }}
              >
                Exam modality{" "}
                <IconSort
                  ThisColumnNumber={3}
                  OrderColumn={orderColumn}
                  OrderAsc={orderAsc}
                />
              </TableCell>
              <TableCell
                style={{ cursor: "pointer" }}
                onClick={() => {
                  onHeaderClick(4);
                }}
              >
                NICIP standard{" "}
                <IconSort
                  ThisColumnNumber={4}
                  OrderColumn={orderColumn}
                  OrderAsc={orderAsc}
                />
              </TableCell>
              <TableCell
                style={{ cursor: "pointer" }}
                onClick={() => {
                  onHeaderClick(5);
                }}
              >
                Generic standard{" "}
                <IconSort
                  ThisColumnNumber={5}
                  OrderColumn={orderColumn}
                  OrderAsc={orderAsc}
                />
              </TableCell>
              <TableCell
                style={{ cursor: "pointer" }}
                onClick={() => {
                  onHeaderClick(6);
                }}
              >
                Hours (NICIP){" "}
                <IconSort
                  ThisColumnNumber={6}
                  OrderColumn={orderColumn}
                  OrderAsc={orderAsc}
                />
              </TableCell>
              <TableCell
                style={{ cursor: "pointer" }}
                onClick={() => {
                  onHeaderClick(7);
                }}
              >
                Hours (generic){" "}
                <IconSort
                  ThisColumnNumber={7}
                  OrderColumn={orderColumn}
                  OrderAsc={orderAsc}
                />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dsNICIP &&
              dsNICIP.length > 0 &&
              dsNICIP.sort(sortData).map((sim, index) => (
                <TableRow key={index}>
                  <TableCell>{sim.reports}</TableCell>
                  <TableCell>{sim.nicip_code}</TableCell>
                  <TableCell>{sim.examination}</TableCell>
                  <TableCell>{sim.exam_modality}</TableCell>
                  <TableCell>{sim.nicip_standard}</TableCell>
                  <TableCell>{sim.generic_standard}</TableCell>
                  <TableCell>{sim.nicip_hours}</TableCell>
                  <TableCell>{sim.generic_hours}</TableCell>
                </TableRow>
              ))}
            <TableRow className={styles.borderedHeadRow}>
              <TableCell>
                <b>{hideIfZero(roundNumber(reports_total, 4))}</b>
              </TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell>
                <b>{hideIfZero(roundNumber(nicip_hours_total, 4))}</b>
              </TableCell>
              <TableCell>
                <b>{hideIfZero(roundNumber(generic_hours_total, 4))}</b>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ReportNicipTable;
