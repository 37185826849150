import React, { useContext, useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { GoPencil } from "react-icons/go";
import { FaTrash } from "react-icons/fa";
import {
  getSimPatientDemand,
  delSimPatientDemand,
} from "../../../api/ApiPtlCalls";
import { Model01SimSettingsContext, Model01IsLocked } from "../Model01";
import ModalConfirm from "../../modal/ModalConfirm";
import PatiendDemand from "./PatientDemand";
import ModalLayout from "../../modal/Modal";
import style from "../../../scss/style.module.scss";
import moment from "moment";

const PatientDemandGrid = ({ ctrlValid, ctrlNumber }) => {
  const [data, writeSimSettings] = useContext(Model01SimSettingsContext); // eslint-disable-line no-unused-vars
  const [isLocked] = useContext(Model01IsLocked);
  const [dsRecords, setdsRecords] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [popupRecord, setPopupRecord] = useState([]);
  const [recordId, setRecordId] = useState("");

  function ValidateFormData() {
    // There is no test
    ctrlValid(ctrlNumber, true);
  }

  // Get the simulation data
  const fetchRecords = async (simId) => {
    getSimPatientDemand(
      simId,
      (simData) => {
        // console.log("Patient Demand: ", simData);
        setdsRecords(simData);
        // Data retieved myst be valid
        ValidateFormData();
      },
      (error) => {
        console.log(error);
      }
    );
  };

  // Initial data population
  useEffect(() => {
    if (data["simulation_id"] !== undefined) {
      // console.log("About to get patient demand:", data["simulation_id"]);
      fetchRecords(data["simulation_id"]);
    }
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  // Automate the form validation
  useEffect(() => {
    ValidateFormData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dsRecords]);

  const rowDelete = () => {
    modalDeleteClose();

    delSimPatientDemand(
      recordId,
      () => {
        // Remove the item from the array
        var myArray = dsRecords.filter((a) => {
          return a.id !== recordId;
        });
        setdsRecords(myArray);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const newRecord = () => {
    var newAilmentRecord = [];
    newAilmentRecord.simulation_id = data.simulation_id;
    newAilmentRecord.id = "00000000-0000-0000-0000-000000000000";

    setPopupRecord([]);
    setPopupRecord(newAilmentRecord);
    setIsNew(true);
    setShowModal(true);
  };

  const updateRecord = async (recordId) => {
    var ail = dsRecords.filter((a) => {
      return a.id === recordId;
    });
    setPopupRecord(ail[0]);
    setIsNew(false);
    setShowModal(true);
  };

  const dataUpdated = (e) => {
    if (!isNew) {
      //alert(e);
      var myArray = dsRecords.filter((a) => {
        return a.id !== e.id;
      });
      myArray.push(e);
      setdsRecords(myArray);
    } else {
      fetchRecords(data["simulation_id"]);
    }
    modalClose();
  };

  const modalClose = () => {
    setShowModal(false);
  };

  const modalDeleteClose = () => {
    setShowDeleteModal(false);
  };

  const calcChangeDate = (changeDay) => {
    const date = new Date(data["model_start_dtg"]);
    date.setDate(date.getDate() + changeDay);
    return moment(date.toISOString().split("T")[0]).format("DD-MM-YYYY");
  };

  const viewSim = (simId) => {
    updateRecord(simId);
  };

  return (
    <div>
      <h3>Change in demand</h3>
      <Table borderless="true" hover="true">
        <thead>
          <tr className={style.borderedHeadRow}>
            <th>Demand change day</th>
            <th>Calc change date</th>
            <th>New patients</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {dsRecords.length > 0 ? (
            dsRecords
              .sort((a, b) => a.demand_change_day - b.demand_change_day)
              .map((sim) => (
                <tr
                  className={style.blackRow}
                  key={sim.id}
                  onClick={() => isLocked === false && viewSim(sim.id)}
                >
                  <td>{sim.demand_change_day}</td>
                  <td>{calcChangeDate(sim.demand_change_day)}</td>
                  <td>{sim.new_patients_per_day}</td>
                  <td>
                    <span
                      role="button"
                      onClick={(e) => {
                        e.stopPropagation();
                        setRecordId(sim.id);
                        setShowDeleteModal(true);
                      }}
                      className={`${isLocked ? "d-none" : ""}`}
                    >
                      <FaTrash size={25} color="#7A2048" />
                    </span>
                    <span
                      role="button"
                      onClick={() => {
                        updateRecord(sim.id);
                      }}
                      className={`${isLocked ? "d-none" : ""}`}
                    >
                      <GoPencil size={25} color="#008181" />
                    </span>
                  </td>
                </tr>
              ))
          ) : (
            <tr>
              <td colSpan={3} style={{ textAlign: "center" }}>
                <p style={{ color: "black" }}>No records to show</p>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <button
          className={`btn btn-outline-primary ${isLocked ? "d-none" : ""}`}
          onClick={newRecord}
        >
          Create New
        </button>
      </div>
      <ModalLayout
        title={isNew ? "Add new patient demand" : "Update patiend demand"}
        show={showModal}
        handleClose={modalClose}
      >
        <PatiendDemand
          data={popupRecord}
          isNew={isNew}
          dataUpdated={dataUpdated}
          simulation_id={data.simulation_id}
        />
      </ModalLayout>
      <ModalConfirm
        show={showDeleteModal}
        handleClose={modalDeleteClose}
        title="Please confirm you want to delete this patient demand"
        confirmed={rowDelete}
      ></ModalConfirm>
    </div>
  );
};

export default PatientDemandGrid;
