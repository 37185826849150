import React, { useContext, useState } from "react";
import Settings from "./Settings";
import { ModalSimInputContext } from "../Model01Tabs";
import RptPtlUpload from "./RptPtlUpload";
import styles from "../../../indexOrg.module.scss";

const TabGeneral = ({ tabNo }) => {
  const validateTab = useContext(ModalSimInputContext);
  const [ctlValidation, setCtlValidation] = useState([false]);
  const [seriesHidden, setSeriesHidden] = useState([]);

  const ctlValid = (controlNumber, value) => {
    ctlValidation[controlNumber] = value;
    setCtlValidation(ctlValidation);
    validateTab(
      tabNo,
      ctlValidation.every((element) => element === true)
    );
  };

  return (
    <>
      <div>
        <h3>Simulation Settings</h3>
        <Settings ctrlValid={ctlValid} ctrlNumber="0" displayType="general" />

        <div className={styles.panel}>
          <RptPtlUpload
            position="first"
            seriesHidden={seriesHidden}
            setSeriesHidden={setSeriesHidden}
          />
        </div>
      </div>
    </>
  );
};

export default TabGeneral;
