import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const Consult_links = ({ selected, showCompany }) => {
  return (
    <div>
      {showCompany && showCompany === "true" && (
        <Link to={"/Co"} className={"link-info"}>
          Trust
        </Link>
      )}
    </div>
  );
};
Consult_links.propTypes = {
  selected: PropTypes.string.isRequired,
};

export default Consult_links;
