// import { List } from "@mui/material";
import React, { useState, useContext, useRef } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Model01RptRefreshContext } from "../Model01";
import { Model01SimSettingsContext, Model01IsLocked } from "../Model01";
import { runModelCelery, runModelTaskStatus } from "../../../api/ApiFnCalls";
import { postAuditTrailEntry } from "../../../api/ApiCoreCalls";

import { useInterval } from "../../../common/timers";
import StatusOfManager from "../../progress/StatusOfManager";
import RunCheckData from "./RunCheckData";
// import StatusOfProcess from "../../progress/StatusOfProcess";
import progress from "../../../scss/style.module.scss";

const RunModelManager = ({ runComplete }) => {
  const [isRefreshNeeded, refreshNeeded] = useContext(Model01RptRefreshContext); // eslint-disable-line no-unused-vars
  const [jsonTaskData, setJsonTaskData] = useState([]);
  const [data, writeSimSettings] = useContext(Model01SimSettingsContext); // eslint-disable-line no-unused-vars
  const [isLocked] = useContext(Model01IsLocked);
  const [isManPolling, setIsManPolling] = useState(false);
  const [errors, setErrors] = useState([]);
  const [warnings, setWarnings] = useState([]);
  const [messages, setMessages] = useState([]);
  const jsonTaskDataRef = useRef([]);

  const addElement = (operation, run_id, title, status, task_id) => {
    jsonTaskDataRef.current = [
      ...jsonTaskDataRef.current,
      { operation, run_id, title, status, task_id },
    ];
    setJsonTaskData(jsonTaskDataRef.current);
    console.log(
      "jsonTaskDataRef.current.length = " +
        jsonTaskDataRef.current.length.toString()
    );
  };

  // Use of a hook for polling.
  useInterval(
    async () => {
      // console.log("Interval writing");
      checkTasks();
    },
    isManPolling ? 2000 : null
  );

  const updateRowStatus = (operation, run_id, newStatus) => {
    const updatedArray = jsonTaskDataRef.current.map((row) => {
      if (row.operation === operation && row.run_id === run_id) {
        return { ...row, status: newStatus };
      }
      return row;
    });
    jsonTaskDataRef.current = updatedArray;
    setJsonTaskData(jsonTaskDataRef.current);
    // setJsonTaskData(updatedArray);
  };
  const updateRowStatusTaskId = (operation, run_id, newStatus, taskId) => {
    // console.log("Just updating row one jsonTaskData");
    const updatedArray = jsonTaskDataRef.current.map((row) => {
      if (row.operation === operation && row.run_id === run_id) {
        return { ...row, status: newStatus, task_id: taskId };
      }
      return row;
    });
    jsonTaskDataRef.current = updatedArray;
    setJsonTaskData(jsonTaskDataRef.current);
    // setJsonTaskData(updatedArray);
  };
  const getRow = (operation, run_id) => {
    return jsonTaskDataRef.current.find(
      (row) => row.operation === operation && row.run_id === run_id
    );
  };
  const getRowsByOperation = (operation) => {
    return jsonTaskDataRef.current.filter((row) => row.operation === operation);
  };
  const getAnalysisRows = (operation) => {
    return jsonTaskDataRef.current.filter((row) => row.operation >= operation);
  };

  // When the timer fires this function checks what needs to be done.
  const checkTasks = () => {
    if (getRow(0, 0) === undefined) {
      // We need to add the first entry - the preparation operation = 0
      addElement(0, 0, "Inital setup", "", "");
      // Once added then send request.
      // console.log("Just set row one jsonTaskData");
      post_task(data.simulation_id, 0, 0);
      return;
    }

    // Check the status of the initial cleanup
    // Locate the cleanup
    var currentRow = getRow(0, 0);
    if (currentRow.status !== "SUCCESS") {
      // Wait for success
      // Consider what to do.
      // The preparation stage is not successful yet...
      if (currentRow.status === "PENDING" || currentRow.status === "Added") {
        // Send another check
        task_status(
          currentRow.task_id,
          currentRow.operation,
          currentRow.run_id
        );
      }
      return;
    }

    // Check the status of the model iterations:
    var modelRows = getRowsByOperation(1);
    // Have any model iterations been added?
    if (modelRows.length === 0) {
      // None added so far.
      // Add the model iterations.
      for (let index = 0; index < data.no_runs; index++) {
        addElement(
          1,
          index + 1,
          "Model iteration - " + (index + 1).toString(),
          "",
          ""
        );
        post_task(data.simulation_id, 1, index + 1);
      }
      return;
    }

    // Check the status of each of the model interations that is not success
    var allSuccessful = true;
    modelRows.forEach((row) => {
      if (row.status === "PENDING" || row.status === "Added") {
        // Send another check
        task_status(row.task_id, row.operation, row.run_id);
      }
      allSuccessful = allSuccessful && row.status === "SUCCESS";
    });
    if (!allSuccessful) {
      // We need to wait until they are all succesful before proceeding.
      return;
    }

    // Check the data analysis tasks
    var queryRows = getAnalysisRows(10);
    // Have any been added if not add one now?
    // The analysis can be performed in 6 separate units - at the moment there is only 1.
    if (queryRows.length === 0) {
      // addElement(2, 1, "Analysing the data", "", "");
      // post_task(data.simulation_id, 2, 1);
      for (let index = 0; index < 6; index++) {
        addElement(
          index + 10,
          999,
          "Data analysis - " + (index + 1).toString() + "/6",
          "",
          ""
        );
        post_task(data.simulation_id, index + 10, 999);
      }
      return;
    }

    // Check the status of each of the amalysis entries
    var completelySuccessful = true;
    queryRows.forEach((row) => {
      if (row.status === "PENDING" || row.status === "Added") {
        // Send another check
        task_status(row.task_id, row.operation, row.run_id);
      }
      completelySuccessful = completelySuccessful && row.status === "SUCCESS";
    });
    if (completelySuccessful) {
      // Everything is now complete so stop the polling
      setIsManPolling(false);
      refreshNeeded();
      runComplete();
    }
  };

  // this is the code that will check the task's status
  const task_status = (task_id, operation, run_id) => {
    runModelTaskStatus(
      task_id,
      (response) => {
        // PENDING
        // SUCCESS
        // REVOKED
        // None
        updateRowStatus(operation, run_id, response);
      },
      () => {}
    );
  };

  // this is the code that will post the request to the function
  const post_task = (sim_id, operation, run_id) => {
    console.log("Just running post task");
    runModelCelery(
      sim_id,
      run_id,
      operation,
      (response) => {
        // {"Result": "Added", "task_id": "16a55e5e-124b-42ed-ad69-5afa0783203f" }
        console.log(
          "Just response from post task just about to update jsonTaskData"
        );
        updateRowStatusTaskId(
          operation,
          run_id,
          response.Result,
          response.task_id
        );
      },
      () => {}
    );
  };

  const post_new_audit = async () => {
    postAuditTrailEntry(
      "PTL model run",
      data.id + "|" + data.title,
      () => {},
      (error) => {
        setWarnings([
          "There has been a problem adding an audit trail entry - " + error,
        ]);
      }
    );
  };

  // className="btn btn-outline-primary d-none"

  return (
    <>
      <button
        className={`btn btn-outline-primary ${isLocked ? "d-none" : ""}`}
        onClick={() => {
          if (!isManPolling) {
            // Record this action to the audit trail
            post_new_audit();
            // Clear the existing array
            setJsonTaskData([]);
            jsonTaskDataRef.current = [];
            setIsManPolling(true);
            // startLooping();
          } else {
            setIsManPolling(false);
          }
        }}
      >
        Run
      </button>

      {isManPolling ? (
        <div className={progress.progressSpinner}>
          <StatusOfManager
            isActive={true}
            onClickCancel={() => {
              setIsManPolling(false);
            }}
            jsonTaskData={jsonTaskData}
          />
          {/* <StatusOfProcess sim_guid={data.id} isActive={true} /> */}
        </div>
      ) : (
        <>
          &nbsp;
          <RunCheckData
            setErrors={setErrors}
            setWarnings={setWarnings}
            setMessages={setMessages}
          />
        </>
      )}

      <Row className="justify-content-md-left">
        <Col md="12" className="row-col-box">
          {errors && <br />}
          {errors &&
            Array.isArray(errors) &&
            errors.map((msg, index) => (
              <div key={index} className="alert alert-danger">
                {msg}
              </div>
            ))}
        </Col>
        <Col md="12" className="row-col-box">
          {warnings && <br />}
          {warnings &&
            Array.isArray(warnings) &&
            warnings.map((msg, index) => (
              <div key={index} className="alert alert-warning">
                {msg}
              </div>
            ))}
        </Col>
        <Col md="12" className="row-col-box">
          {messages && <br />}
          {messages &&
            Array.isArray(messages) &&
            messages.map((msg, index) => (
              <div key={index} className="alert alert-success">
                {msg}
              </div>
            ))}
        </Col>
      </Row>

      <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
        {jsonTaskData &&
          jsonTaskData.map((msg, index) => {
            const labelId = `checkbox-list-label-${msg.title}`;

            return (
              <>
                <ListItem key={index} disablePadding>
                  <ListItemButton
                    role={undefined}
                    // onClick={handleToggle(value)}
                    dense
                    styleName={progress.tightOnControlMargin}
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={msg.status === "SUCCESS"}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ "aria-labelledby": labelId }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      id={labelId}
                      primary={msg.title}
                      secondary={"Status: " + msg.status}
                      styleName={progress.tightOnControl}
                    />
                  </ListItemButton>
                </ListItem>
              </>
            );
          })}
      </List>
    </>
  );
};

export default RunModelManager;
