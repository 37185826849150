import React from "react";
import { TextField as MdTextField } from "@mui/material";

const TextFieldBasic = ({
  required = false,
  variant = "standard",
  label,
  ...props
}) => {
  return (
    <div>
      <MdTextField
        autoComplete="off"
        className={`form-control shadow-none 
        ${props.type && props.type === "date" && " form-control-date "} 
        ${props.type && props.type === "time" && " form-control-time "} 
        ${props.type && props.type === "number" && " form-control-number "}
        ${props.type && props.type === "checkbox" && " form-control-checkbox "}
        `}
        style={{
          paddingRight: "",
          paddingBottom: "20px",
          backgroundColor: "transparent",
        }}
        {...props}
        // fullWidth
        label={label}
        // variant="filled"
        variant={variant}
        required={required}
      />
    </div>
  );
};
export default TextFieldBasic;
