import React, { useState } from "react";
import { useEffect } from "react";
import moment from "moment";

const UploadedFileName = ({ data, upload_type }) => {
  const [fileName, setFileName] = useState("");
  const [uploadDate, setUploadDate] = useState("");

  useEffect(() => {
    if (upload_type === "activity") {
      setFileName(data.activity_file_name);
      setUploadDate(data.activity_upload_date);
    }
    if (upload_type === "jobplan") {
      setFileName(data.job_plan_file_name);
      setUploadDate(data.job_plan_upload_date);
    }
    if (upload_type === "leave") {
      setFileName(data.leave_file_name);
      setUploadDate(data.leave_upload_date);
    }
    if (upload_type === "vetting") {
      setFileName(data.vetting_file_name);
      setUploadDate(data.vetting_upload_date);
    }
  }, [data, upload_type]);

  return (
    <React.Fragment>
      <div
        className={`${
          fileName === undefined || fileName === null || fileName === ""
            ? "d-none"
            : ""
        }`}
      >
        <br />
        <table>
          <tbody>
            <tr>
              <td>File name:</td>
              <td>&#160;&#160;&#160;&#160;&#160;</td>
              <td>{fileName}</td>
            </tr>
            <tr>
              <td>Date Uploaded:</td>
              <td></td>
              <td>{moment(uploadDate).format("DD-MM-YYYY")}</td>
            </tr>
          </tbody>
        </table>
        <br />
      </div>
    </React.Fragment>
  );
};

export default UploadedFileName;
