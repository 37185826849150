import React from "react";
import styles from "../indexOrg.module.scss";

const SelectField = ({ label, ...props }) => {
  return (
    <div className="mb-2">
      {label && <label>{label}</label>}
      <select
        className={`${styles["form-control-25"]} shadow-none small-text-box`}
        {...props}
        autoComplete="off"
      ></select>
    </div>
  );
};

export default SelectField;
