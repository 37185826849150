import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import progress from "./progress.module.scss";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";

/// This is specific to a simulation
const StatusOfManager = ({ isActive, onClickCancel, jsonTaskData }) => {
  const [active, setActive] = useState(isActive); // eslint-disable-line no-unused-vars
  // const active = useRef(isActive);

  return (
    <div className={progress.activeSpinner}>
      {active && (
        <div>
          <div className={progress.spinnerIcon}>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              className={progress.spinner}
            />
          </div>
          <h1>Processing...</h1>

          <button
            className="btn btn-outline-primary w-100"
            onClick={() => {
              onClickCancel();
            }}
          >
            Cancel
          </button>

          <List
            sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
          >
            {jsonTaskData &&
              jsonTaskData.map((msg, index) => {
                const labelId = `checkbox-list-label-${msg.title}`;

                return (
                  <>
                    <ListItem key={index} disablePadding>
                      <ListItemButton
                        role={undefined}
                        // onClick={handleToggle(value)}
                        dense
                        className={progress.tightOnControlMargin}
                      >
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={msg.status === "SUCCESS"}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          id={labelId}
                          primary={msg.title}
                          secondary={"Status: " + msg.status}
                          className={progress.tightOnControl}
                        />
                      </ListItemButton>
                    </ListItem>
                  </>
                );
              })}
          </List>
        </div>
      )}
    </div>
  );
};
export default StatusOfManager;
