import React from "react";
import { BsSearch } from "react-icons/bs";

const AddNewSearch = ({ AddNew, SetSearchText }) => {
  function OnChange(e) {
    SetSearchText(e.currentTarget.value);
  }

  return (
    <div className="container-fluid">
      <div className="d-flex flex-row align-items-center">
        <div className="flex-fill d-flex">
          <div className="input-group me-5">
            <div className="input-group-prepend">
              <span className="input-group-text" id="basic-addon1">
                <BsSearch size={30} color="#008181" />
              </span>
            </div>
            <input
              type="text"
              maxLength="20"
              onChange={(e) => OnChange(e)}
              className="form-control "
            />
          </div>
        </div>
        {AddNew && (
          <div style={{ float: "right" }}>
            <button className="btn btn-outline-primary" onClick={AddNew}>
              Create New
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddNewSearch;
