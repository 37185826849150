import React from "react";
import { Link } from "react-router-dom";

const HeaderTopLink = ({ text, link, selected, props }) => {
  return (
    <>
      <Link
        className={
          selected === true ? "headerLink headerLink--underline" : "headerLink"
        }
        to={link}
        {...props}
      >
        {text}
      </Link>
    </>
  );
};
export default HeaderTopLink;
