import React, { useState, useEffect } from "react";
import styles from "../../../../scss/style.module.scss";
import table from "../../../../table.module.scss";
import moment from "moment";
import Table from "react-bootstrap/Table";
import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  numberWithCommas,
  fixNumberString,
  roundNumber,
  hideIfZero,
} from "../../../../common/math_functions";

const DepartmentRptSummaryTable = ({
  data,
  dsConsultantNames,
  summaryData,
  leaveData,
  setAuthoriser,
  dsVetting,
  rptVetting,
  hiddenConsultants,
}) => {
  const [dsRegionalTable, setDsRegionalTable] = useState({});
  // Totals
  const [total_act_hours, setTotal_act_hours] = useState(0);
  const [total_exp_hours, setTotal_exp_hours] = useState(0);

  const [total_reports, setTotal_reports] = useState(0);
  const [total_vetted, setTotal_vetted] = useState(0);
  const [total_wli_reports, setTotal_wli_reports] = useState(0);
  const [total_leave, setTotal_leave] = useState(0);
  const [orderColumn, setOrderColumn] = useState(0);
  const [orderAsc, setOrderAsc] = useState(true);
  // const [total_leave_adj_hours, setTotal_leave_adj_hours] = useState(0);
  const [total_percentage, setTotal_percentage] = useState(0);

  const [period_days, setPeriod_days] = useState(0);

  useEffect(() => {
    let act_hours = 0;
    let expected_duration_gross = 0;
    let exp_hours = 0;
    let reports = 0;
    let vetted = 0;
    let wli_reports = 0;
    let leave = 0;

    dsRegionalTable &&
      dsRegionalTable.length > 0 &&
      dsRegionalTable.forEach((item) => {
        act_hours += item.act_hours;
        reports += item.reports;
        vetted += item.vetted;
        wli_reports += item.wli_reports;
        leave += item.leave;
        expected_duration_gross += item.expected_duration_gross;
        exp_hours += item.exp_hours;
      });

    let total_actual_hours = act_hours; //  + (vetted * data.vetting_duration) / 60;
    setTotal_act_hours(total_actual_hours);
    setTotal_reports(reports);
    setTotal_vetted(vetted);
    setTotal_wli_reports(wli_reports);
    setTotal_leave(leave);
    setTotal_exp_hours(exp_hours);

    // Calculate the percentage
    let total_exp_hours_gross = (expected_duration_gross / 365) * period_days;
    let proRataHours = ((260 * dsRegionalTable.length) / 365) * period_days; //Length is number of consultants
    let percentage =
      total_actual_hours /
      (total_exp_hours_gross * ((proRataHours - leave) / proRataHours));

    setTotal_percentage(percentage);

    // Number below is used to calculate the leave percentages.
    // For more details on calculation see ReportLeaveAdjustedTable (Set showDevNumbers = true for visiblity.)

    // let gross_hours = expPA * 4 * 52;
    // let gross_hours_in_period = gross_hours * (period_days / 365);
    // setTotal_act_hours_adj(
    //   gross_hours_in_period *
    //     ((260 * (period_days / 365) - leave) / (260 * (period_days / 365)))
    // );
  }, [dsRegionalTable, period_days, data.vetting_duration]);

  useEffect(() => {
    // Build the table
    // Caclulate for this consultant
    // Calc totals that are not leave related
    const rowCalc = (dsLocal, authoriser) => {
      let act_hours = 0;
      let exp_hours = 0;
      let expected_duration_gross = 0;
      let exp_pas = 0;
      let act_pas = 0;
      let reports = 0;
      let wli_reports = 0;
      let leave = 0;
      let leave_adj_hours = 0;
      let leave_adj_hours_act_hours = 0;
      let leave_adj_hours_exp_hours = 0;

      summaryData &&
        summaryData.length > 0 &&
        summaryData.forEach((sData) => {
          if (sData.authoriser === authoriser) {
            exp_hours += sData.expected_duration;
            exp_pas += sData.expected_pa;
            expected_duration_gross += sData.expected_duration_gross;

            if (!sData.waiting_list_initiative) {
              // Only include reports and actual hours when not WLI
              reports += sData.total_reports;
              act_hours += sData.total_exam_time;
              act_pas += sData.actual_pa;
            } else {
              wli_reports += sData.total_reports;
            }
          }
        });

      // Calc the leave figures for this consultant
      let local_period_days = 0;
      leaveData &&
        leaveData.length > 0 &&
        leaveData.forEach((lData) => {
          if (lData.first_report_authoriser === authoriser) {
            if (lData.leave !== null) {
              leave = lData.act_leave;
            } else {
              leave = 0;
            }
            let gross_hours = exp_pas * 4 * 52;
            let gross_hours_in_period = gross_hours * (lData.period_days / 365);

            // // Use the actual hours - not a calc on the expected pas
            // let gross_hours_in_period = exp_hours;

            leave_adj_hours =
              gross_hours_in_period *
              ((260 * (lData.period_days / 365) - lData.act_leave) /
                (260 * (lData.period_days / 365)));

            leave_adj_hours_act_hours =
              act_hours *
              ((260 * (lData.period_days / 365) - lData.act_leave) /
                (260 * (lData.period_days / 365)));

            leave_adj_hours_exp_hours =
              exp_hours *
              ((260 * (lData.period_days / 365) - lData.act_leave) /
                (260 * (lData.period_days / 365)));

            local_period_days = lData.period_days;
          }
        });

      setPeriod_days(local_period_days);
      let proRataDoctorHours = ((260 * 1) / 365) * local_period_days;

      let vetting = countVettingByName(dsVetting, authoriser);

      let total_actual_hours =
        act_hours + (vetting * data.vetting_duration) / 60;

      // Work out the percentage
      let adjPercentage =
        total_actual_hours /
        (((expected_duration_gross * local_period_days) / 365) *
          ((proRataDoctorHours - leave) / proRataDoctorHours));

      // Push the numbers into a local array
      dsLocal.push({
        name: authoriser,
        act_hours: total_actual_hours,
        exp_hours: exp_hours,
        exp_pas: exp_pas,
        act_pas: act_pas,
        reports: reports,
        wli_reports: wli_reports,
        leave: leave,
        leave_adj_hours: leave_adj_hours,
        vetted: vetting,
        leave_adj_hours_act_hours: leave_adj_hours_act_hours,
        leave_adj_hours_exp_hours: leave_adj_hours_exp_hours,
        expected_duration_gross: expected_duration_gross,
        adjusted_percentage: adjPercentage,
      });
    };

    // Main loop for the consultants
    let dsLocal = [];
    dsConsultantNames &&
      dsConsultantNames.length > 0 &&
      dsConsultantNames.forEach((item) => {
        if (item.scheduled_consultant && item.scheduled_consultant === true) {
          if (item.hide && item.hide === true) {
          } else {
            rowCalc(dsLocal, item.authoriser);
          }
        }
      });

    // Set the state vars
    setDsRegionalTable(dsLocal);
  }, [data, summaryData, leaveData, dsConsultantNames, dsVetting]);

  // Work out the colours
  function getColorForValue(value) {
    if (value < 70) {
      return "#DC143C"; // Red
    } else if (value < 90) {
      return "#ffc000"; // Amber
    } else {
      return "#32CD32"; // Green
    }
  }

  const countVettingByName = (dsVet, name) => {
    if (dsVet && dsVet.length > 0) {
      const record = dsVet.find((record) => record.vetted_by === name);
      return record ? record.count : 0;
    }
    return 0;
  };

  const cellStyle = (value) => {
    return {
      color: "#ffffff",
      background: getColorForValue(value),
      padding: "5px",
      borderRadius: 10,
      textAlign: "center",
      border: "3px solid white",
      alignItems: "center",
    };
  };

  const cellCenter = () => {
    return {
      textAlign: "center",
    };
  };

  // Action row clicked
  const rowClicked = (authoriser) => {
    // Switch to individual and update the dropdown
    setAuthoriser(authoriser);
  };

  const sortData = (a, b) => {
    if (orderColumn === 0 && orderAsc === true)
      return String(a.name).localeCompare(b.name);
    if (orderColumn === 0 && orderAsc === false)
      return String(b.name).localeCompare(a.name);

    if (orderColumn === 1 && orderAsc === true)
      return parseFloat(a.act_hours) - parseFloat(b.act_hours);
    if (orderColumn === 1 && orderAsc === false)
      return parseFloat(b.act_hours) - parseFloat(a.act_hours);

    if (orderColumn === 2 && orderAsc === true)
      return (
        parseFloat(a.act_hours / a.exp_hours) -
        parseFloat(b.act_hours / b.exp_hours)
      );
    if (orderColumn === 2 && orderAsc === false)
      return (
        parseFloat(b.act_hours / b.exp_hours) -
        parseFloat(a.act_hours / a.exp_hours)
      );

    if (orderColumn === 3 && orderAsc === true)
      return parseFloat(a.reports) - parseFloat(b.reports);
    if (orderColumn === 3 && orderAsc === false)
      return parseFloat(b.reports) - parseFloat(a.reports);

    if (orderColumn === 4 && orderAsc === true)
      return parseFloat(a.vetted) - parseFloat(b.vetted);
    if (orderColumn === 4 && orderAsc === false)
      return parseFloat(b.vetted) - parseFloat(a.vetted);

    if (orderColumn === 5 && orderAsc === true)
      return parseFloat(a.wli_reports) - parseFloat(b.wli_reports);
    if (orderColumn === 5 && orderAsc === false)
      return parseFloat(b.wli_reports) - parseFloat(a.wli_reports);

    if (orderColumn === 6 && orderAsc === true)
      return parseFloat(a.leave) - parseFloat(b.leave);
    if (orderColumn === 6 && orderAsc === false)
      return parseFloat(b.leave) - parseFloat(a.leave);

    if (orderColumn === 7 && orderAsc === true)
      return (
        parseFloat(a.act_hours / a.leave_adj_hours) -
        parseFloat(b.act_hours / b.leave_adj_hours)
      );
    if (orderColumn === 7 && orderAsc === false)
      return (
        parseFloat(b.act_hours / b.leave_adj_hours) -
        parseFloat(a.act_hours / a.leave_adj_hours)
      );
  };

  const onHeaderClick = (colNumber) => {
    if (colNumber === orderColumn) {
      // The column has been clicked again so simply change the order
      setOrderAsc(!orderAsc);
      return;
    }
    setOrderColumn(colNumber);
    setOrderAsc(true);
  };

  return (
    <React.Fragment>
      <div>
        <h2 className={styles.rptHeaders}>
          Departmental Summary ({moment(data.start_date).format("DD/MM/YYYY")} -{" "}
          {moment(data.end_date).format("DD/MM/YYYY")})
        </h2>
        <br />
        <TableContainer>
          <Table className={`${table.dashboard}`} hover="true">
            <TableHead>
              <TableRow className={styles.borderedHeadRow}>
                <TableCell
                  className={styles.colChar255}
                  onClick={() => {
                    onHeaderClick(0);
                  }}
                >
                  Consultant
                </TableCell>
                <TableCell
                  className={styles.colNumber}
                  style={{ textAlign: "center" }}
                  onClick={() => {
                    onHeaderClick(1);
                  }}
                >
                  Actual Hrs
                </TableCell>
                <TableCell
                  className={styles.colNumber}
                  style={{ textAlign: "center" }}
                  onClick={() => {
                    onHeaderClick(2);
                  }}
                >
                  % Act. vs Exp.
                </TableCell>
                <TableCell
                  className={styles.colNumber}
                  style={{ textAlign: "center" }}
                  onClick={() => {
                    onHeaderClick(3);
                  }}
                >
                  No. of Reports
                </TableCell>

                <TableCell
                  className={`${styles.colNumber} ${
                    rptVetting === "1" ? "d-none" : ""
                  }`}
                  style={{ textAlign: "center" }}
                  onClick={() => {
                    onHeaderClick(4);
                  }}
                >
                  Vetted Reports
                </TableCell>

                <TableCell
                  className={styles.colNumber}
                  style={{ textAlign: "center" }}
                  onClick={() => {
                    onHeaderClick(5);
                  }}
                >
                  WLI Reports
                </TableCell>
                <TableCell
                  className={styles.colNumber}
                  style={{ textAlign: "center" }}
                  onClick={() => {
                    onHeaderClick(6);
                  }}
                >
                  Leave
                </TableCell>
                <TableCell
                  className={`${styles.colNumber} ${
                    leaveData && leaveData.length > 0 ? "" : "d-none"
                  }`}
                  style={{ textAlign: "center" }}
                  onClick={() => {
                    onHeaderClick(7);
                  }}
                >
                  % Adjusted for Leave
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dsRegionalTable &&
                dsRegionalTable.length > 0 &&
                dsRegionalTable.sort(sortData).map((sim, index) => (
                  <TableRow
                    key={index}
                    onClick={(e) => {
                      rowClicked(sim.name);
                    }}
                  >
                    <TableCell>{sim.name}</TableCell>
                    <TableCell
                      className={table.colNumberCenter}
                      style={cellCenter()}
                    >
                      {numberWithCommas(roundNumber(sim.act_hours, 1))}
                    </TableCell>
                    <TableCell
                      style={cellCenter()}
                      // style={cellStyle((sim.act_hours / sim.exp_hours) * 100)}
                    >
                      {fixNumberString(
                        (sim.act_hours / sim.exp_hours) * 100,
                        1
                      )}
                    </TableCell>
                    <TableCell style={cellCenter()}>
                      {numberWithCommas(roundNumber(sim.reports))}
                    </TableCell>
                    <TableCell
                      style={cellCenter()}
                      className={`${rptVetting === "1" ? "d-none" : ""}`}
                    >
                      {numberWithCommas(roundNumber(sim.vetted, 1))}
                    </TableCell>
                    <TableCell style={cellCenter()}>
                      {numberWithCommas(roundNumber(sim.wli_reports, 1))}
                    </TableCell>
                    <TableCell style={cellCenter()}>
                      {numberWithCommas(roundNumber(sim.leave, 1))}
                    </TableCell>
                    <TableCell
                      className={`${
                        leaveData && leaveData.length > 0 ? "" : "d-none"
                      }`}
                      style={cellStyle(sim.adjusted_percentage * 100)}
                    >
                      {fixNumberString(sim.adjusted_percentage * 100, 1)}
                    </TableCell>
                  </TableRow>
                ))}
              <TableRow className={styles.borderedHeadRow} key={10000}>
                <TableCell></TableCell>
                <TableCell style={cellCenter()}>
                  <b>
                    {numberWithCommas(
                      hideIfZero(roundNumber(total_act_hours, 1))
                    )}
                  </b>
                </TableCell>
                <TableCell style={cellCenter()}>
                  <b>
                    {numberWithCommas(
                      hideIfZero(
                        fixNumberString(
                          (total_act_hours / total_exp_hours) * 100,
                          1
                        )
                      )
                    )}
                  </b>
                </TableCell>
                <TableCell style={cellCenter()}>
                  <b>
                    {numberWithCommas(
                      hideIfZero(roundNumber(total_reports, 1))
                    )}
                  </b>
                </TableCell>
                <TableCell style={cellCenter()}>
                  <b>
                    {numberWithCommas(hideIfZero(roundNumber(total_vetted, 1)))}
                  </b>
                </TableCell>
                <TableCell style={cellCenter()}>
                  <b>
                    {numberWithCommas(
                      hideIfZero(roundNumber(total_wli_reports, 1))
                    )}
                  </b>
                </TableCell>
                <TableCell style={cellCenter()}>
                  <b>
                    {numberWithCommas(hideIfZero(roundNumber(total_leave, 1)))}
                  </b>
                </TableCell>

                <TableCell
                  className={`${
                    leaveData && leaveData.length > 0 ? "" : "d-none"
                  }`}
                  style={cellStyle(total_percentage * 100)}
                >
                  {numberWithCommas(
                    hideIfZero(fixNumberString(total_percentage * 100, 1))
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          {hiddenConsultants && hiddenConsultants.length > 0 && (
            <span>
              N.B. Consultants with no reporting activity due to leave &
              excluded from analysis:{" "}
            </span>
          )}
          {hiddenConsultants &&
            hiddenConsultants.length > 0 &&
            hiddenConsultants.map((name, index) => (
              <span key={index}>
                <b>
                  {name}
                  {index < hiddenConsultants.length - 1 ? ", " : ""}
                </b>
              </span>
            ))}
        </TableContainer>
      </div>
    </React.Fragment>
  );
};

export default DepartmentRptSummaryTable;
