import React, { useState, useCallback, useEffect } from "react";
// import { useRef } from "react";
import ProgressBarWithLabel from "../ProgressBarWithLabel";
import { Spinner } from "react-bootstrap";
import progress from "./progress.module.scss";

/// This is specific to a simulation
const StatusOfProcessGeneric = ({
  sim_guid,
  isActive,
  getModellingStatus,
  oneHundredPercent,
}) => {
  const [active, setActive] = useState(isActive); // eslint-disable-line no-unused-vars
  // const active = useRef(isActive);
  const [modelPercent, setModelPercent] = useState(1);
  const [messages, setMessages] = useState([]);
  // useCallback causes this to be remembered during the cycling
  // It is used in this context in combination with useEffect
  const localAddMessage = useCallback(
    (messageResult) => {
      try {
        if (!messages.includes(messageResult.model_processing_status)) {
          setMessages([messageResult.model_processing_status]);
        }
        let percentage = Math.round(messageResult.percentage);
        setModelPercent(percentage);
        if (percentage === 100) oneHundredPercent();
      } catch (error) {
        setMessages();
        setModelPercent(Math.round(0));
      }
    },
    [messages, setModelPercent, setMessages, oneHundredPercent]
  );
  // When the model code is running this should run to return the progress
  useEffect(() => {
    // if (active.current) {
    if (active) {
      const interval = setInterval(() => {
        try {
          // Get the status for this simulation.
          getModellingStatus(
            sim_guid,
            (result) => {
              //Success
              localAddMessage(result);
            },
            (error) => {
              localAddMessage(JSON.stringify(error, null, 2));
              setMessages(["There was an error: " + error]);
            }
          );
        } catch (e) {
          setMessages(["There was an error: " + e.message]);
        }
      }, 2000);
      return () => clearInterval(interval);
    }
  }, [active, localAddMessage, sim_guid, getModellingStatus]);

  // Add event listener when the component mounts
  useEffect(() => {
    const handleEscKeyPress = (event) => {
      if (event.keyCode === 27) {
        oneHundredPercent(true);
      }
    };

    document.addEventListener("keydown", handleEscKeyPress);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("keydown", handleEscKeyPress);
    };
  }, [oneHundredPercent]);

  return (
    <div className={progress.activeSpinner}>
      {active && (
        <div>
          <div className={progress.spinnerIcon}>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              className={progress.spinner}
            />
          </div>
          <h1>Processing...</h1>
          {modelPercent && modelPercent !== "0" && (
            <ProgressBarWithLabel value={modelPercent} />
          )}
        </div>
      )}
      {messages &&
        messages.map((msg, index) => (
          <div key={index} className="alert alert-success">
            {msg}
          </div>
        ))}
    </div>
  );
};
export default StatusOfProcessGeneric;
