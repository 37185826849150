import React, { useState, useCallback, useContext, useEffect } from "react";
import RptModelSummary from "./RptModelGrid";
import ErrorBoundary from "../../ErrorBoundary";
// import RptPtlUpload from "./RptPtlUpload";
import RptModelResults from "./RptModelResults";
import RptPatientAverage from "./RptPatientAverage";
import RptPatientTotal from "./RptPatientTotal";

import CtrlAudioWrapper from "./CtrlAudioWrapper";
import { Model01RptRefreshContext, Model01IsDirty } from "../Model01";
import { getReportDataSummary } from "../../../api/ApiPtlCalls";
import ExportCsv from "./ExportCsv";
import style from "../../../scss/style.module.scss";
import TabRptBarPA from "./TabRptBarPA";
import TabRptBarSum from "./TabRptBarSum";

const TabRptBar = ({ tabNo, simulation_id }) => {
  const [isRefreshNeeded, refreshNeeded] = useContext(Model01RptRefreshContext); // eslint-disable-line no-unused-vars
  const [isDirty, setIsDirty] = useContext(Model01IsDirty); // eslint-disable-line no-unused-vars
  const [viewDataDay, setViewDataDay] = useState(0);
  const [ailmentShow, setAilmentShow] = useState([]);
  const [localSummaryData, setSummaryData] = useState({}); // This contains the targets
  const [seriesHidden, setSeriesHidden] = useState([]);
  const [showReportSegments, setShowReportSegments] = useState(false);
  const [activeTab, setActiveTab] = useState("tab1");
  const [startDate, setStartDate] = useState(Date.UTC(1980, 1, 1));

  // The state of the days for all the children controls is managed here.
  // setSpecificDay used to set all child controls on this tab.
  const setSpecificDay = useCallback((day_number) => {
    setViewDataDay(day_number);
  }, []);

  useEffect(() => {
    // console.log(
    //   "useEffect running on tabRptBar - change in simualation_id or isRefeshNeeded"
    // );
    setViewDataDay(0);
    // console.log("useEffect 01");
    getReportDataSummary(
      simulation_id,
      1,
      (simData) => {
        // console.log("useEffect 02");
        setSummaryData(simData);
        // console.log("simData", simData);
        if (simData != null && simData.model_start_dtg != null) {
          setShowReportSegments(simData.model_start_dtg.length !== 0);
          setStartDate(simData.model_start_dtg);
        } else {
          setShowReportSegments(false);
          setStartDate(Date.UTC(1980, 1, 1));
        }
      },
      (error) => {
        console.log("simDataSummary Error: ", error);
      }
    );
  }, [simulation_id, isRefreshNeeded]);

  return (
    <div>
      {showReportSegments && (
        <>
          <div>
            <ul style={{ paddingTop: "15px" }} className="nav">
              <li
                className={activeTab === "tab1" ? "active" : ""}
                onClick={() => {
                  setActiveTab("tab1");
                }}
              >
                <div className="checkboxContainerLeft">PTL Results</div>
              </li>
              <li
                className={activeTab === "tab2" ? "active" : ""}
                onClick={() => {
                  setActiveTab("tab2");
                }}
              >
                <div
                  className="checkboxContainerLeft"
                  style={{ textAlign: "left" }}
                >
                  PA/FTE Results
                </div>
              </li>
              <li
                className={activeTab === "tab3" ? "active" : ""}
                onClick={() => {
                  setActiveTab("tab3");
                }}
              >
                <div
                  className="checkboxContainerLeft"
                  style={{ textAlign: "left" }}
                >
                  Summary Data
                </div>
              </li>
            </ul>
            <div
              style={{ paddingTop: "15px" }}
              className={activeTab === "tab1" ? "" : "hiddenControl"}
            >
              <div
                className={`${style.flexRowTop}`}
                style={{ marginBottom: "35px" }}
              >
                <div style={{ flex: "1", marginRight: "30px" }}>
                  <div style={{ height: "10px" }}></div>
                  <div>
                    <br />
                    <CtrlAudioWrapper
                      simulation_id={simulation_id}
                      viewDay={viewDataDay}
                      setDay={setSpecificDay}
                      summaryData={localSummaryData}
                    />
                    <div style={{ height: "35px" }}></div>
                    <RptModelResults
                      simulation_id={simulation_id}
                      viewDay={viewDataDay}
                      show_ailments={ailmentShow}
                      setTabDayNumber={setSpecificDay}
                      summaryData={localSummaryData}
                      seriesHidden={seriesHidden}
                      setSeriesHidden={setSeriesHidden}
                    />
                  </div>
                </div>

                <div
                  style={{
                    width: "calc(50% - 10px",
                    verticalAlign: "top",
                    marginTop: "0px",
                  }}
                >
                  <div style={{ verticalAlign: "top" }}>
                    <div style={{ height: "47px" }}></div>
                    <div style={{ fontWeight: "bold", height: "48px" }}>
                      <span style={{ fontWeight: "bold" }}>Total Patients</span>
                    </div>
                    <div style={{ height: "22px" }}></div>
                    <RptPatientTotal
                      simulation_id={simulation_id}
                      viewDay={viewDataDay}
                      show_ailments={ailmentShow}
                      setTabDayNumber={setSpecificDay}
                      summaryData={localSummaryData}
                      seriesHidden={seriesHidden}
                      setSeriesHidden={setSeriesHidden}
                      startDate={startDate}
                    />
                  </div>
                </div>
              </div>

              <div
                className={`${style.flexRow} ${style.fullWidth}`}
                style={{ marginBottom: "35px" }}
              >
                <div style={{ width: "calc(50% - 10px)" }}>
                  <div style={{ height: "22px" }}></div>
                  <div>
                    <div style={{ fontWeight: "bold", height: "48px" }}>
                      <span style={{ fontWeight: "bold" }}>
                        Average Wait Times
                      </span>
                    </div>
                    <div style={{ height: "22px" }}></div>
                    <RptPatientAverage
                      simulation_id={simulation_id}
                      viewDay={viewDataDay}
                      show_ailments={ailmentShow}
                      setTabDayNumber={setSpecificDay}
                      summaryData={localSummaryData}
                      seriesHidden={seriesHidden}
                      setSeriesHidden={setSeriesHidden}
                      startDate={startDate}
                    />
                  </div>
                </div>

                <div
                  className={style.borderedBlock}
                  // style={{ width: "calc(50% - 10px)" }}
                >
                  <div>
                    <div style={{ heigh: "48px", fontWeight: "bold" }}>
                      Waiting Time Targets
                    </div>
                    <div style={{ height: "22px" }}></div>
                    <ErrorBoundary>
                      <RptModelSummary
                        simulation_id={simulation_id}
                        set_day_number={setSpecificDay}
                        show_ailments={setAilmentShow}
                        summaryData={localSummaryData}
                        seriesHidden={seriesHidden}
                        setSeriesHidden={setSeriesHidden}
                      />
                    </ErrorBoundary>
                  </div>
                </div>
              </div>
              <div
                style={{ justifyContent: "flex-end" }}
                className={`${style.flexRow} ${style.fullWidth}`}
              >
                <ExportCsv simulation_id={simulation_id} />
              </div>
            </div>
            <div
              style={{ paddingTop: "15px" }}
              className={activeTab === "tab2" ? "" : "hiddenControl"}
            >
              <TabRptBarPA tabNo={tabNo} simulation_id={simulation_id} />
            </div>
            <div
              style={{ paddingTop: "15px" }}
              className={activeTab === "tab3" ? "" : "hiddenControl"}
            >
              <TabRptBarSum tabNo={tabNo} simulation_id={simulation_id} />
            </div>
          </div>

          <br />

          <br />

          {isDirty && isDirty === true && (
            <div
              key={0}
              className="alert alert-success"
              style={{ display: "inline-block" }}
            >
              The model has been changed since the simulation was run so these
              results may not be current.
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default TabRptBar;
