import React, { useState, useContext, useCallback } from "react";
import { runModel } from "../../../api/ApiPtlCalls";
import { Model01SimSettingsContext } from "../Model01";
import { secondsToHms } from "../../HelperFuctions";
import { Model01RptRefreshContext } from "../Model01";
import StatusOfProcess from "../../progress/StatusOfProcess";
import progress from "../../../scss/style.module.scss";

const RunSim = ({ ctrlValid, ctrlNumber }) => {
  const [isRefreshNeeded, refreshNeeded] = useContext(Model01RptRefreshContext); // eslint-disable-line no-unused-vars
  const [data, writeSimSettings] = useContext(Model01SimSettingsContext); // eslint-disable-line no-unused-vars
  const [messages, setMessages] = useState([]);
  const [errors, setErrors] = useState([]);
  const [isModelRunning, setIsModelRunning] = useState(false);

  const clickRun = useCallback(() => {
    const runSim = async () => {
      var startingDateTime = Date.now();
      console.log("startingDateTime ", startingDateTime);

      setIsModelRunning(true);
      setMessages(["The model is being run. Please wait."]);

      await runModel(
        data.id,
        (result) => {
          // Success
          if (result) {
            setIsModelRunning(false);
            const timeOfModel = (Date.now() - startingDateTime) / 1000; // Seconds
            setMessages([
              "Completed the simulation. (" + secondsToHms(timeOfModel) + ")",
            ]);
            console.log("About to post refreshNeeded in context");
            refreshNeeded();
            console.log("Post refreshNeeded in context");
          } else {
            setErrors([
              "There has been a problem running the model. Please try again.",
            ]);
          }
        },
        (error) => {
          // Failure
          setIsModelRunning(false);
          alert("Error: " + JSON.stringify(error, null, 2));
          setErrors([error]);
        }
      );
    };

    runSim();
  }, [data.id, refreshNeeded]);

  return (
    <div>
      <div>
        {!isModelRunning ? (
          <button
            className="btn btn-outline-primary d-none"
            onClick={() => {
              clickRun();
            }}
          >
            Run Simulation
          </button>
        ) : (
          <div className={progress.progressSpinner}>
            <StatusOfProcess sim_guid={data.id} isActive={true} />
          </div>
        )}
        {messages &&
          messages.map((msg, index) => (
            <div key={index} className="alert alert-success">
              {msg}
            </div>
          ))}
        {errors &&
          errors.map((msg, index) => (
            <div key={index} className="alert alert-danger">
              {msg}
            </div>
          ))}
      </div>
    </div>
  );
};

export default RunSim;
