import React from "react";
import SSButton from "../../SSButton";
import { getReportDataCsv } from "../../../api/ApiPtlCalls";

const ExportCsv = ({ simulation_id, ...props }) => {
  //Then add to your onClick
  const ExportCSVClick = () => {
    getReportDataCsv(
      simulation_id,
      1,
      (simData) => {
        // Admin the csv data
        // https://stackoverflow.com/questions/50694881/how-to-download-file-in-react-js

        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([simData]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `export.csv`);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);

        // Fixes "webkit blob resource error 1"
        setTimeout(function () {
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        }, 200);
      },
      (error) => {
        console.log("simData csv Error: ", error);
      }
    );
  };

  return (
    <>
      <SSButton {...props} text="Export CSV" onClick={ExportCSVClick} />
    </>
  );
};

export default ExportCsv;
