import React, { useContext, useState } from "react";
import SessionDemandGrid from "./SessionDemandGrid";
import TheatreTypeGrid from "./TheatreTypeGrid";
import Settings from "./Settings";
import { ModalSimInputContext } from "../Model01Tabs";
import styles from "../../../scss/style.module.scss";

const TabActivity = ({ tabNo }) => {
  const validateTab = useContext(ModalSimInputContext);
  const [ctlValidation, setCtlValidation] = useState([false, false, false]);

  const ctlValid = (controlNumber, value) => {
    ctlValidation[controlNumber] = value;
    setCtlValidation(ctlValidation);
    validateTab(
      tabNo,
      ctlValidation.every((element) => element === true)
    );
  };

  return (
    <>
      <div className={styles.flexColumn}>
        <div>
          <h3 style={{ marginBottom: "25px" }}>General Theatre Settings</h3>
          <Settings
            ctrlValid={ctlValid}
            ctrlNumber="0"
            displayType="activity"
          />
        </div>
        <div style={{ marginBottom: "35px" }}>
          <TheatreTypeGrid
            ctrlValid={ctlValid}
            ctrlNumber="1"
            displayType="main"
          />
        </div>
        <div>
          <SessionDemandGrid ctrlValid={ctlValid} ctrlNumber="2" />
        </div>
      </div>
    </>
  );
};

export default TabActivity;
