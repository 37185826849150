import React from "react";
import Button from "react-bootstrap/Button";
import PropTypes from "prop-types";

const SSButton = ({ text, onClick, ...props }) => {
  return (
    <Button {...props} onClick={onClick}>
      {text}
    </Button>
  );
};

SSButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  newPath: PropTypes.string,
};

export default SSButton;
