import React, { useRef } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { putUser, postUser } from "../../api/ApiCoreCalls";
import TextField from "../TextField";
import CheckBoxMD from "../CheckBoxMD";
import { emailUserForPassword } from "../../common/email";
import styles from "../../scss/style.module.scss";
import SelectField from "../FormikSeletField";
import moment from "moment";

const User = ({ data, dataUpdated, isNew, companyId }) => {
  // const [dsCompany, setDsCompany] = useContext(Model01SimAilmentsContext); // eslint-disable-line no-unused-vars
  const formRef = useRef(null);

  const frmSchema = Yup.object({
    UserName: Yup.string()
      .max(50, "Must be 50 chars or less")
      .required("Required")
      .test("no-spaces", "Spaces are not allowed", (value) => {
        if (value) {
          return !/\s/.test(value); // Check if there's no space in the value
        }
      }),
    EMail: Yup.string()
      .email()
      .max(255, "Must be 255 chars or less")
      .required("Required"),
    Telephone: Yup.string()
      .max(50, "Must be 50 chars or less")
      .required("Required"),

    IsShowTips: Yup.boolean(),

    Enabled: Yup.boolean(),

    SecurityType: Yup.number().required("Required"),

    PtlEnabled: Yup.boolean(),
    PtlTrialExpiry: Yup.date().when("PtlEnabled", {
      is: true,
      then: Yup.date().required("Please specify a PTL expiry date"),
      otherwise: Yup.date().nullable(),
    }),

    AeEnabled: Yup.boolean(),
    AeTrialExpiry: Yup.date().when("AeEnabled", {
      is: true,
      then: Yup.date().required("Please specify an A & E expiry date"),
      otherwise: Yup.date().nullable(),
    }),

    ConsultEnabled: Yup.boolean(),
    ConsultTrialExpiry: Yup.date().when("ConsultEnabled", {
      is: true,
      then: Yup.date().required(
        "Please specify an Consutlant Analysis expiry date"
      ),
      otherwise: Yup.date().nullable(),
    }),
  });

  const SendEmailForPassword = (userId, raiseSubmit) => {
    if (userId != null) {
      // Submit and check for errors - if no error then go for it.
      if (formRef.current) {
        if (raiseSubmit) {
          formRef.current.handleSubmit();
        }
        if (formRef.current.isValid) {
          emailUserForPassword(userId);
          alert("Sent message");
        }
      }
    }
  };

  const SaveFormData = async (values) => {
    // Assemble the data
    var myDataCheck = { ...data, ...values };

    if (typeof myDataCheck.SecurityType === "string") {
      myDataCheck.SecurityType = parseInt(myDataCheck.SecurityType);
    }
    if (myDataCheck.PtlTrialExpiry === "") myDataCheck.PtlTrialExpiry = null;
    if (myDataCheck.AeTrialExpiry === "") myDataCheck.AeTrialExpiry = null;
    if (myDataCheck.ConsultTrialExpiry === "")
      myDataCheck.ConsultTrialExpiry = null;

    // Put it to the server
    if (!isNew) {
      await putUser(
        myDataCheck,
        (result) => {
          // Success
          if (result) {
            // myDataCheck.id = result.id;
            dataUpdated(myDataCheck);
            // dataUpdated(result);
          } else {
            alert(
              "There has been a problem updating the data - the api did not respond correctly."
            );
          }
        },
        (error) => {
          // Failure
          alert("Error: " + error);
        }
      );
    } else {
      // We need to remove the id from the call.
      delete myDataCheck.id;
      await postUser(
        myDataCheck,
        (result) => {
          // Success
          dataUpdated(result);
          SendEmailForPassword(result.id, false);
        },
        (error) => {
          // Failure
          alert("Error: " + error);
        }
      );
    }
  };

  return (
    <>
      <Formik
        innerRef={formRef}
        enableReinitialize
        initialValues={{
          id: data["id"] || "",
          FkCompanyId: data["FkCompanyId"] || companyId,
          UserName: data["UserName"],
          EMail: data["EMail"],
          Telephone: data["Telephone"] || "",
          // Password: "",
          SecurityType: data["SecurityType"] || 0,
          IsShowTips: data["IsShowTips"] || false,
          Enabled: data["Enabled"] || false,

          PtlEnabled: data["PtlEnabled"] || false,
          PtlTrialExpiry:
            data["PtlTrialExpiry"] &&
            moment(data["PtlTrialExpiry"]).format("YYYY-MM-DD"),

          AeEnabled: data["AeEnabled"] || false,
          AeTrialExpiry:
            data["AeTrialExpiry"] &&
            moment(data["AeTrialExpiry"]).format("YYYY-MM-DD"),

          ConsultEnabled: data["ConsultEnabled"] || false,
          ConsultTrialExpiry:
            data["ConsultTrialExpiry"] &&
            moment(data["ConsultTrialExpiry"]).format("YYYY-MM-DD"),
        }}
        validationSchema={frmSchema}
        onSubmit={async (values, { resetForm }) => {
          SaveFormData(values);
          // resetForm({ values: "" });
          //alert(JSON.stringify(values, null, 2));
        }}
      >
        {({ values, setFieldValue }) => (
          <div>
            <Form id="frmAcuity">
              <SelectField name="SecurityType">
                <option value={0} label="Select the user type">
                  Select a type{" "}
                </option>
                <option value={1} label={`Standard`}></option>
                <option value={2} label={`Sales`}></option>
                <option value={4} label={`Support`}></option>
              </SelectField>

              <TextField
                label="User Login Name"
                name="UserName"
                type="text"
                maxLength="50"
              />
              <TextField
                label="EMail"
                name="EMail"
                type="text"
                maxLength="255"
              />
              <TextField
                label="Telephone"
                name="Telephone"
                type="text"
                maxLength="50"
              />

              <CheckBoxMD
                label=" Is ShowTips"
                name="IsShowTips"
                type="checkbox"
                className="form-control form-check-input"
              />

              <CheckBoxMD
                label=" Enabled"
                name="Enabled"
                type="checkbox"
                className="form-control form-check-input"
              />

              <h4>Patient Treatment List</h4>
              <CheckBoxMD
                label=" Full PTL"
                name="PtlEnabled"
                type="checkbox"
                className="form-control form-check-input form-control-checkbox"
                onChangeExt={(e) => {
                  if (e.target.checked) {
                    // If Trial date is not set then set it to two weeks
                    if (
                      values.PtlTrialExpiry === undefined ||
                      values.PtlTrialExpiry === "" ||
                      values.PtlTrialExpiry === null
                    ) {
                      const newValue = moment()
                        .add(2, "weeks")
                        .format("YYYY-MM-DD");
                      setFieldValue("PtlTrialExpiry", newValue);
                    }
                  }
                }}
              />
              <TextField
                label="PTL Expiry"
                name="PtlTrialExpiry"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
              />

              <h4>A & E</h4>
              <CheckBoxMD
                label=" Full AE"
                name="AeEnabled"
                type="checkbox"
                className="form-control form-check-input form-control-checkbox"
                onChangeExt={(e) => {
                  if (e.target.checked) {
                    // If Trial date is not set then set it to two weeks
                    if (
                      values.AeTrialExpiry === undefined ||
                      values.AeTrialExpiry === "" ||
                      values.AeTrialExpiry === null
                    ) {
                      const newValue = moment()
                        .add(2, "weeks")
                        .format("YYYY-MM-DD");
                      setFieldValue("AeTrialExpiry", newValue);
                    }
                  }
                }}
              />
              <TextField
                label="AE Expiry"
                name="AeTrialExpiry"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
              />

              <h4>Consultant Analysis</h4>
              <CheckBoxMD
                label=" Full Consultant Analysis"
                name="ConsultEnabled"
                type="checkbox"
                className="form-control form-check-input form-control-checkbox"
                onChangeExt={(e) => {
                  if (e.target.checked) {
                    // If Trial date is not set then set it to two weeks
                    if (
                      values.ConsultTrialExpiry === undefined ||
                      values.ConsultTrialExpiry === "" ||
                      values.ConsultTrialExpiry === null
                    ) {
                      const newValue = moment()
                        .add(2, "weeks")
                        .format("YYYY-MM-DD");
                      setFieldValue("ConsultTrialExpiry", newValue);
                    }
                  }
                }}
              />
              <TextField
                label="Analysis Expiry"
                name="ConsultTrialExpiry"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
              />

              {!isNew && (
                <button
                  className="btn btn-outline-primary submit"
                  onClick={() => {
                    SendEmailForPassword(data.id, true);
                  }}
                  form="frmAcuity"
                >
                  Send email link to create password
                </button>
              )}
            </Form>
          </div>
        )}
      </Formik>
      <div className={styles.buttonContainer}>
        <button className="btn btn-outline-primary submit" form="frmAcuity">
          Submit
        </button>
      </div>
    </>
  );
};

export default User;
