import React, { useContext, useState } from "react";
import SSButton from "../../SSButton";
import { runModelCelery, runModelTaskStatus } from "../../../api/ApiFnCalls";
import { Model01PARptRefreshContext, Model01IsLocked } from "../Model01";
import { useInterval } from "../../../common/timers";
import { Spinner } from "react-bootstrap";
import progress from "../../progress/progress.module.scss";

const RptPaRefreh = ({ simulation_id, ...props }) => {
  // eslint-disable-next-line no-unused-vars
  const [isRefreshNeeded, refreshNeeded] = useContext(
    Model01PARptRefreshContext
  );
  const [isLocked] = useContext(Model01IsLocked);
  const [taskId, setTaskId] = useState();
  const [isManPolling, setIsManPolling] = useState(false);

  useInterval(
    async () => {
      // Check the status of the task
      console.log("Timed RptPaRefresh called");
      task_status(taskId);
    },
    isManPolling ? 2000 : null
  );

  const task_status = (task_id) => {
    runModelTaskStatus(
      task_id,
      (response) => {
        // PENDING
        // SUCCESS
        // REVOKED
        // None
        if (response === "SUCCESS" || response === "REVOKED") {
          setIsManPolling(false);
          console.log("Made it to the success");
          refreshNeeded();
        }
      },
      () => {}
    );
  };

  const RefreshPaTab = () => {
    // Adding two seconds here to ensure the form variables are saved correctly
    setTimeout(function () {
      runModelCelery(
        simulation_id,
        1,
        15,
        (response) => {
          // Response: {"Result": "Added", "task_id": "d0dfce6a-1cc4-4a96-9c00-a7fb5e621ba3" }
          setTaskId(response.task_id);
          // Pause for 1 seconds to ensure backend cached flushed
          setTimeout(function () {
            setIsManPolling(true);
          }, 1000);
        },
        (error) => {
          console.log("simData PAs Error: ", error);
        }
      );
    }, 2000);
  };

  return (
    <div
      style={{ display: "flex", alignItems: "center" }}
      className={`${isLocked ? "d-none" : ""}`}
    >
      <SSButton
        {...props}
        text="Update"
        onClick={RefreshPaTab}
        style={{ width: "100px" }}
      />
      {isManPolling && (
        <div style={{ marginLeft: "10px" }}>
          <Spinner
            as="span"
            animation="border"
            size="md"
            role="status"
            aria-hidden="true"
            className={progress.spinner}
          />
        </div>
      )}
    </div>
  );
};
export default RptPaRefreh;
