import React, { useState, useEffect, useContext } from "react";
import Table from "react-bootstrap/Table";
import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import styles from "../../../../scss/style.module.scss";
import table from "../../../../table.module.scss";
import {
  getExamTypesAdditionsFull,
  posttExamTypesAdditional,
} from "../../../../api/ApiConsultCalls";
// import NewExamType from "./NewExamType";

import { Button } from "react-bootstrap";
import AddNewSearch from "../../../AddNewSearch";
import { ConsultSetRefreshChildrenContext } from "../../Consult";

/// This for is a popup that lists all exam types with their changes that effect the analysis.
/// It provides the link to edit and change specifics

const AdditionalTable = ({ fk_analysis_id }) => {
  const [dsAppended, setDsAppended] = useState([]);
  const [searchText, setSearchText] = useState("");

  const [editedRows, setEditedRows] = useState({}); // Keep track of edited rows

  // Grab the function for if there is a ptl upload
  const refreshChildren = useContext(ConsultSetRefreshChildrenContext);

  const isRowInEditMode = (index) => !!editedRows[index];

  const handleEditClick = (index) => {
    // Set the row in edit mode
    setEditedRows((prevEditedRows) => ({
      ...prevEditedRows,
      [index]: true,
    }));
  };

  const handleInputChange = (event, index, field) => {
    // Update the edited value for the specific row and field
    const { value } = event.target;
    setEditedRows((prevEditedRows) => ({
      ...prevEditedRows,
      [index]: {
        ...(prevEditedRows[index] || {}), // Preserve previously edited values for the row
        [field]: value,
      },
    }));
  };

  const handleSaveClick = (index) => {
    // Perform the necessary update logic here
    // For example, if you have an array of data and you want to update a specific item:
    const updatedData = [...dsAppended];
    updatedData[index] = {
      ...updatedData[index],
      ...(editedRows[index] || {}), // Update with edited values if available
    };

    // The updated object is
    var dataToSend = updatedData[index];
    if (dataToSend.fk_analysis_id == null) {
      dataToSend.fk_analysis_id = fk_analysis_id;
    }
    posttExamTypesAdditional(
      dataToSend,
      (data) => {
        // Here are know the id
        updatedData[index] = {
          ...updatedData[index],
          ...({ id: data.id } || {}), // Update with edited values if available
        };
        // data.id;

        // Update your state with the updated data
        setDsAppended(updatedData);

        // dataToSend.fk_company_id, set within API

        // Make sure the children are updated.
        refreshChildren();

        // Remove the row from edit mode
        setEditedRows((prevEditedRows) => {
          const { [index]: _, ...rest } = prevEditedRows;
          return rest;
        });
      },
      () => {}
    );
  };

  const handleCancelClick = (index) => {
    // Revert the row to non-edit mode
    setEditedRows((prevEditedRows) => {
      const { [index]: _, ...rest } = prevEditedRows;
      return rest;
    });
  };

  useEffect(() => {
    // Fetch the JobPlan details.
    if (fk_analysis_id !== undefined) {
      getExamTypesAdditionsFull(
        fk_analysis_id,
        (data) => {
          if (data && data.length > 0) {
            // Append the info.
            setDsAppended(data);
          } else {
            setDsAppended(data);
          }
        },
        (error) => {}
      );
    }
  }, [fk_analysis_id]);

  const SearchChanged = async (text) => {
    setSearchText(text);
  };

  function mySeachFilter(comp) {
    if (comp && comp.nicip_desc) {
      return (
        comp.nicip_desc.toUpperCase().indexOf(searchText.toUpperCase()) > -1
      );
    } else return false;
  }

  /*
{
    "nicip_code": "CABPE",
    "nicip_desc": "CT Abdomen and pelvis",
    "original_duration": 20,
    "id": "a2adc53d-0565-4c96-a34e-714b63ebf382",
    "fk_company_id": "b4db1da9-5ea7-43fe-a80b-93ad5e1fa03c",
    "fk_analysis_id": "a9a77e3f-61db-4679-bdb1-028e4cb44065",
    "duration_company": 30,
    "duration_analysis": 25
}
*/

  const inputStyle = { width: "80px" };

  return (
    <div>
      <div>
        <AddNewSearch SetSearchText={SearchChanged} />
        <TableContainer>
          <Table className={table.dashboard} hover="true">
            <TableHead>
              <TableRow className={styles.borderedHeadRow}>
                <TableCell>nicip_code</TableCell>
                <TableCell>nicip_desc</TableCell>
                <TableCell>orignal duration</TableCell>
                {/* <TableCell>trust duration</TableCell> */}
                <TableCell>analysis duration</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dsAppended &&
                dsAppended.length > 0 &&
                dsAppended.filter(mySeachFilter).map((sim, index) => (
                  <TableRow key={sim.nicip_code}>
                    <TableCell>{sim.nicip_code}</TableCell>
                    <TableCell>{sim.nicip_desc}</TableCell>
                    <TableCell>{sim.original_duration}</TableCell>
                    {/* <TableCell>
                      {isRowInEditMode(index) ? (
                        <input
                          style={inputStyle}
                          value={
                            editedRows[index]?.duration_company ||
                            sim.duration_company
                          } // Use edited data if available, else use original data
                          onChange={(event) =>
                            handleInputChange(event, index, "duration_company")
                          }
                        />
                      ) : (
                        sim.duration_company
                      )}
                    </TableCell> */}
                    <TableCell>
                      {isRowInEditMode(index) ? (
                        <input
                          style={inputStyle}
                          value={
                            editedRows[index]?.duration_analysis ||
                            sim.duration_analysis
                          } // Use edited data if available, else use original data
                          onChange={(event) =>
                            handleInputChange(event, index, "duration_analysis")
                          }
                        />
                      ) : (
                        sim.duration_analysis
                      )}
                    </TableCell>
                    <TableCell>
                      {isRowInEditMode(index) ? (
                        <>
                          <Button onClick={() => handleSaveClick(index)}>
                            Save
                          </Button>
                          <Button onClick={() => handleCancelClick(index)}>
                            Cancel
                          </Button>
                        </>
                      ) : (
                        <Button onClick={() => handleEditClick(index)}>
                          Edit
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default AdditionalTable;
