import React from "react";
import { ErrorMessage, useField } from "formik";
import { TextField as MdTextField } from "@mui/material";
// Nice tutorial from:
// https://www.youtube.com/watch?v=u-CCnDayNJw

// For all functions look at HTML input info:
// https://www.w3schools.com/tags/tag_input.asp

const TextFieldFormat = ({ label, image, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div>
      {image}
      <MdTextField
        autoComplete="off"
        className={`form-control shadow-none  
        ${meta.touched && meta.error && " is-invalid "} 
        ${props.type && props.type === "date" && " form-control-date "}
        ${props.type && props.type === "number" && " form-control-number "}
        `}
        style={{
          paddingRight: meta.touched && meta.error ? "0" : "",
          backgroundColor: "transparent",
        }}
        {...field}
        {...props}
        variant="standard"
        label={label}
      />

      <ErrorMessage component="div" name={field.name} className="error" />
    </div>
  );
};

export default TextFieldFormat;
