import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";

/*  Example data summary:

{
    "summary": [
        {
            "ailment_type": "Cancer",
            "patients_per_run": 576,
            "patient_avg": 59.923,
            "total_sessions": 221,
            "period_days": 365,
            "patients_per_day": 1.578,
            "sessions_per_week": 4.238
        },
        {
            "ailment_type": "Benign",
            "patients_per_run": 1126,
            "patient_avg": 61.363,
            "total_sessions": 544,
            "period_days": 365,
            "patients_per_day": 3.085,
            "sessions_per_week": 10.433
        }
    ]
}


*/

const RptSummaryGrid = ({ summaryData }) => {
  const [visibleData, setVisibleData] = useState(summaryData);
  const sortStringType = (a, b) => {
    return String(a.ailment_type).localeCompare(b.ailment_type);
  };

  useEffect(() => {
    const filterData = () => {
      setVisibleData(summaryData.summary);
    };
    summaryData && summaryData.summary && filterData();
  }, [summaryData]);

  return (
    <>
      <Table borderless className="tableRpt bottom-margin">
        <thead>
          <tr>
            <th>Patient Group</th>
            <th>Daily patients</th>
            <th>Daily treated patients</th>
            <th>Av. Treat Time</th>
            {/* <th>Weekly theatre sessions</th> */}
          </tr>
        </thead>
        <tbody>
          {visibleData &&
            visibleData.length > 0 &&
            visibleData
              .filter((r) => r.ailment_type !== "_Emergency")
              .sort(sortStringType)
              .map((sim) => (
                <tr key={sim.ailment_type}>
                  <td style={{ color: "black" }}>{sim.ailment_type}</td>
                  <td style={{ color: "black" }}>{sim.patients_per_day}</td>
                  <td style={{ color: "black" }}>
                    {sim.treated_patients_per_day}
                  </td>
                  <td style={{ color: "black" }}>{sim.patient_avg}</td>
                  {/* <td style={{color: "black"}}>{sim.sessions_per_week}</td> */}
                </tr>
              ))}
        </tbody>
      </Table>
    </>
  );
};

export default RptSummaryGrid;
