import React, { useContext, useRef } from "react";
import {
  Model01SimAilmentsContext,
  Model01SimAcuityContext,
  Model01SimTheatreLinkContext,
  Model01SimTheatreContext,
} from "../Model01";
import {
  validateAilment,
  validateAcuity,
  validateAilmentAllocation,
  validateAilmentMeanDuration,
  validateAilmentMaxDuration,
  validateAilmentStandDevDuration,
} from "../../../common/validation";

const RunCheckData = ({ setErrors, setWarnings, setMessages }) => {
  const [dsAilments] = useContext(Model01SimAilmentsContext);
  const [dsAcuity] = useContext(Model01SimAcuityContext);
  const [dsTheatreLink] = useContext(Model01SimTheatreLinkContext);
  const [dsTheatre] = useContext(Model01SimTheatreContext);
  const localErrors = useRef([]);
  const localWarnings = useRef([]);
  const localMessages = useRef([]);

  function recordError(error) {
    localErrors.current = [...localErrors.current, error];
  }
  function recordWarning(warning) {
    localWarnings.current = [...localWarnings.current, warning];
  }
  function recordMessage(message) {
    localMessages.current = [...localMessages.current, message];
  }
  const checkData = () => {
    localErrors.current = [];
    localWarnings.current = [];
    localMessages.current = [];
    if (!validateAcuity(dsAcuity)) {
      recordError(
        "Patient Groups - The sum of likelihood for a new patient needs to add up to 100% for each ailment."
      );
    }
    if (!validateAilment(dsAilments)) {
      recordError(
        "Demand - The sum of likelihood for a new patient needs to add up to 100%."
      );
    }

    const alloResult = validateAilmentAllocation(dsAilments, dsTheatreLink);
    if (!alloResult.value) {
      recordWarning(
        "Activity - Theatre Types - These patient groups have not been linked to a theatre type: " +
          alloResult.list.join(", ")
      );
    }

    const meanResult = validateAilmentMeanDuration(
      dsAilments,
      dsTheatre,
      dsTheatreLink
    );
    if (!meanResult.result) {
      recordWarning(
        "Patient Groups - Surgical procedure times exceeds that of a linked theatre type for these procedures: " +
          meanResult.list.join(", ")
      );
    }

    const maxResult = validateAilmentMaxDuration(
      dsAilments,
      dsTheatre,
      dsTheatreLink
    );
    if (!maxResult.result) {
      recordWarning(
        "Patient Groups - The maximum duration exceeds a linked session durations for these procedures: " +
          maxResult.list.join(", ")
      );
    }

    const sdResult = validateAilmentStandDevDuration(
      dsAilments,
      dsTheatre,
      dsTheatreLink
    );
    if (!sdResult.result) {
      recordWarning(
        "Warning: 1 in 10,000 patients may have a procedure time that exceeds the maximum length of a linked session. Patients like these will cause a problem. You may want to add a maximum procedure time to these procedures: " +
          sdResult.list.join(", ")
      );
    }

    if (
      localErrors.current.length === 0 &&
      localWarnings.current.length === 0
    ) {
      recordMessage("Success");
    }
    setErrors(localErrors.current);
    setWarnings(localWarnings.current);
    setMessages(localMessages.current);
  };
  // d-none
  return (
    <button
      className="btn btn-outline-primary"
      onClick={() => {
        checkData();
      }}
    >
      Check
    </button>
  );
};

export default RunCheckData;
