import React from "react";

import { useNavigate } from "react-router-dom";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Button } from "react-bootstrap";

import TextField from "./TextField";
import { NewPassword_Admin } from "../common/login_token";

const NewPassword = ({ token }) => {
  const navigate = useNavigate();

  const validate = Yup.object({
    password: Yup.string()
      .max(20, "Must be 20 characters or less")
      .min(8, "Must be 6 chars or longer")
      .required("Required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      ),
    confirm: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords don't match")
      .required(),
  });

  const PostUserData = async (values) => {
    // Update the localStorage
    NewPassword_Admin(
      values.password,
      token,
      () => {
        // Success
        alert("Successfully updated");
        // Clear out the token - logout
        localStorage.setItem("token", "");
        navigate("/", { replace: true });
        navigate(0);
      },
      () => {
        // Failure
        alert(
          "Unfortunately the link has not worked. It expires 12 hours after its been issued. Please try again."
        );
      }
    );

    // Finally redirect
    navigate("/", { replace: true });
  };

  return (
    <div className="containerBox">
      <Formik
        initialValues={{
          password: "",
          confirm: "",
        }}
        validationSchema={validate}
        onSubmit={async (values) => {
          console.log("Submit with these values:");
          console.log(values);
          PostUserData(values);
        }}
      >
        {(formik) => (
          <div>
            <h1 className="my-4 font-weight-bold-display-4">New Password</h1>
            <Form>
              Please type in your new password:
              <TextField
                label="Password"
                name="password"
                type="password"
                maxLength="20"
                required
              />
              <TextField
                label="Confirm"
                name="confirm"
                type="password"
                maxLength="20"
                required
              />
              <Button className="btn float-end" type="submit">
                Submit
              </Button>
            </Form>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default NewPassword;
