import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { putCompany, postCompany } from "../../api/ApiCoreCalls";
import TextField from "../TextField";
import CheckBoxMD from "../CheckBoxMD";
import moment from "moment";

const Company = ({ data, dataUpdated, isNew }) => {
  // const [dsCompany, setDsCompany] = useContext(Model01SimAilmentsContext); // eslint-disable-line no-unused-vars
  const frmSchema = Yup.object({
    CompanyName: Yup.string()
      .max(50, "Must be 50 chars or less")
      .required("Required"),

    Contact: Yup.string()
      .max(50, "Must be 50 chars or less")
      .required("Required"),
    Telephone: Yup.string()
      .max(50, "Must be 50 chars or less")
      .required("Required"),
    TrustCode: Yup.string()
      .max(5, "Must be 5 chars or less")
      .required("Required"),
    AdminEMail: Yup.string()
      .max(50, "Must be 50 chars or less")
      .required("Required"),

    Expiry: Yup.date("Must be a date").nullable(),
    Enabled: Yup.boolean(),
  });

  const SaveFormData = async (values) => {
    // Assemble the data
    var myDataCheck = { ...data, ...values };

    // There can be problems with dates so ensure that if they are "" then they get replaced with null
    if (myDataCheck.Expiry === "") myDataCheck.Expiry = null;

    // Put it to the server
    if (!isNew) {
      await putCompany(
        myDataCheck,
        (result) => {
          // Success
          if (result) {
            // myDataCheck.id = result.id;
            dataUpdated(myDataCheck);
            // dataUpdated(result);
          } else {
            alert(
              "There has been a problem updating the data - the api did not respond correctly."
            );
          }
        },
        (error) => {
          // Failure
          alert("Error: " + error);
        }
      );
    } else {
      // We need to remove the id from the call.
      delete myDataCheck.id;
      await postCompany(
        myDataCheck,
        (result) => {
          // Success
          dataUpdated(result);
        },
        (error) => {
          // Failure
          alert("Error: " + error);
        }
      );
    }
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          id: data["id"] || "",
          CompanyName: data["CompanyName"],
          Contact: data["Contact"] || "",
          Telephone: data["Telephone"] || "",
          AdminEMail: data["AdminEMail"] || "",
          TrustCode: data["TrustCode"] || "",

          Expiry: data["Expiry"] && moment(data["Expiry"]).format("YYYY-MM-DD"),
          Enabled: data["Enabled"] || false,

          PtlEnabled: data["PtlEnabled"] || false,
          PtlTrial: data["PtlTrial"] || false,
          PtlTrialExpiry:
            data["PtlTrialExpiry"] &&
            moment(data["PtlTrialExpiry"]).format("YYYY-MM-DD"),

          AeEnabled: data["AeEnabled"] || false,
          AeTrial: data["AeTrial"] || false,
          AeTrialExpiry:
            data["AeTrialExpiry"] &&
            moment(data["AeTrialExpiry"]).format("YYYY-MM-DD"),
        }}
        validationSchema={frmSchema}
        onSubmit={async (values, { resetForm }) => {
          SaveFormData(values);
          // resetForm({ values: "" });
          //alert(JSON.stringify(values, null, 2));
        }}
      >
        <div>
          <Form id="frmAcuity">
            <TextField
              label="Trust"
              name="CompanyName"
              type="text"
              maxLength="50"
            />
            <TextField
              label="Contact"
              name="Contact"
              type="text"
              maxLength="50"
            />
            <TextField
              label="Telephone"
              name="Telephone"
              type="text"
              maxLength="50"
            />
            <TextField
              label="AdminEMail"
              name="AdminEMail"
              type="text"
              maxLength="50"
            />
            <TextField
              label="Trust Code"
              name="TrustCode"
              type="text"
              maxLength="5"
            />

            <TextField
              label="Expiry date - disable login"
              name="Expiry"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
            />

            <CheckBoxMD
              label=" Enabled"
              name="Enabled"
              type="checkbox"
              className="form-control form-check-input form-control-checkbox"
            />
          </Form>
        </div>
      </Formik>
      <button className="btn btn-outline-primary submit" form="frmAcuity">
        Submit
      </button>
    </>
  );
};

export default Company;
