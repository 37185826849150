import React from "react";
import UploadLeave from "./UploadLeave";
import DownloadLeaveTemplate from "./DownloadLeaveTemplate";
import LeaveTable from "./LeaveTable";
import styles from "../../../../scss/style.module.scss";

const TabLeave = ({ tabNo }) => {
  return (
    <>
      <div>
        <h3>Leave uploader</h3>
        <div className={styles.filterForm}>
          <UploadLeave />
          <DownloadLeaveTemplate />
        </div>
        <div className={styles.filterForm}></div>
        <div className={styles.form_halves}>
          <div className={styles.left_control}>
            <LeaveTable />
          </div>
        </div>
      </div>
    </>
  );
};

export default TabLeave;
