import React, { useContext, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import RunSim from "./RunSim";
import Settings from "./Settings";
import { ModalSimInputContext } from "../Model01Tabs";
import RunModelManager from "./RunModelManager";

const TabRunSim = ({
  tabNo,
  valTab0,
  valTab1,
  valTab2,
  valTab3,
  valTab4,
  setTabToResults,
}) => {
  const validateTab = useContext(ModalSimInputContext);
  const [ctlValidation, setCtlValidation] = useState([false]);
  const ctlValid = (controlNumber, value) => {
    ctlValidation[controlNumber] = value;
    setCtlValidation(ctlValidation);
    validateTab(
      tabNo,
      ctlValidation.every((element) => element === true)
    );
  };

  return (
    <>
      <Row className="justify-content-md-left">
        <Col md="2" className="row-col-box">
          <Settings ctrlValid={ctlValid} ctrlNumber="0" displayType="run" />
          <p></p>
          <RunSim />
        </Col>
        <Col md="10"></Col>
      </Row>
      <Row className="justify-content-md-left">
        <Col md="12" className="row-col-box"></Col>
      </Row>
      <Row>
        <Col md="3" className="row-col-box">
          <p></p>
          <RunModelManager runComplete={setTabToResults} />
        </Col>
      </Row>
    </>
  );
};

export default TabRunSim;
